import React from "react";
import { LoadingAnimation, theme } from "@nulogy/components";
import styled from "styled-components";

const FadedBackground = styled.div`
  background: white;
  width: 272px;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  left: calc(50% + 128px);
  bottom: 0;
  transform: translate(calc(-50% - 64px), -50%);
  box-shadow: ${theme.shadows.medium};
  z-index: 10;
  transition: opacity ease-in 500ms;
  opacity: 0;
`;

const LoadingPosition = styled.div`
  position: absolute;
  left: calc(50% + 128px);
  bottom: 16px;
  transform: translate(calc(-50% - 64px), -50%);
  height: 24px;
  z-index: 11;
  transition: opacity ease-in 500ms;
  opacity: 0;
`;

export default function ScheduleLoadingAnimation({ fetching }) {
  return (
    <>
      <FadedBackground style={{ opacity: fetching ? 1 : 0 }} />
      <LoadingPosition style={{ opacity: fetching ? 1 : 0 }}>
        <LoadingAnimation />
      </LoadingPosition>
    </>
  );
}
