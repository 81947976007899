import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function usePublishWorkBlockModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.workBlock, ...args };
    updateGlobalState({ publishWorkBlockModalState: newState });
  };

  return {
    open: (workBlock) => setState({ isOpen: true, workBlock }),
    close: () => setState({ isOpen: false }),
    isOpen: state.publishWorkBlockModalState.isOpen,
    workBlock: state.publishWorkBlockModalState.workBlock,
  };
}
