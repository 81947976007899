import React from "react";
import PackmanagerLink from "./PackmanagerLink";

export default function PackmanagerJobLink({ jobId, applicationContext, prefix }) {
  const packmanagerJobPath = `jobs/${jobId}`;

  return (
    <PackmanagerLink packmanagerPath={packmanagerJobPath} applicationContext={applicationContext}>
      {prefix}
      {jobId}
    </PackmanagerLink>
  );
}
