import { useQuery } from "@apollo/client";
import { GetApplicationContext } from "./applicationContext.gql";

export default function useApplicationContext() {
  const { data, loading, error } = useQuery(GetApplicationContext, {});
  const applicationContext = data?.applicationContext;

  const settings = applicationContext ? JSON.parse(applicationContext.settings) : null;

  return { ...applicationContext, data, loading, error, settings };
}
