import React, { useMemo } from "react";
import { TimePicker as NulogyTimePicker } from "@nulogy/components";
import { useField, useFormikContext } from "formik";

export default function TimePicker({ field: name, onChange, labelText, className }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  const handleChange = useMemo(
    () => (value) => {
      if (onChange) {
        onChange({ value, setFieldValue });
      }
      helpers.setValue(value);
    },
    [onChange, helpers, setFieldValue],
  );

  return (
    <NulogyTimePicker
      onChange={handleChange}
      onInputChange={handleChange}
      value={field.value}
      errorMessage={meta.error}
      labelText={labelText}
      className={className}
    />
  );
}
