import React, { useCallback, useMemo, useState } from "react";

const ConfirmModalContext = React.createContext({});

const DEFAULT_STATE = {
  isOpen: false,
  message: "",
  primaryButtonText: "",
  title: "",
  action: () => {},
};

export function ConfirmModalContextProvider({ children }) {
  const [state, setState] = useState(DEFAULT_STATE);
  const { action, message, primaryButtonText, isOpen, title } = state;

  const confirmAction = (theMessage, thePrimaryButtonText, theAction, theTitle = "") => {
    setState({
      isOpen: true,
      message: theMessage,
      primaryButtonText: thePrimaryButtonText,
      action: theAction,
      title: theTitle,
    });
  };

  const handleConfirm = useCallback(() => {
    action();
    setState(DEFAULT_STATE);
  }, [action, setState]);

  const handleCancel = () => setState(DEFAULT_STATE);

  const contextValue = useMemo(
    () => ({
      confirmAction,
      handleConfirm,
      handleCancel,
      message,
      isOpen,
      primaryButtonText,
      title,
    }),
    [handleConfirm, message, isOpen, primaryButtonText, title],
  );

  return <ConfirmModalContext.Provider value={contextValue}>{children}</ConfirmModalContext.Provider>;
}

export default ConfirmModalContext;
