import React from "react";
import { Box, Flex } from "@nulogy/components";
import ReadOnlyName from "./ReadOnlyName";

export default function ShiftHeader({ shiftInstance }) {
  const { maxLabor, startingLabor } = shiftInstance;

  return (
    <Flex flexDirection="column" flexWrap="wrap" justifyContent="flex-start">
      <label>
        <Box>
          <ReadOnlyName className="spec-shift-name" data-testid="shift-name">
            {shiftInstance.name}
          </ReadOnlyName>
        </Box>
        <Box className={`spec-shift-max-labor-${shiftInstance.shiftId}`}>Max labor: {maxLabor}</Box>
        <Box className={`spec-shift-starting-labor-${shiftInstance.shiftId}`}>Starting labor: {startingLabor}</Box>
      </label>
    </Flex>
  );
}
