import React from "react";
import styled from "styled-components";
import { formatDateTime } from "components/helpers/format";

const LastSyncedAtTime = styled.span`
  font-style: italic;
  padding-left: 12px;
`;

export default function LastSyncedAt({ lastSyncedAt }) {
  const dateTime = formatDateTime(lastSyncedAt);

  return <LastSyncedAtTime>{dateTime ? `Last synced at: ${dateTime}` : "Never synced"}</LastSyncedAtTime>;
}
