import React, { useContext } from "react";
import { Box, Button, ButtonGroup, PrimaryButton } from "@nulogy/components";
import Modal from "./Modal";
import ConfirmModalContext from "contexts/ConfirmModalContext";

export default function ConfirmModal() {
  const { handleConfirm, handleCancel, message, isOpen, primaryButtonText, title } = useContext(ConfirmModalContext);
  const messageIsInnerHtml = typeof message === "string";

  const footerContent = (
    <ButtonGroup>
      <PrimaryButton onClick={handleConfirm} className="spec-confirm-modal-ok">
        {primaryButtonText}
      </PrimaryButton>
      <Button onClick={handleCancel}>Cancel</Button>
    </ButtonGroup>
  );

  return (
    <Modal title={title} footerContent={footerContent} isOpen={isOpen} ariaHideApp={false}>
      <Box>
        {messageIsInnerHtml && <div dangerouslySetInnerHTML={{ __html: message }} />}
        {!messageIsInnerHtml && message}
      </Box>
    </Modal>
  );
}
