import React from "react";
import ReadOnlyWorkBlockModalView from "./ReadOnlyWorkBlockModalView";
import WorkBlock from "domain/WorkBlock";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useWorkBlockShowModal from "hooks/state/useWorkBlockShowModal";

export default function ReadOnlyWorkBlockModalContainer() {
  const { block: blockData, isOpen, close } = useWorkBlockShowModal();
  const applicationContext = useApplicationContext();

  if (!blockData) return null;

  const {
    customerSettings: { workBlockLayout },
  } = applicationContext;
  const { block } = blockData;
  const effectiveProductionRate = WorkBlock.effectiveProductionRate(block);
  const workBlockQuantity = WorkBlock.quantity(block);

  return (
    <ReadOnlyWorkBlockModalView
      isOpen={isOpen}
      blockData={blockData}
      onClose={close}
      effectiveProductionRate={effectiveProductionRate}
      productionRateLabel="Standard production rate"
      workBlockQuantity={workBlockQuantity}
      workBlockLayout={workBlockLayout}
    />
  );
}
