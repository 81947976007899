import React, { useContext } from "react";
import styled from "styled-components";
import { DropdownMenu, Flex, IconicButton, DropdownButton as NDSDropdownButton, Text, theme } from "@nulogy/components";
import { FLAG_OPTIONS, NO_FLAG } from "components/common/Scheduler/flagsConfig";
import WorkOrderFlag from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkOrderFlag";
import ConfirmModalContext from "contexts/ConfirmModalContext";

const DropdownButton = styled(NDSDropdownButton)`
  white-space: nowrap;
`;

const DeleteWorkOrderButton = styled(DropdownButton)`
  &:hover {
    color: ${theme.colors.red};
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export default function WorkOrderCardMenuOld({
  onOpenWorkOrderDetails,
  onUnscheduleWorkOrder,
  onRemoveWorkOrder,
  updateWorkOrder,
  userCanUpdate,
  workOrder,
}) {
  const { code, id: workOrderId, unschedulable } = workOrder;

  const { confirmAction } = useContext(ConfirmModalContext);

  const handleUnschedule = () => {
    const message = `This action will unschedule all of the work order’s (${code}) scheduled blocks. However, it will not delete its published jobs, if any.`;

    confirmAction(message, "Unschedule", () => onUnscheduleWorkOrder({ workOrderId }), "Unschedule work order");
  };

  const handleRemove = () => {
    const message = `This action will delete the work order (${code}) and all its scheduled blocks. However, it will not delete its published jobs, if any.`;

    confirmAction(message, "Delete", () => onRemoveWorkOrder({ workOrderId }), "Delete work order");
  };

  const handleUpdateFlag = (flag, closeMenu) => (e) => {
    updateWorkOrder({ id: workOrderId, flag });
    closeMenu(e);
  };

  function flagDropdownMenu(closeMenu) {
    return (
      <DropdownMenu
        trigger={() => (
          <DropdownButton>
            <Flex justifyContent="space-between">
              <Text>Flags</Text>
              <Text>&gt;</Text>
            </Flex>
          </DropdownButton>
        )}
        openOnHover
        placement="left-start"
        showArrow={false}
      >
        <>
          {Object.keys(FLAG_OPTIONS).map((flagOption) => (
            <DropdownButton
              key={flagOption}
              onClick={handleUpdateFlag(flagOption, closeMenu)}
              className="pendo-add-flag-work-order-card-menu-item"
            >
              <WorkOrderFlag flag={flagOption} size="large" />
            </DropdownButton>
          ))}
          <DropdownButton
            onClick={handleUpdateFlag(NO_FLAG, closeMenu)}
            className="pendo-remove-flag-work-order-card-menu-item"
          >
            Remove flag
          </DropdownButton>
        </>
      </DropdownMenu>
    );
  }

  const renderContextMenu = (closeMenu) => (
    <>
      <DropdownButton
        onClick={(e) => {
          onOpenWorkOrderDetails();
          closeMenu(e);
        }}
        className="pendo-view-work-order-details-work-order-card-menu-item"
      >
        View details and notes
      </DropdownButton>

      {userCanUpdate && (
        <>
          {flagDropdownMenu(closeMenu)}
          <DropdownButton
            className="spec-unschedule-work-order-button pendo-unschedule-work-order-work-order-card-menu-item"
            disabled={!unschedulable}
            onClick={(e) => {
              handleUnschedule();
              closeMenu(e);
            }}
          >
            Unschedule work order
          </DropdownButton>
          <DeleteWorkOrderButton
            className="pendo-delete-work-order-work-order-card-menu-item"
            onClick={(e) => {
              handleRemove();
              closeMenu(e);
            }}
          >
            Delete work order
          </DeleteWorkOrderButton>
        </>
      )}
    </>
  );

  return (
    <Menu>
      <DropdownMenu
        placement="bottom-end"
        trigger={() => <IconicButton icon="more" data-testid="context-menu" className="pendo-work-order-card-menu" />}
      >
        {({ closeMenu }) => renderContextMenu(closeMenu)}
      </DropdownMenu>
    </Menu>
  );
}
