import React from "react";
import { useQuery } from "@apollo/client";
import groupBy from "lodash/groupBy";
import SiteSwitcherView from "./SiteSwitcherView";
import { GetAssignedSites } from "hooks/apollo/site/site.gql";
import { GetApplicationContext } from "hooks/apollo/applicationContext/applicationContext.gql";

function SiteSwitcherContainer(props) {
  const {
    applicationContext: { site: currentSite },
  } = useQuery(GetApplicationContext).data;
  const { data } = useQuery(GetAssignedSites);

  const assignedSites = data?.assignedSites || [];
  const companies = groupBy(assignedSites, "company.name");

  if (assignedSites.length <= 1) {
    return null;
  }

  return <SiteSwitcherView currentSiteId={currentSite.id} companies={companies} {...props} />;
}

export default SiteSwitcherContainer;
