import moment from "moment-timezone";

// Guidelines for Time Zones:
// - All code on frontend can assume browser time zone
// - GqlProvider handles which fields must be converted to browser timezone
// - Only call `overrideToZone` when making a remote request
//

const ignoreTimeZoneOffset = moment.HTML5_FMT.DATETIME_LOCAL_SECONDS;

const TimeZone = {
  overrideToBrowserTime,
  overrideZone,
};

function overrideToBrowserTime(time) {
  if (!time) return time;

  return moment(time, ignoreTimeZoneOffset).format();
}

function overrideZone(browserTime, timeZoneName) {
  if (!browserTime) return browserTime;

  const normalizedBrowserTime = moment(browserTime).format();

  return moment.tz(normalizedBrowserTime, ignoreTimeZoneOffset, timeZoneName).format();
}

export default TimeZone;
