import React from "react";
import { useQuery } from "@apollo/client";
import { Page } from "@nulogy/components";
import useGoBack from "hooks/useGoBack";
import LayoutSettingNew from "components/pages/site/LayoutSetting";
import LayoutSettingOld from "components/pages/site/LayoutSettingOld";
import Form from "components/controls/Form";
import FormButtons from "components/controls/FormButtons";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import { GetCustomerSettings, GetLayoutSetting } from "hooks/apollo/customerSettings/customerSettings.gql";
import useUpdateCustomerSettings from "hooks/apollo/customerSettings/useUpdateCutomerSettings";
import paths from "config/routePaths";
import StickyFooter from "components/controls/StickyFooter";

export default function CustomerSettings() {
  const { goBack } = useGoBack();
  const { settings } = useApplicationContext();

  const query = settings.customizeWorkOrderCards ? GetLayoutSetting : GetCustomerSettings;
  const { data, loading } = useQuery(query);

  const LayoutSetting = settings.customizeWorkOrderCards ? LayoutSettingNew : LayoutSettingOld;

  const leavePage = () => {
    goBack(paths.home);
  };

  const updateCustomerSettings = useUpdateCustomerSettings({
    onCompleted: leavePage,
    customizeWorkOrderCards: settings.customizeWorkOrderCards,
  });
  if (loading) return null;

  const initialValues = {
    workBlockLayout: data?.customerSettings.workBlockLayout,
    workOrderCardLayout: settings.customizeWorkOrderCards
      ? data?.workOrderLayoutSetting
      : data?.customerSettings.workOrderCardLayout,
  };

  const handleSave = (layoutSetting) => updateCustomerSettings(layoutSetting);

  return (
    <Page title="Layout" className="spec-customer-settings" data-testid="title">
      <Form {...{ initialValues, onSave: handleSave }}>
        <LayoutSetting />
        <StickyFooter>
          <FormButtons onCancel={leavePage} />
        </StickyFooter>
      </Form>
    </Page>
  );
}
