import { useLocation, useNavigate } from "react-router";

const GO_BACK = -1;
const RELOAD = 0;

export default function useGoBack() {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPreviousState = location.key !== "default";

  return {
    goBack: (fallbackPath) => {
      if (hasPreviousState) {
        navigate(GO_BACK);
      } else {
        navigate(fallbackPath); // Go to fallback path
      }

      // HACK:
      // Prefer to not do a reload here but in the current version of react router (6.2.2),
      // this causes the Site page to not show the newly created site when navigating back.
      navigate(RELOAD);
    },
  };
}
