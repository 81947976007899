import styled from "styled-components";

const BlockCellMenu = styled.div`
  margin-top: 4px;
  margin-left: 8px;
  position: relative;
  left: -4px;
  cursor: pointer;
`;

export default BlockCellMenu;
