import React from "react";
import WorkBlock from "./WorkBlock";

export default function WorkBlockFromSidebar({ dragWorkOrder, dragApplicationContext }) {
  const workOrder = JSON.parse(dragWorkOrder);
  const applicationContext = JSON.parse(dragApplicationContext);

  const blockData = {
    block: {
      effectiveLabor: workOrder.standardLabor,
      workOrder,
    },
  };

  return (
    <div className="b-sch-event">
      <div className="b-sch-event-content">
        <WorkBlock blockData={blockData} applicationContext={applicationContext} />
      </div>
    </div>
  );
}
