import React from "react";
import { useField, useFormikContext } from "formik";
import { Textarea as NulogyTextarea } from "@nulogy/components";

export default function Textarea({ field: name, onChange, ...ndsProps }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    const { value } = event.target;

    if (onChange) {
      onChange({ value, setFieldValue });
    }
    helpers.setValue(value);
  };

  return (
    <NulogyTextarea name={name} value={field.value} errorMessage={meta.error} onChange={handleChange} {...ndsProps} />
  );
}
