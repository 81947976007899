import { useMutation } from "@apollo/client";
import { CreateOrUpdateCustomWorkOrderField } from "./customWorkOrderField.gql";

export default function useCreateOrUpdateCustomWorkOrderField() {
  const [updateScheduleWorkOrderFields] = useMutation(CreateOrUpdateCustomWorkOrderField);

  return ({ name, color }) =>
    updateScheduleWorkOrderFields({
      variables: {
        name,
        color,
      },
    });
}
