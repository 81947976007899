import { useState } from "react";
import axios from "axios";

const fileData = (newFile) => {
  const data = new FormData();
  data.append("file", newFile);

  return data;
};

const useFileImport = (onSuccess, onFailure) => {
  const statuses = {
    IDLE: "idle",
    RUNNING: "running",
    IMPORT_SUCCESS: "success",
    IMPORT_FAILURE: "failure",
  };
  const [status, setStatus] = useState(statuses.IDLE);
  const resetStatus = () => setStatus(statuses.IDLE);

  const importFile = (file, url) => {
    const options = {
      method: "POST",
      headers: { "content-type": "multipart/form-data" },
      data: fileData(file),
      url,
      withCredentials: true,
    };

    setStatus(statuses.RUNNING);

    axios(options)
      .then((response) => {
        if (response.data.errors && response.data.errors.length) {
          setStatus(statuses.IMPORT_FAILURE);
          onFailure(response.data.errors);
        } else {
          setStatus(statuses.IMPORT_SUCCESS);
          onSuccess(response);
        }
      })
      .catch(() => {
        setStatus(statuses.IMPORT_FAILURE);
        onFailure(["Error..."]);
      });
  };

  return [status, resetStatus, importFile];
};

export default useFileImport;
