import { useContext } from "react";
import useFetchSchedulerData from "../useFetchSchedulerData";
import useRefetchWorkOrders from "hooks/apollo/workOrder/useRefetchWorkOrders";
import { useNotificationState } from "hooks/state";
import WorkOrderSearchContext from "contexts/WorkOrderSearchContext";
import NetworkStateContext from "contexts/NetworkStateContext";
import GqlError from "utils/GqlError";

export default function useRefetchSchedule() {
  const {
    state: {
      filters: { filterByScheduleView },
    },
  } = useContext(WorkOrderSearchContext);
  const { refetch: refetchWorkOrders } = useRefetchWorkOrders();
  const { refetch: refetchSchedulerData } = useFetchSchedulerData();
  const { trackLoadingState } = useContext(NetworkStateContext);
  const { setNotification } = useNotificationState();

  return ({ dataTimeRange, visibleTimeRange }) =>
    trackLoadingState("GET_BLOCKS", () => {
      const promise = refetchSchedulerData({ dataTimeRange });

      if (filterByScheduleView) refetchWorkOrders({ visibleTimeRange });

      promise.catch((error) => {
        GqlError.handler(error, () => {
          setNotification({
            title: "Unable to load schedule. Please try again.",
            message: "",
            type: "danger",
          });
        });
      });

      return promise;
    });
}
