import React from "react";
import { ButtonGroup, Modal, PrimaryButton, Text } from "@nulogy/components";
import styled from "styled-components";
import Form from "../../controls/Form";
import DateRange from "../../controls/DateRange";
import Checkbox from "../../controls/Checkbox";
import ModalFooter from "../../common/ModalFooter";

// This is a hack to fix an NDS bug which moved the "-" in DateRange to the wrong location

const DateRangeWrapper = styled.div`
  div {
    max-height: none;
  }
`;
export default function ExportModal({ isExportScheduleModalOpen, setExportScheduleModalOpen, onExport }) {
  const defaultFromDate = new Date();
  defaultFromDate.setHours(0, 0, 0, 0);

  return (
    <Modal
      title="Export schedule"
      isOpen={isExportScheduleModalOpen}
      onRequestClose={() => {
        setExportScheduleModalOpen(false);
      }}
      ariaHideApp={false}
    >
      <Form
        id="exportSchedule"
        initialValues={{ dateRange: { startDate: defaultFromDate, endDate: null }, includeUnavailability: false }}
        onSave={onExport}
      >
        {({ errors }) => (
          <>
            <DateRangeWrapper>
              <DateRange
                field="dateRange"
                defaultStartDate={defaultFromDate}
                startDateInputProps={{
                  dateFormat: "MMM d, yyyy",
                  required: true,
                  labelText: <Text>From</Text>,
                  name: "fromDate",
                }}
                endDateInputProps={{
                  dateFormat: "MMM d, yyyy",
                  labelText: (
                    <Text>
                      To{" "}
                      <Text fontWeight="normal" inline>
                        (optional)
                      </Text>
                    </Text>
                  ),
                  name: "toDate",
                }}
              />
            </DateRangeWrapper>
            <Checkbox
              field="includeUnavailability"
              name="includeUnavailability"
              labelText="Include unavailable shifts"
              pb="x2"
            />
            <ModalFooter>
              <ButtonGroup>
                <PrimaryButton
                  form="exportSchedule"
                  type="submit"
                  mr="x1"
                  className="pendo-export-button"
                  disabled={errors.dateRange}
                >
                  Export
                </PrimaryButton>
              </ButtonGroup>
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
}
