import React from "react";
import { IconicButton, theme } from "@nulogy/components";
import { maxZoomLevel } from "config/zoomConfig";

export default function ZoomLevelSelector({ disabled, onZoomLevelChange, zoomLevel }) {
  const minZoom = 0;

  return (
    <>
      <IconicButton
        icon=""
        onClick={() => onZoomLevelChange(zoomLevel + 1)}
        disabled={zoomLevel === maxZoomLevel || disabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={theme.colors.darkBlue}
          style={{ marginLeft: "-28px" }}
        >
          <path d="M200-440v-80h560v80H200Z" />
        </svg>
      </IconicButton>

      <IconicButton
        icon="add"
        onClick={() => onZoomLevelChange(zoomLevel - 1)}
        disabled={zoomLevel === minZoom || disabled}
      />
    </>
  );
}
