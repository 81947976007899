import React from "react";
import styled from "styled-components";
import { Box, Text } from "@nulogy/components";

export default function Field({ children, labelText, defaultValue = "-", ...boxProps }) {
  return (
    <Box marginBottom="x2" {...boxProps}>
      {labelText && <Label>{labelText}</Label>}
      <FieldValue>{children || defaultValue}</FieldValue>
    </Box>
  );
}

function Label({ children }) {
  return (
    <Text className="field-label" fontSize="small" fontWeight="bold">
      {children}
    </Text>
  );
}

const Value = styled(Text)`
  word-wrap: break-word;
`;

function FieldValue({ children }) {
  return <Value className="field-value">{children}</Value>;
}
