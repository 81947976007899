import get from "lodash/get";

export default class LocalStorage {
  constructor(serializer = DefaultSeriazlier) {
    this.serializer = serializer;
  }

  write(key, rawData) {
    localStorage.setItem(key, this.serializer.serialize(rawData));
  }

  read(key) {
    const serializedData = localStorage.getItem(key);

    return serializedData ? this.serializer.deserialize(serializedData) : {};
  }
}

export class DefaultSeriazlier {
  static serialize(rawData) {
    return JSON.stringify(rawData);
  }

  static deserialize(serializedData) {
    return JSON.parse(serializedData);
  }
}

export class WorkOrderSearchSerializer {
  static serialize(rawData) {
    return JSON.stringify(rawData);
  }

  static deserialize(serializedData) {
    const deserializedData = JSON.parse(serializedData);

    const scheduledBetween = get(deserializedData, "filters.scheduledBetween", null);
    if (scheduledBetween.startsAt) scheduledBetween.startsAt = new Date(scheduledBetween.startsAt);
    if (scheduledBetween.endsAt) scheduledBetween.endsAt = new Date(scheduledBetween.endsAt);

    const dueBetween = get(deserializedData, "filters.dueBetween", null);
    if (dueBetween.startsAt) dueBetween.startsAt = new Date(dueBetween.startsAt);
    if (dueBetween.endsAt) dueBetween.endsAt = new Date(dueBetween.endsAt);

    return deserializedData;
  }
}
