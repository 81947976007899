import React from "react";
import { Link, Icon as NDSIcon, theme } from "@nulogy/components";
import styled from "styled-components";

const Icon = styled(NDSIcon)`
  position: relative;
  top: 2px;
  left: 2px;
`;

export default function PackmanagerLink({ packmanagerPath, children, applicationContext }) {
  const { packmanagerUrl } = applicationContext;
  const linkToWorkOrderInPackmanager = `${packmanagerUrl}/${packmanagerPath}`;

  return (
    <>
      {packmanagerPath ? (
        <Link href={linkToWorkOrderInPackmanager} target="_blank">
          {children}
          <Icon icon="openInNew" size={theme.fontSizes.medium} />
        </Link>
      ) : (
        children
      )}
    </>
  );
}
