import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { useParams } from "react-router";
import Home from "./pages/home/Home";
import CompanyIndexContainer from "./pages/company/CompanyIndexContainer";
import CompanyCreateContainer from "./pages/company/CompanyCreateContainer";
import CompanyEditContainer from "./pages/company/CompanyEditContainer";
import CustomWorkOrderFieldsContainer from "./pages/customWorkOrderFields/CustomWorkOrderFieldsContainer";
import LaborSummary from "./pages/labor/LaborSummary";
import SiteCreateContainer from "./pages/site/SiteCreateContainer";
import SiteEditContainer from "./pages/site/SiteEditContainer";
import SiteIndexContainer from "./pages/site/SiteIndexContainer";
import UserIndexContainer from "./pages/user/UserIndexContainer";
import UserCreateContainer from "./pages/user/UserCreateContainer";
import UserEditContainer from "./pages/user/UserEditContainer";
import NotFound from "./pages/notFound/NotFound";
import AdminDashboardContainer from "./pages/admin/AdminDashboardContainer";
import DefaultAvailability from "./pages/availability/DefaultAvailability";
import ManageData from "./pages/manageData/ManageData";
import TeardownTimes from "./pages/teardownTimes/TeardownTimes";
import authorizePage from "./authorizePage";
import CustomerSettings from "./pages/customerSettings/CustomerSettings";
import AvailabilityOverrides from "./pages/availability/AvailabilityOverrides";
import DowntimeReasons from "./pages/downtimeReasons/DowntimeReasons";
import Lines from "./pages/line/Lines";
import Shifts from "./pages/shift/Shifts";
import Version from "./pages/version/Version";
import UserPreferences from "./pages/user/UserPreferences";
import paths from "config/routePaths";
import Authorization from "policies/Authorization";

function CompanyEdit({ policy }) {
  const { id } = useParams();

  return authorizePage(policy.canUpdate(Authorization.EntityTypes.Company), <CompanyEditContainer id={id} />);
}

function SiteCreate({ policy }) {
  const { companyId } = useParams();

  return authorizePage(policy.canUpdate(Authorization.EntityTypes.Site), <SiteCreateContainer companyId={companyId} />);
}

function SiteEdit({ policy }) {
  const { id } = useParams();

  return authorizePage(policy.canUpdate(Authorization.EntityTypes.Site), <SiteEditContainer id={id} />);
}

function UserCreateCompany({ policy }) {
  const { companyId } = useParams();

  return authorizePage(policy.canUpdate(Authorization.EntityTypes.User), <UserCreateContainer companyId={companyId} />);
}

function UserCreateSite({ policy }) {
  const { companyId, siteId } = useParams();

  return authorizePage(
    policy.canUpdate(Authorization.EntityTypes.User),
    <UserCreateContainer companyId={companyId} siteId={siteId} />,
  );
}

function UserEdit({ policy }) {
  const { id } = useParams();

  return authorizePage(policy.canUpdate(Authorization.EntityTypes.User), <UserEditContainer id={id} />);
}

function RouterComponent() {
  const policy = Authorization.usePolicy();

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact path={paths.home} element={<Home />} />
          <Route
            path={paths.adminDashboard}
            element={authorizePage(policy.canShow(Authorization.EntityTypes.UsageData), <AdminDashboardContainer />)}
          />
          <Route
            exact
            path={paths.companyIndex}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.Company), <CompanyIndexContainer />)}
          />
          <Route
            exact
            path={paths.companyNew}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.Company), <CompanyCreateContainer />)}
          />
          <Route path={paths.companyEdit(":id")} element={<CompanyEdit policy={policy} />} />
          <Route
            exact
            path={paths.customWorkOrderFields}
            element={authorizePage(
              policy.canUpdate(Authorization.EntityTypes.CustomWorkOrderField),
              <CustomWorkOrderFieldsContainer />,
            )}
          />
          <Route
            exact
            path={paths.manageData}
            element={authorizePage(policy.canImport(Authorization.EntityTypes.WorkOrder), <ManageData />)}
          />
          <Route exact path={paths.laborSummary} element={<LaborSummary />} />
          <Route
            exact
            path={paths.siteIndex}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.Site), <SiteIndexContainer />)}
          />
          <Route
            exact
            path={paths.siteNew}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.Site), <SiteCreateContainer />)}
          />
          <Route exact path={paths.siteNewForCompany(":companyId")} element={<SiteCreate policy={policy} />} />
          <Route path={paths.siteEdit(":id")} element={<SiteEdit policy={policy} />} />
          <Route
            exact
            path={paths.teardownTimes}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.TeardownTime), <TeardownTimes />)}
          />
          <Route
            exact
            path={paths.userIndex}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.User), <UserIndexContainer />)}
          />
          <Route
            exact
            path={paths.userNew}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.User), <UserCreateContainer />)}
          />
          <Route exact path={paths.userNewForCompany(":companyId")} element={<UserCreateCompany policy={policy} />} />
          <Route
            exact
            path={paths.userNewForSite(":companyId", ":siteId")}
            element={<UserCreateSite policy={policy} />}
          />
          <Route path={paths.userEdit(":id")} element={<UserEdit policy={policy} />} />
          <Route
            path={paths.customerSettings}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.CustomerSettings), <CustomerSettings />)}
          />
          <Route
            path={paths.defaultAvailability}
            element={authorizePage(
              policy.canUpdate(Authorization.EntityTypes.DefaultAvailability),
              <DefaultAvailability />,
            )}
          />
          <Route
            path={paths.availabilityOverrides}
            element={authorizePage(
              policy.canUpdate(Authorization.EntityTypes.AvailabilityOverride),
              <AvailabilityOverrides />,
            )}
          />
          <Route
            path={paths.downtimeReasons}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.DowntimeReason), <DowntimeReasons />)}
          />

          <Route
            path={paths.lines}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.Line), <Lines />)}
          />

          <Route
            path={paths.shifts}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.Shift), <Shifts />)}
          />

          <Route
            path={paths.preferences}
            element={authorizePage(policy.canUpdate(Authorization.EntityTypes.UserPreferences), <UserPreferences />)}
          />

          <Route path={paths.version} element={authorizePage(policy.canSeeVersion(), <Version />)} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default RouterComponent;
