import React from "react";
import styled from "styled-components";
import { Box, Divider, Flex, Icon, IconicButton, Card as NDSCard, Text, Tooltip, theme } from "@nulogy/components";
import classNames from "classnames";
import WorkOrderCardStatus from "./WorkOrderCardStatus";
import WorkOrderCardWrapper from "./WorkOrderCardWrapper";
import WorkOrderCardMenu from "components/pages/schedule/workOrderCards/WorkOrderCardMenu";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";
import useSlideOutState from "hooks/state/useSlideOutState";
import useDeleteWorkOrder from "hooks/apollo/workOrder/useDeleteWorkOrder";
import useUnscheduleWorkOrder from "hooks/apollo/workOrder/useUnscheduleWorkOrder";
import Authorization from "policies/Authorization";
import TruncatedText from "components/common/TruncatedText";
import WorkOrder from "domain/WorkOrder";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import UnstyledLink from "components/common/UnstyledLink";
import CustomWorkOrderFieldIndicator from "components/pages/schedule/workOrderCards/CustomWorkOrderFieldIndicator";
import { formatDate, formatDuration, formatQuantity } from "components/helpers/format";
import NotesIndicator from "components/pages/schedule/NotesIndicator";
import ProductionProgressBar from "components/pages/schedule/workOrderCards/ProductionProgressBar";
import WorkOrderFlag from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkOrderFlag";
import LineTypePreferences from "components/pages/schedule/workOrderCards/LineTypePreferences";

const DragIcon = styled.div(({ schedulable }) => {
  const color = schedulable ? theme.colors.darkBlue : theme.colors.grey;

  return {
    left: "4px",
    color,

    svg: {
      verticalAlign: "middle",
    },
  };
});

const Information = styled(Box)`
  display: grid;
  grid-template-columns: 108px auto;
  column-gap: 2px;
  max-height: ${({ hidden = false }) => (hidden ? "0px" : "400px")};
  overflow: hidden;
  //transition: max-height 0.2s ease-in-out;
`;

const StyledIconicButton = styled(IconicButton)`
  span {
    height: 24px;
    width: 24px;
  }

  margin-right: -2px;
`;

const FieldInformation = ({ label, value, settingKey, settings }) => {
  const dataTestIdValue = `${label.replace(/\s/g, "-").toLowerCase()}-value`;
  const dataTestIdLabel = `${label.replace(/\s/g, "-").toLowerCase()}-label`;

  if (settings && !settings[settingKey]) return null;

  return (
    <>
      <TruncatedText fontSize="12px" color={theme.colors.midGrey} title={label} data-testid={dataTestIdLabel}>
        {label}
      </TruncatedText>
      <TruncatedText fontSize="12px" title={typeof value === "string" ? value : null} data-testid={dataTestIdValue}>
        {value ?? "-"}
      </TruncatedText>
    </>
  );
};

const ToBeScheduledValue = ({ workOrder }) => {
  const { unscheduledQuantity, unitOfMeasure, quantity, remainingDuration, standardProductionRate } = workOrder;
  const roundedUnscheduledQuantity = WorkOrder.isScheduledCompletedOrCancelled(workOrder)
    ? 0
    : formatQuantity(Math.abs(unscheduledQuantity));
  const remainingTime = WorkOrder.isScheduledCompletedOrCancelled(workOrder)
    ? formatDuration(0)
    : formatDuration(remainingDuration);

  return (
    <>
      {quantity > 0 &&
        standardProductionRate > 0 &&
        `${remainingTime} • ${roundedUnscheduledQuantity} ${unitOfMeasure}`}
      {standardProductionRate <= 0 && <QuantityError prefix="Production rate" />}
      {quantity <= 0 && <QuantityError prefix="Work order quantity" />}
    </>
  );
};

const ToBeScheduledField = ({ workOrder }) => {
  const toBeScheduledLabel = WorkOrder.isOverscheduled(workOrder) ? "Overscheduled by" : "To be scheduled";

  return <FieldInformation label={toBeScheduledLabel} value={<ToBeScheduledValue workOrder={workOrder} />} />;
};

const Card = styled(NDSCard)(({ workOrder, userCanUpdate }) => {
  const draggable = workOrder.schedulable && userCanUpdate;

  return {
    padding: "7px",
    [!draggable && "&:hover, &:active"]: { cursor: "default" },
    [WorkOrder.isMissingData(workOrder) && "color"]: theme.colors.darkGrey,
    border: `solid 1px ${theme.colors.grey}`,
    backgroundColor: theme.colors.white,
  };
});

const HeaderContainer = styled(Flex)`
  flex-grow: 1;
  overflow: hidden; // allow the item to shrink to fit inside the container
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.red};
`;

const ErrorSpan = styled.span`
  position: relative;
  bottom: 5px;
  margin-left: 3px;
`;

const ErrorTooltip = styled(Tooltip)({
  whiteSpace: "normal",
  textAlign: "left",
});

const QuantityError = ({ prefix }) => (
  <ErrorWrapper>
    <ErrorTooltip tooltip="This work order is missing data and cannot be scheduled.">
      <Icon icon="error" size="20px" />
    </ErrorTooltip>
    <ErrorSpan>{prefix} is 0</ErrorSpan>
  </ErrorWrapper>
);

const getPercentageLabel = ({ producedQuantity, quantity }) => {
  if (producedQuantity && !quantity) return "% cannot be calculated";
  if (!quantity || !producedQuantity) return "0% complete";

  const roundedProducedQuantity = formatQuantity(producedQuantity);
  const roundedQuantity = formatQuantity(quantity);
  const producedPercentage = Math.min(Math.floor((roundedProducedQuantity / roundedQuantity) * 100), 100);

  return `${producedPercentage}% complete`;
};

export default function WorkOrderCard({ workOrder, expanded, onToggleExpandWorkOrder, layoutSettingData }) {
  const removeWorkOrder = useDeleteWorkOrder();
  const handleUnscheduleWorkOrder = useUnscheduleWorkOrder();
  const updateWorkOrder = useUpdateWorkOrder();
  const policy = Authorization.usePolicy();
  const slideOutState = useSlideOutState();
  const applicationContext = useApplicationContext();

  if (applicationContext.loading) return null;

  const { settings } = applicationContext;
  const {
    id: workOrderId,
    code,
    customerName,
    customWorkOrderFieldColor,
    customWorkOrderFieldName,
    flag,
    lineTypePreferencePrimary,
    lineTypePreferenceSecondary,
    remainingDuration,
    schedulable,
    standardProductionRate,
    performance,
    unscheduledQuantity,
    unitOfMeasure,
    quantity,
    producedQuantity,
    scheduledQuantity,
    scheduledDuration,
    notes,
  } = workOrder;

  const roundedQuantity = formatQuantity(quantity);
  const roundedProducedQuantity = formatQuantity(producedQuantity);
  const roundedScheduledQuantity = formatQuantity(scheduledQuantity);

  const scheduledTime = formatDuration(scheduledDuration);

  const handleOpenWorkOrderDetails = () => {
    slideOutState.openWorkOrderDetails(workOrder.id);
  };

  const handleRemoveWorkOrder = () => {
    const areDetailsOpen = workOrderId === slideOutState.workOrderId;
    if (areDetailsOpen) {
      slideOutState.closeSlideOut();
    }

    removeWorkOrder({ workOrderId });
  };

  const userCanUpdate = policy.canUpdate(Authorization.EntityTypes.WorkOrder);
  const draggability = schedulable && userCanUpdate ? "draggable" : "non-draggable";
  const workOrderCodeKebabCase = code.replace(/\s/g, "-").toLowerCase();

  const hasLineTypePreference = lineTypePreferencePrimary || lineTypePreferenceSecondary;

  const layoutSetting = layoutSettingData.workOrderLayoutSetting;

  const expandedFields = [
    { label: "Work order ID", value: workOrder.externalId, settingKey: "workOrderId" },
    { label: "Work order description", value: workOrder.description, settingKey: "workOrderDescription" },
    {
      label: "Material availability date",
      value: formatDate(workOrder.materialAvailabilityDate),
      settingKey: "materialAvailabilityDate",
    },
    { label: "Planned start (from Shop Floor)", value: formatDate(workOrder.startAt), settingKey: "plannedStartDate" },
    { label: "Planned end (from Shop Floor)", value: formatDate(workOrder.endAt), settingKey: "plannedEndDate" },
    { label: "Custom work order field", value: workOrder.customWorkOrderFieldName, settingKey: "customWorkOrderField" },
    { label: "Reference 1", value: workOrder.reference1, settingKey: "reference1" },
    { label: "Reference 2", value: workOrder.reference2, settingKey: "reference2" },
    { label: "Reference 3", value: workOrder.reference3, settingKey: "reference3" },
    { label: "Item class", value: workOrder.itemClass?.name, settingKey: "itemClass" },
    { label: "Item family", value: workOrder.itemFamily?.name, settingKey: "itemFamily" },
    { label: "Item type", value: workOrder.itemType?.name, settingKey: "itemType" },
    { label: "Item category", value: workOrder.itemCategory?.name, settingKey: "itemCategory" },
    { label: "Service category", value: workOrder.serviceCategory?.name, settingKey: "serviceCategory" },
    {
      label: "Standard production rate",
      value: workOrder.standardProductionRate,
      settingKey: "standardProductionRate",
    },
    {
      label: "Recommended production rate",
      value: workOrder.recommendedProductionRate,
      settingKey: "recommendedProductionRate",
    },
    { label: "Standard people", value: workOrder.standardLabor, settingKey: "standardPeople" },
    { label: "Recommended people", value: workOrder.recommendedLabor, settingKey: "recommendedPeople" },
  ];

  const percentageLabel = getPercentageLabel({ producedQuantity, quantity });

  return (
    <WorkOrderCardWrapper workOrderId={workOrder.id}>
      <div
        data-drag-remaining-duration={remainingDuration || ""}
        data-drag-standard-production-rate={standardProductionRate}
        data-drag-performance={performance}
        data-drag-unscheduled-quantity={unscheduledQuantity}
        data-drag-settings={JSON.stringify(settings)}
        data-work-order-id={workOrderId}
        data-drag-work-order={JSON.stringify(workOrder)}
        data-drag-application-context={JSON.stringify(applicationContext)}
        className={classNames(
          "spec-drag-root",
          draggability,
          `pendo-work-order-card-${workOrderCodeKebabCase}`,
          "pendo-work-order-card",
        )}
        data-testid="work-order-card"
      >
        <Card workOrder={workOrder} userCanUpdate={userCanUpdate}>
          <WorkOrderCardStatus workOrder={workOrder} />
          {notes && <NotesIndicator />}
          <Flex gap="x1" flexDirection="column">
            <Flex flexDirection="row" alignItems="center" gap="half" height="x3">
              {userCanUpdate && (
                <DragIcon schedulable={schedulable}>
                  <Icon icon="drag" />
                </DragIcon>
              )}
              <CustomWorkOrderFieldIndicator
                settings={settings}
                name={customWorkOrderFieldName}
                color={customWorkOrderFieldColor}
                userCanUpdate={userCanUpdate}
              />
              <HeaderContainer flex="1" alignItems="center" gap="half" height="x3" justifyContent="flex-start">
                <TruncatedText
                  inline
                  fontSize="small"
                  className={`spec-work-order-${workOrderId} pendo-view-work-order-details-work-order-card-code`}
                  title={code}
                  maxWidth="70%"
                >
                  <UnstyledLink draggable="false" fontSize="small" onClick={handleOpenWorkOrderDetails}>
                    {code}
                  </UnstyledLink>
                </TruncatedText>
                <Text inline fontWeight="bold" marginX="4px" color="midGrey">
                  •
                </Text>
                <TruncatedText inline fontSize="small" title={customerName}>
                  {customerName}
                </TruncatedText>
              </HeaderContainer>
              <Box>
                <StyledIconicButton
                  icon={expanded ? "collapse" : "expand"}
                  onClick={() => {
                    onToggleExpandWorkOrder(workOrderId);
                  }}
                />
              </Box>

              <Box>
                <WorkOrderCardMenu
                  onOpenWorkOrderDetails={handleOpenWorkOrderDetails}
                  onUnscheduleWorkOrder={handleUnscheduleWorkOrder}
                  onRemoveWorkOrder={handleRemoveWorkOrder}
                  updateWorkOrder={updateWorkOrder}
                  workOrder={workOrder}
                  userCanUpdate={userCanUpdate}
                  customiseWorkOrderCards
                />
              </Box>
            </Flex>

            <Box>
              <Information paddingRight="10px">
                <FieldInformation label="Due date" value={formatDate(workOrder.due)} />
                <FieldInformation
                  label="Item code"
                  value={workOrder.itemCode}
                  settingKey="itemCode"
                  settings={layoutSetting}
                />
                <FieldInformation
                  label="Item description"
                  value={workOrder.itemDescription}
                  settingKey="itemDescription"
                  settings={layoutSetting}
                />
              </Information>
              <Information hidden={!expanded}>
                {expandedFields.map(({ label, value, settingKey }) => (
                  <FieldInformation
                    key={label}
                    label={label}
                    value={value}
                    settingKey={settingKey}
                    settings={layoutSetting}
                  />
                ))}
              </Information>
              <Divider />
              <Information>
                <ToBeScheduledField workOrder={workOrder} />
                <FieldInformation
                  label="Scheduled"
                  value={`${scheduledTime} • ${roundedScheduledQuantity} ${unitOfMeasure}`}
                />
                <FieldInformation
                  label="Produced"
                  value={`${roundedProducedQuantity}/${roundedQuantity} ${unitOfMeasure} • ${percentageLabel}`}
                />
              </Information>
            </Box>
            <Information>
              <ProductionProgressBar quantity={quantity} producedQuantity={producedQuantity} />
            </Information>
            {(flag || hasLineTypePreference) && (
              <Box>
                <Divider />
                <Flex flexDirection="row" alignItems="center" height="25px">
                  <WorkOrderFlag flag={flag} />
                  <LineTypePreferences workOrder={workOrder} />
                </Flex>
              </Box>
            )}
          </Flex>
        </Card>
      </div>
    </WorkOrderCardWrapper>
  );
}
