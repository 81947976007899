import React, { useEffect } from "react";
import styled from "styled-components";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import WorkOrderCardOld from "./workOrderCards/WorkOrderCardOld";
import MockWorkOrderCardContainerOld from "./workOrderCards/MockWorkOrderCardContainerOld";
import MoreResultsMessage from "./MoreResultsMessage";
import NoResultsFound from "./NoResultsFound";
import { SIDE_BAR_LIMIT } from "config/appConfig";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import Authorization from "policies/Authorization";

const FULL_PAGE_INCLUDING_FOOTER = SIDE_BAR_LIMIT + 1;
const CARD_HEIGHT_INCLUDING_PADDING = 161;
const cache = new CellMeasurerCache({
  defaultHeight: CARD_HEIGHT_INCLUDING_PADDING,
  minHeight: CARD_HEIGHT_INCLUDING_PADDING,
  fixedWidth: true,
});

const Wrapper = styled.div`
  height: ${({ searchBarHeight }) => `calc(100% - ${searchBarHeight + 36}px)`};

  /*
   * This fixes a bug where the context menu gets cut off if there are only a few
   * work orders in the sidebar.  This appears to be an issue for several folks
   * out there but, unfortunately, the creators of react-virtualized-list don't
   * seem to care too much about fixing this.
   *
   * https://github.com/bvaughn/react-virtualized/issues/876
  */
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

export default function VirtualizedWorkOrderListOld({ workOrders }) {
  const applicationContext = useApplicationContext();
  const policy = Authorization.usePolicy();

  useEffect(() => cache.clearAll(), [workOrders]);

  return (
    <Wrapper searchBarHeight={126} className="spec-side-bar">
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowCount={getRowCount(workOrders)}
            estimatedRowSize={CARD_HEIGHT_INCLUDING_PADDING}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer({ workOrders, applicationContext, policy })}
            noRowsRenderer={NoResultsFound}
            width={width}
            overscanRowCount={0}
            deferredMeasurementCache={cache}
            style={{ outline: "none" }}
          />
        )}
      </AutoSizer>
    </Wrapper>
  );
}

function getRowCount(workOrders) {
  if (workOrders?.length === SIDE_BAR_LIMIT) {
    return FULL_PAGE_INCLUDING_FOOTER;
  }

  return workOrders?.length;
}

function rowRenderer({ workOrders, applicationContext, policy }) {
  return ({ index, style, isScrolling, parent, key }) => {
    const workOrder = workOrders[index];
    const atPageLimit = index === SIDE_BAR_LIMIT;

    if (atPageLimit) {
      return (
        <CellMeasurer cache={cache} key="-1" parent={parent} rowIndex={index}>
          {() => (
            <div style={{ ...style, paddingBottom: "4px" }} key={key}>
              <MoreResultsMessage />
            </div>
          )}
        </CellMeasurer>
      );
    }

    return (
      <CellMeasurer cache={cache} key={workOrder.id} parent={parent} rowIndex={index}>
        {() => (
          <div style={{ ...style, padding: "16px 4px 0 4px" }}>
            {isScrolling ? (
              <MockWorkOrderCardContainerOld
                applicationContext={applicationContext}
                workOrder={workOrder}
                policy={policy}
              />
            ) : (
              <WorkOrderCardOld workOrder={workOrder} />
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };
}
