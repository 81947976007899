import { Box, Button, Flex } from "@nulogy/components";
import React, { useState } from "react";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";
import uniq from "lodash/uniq";
import Form from "components/controls/Form";
import Input from "components/controls/Input";
import StackedAreaChart from "components/charts/StackedAreaChart";
import useGetEngagedSites from "hooks/apollo/usageData/useGetEngagedSites";
import { ACTION_OPERATIONS } from "hooks/apollo/usageData/EventTypes";
import Enum from "utils/Enum";

const DEFAULT_ENGAGEMENT_THRESHOLD = 15;

export default function EngagedSites({ startDate, endDate }) {
  const [threshold, setThreshold] = useState(DEFAULT_ENGAGEMENT_THRESHOLD);
  const [engagedSites, setEngagedSites] = useState({});

  const handleFetchEngagedSites = (newThreshold) => {
    if (!newThreshold) return;

    setThreshold(newThreshold);
    getEngagedSites();
  };

  const { fetch: getEngagedSites } = useGetEngagedSites({
    eventNames: Object.values(ACTION_OPERATIONS),
    startDate,
    endDate,
    threshold,
    onCompleted: (data) => {
      setEngagedSites({
        label: "Engaged sites",
        data: getRechartCompatibleDataForEngagedSites(data.engagedSites),
        keys: getKeys(data.engagedSites),
      });
    },
  });

  return (
    <Box width={1}>
      <Box boxShadow="large" margin="x3">
        <Form initialValues={{ threshold: DEFAULT_ENGAGEMENT_THRESHOLD }}>
          {({ values: { threshold: formThreshold } }) => (
            <Flex alignItems="flex-end" justifyContent="left">
              <Box marginRight="x2">
                <Input
                  field="threshold"
                  inputWidth="100px"
                  labelText="Engagement threshold"
                  suffix="scheduling operations"
                  type="number"
                />
              </Box>
              <Box>
                <Button type="button" onClick={() => handleFetchEngagedSites(formThreshold)}>
                  Fetch Data
                </Button>
              </Box>
            </Flex>
          )}
        </Form>
        <StackedAreaChart data={engagedSites.data} title={engagedSites.label} keys={engagedSites.keys} />
      </Box>
    </Box>
  );
}

function getRechartCompatibleDataForEngagedSites(usageData) {
  if (!usageData) return [];

  return Enum.chain(usageData)
    .groupBy("date")
    .map((rows, date) => ({ name: date, ...getUsageBySiteForEngagedSites(rows) }))
    .value();
}

function getUsageBySiteForEngagedSites(rows) {
  return fromPairs(map(rows, (row) => [getSiteKey(row), row.value >= 1 ? 1 : 0]));
}

function getKeys(data) {
  if (!data) return [];
  if (data.length === 0) return [];

  return uniq(data.map(getSiteKey));
}

function getSiteKey(row) {
  return `${row.companyName} - ${row.siteName}`;
}
