import styled from "styled-components";

const BlockCellName = styled.div`
  margin-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 10px;

  a {
    font-weight: 700;
  }
`;

export default BlockCellName;
