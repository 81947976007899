import DateTime from "./DateTime";

/**
 * Time
 *
 * This module contains utility methods for "modulo time", which is a time that is unassociated with any date.
 *
 * Datetime: January 1, 2001, 9:00 AM
 * Modulo time: 9:00 AM
 */
const Time = {
  to12HourShort,
  to12Hour,
  to24Hour,
};

function to24Hour(twelveHourModuloTimeString) {
  if (!twelveHourModuloTimeString) {
    return twelveHourModuloTimeString;
  }

  return DateTime.format(new Date(`2000-01-01 ${twelveHourModuloTimeString}`), "HH:mm");
}

function to12Hour(twentyFourHourModuloTimeString) {
  if (!twentyFourHourModuloTimeString) {
    return twentyFourHourModuloTimeString;
  }

  return DateTime.format(new Date(`2000-01-01 ${twentyFourHourModuloTimeString}`), "h:mm A");
}

function to12HourShort(timeString) {
  if (timeString.endsWith(":00")) {
    return DateTime.format(`2000-01-01T${timeString}`, "h A");
  } else {
    return DateTime.format(`2000-01-01T${timeString}`, "h:mm A");
  }
}

export default Time;
