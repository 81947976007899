import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import times from "lodash/times";
import toPairs from "lodash/toPairs";

const Enum = {
  chain,
  eachCons,
};

// just add here the lodash functions you want to support
const chainableFunctions = {
  filter,
  groupBy,
  map,
  orderBy,
  sortBy,
  toPairs,
};

function chain(input) {
  let value = input;
  const wrapper = {
    ...mapValues(chainableFunctions, (f) => (...args) => {
      value = f(value, ...args);

      return wrapper;
    }),
    value: () => value,
  };

  return wrapper;
}

function eachCons(array, n) {
  return times(array.length - n + 1).map((i) => array.slice(i, i + n));
}

export default Enum;
