import React, { useRef, useState } from "react";
import { Box, DateRange, Flex, Heading2, Heading3, LoadingAnimation, Page, theme } from "@nulogy/components";

import filter from "lodash/filter";
import { NetworkStatus, useQuery } from "@apollo/client";
import toLower from "lodash/toLower";
import { useNavigate } from "react-router";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import xorWith from "lodash/xorWith";
import AvailabilityEmptyState from "./AvailabilityEmptyState";
import AvailabilityOverridesLegend from "./AvailabilityOverridesLegend";
import AvailabilityOverridesUnsavedChangesModal from "./AvailabilityOverridesUnsavedChangesModal";
import { AvailabilityOverridesTable } from "./AvailabilityOverridesTable";
import { useNotificationState } from "hooks/state";
import StickyFooter from "components/controls/StickyFooter";
import UnstyledLink from "components/common/UnstyledLink";
import Form from "components/controls/Form";
import FormButtons from "components/controls/FormButtons";
import useUpdateAvailabilityOverrides from "hooks/apollo/availabilityOverride/useUpdateAvailabilityOverrides";
import { GetDefaultAvailabilities } from "hooks/apollo/defaultAvailability/defaultAvailability.gql";
import { GetAvailabilityOverrides } from "hooks/apollo/availabilityOverride/availabilityOverride.gql";
import paths from "config/routePaths";
import DateRangeUtil from "utils/DateRange";
import DateTime from "utils/DateTime";
import useAvailabilityOverridesUnsavedChangesModal from "hooks/state/useAvailabilityOverridesUnsavedChangesModal";

function dayOfWeekName(date) {
  return toLower(DateTime.dayOfWeekName(date));
}

function getDateInFuture(days) {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + days);
}

function renderLoading() {
  return (
    <Flex alignItems="center" height="100vh" width="100vw" justifyContent="center">
      <LoadingAnimation />
    </Flex>
  );
}

const today = getDateInFuture(0);
const todayInAWeek = getDateInFuture(6);

export default function AvailabilityOverrides() {
  const [dateRange, setDateRange] = useState({
    startDate: today,
    endDate: todayInAWeek,
  });
  const navigate = useNavigate();
  const [dateRangeError, setDateRangeError] = useState(null);
  const [updateAvailabilityOverrides, { loading: updateAvailabilityOverridesLoading }] =
    useUpdateAvailabilityOverrides();
  const currentFormData = useRef(null);
  const {
    close: closeUnsavedChangesModal,
    open: openUnsavedChangesModal,
    startDate: modalStartDate,
    endDate: modalEndDate,
  } = useAvailabilityOverridesUnsavedChangesModal();

  const { data: defaultAvailabilitiesData, loading: defaultAvailabilitiesLoading } = useQuery(GetDefaultAvailabilities);

  const {
    data: availabilityOverridesData,
    loading: availabilityOverridesLoading,
    refetch: refetchAvailabilityOverrides,
    networkStatus: availabilityOverridesNetworkStatus,
  } = useQuery(GetAvailabilityOverrides, {
    variables: {
      startDate: DateTime.toISO8601Date(dateRange.startDate),
      endDate: DateTime.toISO8601Date(dateRange.endDate),
    },
    notifyOnNetworkStatusChange: true,
  });

  const { setNotification } = useNotificationState();

  if (availabilityOverridesNetworkStatus === NetworkStatus.refetch) return renderLoading();

  if (availabilityOverridesNetworkStatus === NetworkStatus.error) {
    return <h2>There was an error loading this page. Please check your internet connection and refresh.</h2>;
  }

  if (defaultAvailabilitiesLoading || availabilityOverridesLoading || updateAvailabilityOverridesLoading)
    return renderLoading();

  const { defaultAvailabilities } = defaultAvailabilitiesData;
  const { availabilityOverrides } = availabilityOverridesData;

  const leavePage = () => {
    navigate(paths.home);
  };

  const handleSave = (formData) => {
    updateAvailabilityOverrides(
      formData.availabilities,
      DateTime.toISO8601Date(dateRange.startDate),
      DateTime.toISO8601Date(dateRange.endDate),
    )
      .then(() => {
        setNotification({
          title: "The schedule’s availability was successfully updated.",
          type: "success",
        });
        if (modalEndDate && modalStartDate) {
          setDateRange({ startDate: modalStartDate, endDate: modalEndDate });
        } else {
          refetchAvailabilityOverrides();
        }
      })
      .catch(() =>
        setNotification({
          title: "The changes were not saved. Please try again.",
          type: "danger",
        }),
      )
      .finally(() => closeUnsavedChangesModal());
  };

  const findOverride = (date, shift, line) =>
    filter(
      availabilityOverrides,
      (override) =>
        DateTime.toISO8601Date(override.overrideDate) === DateTime.toISO8601Date(date) &&
        override.shift.id === shift.id &&
        override.line.id === line.id,
    )[0];

  const dates = DateTime.dateRange(dateRange.startDate, dateRange.endDate);
  const availabilities = [];

  dates.forEach((date) => {
    const dataForDate = defaultAvailabilities.map((defaultAvailability, index) => {
      const disabled = !defaultAvailability.shift[dayOfWeekName(date)];
      const overrideValue = findOverride(date, defaultAvailability.shift, defaultAvailability.line);
      const overridden = overrideValue !== undefined;
      const available = overridden ? overrideValue.available : defaultAvailability[dayOfWeekName(date)];

      return {
        date: DateTime.toISO8601Date(date),
        line: defaultAvailability.line.id,
        shift: defaultAvailability.shift.id,
        available: !disabled && available,
        disabled,
        defaultAvailability: defaultAvailability[dayOfWeekName(date)],
        index: availabilities.length + index,
      };
    });

    availabilities.push(...dataForDate);
  });

  const isArrayDifferent = (x, y) => !isEmpty(xorWith(x, y, isEqual));

  const handleRangeChange = (e) => {
    setDateRangeError(null);

    if (!e.startDate || !e.endDate) return; // Just dont run the query
    if (e.endDate < e.startDate) return; // NDS will set an error and dont run the query

    if (DateTime.differenceInDays(e.startDate, e.endDate) > 14) {
      setDateRangeError("Date range must be 14 days or less");

      return;
    }

    if (isArrayDifferent(availabilities, currentFormData.current.values.availabilities)) {
      openUnsavedChangesModal(e.startDate, e.endDate);
    } else {
      setDateRange({ startDate: e.startDate, endDate: e.endDate });
    }
  };

  const onModalClose = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  const renderContent = () => {
    if (defaultAvailabilities.length === 0) {
      return <AvailabilityEmptyState />;
    }

    return (
      <>
        <DateRange
          errorMessage={dateRangeError}
          className="spec-date-range"
          onRangeChange={handleRangeChange}
          disableRangeValidation={false}
          defaultStartDate={dateRange.startDate}
          defaultEndDate={dateRange.endDate}
          showTimes={false}
          disableFlipping={false}
          mb="x3"
        />
        <Heading3 fontWeight="normal" color="darkGrey">
          {DateRangeUtil.getDateRangeMonthText(dateRange)}
        </Heading3>

        <Form initialValues={{ availabilities }} innerRef={currentFormData} onSave={handleSave} enableReinitialize>
          <Flex alignItems="left" style={{ maxWidth: "100vw", overflowX: "scroll", position: "relative" }}>
            <AvailabilityOverridesTable availabilities={availabilities} dates={dates} />
          </Flex>

          <StickyFooter>
            <FormButtons onCancel={leavePage} saveButtonText="Save and apply to schedule" />
          </StickyFooter>

          <AvailabilityOverridesUnsavedChangesModal onClose={onModalClose} />
        </Form>
      </>
    );
  };

  return (
    <Page data-testid="content">
      <Heading2 mb="x3" data-testid="title">
        Availability overrides
      </Heading2>
      <Box right={24} position="absolute">
        <AvailabilityOverridesLegend />
      </Box>
      <Box mb="x3" color={theme.colors.darkGrey} maxWidth="600px">
        Accommodate day to day variation in availability by overriding the schedule’s{" "}
        <UnstyledLink style={{ fontWeight: "normal" }} href={paths.defaultAvailability}>
          Default availability
        </UnstyledLink>
        .
      </Box>

      {renderContent()}
    </Page>
  );
}
