import React from "react";
import { Modal } from "@nulogy/components";
import PublishSummaryModalView from "./PublishSummaryModalView";
import usePublishSummaryModal from "hooks/state/usePublishSummaryModal";

export default function PublishSummaryModal() {
  const { close: closeModal, isOpen, data } = usePublishSummaryModal();

  const close = () => {
    closeModal();
  };

  return (
    <Modal title="Publish Summary" isOpen={isOpen} onRequestClose={close} ariaHideApp={false} maxWidth="816px">
      <PublishSummaryModalView summaryData={data} />
    </Modal>
  );
}
