import styled from "styled-components";

const Dates = styled.div`
  font-size: 12px;
  position: relative;
  top: -4px;
  padding-left: 10px;
`;

export default Dates;
