import isEqual from "lodash/isEqual";
import pickBy from "lodash/pickBy";
import isFunction from "lodash/isFunction";

const ReactExt = {
  checkPropsForReRender,
};

function checkPropsForReRender(prevProps, nextProps) {
  const prevPropsToCheck = pickBy(prevProps, (prop) => !isFunction(prop));
  const nextPropsToCheck = pickBy(nextProps, (prop) => !isFunction(prop));

  return isEqual(prevPropsToCheck, nextPropsToCheck);
}

export default ReactExt;
