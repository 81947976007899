import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import ResetSiteView from "./ResetSiteView";
import { ResetSite } from "hooks/apollo/site/site.gql";
import paths from "config/routePaths";
import useTimeZone from "hooks/useTimeZone";
import Authorization from "policies/Authorization";

export default function ResetSiteContainer({ id }) {
  const policy = Authorization.usePolicy();
  const { overrideToSiteTimeZone } = useTimeZone();

  const navigate = useNavigate();
  const [resetSite] = useMutation(ResetSite, {
    onCompleted: () => {
      navigate(paths.home);
      navigate(0);
    },
  });
  const [isResetSiteOpen, setResetSiteOpen] = useState(false);

  const handleReset = ({ preset, relativeDate }) => {
    /* eslint-disable no-alert */
    const confirmed = window.confirm(
      "Are you sure you want to delete existing data and create sample data? This action is irreversible.",
    );
    /* eslint-enable no-alert */

    if (confirmed) {
      const overriddenDate = overrideToSiteTimeZone(relativeDate);

      return resetSite({
        variables: { id, preset, relativeDate: overriddenDate },
      });
    } else {
      return Promise.resolve();
    }
  };

  return (
    <ResetSiteView
      onReset={handleReset}
      isResetSiteOpen={isResetSiteOpen}
      setResetSiteOpen={setResetSiteOpen}
      canReset={policy.canReset(Authorization.EntityTypes.Site)}
    />
  );
}
