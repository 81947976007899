import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { FullyScheduleWorkOrder } from "./workBlock.gql";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import { useNotificationState } from "hooks/state";
import useFetchShiftInstances from "hooks/apollo/shiftInstance/useFetchShiftInstances";
import GqlError from "utils/GqlError";
import TimeZone from "utils/TimeZone";

export default function useFullyScheduleWorkOrder() {
  const { refetch: refetchShiftInstances } = useFetchShiftInstances();
  const { setNotification } = useNotificationState();
  const [fullyScheduleWorkOrder] = useMutation(FullyScheduleWorkOrder, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to fully schedule work block",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: () => {
      refetchShiftInstances();
    },
  });
  const applicationContext = useApplicationContext();

  return (workBlock) => {
    const workBlockId = workBlock.id;

    return fullyScheduleWorkOrder({
      variables: {
        workBlockId,
        workTimeStartsAt: TimeZone.overrideZone(workBlock.workTimeStartsAt, applicationContext.timeZone.name),
        workTimeEndsAt: TimeZone.overrideZone(workBlock.workTimeEndsAt, applicationContext.timeZone.name),
        lineId: workBlock.line.id,
        workOrderId: workBlock.workOrder.id,
        lockedOnSchedule: workBlock.lockedOnSchedule,
        teardownTimeLabel: workBlock.teardownTimeLabel,
        teardownTimeDuration: workBlock.teardownTimeDuration,
        productionRateOverride: workBlock.productionRateOverride,
        efficiencyOverride: workBlock.efficiencyOverride,
        laborOverride: workBlock.laborOverride,
      },
      optimisticResponse: {
        fullyScheduleWorkOrder: {
          workBlock,
          __typename: "FullyScheduleWorkOrderPayload",
        },
      },
    });
  };
}
