import React from "react";
import { Box, Flex, Heading4, Icon, Modal, Text, Tooltip } from "@nulogy/components";
import styled from "styled-components";

const FILL_IN_THE_GAP_TOOLTIP =
  "Use this mode to schedule a block to fit precisely within an existing gap, which could be a shift or a space between two blocks.";
const MULTI_SELECT_TOOLTIP =
  "Use this mode to move blocks across one or more lines. To clear your entire selection, click outside the selected area. To deselect a specific block, simply click on it again.";
// HACK: This requires a 16px margin bottom, but we can't control the margin.
// Since there is already an 8px margin, we add 8px padding.
const Heading = ({ children: heading }) => <Heading4 paddingBottom="x2">{heading}</Heading4>;

const Item = ({ name, svgFile }) => (
  <Flex alignItems="center" paddingBottom="x1">
    <img src={`./svg/components/pages/schedule/${svgFile}.svg`} alt={name} />
    <Text marginLeft="x2">{name}</Text>
  </Flex>
);

const AlignedIcon = styled(Icon)`
  position: relative;
  top: 2px;
`;

export default function LegendModal({ isLegendModalOpen, setLegendModalOpen }) {
  return (
    <Modal
      title="Legend"
      isOpen={isLegendModalOpen}
      onRequestClose={() => setLegendModalOpen(false)}
      ariaHideApp={false}
      maxWidth="817px"
    >
      <Flex justifyContent="space-between" data-testid="scheduling-guide-statuses" marginBottom="x2">
        <Box>
          <Heading>Statuses</Heading>
          <Item name="Scheduled" svgFile="scheduled" />
          <Item name="Underscheduled" svgFile="underscheduled" />
          <Item name="Overscheduled" svgFile="overscheduled" />
          <Item name="Completed" svgFile="completed" />
          <Item name="Cancelled" svgFile="cancelled" />
        </Box>

        <Box>
          <Heading>Indicators</Heading>
          <Item name="Has notes" svgFile="notes" />
          <Item name="Has custom work order field" svgFile="custom-work-order-field" />
          <Item name="Flagged" svgFile="flagged" />
          <Item name="Overdue" svgFile="overdue" />
          <Item name="Has teardown time" svgFile="teardown-time" />
          <Item name="Unavailable for scheduling" svgFile="unavailable" />
        </Box>

        <Box>
          <Heading>Quick actions</Heading>
          <Item name="Fully schedule block" svgFile="fully-schedule" />
          <Item name="Add work order notes" svgFile="add-notes" />
        </Box>
      </Flex>
      <Box marginBottom="x1">
        <Heading4 paddingBottom="x1">Keyboard shortcuts</Heading4>
        <Shortcut tooltip={FILL_IN_THE_GAP_TOOLTIP}>
          Fill-in-the-gap mode: Activate by holding “Shift” when dragging.
        </Shortcut>
        <Shortcut tooltip={MULTI_SELECT_TOOLTIP}>
          Multi-select mode: Activate by holding “Control” then selecting blocks. For an Apple device, hold “Command ⌘”
          instead.
        </Shortcut>
      </Box>
    </Modal>
  );
}

function Shortcut({ children, tooltip }) {
  return (
    <Text mb="x2">
      {children}
      <Tooltip placement="bottom" tooltip={tooltip}>
        <AlignedIcon icon="help" color="darkBlue" size="16px" ml="half" />
      </Tooltip>
    </Text>
  );
}
