import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { DeleteTeardownTime } from "./teardownTime.gql";
import { formatDuration } from "components/helpers/format";
import { useNotificationState } from "hooks/state";
import GqlError from "utils/GqlError";

export default function useDeleteTeardownTime() {
  const { setNotification } = useNotificationState();
  const [deleteTeardownTime] = useMutation(DeleteTeardownTime, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to delete teardown time",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: ({ deleteTeardownTime: { duration, label } }) => {
      const formattedDuration = formatDuration(duration);
      const errorText = `${label} (${formattedDuration})`;

      setNotification({
        title: `${errorText} was successfully deleted.`,
        type: "success",
      });
    },
  });

  return (options) => deleteTeardownTime(options);
}
