import { theme } from "@nulogy/components";

const {
  colors: { black, categorical2: purple, green, lightGreen, lightRed, red, white, yellow },
} = theme;

export const FLAG_OPTIONS = {
  A: { color: white, backgroundColor: red, borderColor: red },
  B: { color: black, backgroundColor: yellow, borderColor: yellow },
  C: { color: white, backgroundColor: green, borderColor: green },
  D: { color: white, backgroundColor: purple, borderColor: purple },
  E: { color: black, backgroundColor: lightGreen, borderColor: black },
  F: { color: black, backgroundColor: lightRed, borderColor: black },
};
export const NO_FLAG = "";
