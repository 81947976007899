import React from "react";
import { Box, Flex, PrimaryButton, QuietButton, theme } from "@nulogy/components";
import Form from "components/controls/Form";
import Textarea from "components/controls/Textarea";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";

const EditNotes = ({ workOrderId: id, notes: workOrderNotes, readonly }) => {
  const updateWorkOrder = useUpdateWorkOrder();

  const handleSave = ({ notes }) => updateWorkOrder({ id, notes });

  const handleBeforeSave = (values, actions) => {
    actions.setFieldValue("notes", values.notes.trim());

    return values;
  };

  return (
    <Form
      initialValues={{ notes: workOrderNotes || "" }}
      onSave={handleSave}
      onBeforeSave={handleBeforeSave}
      enableReinitialize
    >
      {({ dirty, handleReset, isSubmitting }) => (
        <>
          <Flex marginBottom="half">
            <Textarea
              id="work-order-details-notes"
              field="notes"
              rows={5}
              style={{ color: theme.colors.black, maxWidth: "100%", minWidth: "100%" }}
              disabled={readonly || isSubmitting}
            />
          </Flex>
          {!readonly && (
            <Box marginTop="x1">
              <PrimaryButton size="small" type="submit" disabled={!dirty || isSubmitting} mr="x1">
                Save
              </PrimaryButton>
              <QuietButton size="small" type="button" onClick={handleReset} disabled={!dirty || isSubmitting}>
                Cancel
              </QuietButton>
            </Box>
          )}
        </>
      )}
    </Form>
  );
};

export default EditNotes;
