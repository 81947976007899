import {
  buildDowntimeBlockEvents,
  buildShiftTimeRanges,
  buildUnavailabilityTimeRanges,
  buildWorkBlockEvents,
} from "./bryntum/eventBuilders";

const isLineFiltered = (bryntumScheduler, lineId) => !bryntumScheduler.resourceStore.isAvailable(lineId);

const filteredLineIds = (bryntumScheduler) => {
  const lineIds = bryntumScheduler?.resourceStore.data.map((d) => d.id);

  return lineIds ? lineIds.filter((lineId) => isLineFiltered(bryntumScheduler, lineId)) : [];
};

export default {
  buildShiftTimeRanges,
  buildUnavailabilityTimeRanges,
  buildDowntimeBlockEvents,
  buildWorkBlockEvents,
  isLineFiltered,
  filteredLineIds,
};
