import { useApolloClient } from "@apollo/client";
import workBlockInfo from "./workBlockInfo.gql";

export default function useGetWorkBlockFromCache() {
  const client = useApolloClient();

  return (id) =>
    client.readFragment({
      id: `WorkBlock:${id}`,
      fragmentName: "workBlockInfo",
      fragment: workBlockInfo,
    });
}
