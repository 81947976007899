import React from "react";
import { Box, Icon, Tooltip, theme } from "@nulogy/components";
import styled from "styled-components";
import { formatDuration, formatQuantity } from "components/helpers/format";
import WorkOrder from "domain/WorkOrder";

const ErrorWrapper = styled.div`
  color: ${theme.colors.red};
`;

const ErrorSpan = styled.span`
  position: relative;
  bottom: 5px;
  margin-left: 3px;
`;

const TruncatedField = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ErrorTooltip = styled(Tooltip)({
  whiteSpace: "normal",
  textAlign: "left",
});

const QuantityError = () => (
  <ErrorWrapper>
    <ErrorTooltip tooltip="This work order is missing data and cannot be scheduled.">
      <Icon icon="error" size="20px" />
    </ErrorTooltip>
    <ErrorSpan>Work order quantity is 0</ErrorSpan>
  </ErrorWrapper>
);

export default function Quantity(workOrder) {
  const { quantity, scheduledDuration, scheduledQuantity, unitOfMeasure } = workOrder;
  const roundedQuantity = formatQuantity(quantity);
  const roundedScheduledQuantity = formatQuantity(scheduledQuantity);
  const scheduledTime = formatDuration(scheduledDuration);

  const renderedScheduledQuantity = WorkOrder.isScheduled(workOrder) ? roundedQuantity : roundedScheduledQuantity;

  const content = `${renderedScheduledQuantity}/${roundedQuantity} ${unitOfMeasure}`;

  return (
    <Box data-testid="scheduled-quantity">
      {roundedQuantity === 0 ? (
        <QuantityError />
      ) : (
        <TruncatedField title={content}>
          {content} <strong>({scheduledTime})</strong> scheduled
        </TruncatedField>
      )}
    </Box>
  );
}
