import DateTime from "utils/DateTime";
import Enum from "utils/Enum";
import Timespan from "utils/Timespan";

export default function fillInGap(timeToSchedule, currentBlocks, shiftInstances) {
  const targetShifts = Enum.chain(shiftInstances)
    .map(buildTimespanFromShiftInstance)
    .filter((shiftTimespan) => shiftTimespan.include(timeToSchedule))
    .sortBy(["startsAt", "endsAt"])
    .value();

  let [result] = targetShifts;

  // Sentry reported an error where 'result' was undefined, which could not be reproduced. This error messaging is to get more info in the future.
  if (result === undefined)
    throw new Error(
      `fillInGap result undefined after getting 'targetShifts'; 
      timeToSchedule: ${timeToSchedule}; 
      currentBlocks: ${JSON.stringify(currentBlocks)}; 
      shiftInstances: ${JSON.stringify(shiftInstances)}; 
      targetShifts: ${JSON.stringify(targetShifts)}`,
    );

  const nextBlocks = Enum.chain(currentBlocks)
    .map(pluckStartsAtFromBlock)
    .sortBy()
    .filter((startsAt) => timeToSchedule < startsAt)
    .value();

  const prevBlocks = Enum.chain(currentBlocks)
    .map(pluckEndsAtFromBlock)
    .sortBy()
    .filter((endsAt) => endsAt <= timeToSchedule)
    .value();

  if (nextBlocks.length) {
    const nextBlock = nextBlocks[0];
    result = result.shrinkEndTo(nextBlock);

    // Sentry reported an error where 'result' was undefined, which could not be reproduced. This error messaging is to get more info in the future.
    if (result === undefined)
      throw new Error(
        `fillInGap result undefined after calling 'shrinkEndTo'; 
        timeToSchedule: ${timeToSchedule}; 
        currentBlocks: ${JSON.stringify(currentBlocks)}; 
        shiftInstances: ${JSON.stringify(shiftInstances)}; 
        targetShifts: ${JSON.stringify(targetShifts)}
        nextBlocks: ${JSON.stringify(nextBlocks)};
        prevBlocks: ${JSON.stringify(prevBlocks)}`,
      );
  }

  if (prevBlocks.length) {
    const prevBlock = prevBlocks[prevBlocks.length - 1];
    result = result.shrinkStartTo(prevBlock);

    // Sentry reported an error where 'result' was undefined, which could not be reproduced. This error messaging is to get more info in the future.
    if (result === undefined)
      throw new Error(
        `fillInGap result undefined after calling 'shrinkStartTo'; 
        timeToSchedule: ${timeToSchedule}; 
        currentBlocks: ${JSON.stringify(currentBlocks)}; 
        shiftInstances: ${JSON.stringify(shiftInstances)}; 
        targetShifts: ${JSON.stringify(targetShifts)}
        nextBlocks: ${JSON.stringify(nextBlocks)};
        prevBlocks: ${JSON.stringify(prevBlocks)}`,
      );
  }

  return { startsAt: result.startTime, endsAt: result.endTime };
}

function buildTimespanFromShiftInstance({ data: { startDate, endDate } }) {
  return new Timespan(startDate, endDate);
}

function pluckStartsAtFromBlock(block) {
  return DateTime.ensureDate(block.startsAt);
}

function pluckEndsAtFromBlock(block) {
  return DateTime.ensureDate(block.endsAt);
}
