import React from "react";
import styled from "styled-components";
import { Box, theme } from "@nulogy/components";

const Checkbox = styled.div(
  ({ overridden, interactive }) => `
  position: relative;
  height: 24px;
  width: 24px;
  margin: 2px;
  label {
    background-color: #fff;
    border: 1px solid ${overridden ? "transparent" : "#ccc"};
    border-radius: 4px;
    cursor: ${interactive ? "pointer" : "default"};
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }

  input[type="checkbox"] {
    visibility: hidden;
  }

  input[type="checkbox"]:disabled + label {
    border-color: ${theme.colors.lightGrey};
    background-color: ${theme.colors.lightGrey};
    cursor: default;
  }

  input[type="checkbox"]:checked + label {
    background-color: #216beb;
    border-color: ${overridden ? "transparent" : theme.colors.lightGrey};
  }

  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
  }
`,
);

export default function BaseAvailabilityCheckbox({ overridden, children, interactive = true }) {
  const borderColor = overridden ? theme.colors.yellow : "transparent";

  return (
    <Box data-testid="border" border={`2px solid ${borderColor}`} borderRadius={6}>
      <Checkbox overridden={overridden} interactive={interactive}>
        {children}
      </Checkbox>
    </Box>
  );
}
