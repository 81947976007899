import React from "react";
import { Box, Flex, Heading3 } from "@nulogy/components";
import DatePicker from "components/controls/DatePicker";
import DowntimeReasonSelect from "components/pages/schedule/downtimeBlocks/DowntimeReasonSelect";
import FormErrors from "components/controls/FormErrors";
import Form from "components/controls/Form";
import LockToggle from "components/controls/LockToggle";
import Select from "components/controls/Select";
import DateTime from "utils/DateTime";
import DowntimeBlock from "domain/DowntimeBlock";

export default function CreateDowntimeBlockForm(props) {
  const {
    onClose,
    onSave,
    lines,
    errors,
    setErrors,
    blockData: { block, startDate, endDate },
  } = props;
  const lineOptions = lines.map((line) => ({ label: line.name, value: line.id }));

  const initialValues = {
    downtimeBlock: DowntimeBlock.build({
      line: block.line,
      startsAt: DateTime.toISO8601(startDate),
      endsAt: DateTime.toISO8601(endDate),
      reason: "",
    }),
  };

  const validate = (data) => {
    const validationErrors = [];

    if (new Date(data.downtimeBlock.startsAt) >= new Date(data.downtimeBlock.endsAt)) {
      validationErrors.push("End time must be after start time");
    }

    setErrors({ base: validationErrors });
  };

  const handleSave = (data) =>
    new Promise(() => {
      onSave({ ...data.downtimeBlock, __typename: "DowntimeBlock" });
      onClose();
    });

  return (
    <Form id="createDowntime" validate={validate} initialValues={initialValues} onSave={handleSave}>
      {({
        values: {
          downtimeBlock: { lockedOnSchedule },
        },
      }) => (
        <>
          <Flex justifyContent="space-between" alignItems="center" paddingY="15px">
            <Box>
              <Heading3 className="spec-scheduling-header">Scheduling Details</Heading3>
            </Box>
            <Box>
              <LockToggle field="downtimeBlock[lockedOnSchedule]" />
            </Box>
          </Flex>
          {errors.base.length > 0 && <FormErrors errors={errors} />}
          <DowntimeReasonSelect lockedOnSchedule={lockedOnSchedule} />
          <Box marginBottom="x2">
            <Select
              className="spec-line-select"
              field="downtimeBlock[line][id]"
              labelText="Line"
              options={lineOptions}
              disabled={lockedOnSchedule}
            />
          </Box>
          <Box marginBottom="x2">
            <Flex className="customDatePicker">
              <Box width={1 / 2} marginRight="x3">
                <DatePicker
                  field="downtimeBlock[startsAt]"
                  labelText="Starts at"
                  className="spec-startsAt"
                  disabled={lockedOnSchedule}
                />
              </Box>
              <Box width={1 / 2} marginLeft="x3">
                <DatePicker
                  field="downtimeBlock[endsAt]"
                  labelText="Ends at"
                  className="spec-endsAt"
                  disabled={lockedOnSchedule}
                />
              </Box>
            </Flex>
          </Box>
        </>
      )}
    </Form>
  );
}
