import moment from "moment";

const SECONDS_IN_HOUR = 3600;
const MILLISECONDS_IN_SECOND = 1000;
const DAYS_OF_WEEK = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const DateTime = {
  DAYS_OF_WEEK,
  addDuration,
  addDurationDate,
  dateRange,
  dayOfWeekName,
  differenceInDays,
  duration,
  endOfDay,
  ensureDate,
  format,
  hoursToSeconds,
  isInTheFuture,
  isStartOfWeek,
  isToday,
  isSameDate,
  momentDate,
  now,
  secondsToHours,
  secondsToMilliseconds,
  startOfDay,
  startOfWeek,
  subtractDuration,
  timeAgo,
  timeFromNow,
  toISO8601,
  toISO8601Date,
  roundDownToNearestIncrement,
};

function now() {
  return moment(new Date());
}

function toISO8601(date) {
  if (!date) {
    return date;
  }

  return moment(date).format();
}

function dayOfWeekName(date) {
  return moment(date).format("dddd");
}

function toISO8601Date(date) {
  if (!date) {
    return date;
  }

  return moment(date).format("YYYY-MM-DD");
}

function startOfDay(date) {
  return moment(date).startOf("day");
}

function startOfWeek(date) {
  return moment(date).startOf("isoWeek");
}

function endOfDay(date) {
  return moment(date).endOf("day");
}

function secondsToHours(seconds) {
  return seconds / SECONDS_IN_HOUR;
}

function hoursToSeconds(hours) {
  return hours * SECONDS_IN_HOUR;
}

function secondsToMilliseconds(seconds) {
  return seconds * MILLISECONDS_IN_SECOND;
}

function subtractDuration(dateTime, amount, unit) {
  return moment(dateTime).subtract(amount, unit).format();
}

function addDuration(dateTime, amount, unit) {
  return moment(dateTime).add(amount, unit).format();
}

function addDurationDate(dateTime, amount, unit) {
  return moment(dateTime).add(amount, unit).toDate();
}

function duration(startsAt, endsAt) {
  return moment(endsAt).diff(moment(startsAt), "seconds");
}

function dateRange(startsAt, endsAt) {
  const startDate = moment(toISO8601Date(startsAt));
  const endDate = moment(toISO8601Date(endsAt));

  const days = [];
  let day = startDate;

  while (day <= endDate) {
    days.push(toISO8601Date(day));
    day = day.clone().add(1, "d");
  }

  return days;
}

function format(date, formatString) {
  return moment(date).format(formatString);
}

function differenceInDays(startDate, endDate) {
  return moment(endDate).diff(moment(startDate), "days");
}

function isInTheFuture(date) {
  return moment().isBefore(date);
}

function isToday(date) {
  const today = moment();

  return isSameDate(today, date);
}

function isStartOfWeek(date) {
  const today = moment();

  return isSameDate(startOfWeek(today), date);
}

function isSameDate(date1, date2) {
  return (
    moment(date1).isSame(date2, "day") && moment(date1).isSame(date2, "month") && moment(date1).isSame(date2, "year")
  );
}

function timeAgo(amount, unit) {
  return moment().subtract(amount, unit).toDate();
}

function timeFromNow(amount, unit) {
  return moment().add(amount, unit).toDate();
}

function ensureDate(date) {
  return moment(date).toDate();
}

function roundDownToNearestIncrement(date, incrementMinutes) {
  const mDate = moment(date);
  const remainder = mDate.minute() % incrementMinutes;

  return mDate.subtract(remainder, "minutes").seconds(0).milliseconds(0).toDate();
}

// Here for historical reasons, be careful about using this without toDate() or format()
function momentDate(date) {
  return moment(date);
}

export default DateTime;
