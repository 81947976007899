import { GetAllLines } from "hooks/apollo/line/line.gql";

export function reorderLines(newLines, proxy) {
  write(newLines, proxy);
}

function write(lines, proxy) {
  proxy.writeQuery({
    query: GetAllLines,
    data: { lines },
  });
}
