import DateTime from "utils/DateTime";

const WorkBlockFactory = {
  buildWithBlockTime,
  buildWithWorkTime,
  updateBlockTime,
  updateTeardownTime,
  updateWorkTime,
};

function buildWithBlockTime(workBlock) {
  const { startsAt, endsAt, teardownTimeDuration } = workBlock;

  _validateType(workBlock);

  return _build({
    ...workBlock,
    workTimeEndsAt: DateTime.subtractDuration(endsAt, teardownTimeDuration, "seconds"),
    workTimeStartsAt: startsAt,
  });
}

function buildWithWorkTime(workBlock) {
  const { workTimeStartsAt, workTimeEndsAt, teardownTimeDuration } = workBlock;

  _validateType(workBlock);

  return _build({
    ...workBlock,
    startsAt: workTimeStartsAt,
    endsAt: DateTime.addDuration(workTimeEndsAt, teardownTimeDuration, "seconds"),
  });
}

function updateBlockTime(workBlock, { startsAt, endsAt }) {
  return {
    ...workBlock,
    startsAt,
    endsAt,
    workTimeStartsAt: startsAt,
    workTimeEndsAt: DateTime.subtractDuration(endsAt, workBlock.teardownTimeDuration, "seconds"),
  };
}

function updateTeardownTime(workBlock, newTeardownTimeDuration) {
  return {
    ...workBlock,
    teardownTimeDuration: newTeardownTimeDuration,
    endsAt: DateTime.addDuration(workBlock.workTimeEndsAt, newTeardownTimeDuration, "seconds"),
  };
}

function updateWorkTime(workBlock, { workTimeStartsAt, workTimeEndsAt }) {
  return {
    ...workBlock,
    startsAt: workTimeStartsAt,
    endsAt: DateTime.addDuration(workTimeEndsAt, workBlock.teardownTimeDuration, "seconds"),
    workTimeStartsAt,
    workTimeEndsAt,
  };
}

function _build(workBlock) {
  return {
    teardownTimeLabel: "Teardown Time",
    teardownTimeDuration: 0,
    jobId: null,
    ...workBlock,
    __typename: "WorkBlock",
  };
}

function _validateType(workBlock) {
  if (workBlock.__typename && workBlock.__typename !== "WorkBlock") {
    throw new Error(`Attempt to build WorkBlock with object of different type: ${workBlock.__typename}`);
  }
}

export default WorkBlockFactory;
