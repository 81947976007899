import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import { Box } from "@nulogy/components";
import { GetDowntimeReasons } from "hooks/apollo/downtimeReason/downtimeReason.gql";
import Input from "components/controls/Input";
import Select from "components/controls/Select";
import DowntimeBlock from "domain/DowntimeBlock";

const inputType = (isCustom) => {
  if (isCustom) return "text";

  return "hidden";
};

const hasCustomReason = (downtime) => downtime && downtime.reasonCode === null;

export default function DowntimeReasonSelect({ downtimeBlock, lockedOnSchedule }) {
  const { data, loading } = useQuery(GetDowntimeReasons);
  const [isCustom, setIsCustom] = useState(hasCustomReason(downtimeBlock));
  const { setFieldValue } = useFormikContext();

  if (loading) return null;

  const { downtimeReasons } = data;

  const options = downtimeReasons.map((reason) => {
    const reasonDescription = DowntimeBlock.reasonDescription({ reason: reason.reason, reasonCode: reason.code });

    return {
      value: reason.code,
      label: reasonDescription,
    };
  });

  options.push({ label: "Custom reason", value: null });

  if (downtimeBlock?.reasonCode) {
    const reasonDescription = DowntimeBlock.reasonDescription(downtimeBlock);
    const matchingDowntimeReason = (option) =>
      option.value === downtimeBlock.reasonCode && option.label === reasonDescription;

    if (!options.find(matchingDowntimeReason)) {
      options.unshift({ value: downtimeBlock.reasonCode, label: reasonDescription });
    }
  }

  const handleDowntimeReasonChange = ({ value }) => {
    setIsCustom(value === null);

    if (value !== null) {
      const reason = downtimeReasons.find((downtimeReason) => downtimeReason.code === value);
      setFieldValue("downtimeBlock[reason]", reason.reason);
    } else {
      setFieldValue("downtimeBlock[reason]", "");
    }
  };

  const enableDropdown = options.length > 1;

  return (
    <>
      {enableDropdown && (
        <>
          <Select
            options={options}
            field="downtimeBlock[reasonCode]"
            onChange={handleDowntimeReasonChange}
            labelText="Reason"
            disabled={lockedOnSchedule}
            required
          />
          <Box marginBottom={isCustom && "x2"}>
            <Input
              type={inputType(isCustom)}
              field="downtimeBlock[reason]"
              data-testid="reason-input"
              disabled={lockedOnSchedule}
              required
            />
          </Box>
        </>
      )}
      {!enableDropdown && (
        <Box marginBottom="x2">
          <Input
            field="downtimeBlock[reason]"
            data-testid="reason-input"
            labelText="Reason"
            disabled={lockedOnSchedule}
            required
          />
        </Box>
      )}
    </>
  );
}
