import fillInGapTimespan from "components/pages/schedule/helpers/fillInGapTimespan";
import WorkBlock from "domain/WorkBlock";
import Block from "domain/Block";

const PLACEHOLDER_WORK_BLOCK_DURATION = 60 * 60 * 1000;

export default function dragToReschedule({ gapFiller }) {
  return function dragToRescheduleClosure({ eventRecords, context }) {
    const cursorPositionX = context.browserEvent.clientX;
    const {
      data: { block },
    } = eventRecords[0];
    const { newResource } = context;

    if (Block.isDowntime(block)) return;

    const line = newResource?.data;
    if (!line) return;

    if (!isMultiScheduling({ eventRecords })) {
      gapFiller.onTaskDrag({
        line,
        cursorPositionX,
        blockToReschedule: block,
      });
    }
    const timespanWithinGap = fillInGapTimespan({ block, gapFiller, line });

    const durationInSeconds =
      timespanWithinGap?.durationInSeconds() ||
      WorkBlock.calculateBlockDurationForLine(block, line, gapFiller._shouldFillInGap());

    const durationInMilliseconds = durationInSeconds * 1000 || PLACEHOLDER_WORK_BLOCK_DURATION;
    const width = this.timeAxisViewModel.getDistanceForDuration(durationInMilliseconds);

    context.context.element.style.width = `${width}px`; // eslint-disable-line
  };
}

function isMultiScheduling({ eventRecords }) {
  return eventRecords.length > 1;
}
