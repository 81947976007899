import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Box, DateRange, Heading1, Page } from "@nulogy/components";
import LaborChart from "components/charts/LaborChart";
import { GetShiftInstanceByRange } from "hooks/apollo/shiftInstance/shiftInstance.gql";
import useTimeZone from "hooks/useTimeZone";
import DateTime from "utils/DateTime";
import useTrackUsage from "hooks/apollo/usageData/useTrackUsage";
import useComponentDidMount from "hooks/useComponentDidMount";
import EventTypes from "hooks/apollo/usageData/EventTypes";

export default function LaborSummary() {
  const trackUsage = useTrackUsage();
  useComponentDidMount(() => trackUsage(EventTypes.LABOR_SUMMARY));

  const [scheduledStart, setScheduledStart] = useState(new Date());
  const [scheduledEnd, setScheduledEnd] = useState(DateTime.timeFromNow(13, "days"));

  const { overrideToSiteTimeZone } = useTimeZone();

  const { data } = useQuery(GetShiftInstanceByRange, {
    variables: {
      scheduledStart: overrideToSiteTimeZone(DateTime.startOfDay(scheduledStart).toDate()),
      scheduledEnd: overrideToSiteTimeZone(DateTime.endOfDay(scheduledEnd).toDate()),
    },
  });

  let laborData = [];

  if (data) {
    laborData = data.shiftInstances.map((shiftInstance) => ({
      name: name(shiftInstance),
      "Max labor": shiftInstance.maxLabor,
      "Starting labor": shiftInstance.startingLabor,
    }));
  }

  return (
    <Page>
      <Heading1>Labor summary</Heading1>
      <Box marginBottom="x4">
        <DateRange
          labelProps={{ labelText: "Date range" }}
          onRangeChange={({ startDate, endDate }) => {
            setScheduledStart(startDate);
            setScheduledEnd(endDate);
          }}
          defaultStartDate={scheduledStart}
          defaultEndDate={scheduledEnd}
        />
      </Box>
      <LaborChart data={laborData} />
    </Page>
  );
}

function name(shiftInstance) {
  const date = DateTime.format(shiftInstance.scheduledStart, "MMM DD");

  return `${date} ${shiftInstance.name}`;
}
