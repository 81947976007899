export default class ServerAuthorizationPolicy {
  constructor(serverPolicies) {
    this.serverPolicies = serverPolicies;
  }

  canCreate(entityType) {
    return this.isAllowed(entityType, "create");
  }

  canShow(entityType) {
    return this.isAllowed(entityType, "show");
  }

  canUpdate(entityType) {
    return this.isAllowed(entityType, "update");
  }

  canDelete(entityType) {
    return this.isAllowed(entityType, "delete");
  }

  canReset(entityType) {
    return this.isAllowed(entityType, "reset");
  }

  canImport(entityType) {
    return this.isAllowed(entityType, "import");
  }

  canExport(entityType) {
    return this.isAllowed(entityType, "export");
  }

  canSync(entityType) {
    return this.isAllowed(entityType, "sync");
  }

  canSyncAll(entityType) {
    return this.isAllowed(entityType, "sync_all");
  }

  canPublishSchedule(entityType) {
    return this.isAllowed(entityType, "publish_schedule");
  }

  canPublishWorkBlock(entityType) {
    return this.isAllowed(entityType, "publish_work_block");
  }

  isAllowed(entityType, action) {
    const policy = this.getServerPolicy(entityType);

    if (action in policy) {
      return policy[action];
    } else {
      throw new Error(`"${action}" policy for ${entityType} cannot be found`);
    }
  }

  getServerPolicy(entityType) {
    if (!this.serverPolicies[entityType]) throw new Error(`No policy found for data type ${entityType}`);

    return this.serverPolicies[entityType];
  }

  canSeeVersion() {
    return true;
  }
}
