import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import { Alert, List, ListItem } from "@nulogy/components";
import styled from "styled-components";
import String from "utils/String";

const ErrorMessages = styled(List)`
  margin-left: -20px;
`;

const renderErrorLine = (errorMessage) => {
  if (!errorMessage) return null;

  return <ListItem key={errorMessage}>{errorMessage}</ListItem>;
};

function FormErrors(props) {
  const { errors } = props;
  const gqlError = get(errors, "gqlError");
  const baseErrors = get(errors, "base");

  if (!gqlError && !baseErrors) return null;

  const errorCount = gqlError ? 1 : baseErrors.length;

  return (
    <Alert
      data-testid="form-errors-alert"
      type="danger"
      title={`The following ${String.pluralize("error", errorCount)} occurred`}
    >
      {renderErrorLine(gqlError)}
      <ErrorMessages>{map(baseErrors, renderErrorLine)}</ErrorMessages>
    </Alert>
  );
}

export default FormErrors;
