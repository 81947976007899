import React, { useState } from "react";
import { Box, Icon, IconicButton, TruncatedText, theme } from "@nulogy/components";
import { useMutation } from "@apollo/client";
import { Draggable } from "react-beautiful-dnd";
import LineStatusIndicator from "./LineStatusIndicator";
import LineStatusSelect from "./LineStatusSelect";
import Num from "utils/Num";
import { GridBox7, StyledInput, StyledText } from "components/common/EditableListStyledComponents";
import FieldRow from "components/common/FieldRow";
import Form from "components/controls/Form";
import { UpdateLine } from "hooks/apollo/line/line.gql";

export default function Line({ line, onDelete, index }) {
  const [editing, setEditing] = useState(false);
  const [updateLine] = useMutation(UpdateLine, { onCompleted: () => setEditing(false) });

  const { id, name, lineType, efficiency, active } = line;

  const handleCancel = (onReset) => {
    onReset();
    setEditing(false);
  };

  const handleSave = (updatedLine) => {
    const efficiencyDecimal = updatedLine.efficiency === "" ? null : Num.percentToDecimal(updatedLine.efficiency);

    return updateLine({
      variables: {
        id: updatedLine.id,
        name: updatedLine.name,
        lineType: updatedLine.lineType,
        efficiency: efficiencyDecimal,
        active: updatedLine.active,
      },
    });
  };

  return (
    <Draggable key={line.id} draggableId={line.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {!editing && (
            <GridBox7 data-testid="line" className={`spec-line-${id}`}>
              <div {...provided.dragHandleProps} className={`spec-line-drag-${id} spec-line-drag`}>
                <StyledText>
                  <Icon icon="drag" color={theme.colors.darkBlue} />
                </StyledText>
              </div>
              <StyledText data-testid="line-name" className="spec-line-name">
                <TruncatedText fullWidth>{name}</TruncatedText>
              </StyledText>
              <StyledText data-testid="line-type">
                <TruncatedText fullWidth>{lineType}</TruncatedText>
              </StyledText>
              <StyledText data-testid="line-efficiency">{Num.decimalToPercent(efficiency)}</StyledText>
              <StyledText data-testid="line-active">
                <LineStatusIndicator value={active} />
              </StyledText>
              <IconicButton type="button" icon="edit" onClick={() => setEditing(true)}>
                Edit
              </IconicButton>
              <IconicButton type="button" icon="delete" onClick={() => onDelete(line)}>
                Delete
              </IconicButton>
            </GridBox7>
          )}
          {editing && (
            <Form
              initialValues={{ id, name, lineType, efficiency: Num.decimalToPercent(efficiency), active }}
              onSave={handleSave}
            >
              {({ dirty, handleReset }) => (
                <FieldRow marginBottom="none" data-testid="line-row-editing">
                  <GridBox7>
                    <div {...provided.dragHandleProps}>
                      <StyledText>
                        <Icon icon="drag" color={theme.colors.darkBlue} />
                      </StyledText>
                    </div>
                    <StyledInput field="name" placeholder="Name" />
                    <StyledInput field="lineType" placeholder="Type" />
                    <StyledInput
                      field="efficiency"
                      type="number"
                      step="any"
                      placeholder="Efficiency"
                      style={{ width: "112px" }}
                    />
                    <LineStatusSelect />
                    <Box paddingTop="x1">
                      <IconicButton type="submit" icon="check" disabled={!dirty}>
                        Save
                      </IconicButton>
                    </Box>
                    <Box paddingTop="x1">
                      <IconicButton type="button" icon="close" onClick={() => handleCancel(handleReset)}>
                        Cancel
                      </IconicButton>
                    </Box>
                  </GridBox7>
                </FieldRow>
              )}
            </Form>
          )}
        </div>
      )}
    </Draggable>
  );
}
