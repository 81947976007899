import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { GetWorkOrders } from "./workOrder.gql";
import buildFilterQueryParameters from "./helpers/buildFilterQueryParameters";
import { useSchedulerState } from "hooks/state";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useBryntumScheduler from "hooks/useBryntumScheduler";
import WorkOrderSearchContext from "contexts/WorkOrderSearchContext";
import { SIDE_BAR_LIMIT } from "config/appConfig";
import NetworkStateContext from "contexts/NetworkStateContext";
import Uuid from "utils/Uuid";

let lastQueryId = 0;

const debouncedFetchMore = ({ variables, fetchMore }) => {
  const queryId = Uuid.generate();
  lastQueryId = queryId;

  return fetchMore({
    variables,
    updateQuery: (previousResult, result) => {
      if (!result.fetchMoreResult || lastQueryId !== queryId) {
        return previousResult;
      }

      return result.fetchMoreResult;
    },
  });
};

export default function useGetWorkOrders() {
  const applicationContext = useApplicationContext();
  const { getBryntumScheduler } = useBryntumScheduler();
  const {
    state: { visibleTimeRange },
  } = useSchedulerState();

  const { trackLoadingState, isFetching } = useContext(NetworkStateContext);

  const buildQueryParameters = (innerSearchState) =>
    buildFilterQueryParameters({
      applicationContext,
      bryntumScheduler: getBryntumScheduler(),
      visibleTimeRange,
      ...innerSearchState,
      limit: SIDE_BAR_LIMIT,
    });

  const { state: searchState } = useContext(WorkOrderSearchContext);

  const { data, fetchMore, loading, error } = useQuery(GetWorkOrders, {
    variables: buildQueryParameters(searchState),
    notifyOnNetworkStatusChange: true,
  });

  const doFetchMore = ({
    searchText,
    searchCategory,
    filters,
    visibleTimeRange: newDataTimeRange = visibleTimeRange,
  }) => {
    trackLoadingState("GET_WORK_ORDERS", () => {
      const variables = buildQueryParameters({
        searchText,
        searchCategory,
        filters,
        visibleTimeRange: newDataTimeRange,
      });

      return debouncedFetchMore({ variables, fetchMore });
    });
  };

  return {
    workOrders: data?.workOrders,
    fetch: doFetchMore,
    data,
    loading,
    fetching: isFetching("GET_WORK_ORDERS"),
    error,
  };
}
