import React from "react";
import { Modal } from "@nulogy/components";
import PublishWorkBlockModalForm from "./PublishWorkBlockModalForm";
import usePublishWorkBlockModal from "hooks/state/usePublishWorkBlockModal";

export default function PublishWorkBlockModal() {
  const { close: closeModal, isOpen, workBlock } = usePublishWorkBlockModal();

  const close = () => {
    closeModal();
  };

  return (
    <Modal title="Publish to job" isOpen={isOpen} onRequestClose={close} ariaHideApp={false} maxWidth="816px">
      <PublishWorkBlockModalForm workBlock={workBlock} />
    </Modal>
  );
}
