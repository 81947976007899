import React from "react";
import { Box, Heading3, Table, theme } from "@nulogy/components";
import styled from "styled-components";

function ExecutionSummary({ testId, content }) {
  return <Box data-testid={testId}>{content}</Box>;
}

const TopAlignedTable = styled(Table)`
  margin-top: ${theme.sizes.x2};

  tr td {
    vertical-align: top;
    padding-top: ${theme.sizes.x2};
  }
`;

function PublishResultSection({ rows, title, testIdPrefix, summary, columns }) {
  if (rows.length === 0) return null;

  return (
    <>
      <Heading3 mt="x3">{title}</Heading3>
      <ExecutionSummary testId={`${testIdPrefix}-summary`} content={summary} />
      <TopAlignedTable data-testid={`${testIdPrefix}-table`} columns={columns} rows={rows} />
    </>
  );
}

export { ExecutionSummary, PublishResultSection };
