import React, { useContext } from "react";
import { Sidebar } from "@nulogy/components";
import FilterSidebarContainer from "./FilterSidebarContainer";
import WorkOrderDetailsContainer from "./workOrderDetails/WorkOrderDetailsContainer";
import UIStateContext from "contexts/UIStateContext";
import useSlideOutState from "hooks/state/useSlideOutState";

export default function SlideOutPanel() {
  const {
    state: { sidebarOpen },
  } = useContext(UIStateContext);
  const { isOpen, closeSlideOut: onClose, isFilterMode } = useSlideOutState();

  const showSidebar = isOpen();

  const handleCloseSlideOutPanel = () => {
    onClose();
  };

  return (
    <Sidebar
      isOpen={showSidebar}
      offset={sidebarOpen ? "400px" : "35px"}
      duration={sidebarOpen && !showSidebar ? 0.5 : 0.25}
      onClose={handleCloseSlideOutPanel}
      closeOnOutsideClick={false}
      overlay={false}
      title={isFilterMode() ? "Work order filters" : "Work order details"}
      closeButtonTestId={isFilterMode() ? "spec-filter-close-button" : "spec-work-order-details-close-button"}
      style={{ zIndex: 10 }}
    >
      <FilterSidebarContainer />
      <WorkOrderDetailsContainer />
    </Sidebar>
  );
}
