import { useMutation } from "@apollo/client";
import { TrackUsage } from "./usageData.gql";

export default function useTrackUsage() {
  const [trackUsage] = useMutation(TrackUsage);

  return (eventName) => {
    trackUsage({ variables: { eventName } });
  };
}
