import React from "react";
import { Button, DropdownButton } from "@nulogy/components";

export default function ExportScheduleView({ canExport, setExportScheduleModalOpen, isInDropDown, closeMenu }) {
  if (!canExport) return null;

  return (
    <>
      {isInDropDown ? (
        <DropdownButton
          data-testid="menu-button"
          onClick={(e) => {
            setExportScheduleModalOpen(true);
            closeMenu(e);
          }}
        >
          Export
        </DropdownButton>
      ) : (
        <Button
          data-testid="menu-button"
          onClick={() => {
            setExportScheduleModalOpen(true);
          }}
        >
          Export
        </Button>
      )}
    </>
  );
}
