import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { RescheduleBlocks } from "./block.gql";
import useBlockFactory from "./useBlockFactory";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import { useNotificationState } from "hooks/state";
import useFetchShiftInstances from "hooks/apollo/shiftInstance/useFetchShiftInstances";
import TimeZone from "utils/TimeZone";
import GqlError from "utils/GqlError";
import Block from "domain/Block";

export default function useRescheduleBlocks() {
  const { refetch: refetchShiftInstances } = useFetchShiftInstances();
  const blockFactory = useBlockFactory();
  const { setNotification } = useNotificationState();
  const [rescheduleBlocks] = useMutation(RescheduleBlocks, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to reschedule block(s)",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: () => {
      refetchShiftInstances();
    },
  });
  const applicationContext = useApplicationContext();

  return (blocks) => {
    const blocksToSchedule = blocks.map((block) => blockFactory(block));

    const workBlocks = blocksToSchedule.filter((block) => Block.isWorkBlock(block));
    const downtimeBlocks = blocksToSchedule.filter((block) => Block.isDowntime(block));

    const formattedWorkBlocks = workBlocks.map((workBlock) => ({
      id: workBlock.id,
      workTimeStartsAt: TimeZone.overrideZone(workBlock.workTimeStartsAt, applicationContext.timeZone.name),
      workTimeEndsAt: TimeZone.overrideZone(workBlock.workTimeEndsAt, applicationContext.timeZone.name),
      lineId: workBlock.line.id,
      workOrderId: workBlock.workOrder.id,
      lockedOnSchedule: workBlock.lockedOnSchedule,
      teardownTimeLabel: workBlock.teardownTimeLabel,
      teardownTimeDuration: workBlock.teardownTimeDuration,
      productionRateOverride: workBlock.productionRateOverride,
      performanceOverride: workBlock.performanceOverride,
      efficiencyOverride: workBlock.efficiencyOverride,
      laborOverride: workBlock.laborOverride,
    }));
    const formattedDowntimeBlocks = downtimeBlocks.map((downtimeBlock) => ({
      id: downtimeBlock.id,
      reason: downtimeBlock.reason,
      reasonCode: downtimeBlock.reasonCode,
      startsAt: TimeZone.overrideZone(downtimeBlock.startsAt, applicationContext.timeZone.name),
      endsAt: TimeZone.overrideZone(downtimeBlock.endsAt, applicationContext.timeZone.name),
      lineId: downtimeBlock.line.id,
      lockedOnSchedule: downtimeBlock.lockedOnSchedule,
    }));

    return rescheduleBlocks({
      variables: {
        workBlocks: formattedWorkBlocks,
        downtimeBlocks: formattedDowntimeBlocks,
      },
      optimisticResponse: {
        rescheduleBlocks: {
          workBlocks,
          downtimeBlocks,
          __typename: "RescheduleBlocksPayload",
        },
      },
    });
  };
}
