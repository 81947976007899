import Schedule from "domain/Schedule";

export default function fillInGapTimespan({ block, gapFiller, line }) {
  const fullShiftTimespan = gapFiller.timespanWithinGap();
  if (!fullShiftTimespan) {
    return null;
  }

  const gapBlock = {
    ...block,
    workOrder: { ...block.workOrder, unscheduledQuantity: block.workOrder.unscheduledQuantity + block.quantity },
    line: { ...line },
    lineEfficiency: line.efficiency,
  };

  return Schedule.shrinkTimeSpanToWorkOrderRemaining({
    remainingBlockDuration: Schedule.remainingDuration(gapBlock),
    timespan: fullShiftTimespan,
    existingBlock: block,
  });
}
