import { Card, Text } from "@nulogy/components";
import React from "react";

export default function CustomChartTooltip({ active, payload, label }) {
  if (!active || !payload || payload.length === 0) return null;

  return (
    <Card bg="white">
      <Text>{label}</Text>
      {payload.map(
        ({ name, value, fill }) =>
          value > 0 && (
            <Text key={name} color={fill} mt="half">
              {name} : {value}
            </Text>
          ),
      )}
    </Card>
  );
}
