import React from "react";
import { theme } from "@nulogy/components";
import styled from "styled-components";
import { formatQuantity } from "components/helpers/format";
import Schedule from "domain/Schedule";

const Wrapper = styled.div`
  padding-top: 0;
  font-size: 12px;
  line-height: ${theme.lineHeights.smallTextBase};
`;

const Units = styled.span`
  font-weight: bold;
`;

export default function ExpectedUnits({ workBlock, workTimeStartsAt, workTimeEndsAt }) {
  return (
    <Wrapper data-testid="expected-units">
      <Units>{formatQuantity(Schedule.resizedQuantity(workBlock, workTimeStartsAt, workTimeEndsAt))}</Units>{" "}
      {workBlock.unitOfMeasure} expected
    </Wrapper>
  );
}
