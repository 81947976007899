import { useQuery } from "@apollo/client";
import { GetAllBlocks } from "./block.gql";
import { useSchedulerState } from "hooks/state";
import useTimeZone from "hooks/useTimeZone";

export default function useGetBlocks() {
  const {
    state: { dataTimeRange },
  } = useSchedulerState();
  const { overrideToSiteTimeZone } = useTimeZone();

  const { data, loading, error, fetchMore } = useQuery(GetAllBlocks, {
    variables: {
      startsAt: overrideToSiteTimeZone(dataTimeRange.startDate),
      endsAt: overrideToSiteTimeZone(dataTimeRange.endDate),
    },
  });

  return {
    blocks: data?.blocks,
    data,
    loading,
    error,
    refetch: ({ dataTimeRange: newDataTimeRange } = {}) => {
      const actualStart = newDataTimeRange?.startDate || dataTimeRange.startDate;
      const actualEnd = newDataTimeRange?.endDate || dataTimeRange.endDate;

      return fetchMore({
        variables: { startsAt: overrideToSiteTimeZone(actualStart), endsAt: overrideToSiteTimeZone(actualEnd) },
      });
    },
  };
}
