import map from "lodash/map";
import flatMap from "lodash/flatMap";
import classNames from "classnames";
import styles from "./eventBuilders.module.scss";
import WorkOrder from "domain/WorkOrder";
import WorkBlock from "domain/WorkBlock";
import shiftHeaderRenderer from "components/common/Scheduler/SchedulerRenderers/renderers/shiftHeaderRenderer";

export function buildShiftTimeRanges(shifts) {
  return map(shifts, (shiftInstance) => {
    const shiftHeader = shiftHeaderRenderer({ shiftInstance });
    const uniqueId = `${shiftInstance.name}$${shiftInstance.maxLabor}$${shiftInstance.startingLabor}$${shiftInstance.shiftId}$${shiftInstance.scheduledStart}$${shiftInstance.scheduledEnd}`;

    return {
      id: uniqueId,
      name: shiftHeader,
      startDate: shiftInstance.scheduledStart,
      endDate: shiftInstance.scheduledEnd,
    };
  });
}

export function buildUnavailabilityTimeRanges(unavailabilities) {
  return map(unavailabilities, (unavailability) => {
    const uniqueId = `$${unavailability.line.name}$${unavailability.shift.name}$-${unavailability.startsAt}`;

    return {
      id: uniqueId,
      resourceId: unavailability.line.id,
      startDate: unavailability.startsAt,
      endDate: unavailability.endsAt,
      name: "Unavailable",
      cls: "unavailableBlock spec-unavailability-time-range",
    };
  });
}

export function buildDowntimeBlockEvents(downtimeBlocks) {
  return map(downtimeBlocks, (downtimeBlock) => ({
    id: downtimeBlock.id,
    startDate: new Date(downtimeBlock.startsAt),
    endDate: new Date(downtimeBlock.endsAt),
    draggable: !downtimeBlock.lockedOnSchedule,
    resizable: !downtimeBlock.lockedOnSchedule,
    cls: styles.downtimeBlock,
    resourceId: downtimeBlock.line.id,
    block: downtimeBlock,
  }));
}

export function buildWorkBlockEvents(queriedWorkBlocks) {
  const workOrders = groupWorkBlocksByWorkOrders(queriedWorkBlocks);

  return flatMap(workOrders, (workBlocks) =>
    map(workBlocks, (workBlock) => {
      const blockEvent = buildBlockEvent(workBlock);
      const cardStatus = WorkOrder.cardStatus(workBlock.workOrder);
      const workBlockIdClass = `work-block-${workBlock.id}`;
      const borderStatus = borderCardStatus(workBlock);
      const workBlockBorderClass = styles[`${borderStatus}Border`];

      return {
        ...blockEvent,
        id: workBlock.id,
        startDate: new Date(workBlock.startsAt),
        endDate: new Date(workBlock.endsAt),
        draggable: !workBlock.lockedOnSchedule,
        resizable: !workBlock.lockedOnSchedule,
        eventStyle: "null",
        cls: classNames(workBlockIdClass, styles[cardStatus], workBlockBorderClass),
        block: workBlock,
      };
    }),
  );
}

function borderCardStatus(workBlock) {
  const pastDue = WorkBlock.isPastDue(workBlock);
  const cardStatus = WorkOrder.cardStatus(workBlock.workOrder);

  return pastDue ? "pastDue" : cardStatus;
}

function buildBlockEvent(block) {
  return {
    resourceId: block.line.id,
  };
}

function groupWorkBlocksByWorkOrders(workBlocks) {
  const workOrders = {};

  workBlocks.forEach((workBlock) => {
    const workOrderId = workBlock.workOrder.id;

    if (workOrders[workOrderId]) {
      workOrders[workOrderId].push(workBlock);
    } else {
      workOrders[workOrderId] = [workBlock];
    }
  });

  return workOrders;
}
