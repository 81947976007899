import React from "react";
import noop from "lodash/noop";
import WorkOrderCardDetails from "./WorkOrderCardDetails";
import WorkOrderCardWrapper from "./WorkOrderCardWrapper";
import WorkOrderCardStatus from "./WorkOrderCardStatus";
import ProductionProgressBarOld from "components/pages/schedule/workOrderCards/ProductionProgressBarOld";
import WorkOrderCardMenuOld from "components/pages/schedule/workOrderCards/WorkOrderCardMenuOld";
import Authorization from "policies/Authorization";

export default function MockWorkOrderCardContainerOld({ policy, workOrder, applicationContext }) {
  return (
    <WorkOrderCardWrapper workOrderId={workOrder.id}>
      <WorkOrderCardDetails
        onOpenWorkOrderDetails={noop}
        applicationContext={applicationContext}
        workOrder={workOrder}
        workOrderForDetailsViewId={null}
        userCanUpdate={policy.canUpdate(Authorization.EntityTypes.WorkOrder)}
      />
      <WorkOrderCardStatus workOrder={workOrder} />
      <ProductionProgressBarOld {...workOrder} />
      <WorkOrderCardMenuOld
        onOpenWorkOrderDetails={noop}
        onUnscheduleWorkOrder={noop}
        onRemoveWorkOrder={noop}
        updateWorkOrder={noop}
        workOrder={workOrder}
        userCanUpdate={policy.canUpdate(Authorization.EntityTypes.WorkOrder)}
      />
    </WorkOrderCardWrapper>
  );
}
