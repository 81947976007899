import React from "react";
import { Box, DropdownButton, DropdownMenu, Flex, QuietButton, TruncatedText, theme } from "@nulogy/components";
import styled from "styled-components";
import { columnWidth } from "./WidthCalculator";
import DateTime from "utils/DateTime";
import Time from "utils/Time";

const CenteredHeader = styled(Flex)(
  ({ shiftCount }) => `
  align-items: center;
  justify-content: center;
  min-width: ${columnWidth(shiftCount)}px;
  max-width: ${columnWidth(shiftCount)}px;
`,
);

const ShiftName = styled(TruncatedText)(({ shiftCount }) => ({
  color: theme.colors.darkGrey,
  paddingTop: theme.sizes.half,
  paddingBottom: theme.sizes.half,
  paddingLeft: theme.sizes.half,
  paddingRight: theme.sizes.half,
  textAlign: "center",
  fontWeight: "normal",
  maxWidth: `${columnWidth(shiftCount)}px`,
}));

const ShiftHeaderButton = styled(QuietButton)(({ shiftCount }) => ({
  padding: 0,
  margin: 0,
  display: "block",
  color: theme.colors.black,
  minWidth: `${columnWidth(shiftCount)}px`,
  maxWidth: `${columnWidth(shiftCount)}px`,
}));

const ShiftTime = ({ children }) => (
  <Box
    fontSize="12px"
    fontWeight="300"
    lineHeight="12px"
    color={theme.colors.midGrey}
    textAlign="center"
    paddingRight="half"
    paddingBottom="x1"
    paddingLeft="half"
  >
    {children}
  </Box>
);

function ShiftInfo({ shift, shiftCount }) {
  return (
    <>
      <Flex fontWeight={theme.fontWeights.normal} textAlign="center">
        <ShiftName shiftCount={shiftCount} fullWidth>
          {shift.name}
        </ShiftName>
      </Flex>
      <Flex justifyContent="center">
        <ShiftTime>
          {Time.to12HourShort(shift.startTime)} -
          <br />
          {Time.to12HourShort(shift.endTime)}&nbsp;&nbsp;
        </ShiftTime>
      </Flex>
    </>
  );
}

function shiftEnabled(shift, date) {
  const dayOfWeek = DateTime.dayOfWeekName(date).toLowerCase();

  return shift[dayOfWeek];
}

export default function AvailabilityShiftHeader({ shift, date, selectAllForShift, deselectAllForShift, shiftCount }) {
  if (!shiftEnabled(shift, date))
    return (
      <CenteredHeader shiftCount={shiftCount}>
        <Box
          marginTop="1px"
          marginBottom="1px"
          minWidth={`${columnWidth(shiftCount)}px`}
          maxWidth={`${columnWidth(shiftCount)}px`}
        >
          <ShiftInfo shiftCount={shiftCount} shift={shift} />
        </Box>
      </CenteredHeader>
    );

  return (
    <CenteredHeader shiftCount={shiftCount}>
      <DropdownMenu
        placement="bottom-start"
        trigger={() => (
          <ShiftHeaderButton shiftCount={shiftCount}>
            <ShiftInfo shift={shift} shiftCount={shiftCount} />
          </ShiftHeaderButton>
        )}
      >
        {({ closeMenu }) => (
          <>
            <DropdownButton
              type="button"
              onClick={(e) => {
                selectAllForShift(shift, date);
                closeMenu(e);
              }}
            >
              Select&nbsp;all
            </DropdownButton>
            <DropdownButton
              type="button"
              onClick={(e) => {
                deselectAllForShift(shift, date);
                closeMenu(e);
              }}
            >
              Deselect&nbsp;all
            </DropdownButton>
          </>
        )}
      </DropdownMenu>
    </CenteredHeader>
  );
}
