import React from "react";
import styled from "styled-components";
import { theme } from "@nulogy/components";
import { formatQuantity } from "components/helpers/format";

const ProgressBar = styled.div`
  height: 6px;
  grid-column: 2 / 2;
  background-color: ${theme.colors.lightGrey};
  border-radius: 3px;
`;

const Progress = styled.span`
  display: block;
  width: ${({ producedProgressBarWidth }) => `${producedProgressBarWidth}%`};
  height: 100%;
  border-radius: 3px;
  background-color: ${({ producedProgressBarWidth }) =>
    producedProgressBarWidth >= 100 ? theme.colors.green : theme.colors.blue};
`;

export default function ProductionProgressBar({ producedQuantity, quantity }) {
  function calculateProgressBarWidth() {
    if (!producedQuantity || !quantity) return 0;

    const roundedProducedQuantity = formatQuantity(producedQuantity);
    const roundedQuantity = formatQuantity(quantity);
    const producedPercentage = Math.floor((roundedProducedQuantity / roundedQuantity) * 100);

    return Math.min(producedPercentage, 100);
  }

  const producedProgressBarWidth = calculateProgressBarWidth();

  return (
    <ProgressBar>
      <Progress data-testid="progress" producedProgressBarWidth={producedProgressBarWidth} />
    </ProgressBar>
  );
}
