import React from "react";
import { Box, Text } from "@nulogy/components";
import BlockTooltip from "./BlockTooltip";
import DowntimeBlock from "domain/DowntimeBlock";

export default function DowntimeBlockTooltip({ downtimeBlock, startDate, endDate }) {
  const reasonDescription = DowntimeBlock.reasonDescription(downtimeBlock);

  return (
    <Box className="b-sch-event-tooltip spec-downtime-tooltip">
      <Text mb="x1" data-testid="reason">
        {reasonDescription}
      </Text>
      <BlockTooltip startDate={startDate} endDate={endDate} />
    </Box>
  );
}
