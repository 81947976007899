import styled from "styled-components";
import { Box, theme } from "@nulogy/components";

const LineTypeTag = styled(Box)`
  background-color: ${theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${theme.colors.midGrey};
  color: ${theme.colors.midGrey};
  font-size: ${theme.fontSizes.smaller};
  font-weight: ${theme.fontWeights.normal};
  line-height: ${theme.lineHeights.smallerText};
  margin-right: ${theme.space.x1};
  padding: 2px 4px;
  width: max-content;
`;

export default LineTypeTag;
