import React from "react";
import { Flex, theme } from "@nulogy/components";
import styled from "styled-components";
import AvailabilityCheckbox from "./AvailabilityCheckbox";
import { columnWidth } from "./WidthCalculator";
import DateTime from "utils/DateTime";

const CenteredCell = styled(Flex)({
  paddingTop: theme.sizes.x2,
  paddingBottom: theme.sizes.x2,
  alignItems: "center",
  justifyContent: "center",
});

export default function AvailabilityCell({ column, row }) {
  const shiftId = column.dataKey;
  const { shifts } = column.metadata;
  const col = row[shiftId];

  const formattedDate = DateTime.toISO8601Date(col.date);

  return (
    <CenteredCell height="56px" maxWidth={`${columnWidth(shifts.length)}px`}>
      <AvailabilityCheckbox
        className={`spec-availability-override-${formattedDate}`}
        data-testid={`${formattedDate}-${col.line}-${col.shift}`}
        disabled={col.disabled}
        field={`availabilities[${col.index}]['available']`}
        defaultAvailability={col.defaultAvailability}
      />
    </CenteredCell>
  );
}
