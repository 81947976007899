import React from "react";
import ExportScheduleView from "./ExportScheduleView";
import endpoints from "config/endpoints";
import Authorization from "policies/Authorization";

export const exportUrlBuilder =
  (overrideToSiteTimeZone) =>
  ({ startDate, endDate }, includeUnavailability) => {
    const overriddenFromDate = overrideToSiteTimeZone(startDate);
    let overriddenToDate = "";
    if (endDate) overriddenToDate = overrideToSiteTimeZone(endDate);

    return `${endpoints.exportSchedule}?from_date=${overriddenFromDate}&to_date=${overriddenToDate}&include_unavailability=${includeUnavailability}`;
  };

export default function ExportScheduleContainer({ isInDropDown, closeMenu, setExportScheduleModalOpen }) {
  const policy = Authorization.usePolicy();

  return (
    <ExportScheduleView
      setExportScheduleModalOpen={setExportScheduleModalOpen}
      canExport={policy.canExport(Authorization.EntityTypes.WorkBlock)}
      isInDropDown={isInDropDown}
      closeMenu={closeMenu}
    />
  );
}
