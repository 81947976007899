import React, { useRef } from "react";
import { Button, ButtonGroup, Modal, PrimaryButton } from "@nulogy/components";
import useConfirmDefaultAvailabilityChangesModal from "hooks/state/useConfirmDefaultAvailabilityChangesModal";

export default function ConfirmDefaultAvailabilityChangesModal() {
  const { close, isOpen } = useConfirmDefaultAvailabilityChangesModal();
  const submitButton = useRef();

  const submit = () => {
    submitButton.current.click();
  };

  const footerContent = (
    <ButtonGroup>
      <PrimaryButton onClick={submit} type="submit" data-testid="save-in-confirmation">
        Save and apply to schedule
      </PrimaryButton>
      <Button onClick={close}>Cancel</Button>
    </ButtonGroup>
  );

  return (
    <>
      <input type="submit" style={{ display: "none" }} ref={submitButton} />
      <Modal
        ariaHideApp={false}
        title="Changing default availability"
        footerContent={footerContent}
        isOpen={isOpen}
        onRequestClose={close}
      >
        The changes made to default availability will impact the schedule’s past, present, and future availability.
        <br />
        <br />
        Blocks scheduled in the past will not be moved, but may overlap with the unavailable slots.
      </Modal>
    </>
  );
}
