import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { SplitWorkBlockAcrossShifts } from "./workBlock.gql";
import useFetchShiftInstances from "hooks/apollo/shiftInstance/useFetchShiftInstances";
import { useNotificationState } from "hooks/state";
import GqlError from "utils/GqlError";

export default function useSplitWorkBlockAcrossShifts() {
  const { refetch: refetchShiftInstances } = useFetchShiftInstances();
  const { setNotification } = useNotificationState();
  const [splitWorkBlockAcrossShifts] = useMutation(SplitWorkBlockAcrossShifts, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to split work block",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: () => {
      refetchShiftInstances();
    },
  });

  return (workBlock) =>
    splitWorkBlockAcrossShifts({
      variables: {
        workBlockId: workBlock.id,
      },
    });
}
