import React from "react";
import { Heading2, Modal } from "@nulogy/components";
import PublishScheduleFormContainer from "./PublishScheduleFormContainer";
import usePublishScheduleModal from "hooks/state/usePublishScheduleModal";

const Heading = () => (
  <Heading2 inline mb="none">
    Publish to jobs
  </Heading2>
);

export default function PublishScheduleModal() {
  const { close, isOpen } = usePublishScheduleModal();

  return (
    <Modal isOpen={isOpen} ariaHideApp={false} title={<Heading />} onRequestClose={close}>
      <PublishScheduleFormContainer />
    </Modal>
  );
}
