import DateTime from "utils/DateTime";
import TimeZone from "utils/TimeZone";

const READONLY_ROLE = "readonly";
const DEFAULT_TIME_ZONE = "Etc/UTC";
const DEFAULT_START_OF_WORK_DAY = "00:00";

const ApplicationContext = {
  sessionIdentifier,
  overrideToSiteTimeZone,
  startOfWorkWeek,
  startOfWorkDay,
  endOfWorkDay,
  startOfWorkDayForWorkBlock,
  isReadonly,
  isStandalone,
};

function sessionIdentifier({ user, site }) {
  return `${user?.email}-${site?.id}`;
}

function overrideToSiteTimeZone({ timeZone }, dateTime) {
  const timeZoneName = timeZone?.name || DEFAULT_TIME_ZONE;

  return TimeZone.overrideZone(dateTime, timeZoneName);
}

function startOfWorkWeek({ site }, date) {
  return startOfWorkDay({ site }, DateTime.startOfWeek(date));
}

function startOfWorkDay({ site }, date) {
  const startOfDayString = site?.startOfDay || DEFAULT_START_OF_WORK_DAY;

  const dateString = DateTime.toISO8601Date(DateTime.startOfDay(date));
  const startOfWorkdayString = `${dateString}T${startOfDayString}`;

  return new Date(startOfWorkdayString);
}

function endOfWorkDay(applicationContext, date) {
  return DateTime.momentDate(startOfWorkDay(applicationContext, date)).add(1, "day").subtract(1, "second").toDate();
}

function startOfWorkDayForWorkBlock(applicationContext, date) {
  const {
    site: { startOfDay: startOfDayString },
  } = applicationContext;

  const dateTime = DateTime.format(date, "HH:mm");
  const startDate = dateTime >= startOfDayString ? date : DateTime.startOfDay(date).subtract(1, "day").toDate();

  return ApplicationContext.startOfWorkDay(applicationContext, startDate);
}

function isReadonly(applicationContext) {
  return applicationContext.role === READONLY_ROLE;
}

function isStandalone({ settings }) {
  return Boolean(settings?.productLedGrowthCustomer);
}

export default ApplicationContext;
