import styled from "styled-components";

const colors = {
  red: "#bc0010",
  green: "#43aa27",
  yellow: "#efc822",
  orange: "#f17b27",
  blue: "#1b5c8c",
  gray: "#686868",
  blank: "#ffffff",
};

const getBackgroundImage = (color) => {
  switch (color) {
    case "green":
      return "background-image: linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, 0.3) 50%);";
    case "orange":
      return "background-image: linear-gradient(135deg, transparent 50%, rgba(255, 255, 255, 0.3) 50%);";
    case "blue":
      return "background-image: linear-gradient(45deg, transparent 50%, rgba(255, 255, 255, 0.3) 50%);";
    case "gray":
      return "background-image: linear-gradient(0deg, transparent 50%, rgba(255, 255, 255, 0.3) 50%);";
    default:
      return "";
  }
};

const CustomWorkOrderFieldIndicator = styled.div`
  border-radius: 2px;
  display: inline-block;
  height: 12px;
  width: 12px;
  min-height: 12px;
  min-width: 12px;
  ${({ color }) => (color ? `background-color: ${colors[color]};` : "")}
  ${({ color }) => (color ? getBackgroundImage(color) : "")}
`;

export default CustomWorkOrderFieldIndicator;
