import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useWorkBlockEditModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.editWorkBlockModalState, ...args };
    updateGlobalState({ editWorkBlockModalState: newState });
  };

  return {
    open: (block) => {
      setState({ isOpen: true, block });
    },
    close: () => {
      setState({ isOpen: false, block: null });
    },
    block: state.editWorkBlockModalState.block,
    isOpen: state.editWorkBlockModalState.isOpen,
  };
}
