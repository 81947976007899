import React from "react";
import styled from "styled-components";
import { SortingTable } from "@nulogy/components";

const Table = styled(SortingTable)`
  th {
    z-index: auto;
  }

  th:first-child {
    padding-left: 0;
  }
`;

export default function IndexTable(props) {
  return <Table stickyHeader {...props} />;
}
