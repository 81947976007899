import React from "react";
import { TruncatedText } from "@nulogy/components";
import { ExecutionSummary, PublishResultSection } from "./PublishSummaryModalComponents";
import PackmanagerJobLink from "components/pages/schedule/PackmanagerJobLink";
import { formatShortDateTime } from "components/helpers/format";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";

function formatTime({ cellData }) {
  return formatShortDateTime(cellData);
}

function renderWorkOrder({ cellData }) {
  return <TruncatedText pl="x2">{cellData}</TruncatedText>;
}

export default function PublishSuccessTabContent({ createSuccessRows, updateSuccessRows, createTotal, updateTotal }) {
  const applicationContext = useApplicationContext();

  if (createSuccessRows.length === 0 && updateSuccessRows.length === 0)
    return <ExecutionSummary testId="success-summary" content="No jobs were published successfully." />;

  const createSuccessSummary = `Successfully created ${createSuccessRows.length} of ${createTotal} jobs.`;
  const updateSuccessSummary = `Successfully updated ${updateSuccessRows.length} of ${updateTotal} jobs.`;

  const successColumns = [
    {
      dataKey: "workOrder",
      label: "Work order",
      width: "25%",
      cellRenderer: renderWorkOrder,
    },
    {
      dataKey: "job",
      label: "Job",
      cellRenderer: renderJob,
      width: "25%",
    },
    {
      dataKey: "line",
      label: "Line",
      width: "25%",
    },
    {
      dataKey: "startTime",
      label: "Start time",
      cellFormatter: formatTime,
      width: "25%",
    },
  ];

  function renderJob({ cellData: jobId }) {
    return <PackmanagerJobLink applicationContext={applicationContext} jobId={jobId} />;
  }

  return (
    <>
      <PublishResultSection
        rows={createSuccessRows}
        title="Create"
        testIdPrefix="create-success"
        summary={createSuccessSummary}
        columns={successColumns}
      />

      <PublishResultSection
        rows={updateSuccessRows}
        title="Update"
        testIdPrefix="update-success"
        summary={updateSuccessSummary}
        columns={successColumns}
      />
    </>
  );
}
