// @keep_environment_variables_in_sync
const ENVIRONMENTS = [
  // production environments
  { name: "eu_production", domain: /^eu.ps.nulogy.net/, enableSentry: true },
  { name: "na_production", domain: /^ps.nulogy.net/, enableSentry: true },
  { name: "training", domain: /^training.ps.nulogy.net/, enableSentry: true },

  // test environments
  { name: "test", domain: /^test.qa-ps.nulogy.net/, enableSentry: true },

  // development
  { name: "development", domain: /./, enableSentry: false },
];

export default {
  enableSentry,
  environmentName,
};

function enableSentry() {
  return _environment().enableSentry;
}

function environmentName() {
  return _environment().name;
}

function _environment() {
  const domainName = _extractDomainName(window.location.href);

  return ENVIRONMENTS.find((e) => domainName.match(e.domain));
}

function _extractDomainName(url) {
  const a = document.createElement("a");
  a.href = url;

  return a.hostname;
}
