import React from "react";
import styled from "styled-components";
import { StatusIndicator, theme } from "@nulogy/components";
import WorkOrder from "domain/WorkOrder";

export const WORK_ORDER_CARD_STATUSES = {
  [WorkOrder.SCHEDULED_STATUS_TYPES.UNDERSCHEDULED]: {
    backgroundColor: theme.colors.lightBlue,
    color: theme.colors.darkGrey,
  },
  [WorkOrder.SCHEDULED_STATUS_TYPES.OVERSCHEDULED]: {
    backgroundColor: theme.colors.yellow,
    color: theme.colors.darkGrey,
  },
  [WorkOrder.SCHEDULED_STATUS_TYPES.SCHEDULED]: { backgroundColor: theme.colors.darkBlue, color: theme.colors.white },
  [WorkOrder.STATUS_TYPES.CANCELLED]: { backgroundColor: theme.colors.darkGrey, color: theme.colors.white },
  [WorkOrder.COMPLETED_LABEL]: { backgroundColor: theme.colors.green, color: theme.colors.white },
};

export const InlineStatus = styled(StatusIndicator)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`;

export const SidebarStatus = styled(InlineStatus)`
  position: absolute;
  top: -9px;
  right: 20px;
  cursor: default;
  z-index: 1;
`;

export default function WorkOrderCardStatus({ workOrder, inline }) {
  const cardStatus = getCardStatus({ workOrder, inline });
  if (!cardStatus) return null;

  const { color, backgroundColor } = WORK_ORDER_CARD_STATUSES[cardStatus];
  const Status = inline ? InlineStatus : SidebarStatus;

  return (
    <Status
      color={color}
      backgroundColor={backgroundColor}
      className="spec-work-order-card-status"
      data-testid="work-order-card-status"
    >
      {cardStatus}
    </Status>
  );
}

function getCardStatus({ workOrder, inline }) {
  if (!workOrder) return null;
  const cardStatus = WorkOrder.cardStatus(workOrder);
  if (!cardStatus) return null;
  if (!inline && cardStatus === WorkOrder.SCHEDULED_STATUS_TYPES.UNDERSCHEDULED) return null;

  return cardStatus;
}
