import React from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Flex, Heading3 } from "@nulogy/components";
import generateHexCode from "./generateHexCode";
import CustomChartTooltip from "./CustomChartTooltip";

export default function StackedAreaChart(props) {
  const { data, keys, title } = props;

  return (
    <div>
      <Flex justifyContent="center">
        <Heading3>{title}</Heading3>
      </Flex>
      <ResponsiveContainer width="100%" height={500}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 50,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomChartTooltip />} />
          {keys &&
            keys.map((key) => {
              const hexColor = generateHexCode(key);

              return <Area type="monotone" dataKey={key} key={key} stackId="1" stroke={hexColor} fill={hexColor} />;
            })}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
