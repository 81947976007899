import React from "react";
import { Box, DropdownButton, DropdownMenu, Heading3, QuietButton, TruncatedText, theme } from "@nulogy/components";
import styled from "styled-components";
import { calculateDateWidthFromShifts } from "./WidthCalculator";
import DateTime from "utils/DateTime";

const Header = styled(Box)(({ width, left }) => ({
  maxWidth: `${width}px`,
  minWidth: `${width}px`,
  borderRight: `1px solid ${theme.colors.lightGrey}`,
  background: "white",
  marginLeft: `${left}px`,
}));

function dateDisplayName(date) {
  return DateTime.format(date, "ddd D");
}

export default function AvailabilityDateHeader({ date, index, shifts, selectAllForDate, deselectAllForDate }) {
  const width = calculateDateWidthFromShifts(index, shifts);
  const left = index === 0 ? 107 : 0;

  return (
    <Header width={width} left={left}>
      <DropdownMenu
        placement="bottom-start"
        trigger={() => (
          <QuietButton style={{ width: "100%" }}>
            <TruncatedText fullWidth>
              <Heading3
                data-testid="date-header"
                whiteSpace="nowrap"
                color={theme.colors.darkGrey}
                fontWeight={theme.fontWeights.light}
                mb={0}
              >
                {dateDisplayName(date)}
              </Heading3>
            </TruncatedText>
          </QuietButton>
        )}
      >
        {({ closeMenu }) => (
          <>
            <DropdownButton
              type="button"
              onClick={(e) => {
                selectAllForDate(date);
                closeMenu(e);
              }}
            >
              Select&nbsp;all
            </DropdownButton>
            <DropdownButton
              type="button"
              onClick={(e) => {
                deselectAllForDate(date);
                closeMenu(e);
              }}
            >
              Deselect&nbsp;all
            </DropdownButton>
          </>
        )}
      </DropdownMenu>
    </Header>
  );
}
