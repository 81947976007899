import React from "react";
import { ApplicationFrame } from "@nulogy/components";
import ApplicationNavBar from "./navbar/ApplicationNavBar";
import ConfirmModal from "components/common/ConfirmModal";

function ApplicationLayoutView({ children, applicationContext: { trainingMode } }) {
  return (
    <ApplicationFrame className="appContainer" navBar={<ApplicationNavBar environment={trainingMode && "TRAINING"} />}>
      {children}
      <ConfirmModal />
    </ApplicationFrame>
  );
}

export default ApplicationLayoutView;
