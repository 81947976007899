const Site = {
  build,
};

function build() {
  return {
    name: "",
    startOfDay: "12:00 AM",
    externalId: "",
    externalAccountUuid: "",
    settings: JSON.stringify({
      showCustomWorkOrderFields: true,
    }),
    __typename: "Site",
  };
}

export default Site;
