import { useQuery } from "@apollo/client";
import { GetAllLines } from "./line.gql";

export default function useGetLines(variables) {
  const {
    data: { lines },
    data,
    loading,
    error,
  } = useQuery(GetAllLines, { variables: { activeOnly: variables?.activeOnly } });

  return { lines, data, loading, error };
}
