import React from "react";
import { Box, Button, ButtonGroup, DangerButton, Heading2, Modal, Text } from "@nulogy/components";
import styled from "styled-components";
import Form from "components/controls/Form";
import DatePicker from "components/controls/NDSDatePicker";
import Select from "components/controls/Select";

const PresetSelect = styled(Select)`
  z-index: 5;
`;

export default function ResetSiteView({ onReset, canReset, isResetSiteOpen, setResetSiteOpen }) {
  const dataPresets = [
    { label: "Default", value: "default" },
    { label: "Coman - Household", value: "cm_household" },
    { label: "Coman - Personal care", value: "cm_personal_care" },
    { label: "Coman - Cosmetics", value: "cm_cosmetics" },
    { label: "Coman - Food & bev", value: "cm_food_and_bev" },
    { label: "Copack - Household", value: "cp_household" },
    { label: "Copack - Personal care", value: "cp_personal_care" },
    { label: "Copack - Cosmetics", value: "cp_cosmetics" },
    { label: "Copack - Food & bev", value: "cp_food_and_bev" },
  ];

  const relativeDate = new Date();
  relativeDate.setHours(0, 0, 0, 0);
  const initialValues = { preset: "default", relativeDate };

  const resetButton = (
    <ButtonGroup>
      <DangerButton form="resetSite" type="submit" mr="x1" className="spec-reset-button">
        Reset
      </DangerButton>
    </ButtonGroup>
  );

  if (!canReset) return null;

  return (
    <>
      <Heading2>Danger zone</Heading2>
      <Button
        onClick={() => {
          setResetSiteOpen(true);
        }}
      >
        Reset site
      </Button>
      <Modal
        title="Reset site"
        isOpen={isResetSiteOpen}
        onRequestClose={() => {
          setResetSiteOpen(false);
        }}
        footerContent={resetButton}
        ariaHideApp={false}
      >
        <Text style={{ marginBottom: "8px", color: "red" }}>
          Do you want to delete existing data and create sample data?
        </Text>
        <Form id="resetSite" initialValues={initialValues} onSave={onReset}>
          <Box marginTop="x2">
            <Text marginBottom="x1" fontWeight="bold">
              Please select the data set to reset to:
            </Text>
            <PresetSelect field="preset" options={dataPresets} className="spec-preset-select" menuPosition="fixed" />
          </Box>
          <Box marginTop="x2">
            <Text marginBottom="x1" fontWeight="bold">
              Relative date
            </Text>
            <span className="customDatePicker">
              <DatePicker field="relativeDate" dateFormat="MMM d, yyyy" className="spec-relative-date" />
            </span>
          </Box>
        </Form>
      </Modal>
    </>
  );
}
