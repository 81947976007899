import filter from "lodash/filter";
import cloneDeep from "lodash/cloneDeep";
import { SIDE_BAR_LIMIT } from "config/appConfig";
import { GetAllBlocks } from "hooks/apollo/blocks/block.gql";
import { GetAllLines } from "hooks/apollo/line/line.gql";
import { GetWorkOrder, GetWorkOrders } from "hooks/apollo/workOrder/workOrder.gql";

function insertWorkBlock(newWorkBlock, proxy) {
  const completeNewWorkBlock = prepareWorkBlock(newWorkBlock, proxy);
  const { blocks } = read(proxy);

  const workBlocks = [completeNewWorkBlock].concat(filter(blocks.workBlocks, (w) => w.id !== newWorkBlock.id));

  write({ blocks: { ...blocks, workBlocks } }, proxy);

  insertWorkBlockToVisibleWorkOrder(completeNewWorkBlock, proxy);
}

function removeWorkBlocksForWorkOrder(workOrderId, proxy) {
  const { blocks } = read(proxy);

  const workBlocks = blocks.workBlocks.filter((workBlock) => workBlock.workOrder.id !== workOrderId);

  write({ blocks: { ...blocks, workBlocks } }, proxy);

  removeAllWorkBlocksFromVisibleWorkOrder(workOrderId, proxy);
}

function removeWorkBlock(workBlockId, proxy) {
  const { blocks } = read(proxy);

  const workBlock = blocks.workBlocks.filter((wb) => wb.id === workBlockId)[0];
  const workBlocks = blocks.workBlocks.filter((wb) => wb.id !== workBlockId);

  write({ blocks: { ...blocks, workBlocks } }, proxy);

  removeWorkBlockFromVisibleWorkOrder(workBlock, proxy);
}

function insertWorkBlockToVisibleWorkOrder(completeNewWorkBlock, proxy) {
  const { workOrder } = read(proxy, GetWorkOrder, {
    id: completeNewWorkBlock.workOrder.id,
  });

  if (workOrder) {
    const workOrderCopy = cloneDeep(workOrder);
    if (filter(workOrderCopy.workBlocks, (w) => w.id === completeNewWorkBlock.id).length === 0) {
      workOrderCopy.workBlocks.push(completeNewWorkBlock);
      write({ workOrder: workOrderCopy }, proxy, GetWorkOrder, {
        id: completeNewWorkBlock.workOrder.id,
      });
    }
  }
}

function removeAllWorkBlocksFromVisibleWorkOrder(workOrderId, proxy) {
  const { workOrder } = read(proxy, GetWorkOrder, {
    id: workOrderId,
  });

  if (workOrder) {
    const workOrderCopy = cloneDeep(workOrder);

    workOrderCopy.workBlocks = [];
    write({ workOrder: workOrderCopy }, proxy, GetWorkOrder, {
      id: workOrderId,
    });
  }
}

function removeWorkBlockFromVisibleWorkOrder(workBlock, proxy) {
  if (!workBlock) return;

  const { workOrder } = read(proxy, GetWorkOrder, {
    id: workBlock.workOrder.id,
  });

  if (workOrder) {
    const workOrderCopy = cloneDeep(workOrder);
    workOrderCopy.workBlocks = workOrderCopy.workBlocks.filter((wb) => wb.id !== workBlock.id);
    write({ workOrder: workOrderCopy }, proxy, GetWorkOrder, {
      id: workBlock.workOrder.id,
    });
  }
}

function prepareWorkBlock(workBlock, proxy) {
  return {
    ...workBlock,
    workOrder: getWorkOrder(workBlock.workOrder.id, proxy) || workBlock.workOrder,
    line: getLine(workBlock.line.id, proxy) || workBlock.line,
  };
}

function getLine(lineId, proxy) {
  const { lines } = read(proxy, GetAllLines, { activeOnly: true });

  return lines.find((line) => line.id === lineId);
}

function getWorkOrder(workOrderId, proxy) {
  const { workOrders } = read(proxy, GetWorkOrders, { limit: SIDE_BAR_LIMIT });

  return workOrders.find((workOrder) => workOrder.id === workOrderId);
}

function read(proxy, query = GetAllBlocks, variables = null) {
  return proxy.readQuery({ query, returnPartialData: true, variables });
}

function write(data, proxy, query = GetAllBlocks, variables = null) {
  proxy.writeQuery({
    query,
    variables,
    data,
  });
}

export { insertWorkBlock, removeWorkBlock, removeWorkBlocksForWorkOrder };
