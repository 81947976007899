import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import CompanyEditView from "./CompanyEditView";
import useGoBack from "hooks/useGoBack";
import { DeleteCompany, GetAllCompanies, GetCompany, UpdateCompany } from "hooks/apollo/company/company.gql";
import paths from "config/routePaths";
import Authorization from "policies/Authorization";

export default function CompanyEditContainer({ id }) {
  const { goBack } = useGoBack();
  const policy = Authorization.usePolicy();
  const leavePage = () => {
    goBack(paths.companyIndex);
  };
  const [updateCompany] = useMutation(UpdateCompany, { onCompleted: () => leavePage() });
  const [deleteCompany] = useMutation(DeleteCompany, { onCompleted: () => leavePage() });

  const { data, loading } = useQuery(GetCompany, { variables: { id } });

  if (loading) return null;

  const handleCancel = () => leavePage();

  const handleSave = (args) => {
    const defaultCompany = {
      name: "",
    };

    const company = { ...defaultCompany, ...data?.company, ...args };

    return updateCompany({ variables: company, refetchQueries: [{ query: GetAllCompanies }] });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteCompany({ variables: { id }, refetchQueries: [{ query: GetAllCompanies }] });
  };

  const canCreateSite = policy.canCreate(Authorization.EntityTypes.Site);
  const canCreateUser = policy.canCreate(Authorization.EntityTypes.User);
  const canUpdateCompany = policy.canUpdate(Authorization.EntityTypes.Company);
  const createSitePath = paths.siteNewForCompany(id);
  const createUserPath = paths.userNewForCompany(id);

  return (
    <CompanyEditView
      company={data?.company}
      onSave={handleSave}
      onCancel={handleCancel}
      onDelete={handleDelete}
      canCreateSite={canCreateSite}
      canCreateUser={canCreateUser}
      canUpdateCompany={canUpdateCompany}
      createSitePath={createSitePath}
      createUserPath={createUserPath}
    />
  );
}
