const COMPLETED_LABEL = "completed";

const SCHEDULED_STATUS_TYPES = {
  UNDERSCHEDULED: "underscheduled",
  SCHEDULED: "scheduled",
  OVERSCHEDULED: "overscheduled",
};

// @keep_work_order_statuses_in_sync
const STATUS_TYPES = {
  OPEN: "open",
  BOOKED: "booked",
  CLOSED: "closed",
  CANCELLED: "cancelled",
};

const ITEM_DESCRIPTION_LAYOUT = "item_description";
const WORK_ORDER_DESCRIPTION_LAYOUT = "work_order_description";

const LAYOUTS = [
  { label: "Item description", value: ITEM_DESCRIPTION_LAYOUT },
  { label: "Work order description", value: WORK_ORDER_DESCRIPTION_LAYOUT },
];

const WorkOrder = {
  COMPLETED_LABEL,
  SCHEDULED_STATUS_TYPES,
  STATUS_TYPES,
  ITEM_DESCRIPTION_LAYOUT,
  WORK_ORDER_DESCRIPTION_LAYOUT,
  LAYOUTS,
  cardStatus,
  hasNonZeroProductionRate,
  isCancelled,
  isCompleted,
  isFullyProduced,
  isMissingData,
  isScheduled,
  isOverscheduled,
  isScheduledCompletedOrCancelled,
};

function cardStatus(workOrder) {
  if (isCancelled(workOrder)) return STATUS_TYPES.CANCELLED;
  if (isCompleted(workOrder)) return COMPLETED_LABEL;
  if (isMissingData(workOrder)) return SCHEDULED_STATUS_TYPES.UNDERSCHEDULED;

  return workOrder.scheduledStatus;
}

function isCompleted({ completed }) {
  return completed;
}

function isCancelled({ status }) {
  return STATUS_TYPES.CANCELLED === status;
}

function isMissingData({ missingData }) {
  return missingData;
}

function isScheduled({ scheduledStatus }) {
  return SCHEDULED_STATUS_TYPES.SCHEDULED === scheduledStatus;
}

function isOverscheduled({ scheduledStatus }) {
  return SCHEDULED_STATUS_TYPES.OVERSCHEDULED === scheduledStatus;
}

function isFullyProduced({ producedQuantity, quantity }) {
  return quantity > 0 && producedQuantity >= quantity;
}

function isScheduledCompletedOrCancelled(workOrder) {
  return isScheduled(workOrder) || isCompleted(workOrder) || isCancelled(workOrder);
}

function hasNonZeroProductionRate(workOrder) {
  return workOrder.standardProductionRate > 0 && workOrder.performance > 0;
}
export default WorkOrder;
