import filter from "lodash/filter";
import { GetTeardownTimes } from "hooks/apollo/teardownTime/teardownTime.gql";

function insertTeardownTime(newTeardownTime, proxy) {
  const teardownTimes = read(proxy);

  const updatedTeardownTimes = [newTeardownTime].concat(filter(teardownTimes, (t) => t.id !== newTeardownTime.id));

  write(updatedTeardownTimes, proxy);
}

function removeTeardownTime(teardownTimeId, proxy) {
  const internalTeardownTime = proxy.identify({ id: teardownTimeId, __typename: "TeardownTime" });
  proxy.evict({ id: internalTeardownTime });
  proxy.gc();
}

function read(proxy) {
  const { teardownTimes } = proxy.readQuery({ query: GetTeardownTimes });

  return teardownTimes;
}

function write(teardownTimes, proxy) {
  proxy.writeQuery({
    query: GetTeardownTimes,
    data: { teardownTimes },
  });
}

export { insertTeardownTime, removeTeardownTime };
