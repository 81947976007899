import { Alert, Button } from "@nulogy/components";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import SettingsForm from "components/controls/SettingsForm";
import {
  GetAuthenticationUserBlockedStatus,
  UnblockAuthenticationUser,
} from "hooks/apollo/authenticationUser/authenticationUser.gql";

const UNBLOCK_SUCCESS_MESSAGE = "User successfully unblocked";

export default function UnblockUserButton({ user }) {
  const [userBlocked, setUserBlocked] = useState(false);
  const [success, setSuccess] = useState(false);
  const { data } = useQuery(GetAuthenticationUserBlockedStatus, {
    skip: !user,
    variables: { email: user?.email },
  });

  const [unblockAuthenticationUser, { loading }] = useMutation(UnblockAuthenticationUser, {
    onCompleted: (response) => {
      const { blocked } = response.unblockAuthenticationUser.authenticationUser;
      setUserBlocked(blocked);

      if (!blocked) setSuccess(true);
    },
  });

  const handleUnblockUser = () => unblockAuthenticationUser({ variables: { email: user?.email } });

  useEffect(() => {
    setUserBlocked(data?.authenticationUserBlockedStatus.blocked);
  }, [data]);

  return (
    <SettingsForm onSave={handleUnblockUser}>
      {success && <Alert type="success">{UNBLOCK_SUCCESS_MESSAGE}</Alert>}
      <Button type="submit" mb="x2" disabled={!userBlocked || loading}>
        Unblock
      </Button>
    </SettingsForm>
  );
}
