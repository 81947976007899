import React from "react";
import { Button, ButtonGroup, Modal, PrimaryButton } from "@nulogy/components";
import useDeleteConfirmationModal from "hooks/state/useDeleteConfirmationModal";

export default function DeleteConfirmationModal({ onDelete, title, message }) {
  const { close: closeModal, isOpen, data } = useDeleteConfirmationModal();

  const handleDelete = () => {
    onDelete(data);
    closeModal();
  };

  const close = () => {
    closeModal();
  };

  const footerContent = (
    <ButtonGroup>
      <PrimaryButton onClick={handleDelete} className="spec-delete-in-modal" data-testid="modal-delete-button">
        Delete
      </PrimaryButton>
      <Button onClick={close}>Cancel</Button>
    </ButtonGroup>
  );

  return (
    <Modal title={title} footerContent={footerContent} isOpen={isOpen} onRequestClose={close} ariaHideApp={false}>
      {message}
    </Modal>
  );
}
