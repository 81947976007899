import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Box, IconicButton, TruncatedText } from "@nulogy/components";
import { GridBox4, StyledInput, StyledText } from "components/common/EditableListStyledComponents";
import Form from "components/controls/Form";
import FieldRow from "components/common/FieldRow";
import { formatTeardownTimeInputInHours } from "components/helpers/format";
import DateTime from "utils/DateTime";
import { UpdateTeardownTime } from "hooks/apollo/teardownTime/teardownTime.gql";

const ShowTeardownTime = ({ label, formattedDuration, setEditMode, onDelete, id }) => (
  <>
    <StyledText data-testid="teardown-time-label">
      <TruncatedText fullWidth>{label}</TruncatedText>
    </StyledText>
    <StyledText data-testid="teardown-time-duration">
      <TruncatedText fullWidth>{formattedDuration}</TruncatedText>
    </StyledText>
    <IconicButton
      type="button"
      icon="edit"
      onClick={() => setEditMode(true)}
      data-testid="edit-teardown-time-button"
      className="spec-edit-teardown-time-icon"
    >
      Edit
    </IconicButton>
    <IconicButton
      type="button"
      icon="delete"
      onClick={() => onDelete(id)}
      data-testid="delete-teardown-time-button"
      className="spec-delete-teardown-time-icon"
    >
      Delete
    </IconicButton>
  </>
);

const EditTeardownTime = ({ disableSave, onReset, handleCancel }) => (
  <>
    <StyledInput field="label" placeholder="Teardown Time" className="spec-edit-teardown-time-label-input" />
    <StyledInput
      field="duration"
      type="number"
      step="any"
      style={{ textAlign: "left" }}
      className="spec-edit-teardown-time-duration-input"
    />
    <Box paddingTop="x1">
      <IconicButton type="submit" icon="check" className="spec-save-teardown-time-icon" disabled={disableSave}>
        Save
      </IconicButton>
    </Box>
    <Box paddingTop="x1">
      <IconicButton type="button" icon="close" onClick={() => handleCancel(onReset)}>
        Cancel
      </IconicButton>
    </Box>
  </>
);

export default function TeardownTime({ teardownTime, onDelete }) {
  const [editMode, setEditMode] = useState(false);
  const [updateTeardownTime] = useMutation(UpdateTeardownTime, { onCompleted: () => setEditMode(false) });

  const { id, label, duration } = teardownTime;

  const handleUpdate = (updatedTeardownTime) =>
    updateTeardownTime({
      variables: {
        id: updatedTeardownTime.id,
        label: updatedTeardownTime.label,
        duration: DateTime.hoursToSeconds(updatedTeardownTime.duration),
      },
    });

  const handleCancel = (onReset) => {
    onReset();
    setEditMode(false);
  };

  const formattedDuration = formatTeardownTimeInputInHours(duration);

  return (
    <>
      {!editMode && (
        <GridBox4 data-testid="teardown-time">
          <ShowTeardownTime
            label={label}
            formattedDuration={formattedDuration}
            setEditMode={setEditMode}
            onDelete={onDelete}
            id={id}
          />
        </GridBox4>
      )}
      {editMode && (
        <Form
          id={`editTeardownTime-${id}`}
          initialValues={{ id, label, duration: formattedDuration }}
          onSave={handleUpdate}
        >
          {({ dirty, handleReset }) => (
            <FieldRow marginBottom="none">
              <GridBox4>
                <EditTeardownTime disableSave={!dirty} onReset={handleReset} handleCancel={handleCancel} />
              </GridBox4>
            </FieldRow>
          )}
        </Form>
      )}
    </>
  );
}
