import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { ScheduleDowntimeBlock } from "./downtimeBlock.gql";
import { insertDowntimeBlock } from "./helpers/handleUpdateDowntimeBlocks";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import { useNotificationState } from "hooks/state";
import TimeZone from "utils/TimeZone";
import GqlError from "utils/GqlError";
import Uuid from "utils/Uuid";

export default function useScheduleDowntimeBlock() {
  const { setNotification } = useNotificationState();
  const [scheduleDowntimeBlock] = useMutation(ScheduleDowntimeBlock, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to schedule downtime block",
          message: allErrors,
          type: "danger",
        });
      });
    },
  });

  const applicationContext = useApplicationContext();

  return (downtimeBlock) => {
    const { reason, startsAt: rawStartsAt, endsAt: rawEndsAt, line, lockedOnSchedule, reasonCode } = downtimeBlock;
    const id = Uuid.generate();

    return scheduleDowntimeBlock({
      variables: {
        id,
        reason,
        reasonCode,
        startsAt: TimeZone.overrideZone(rawStartsAt, applicationContext.timeZone.name),
        endsAt: TimeZone.overrideZone(rawEndsAt, applicationContext.timeZone.name),
        lineId: line.id,
        lockedOnSchedule,
      },
      optimisticResponse: {
        scheduleDowntimeBlock: {
          downtimeBlock: { ...downtimeBlock, id },
          __typename: "ScheduleDowntimeBlockPayload",
        },
      },
      update: (proxy, { data }) => {
        const newDowntimeBlock = data.scheduleDowntimeBlock.downtimeBlock;

        insertDowntimeBlock(newDowntimeBlock, proxy);
      },
    });
  };
}
