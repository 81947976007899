import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { DeleteLine } from "./line.gql";
import { useNotificationState } from "hooks/state";
import GqlError from "utils/GqlError";

export default function useDeleteLine() {
  const { setNotification } = useNotificationState();
  const [deleteLine] = useMutation(DeleteLine, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to delete line",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: ({ deleteLine: { name } }) => {
      setNotification({
        title: `${name} was successfully deleted.`,
        type: "success",
      });
    },
  });

  return (options) => deleteLine(options);
}
