import { Box, Flex, Table, TruncatedText, theme } from "@nulogy/components";
import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import AvailabilityDateHeader from "./AvailabilityDateHeader";
import AvailabilityShiftHeader from "./AvailabilityShiftHeader";
import AvailabilityCell from "./AvailabilityCell";
import { calculateTotalTableWidth, columnWidth } from "./WidthCalculator";
import { GetAllShifts } from "hooks/apollo/shift/shift.gql";
import { GetAllLines } from "hooks/apollo/line/line.gql";

const Container = styled.div`
  position: relative;
  max-height: calc(100vh - (460px));
  height: fit-content;
  width: 100vw;
  overflow: scroll;
`;

const ScrollTable = styled(Table)(
  ({ shiftCount }) => `
  td,
  th {
    background: white;
    z-index: 0;
    width: ${columnWidth(shiftCount)}px;
  }

  td:nth-child(${shiftCount}n + ${shiftCount + 1}),
  th:nth-child(${shiftCount}n + ${shiftCount + 1}) {
    border-right: 1px solid ${theme.colors.lightGrey};
    padding-right: 0px
  }

  thead tr {
    border: none;
  }

  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 42px;
    z-index: 2;
    background: white;
  }

  thead th:first-child {
    left: 0;
    top: 42px;
    z-index: 8;
    width: 108px;
  }

  tbody {
    overflow: scroll;
    border-bottom: 1px solid ${theme.colors.lightGrey};
  }

  thead {
    position: sticky;
    box-shadow: 0 1px #ddd;
    top: 42px;
    z-index: 8;
  }

  tr > td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 7;
    width: 108px;
  }

  // Hack to make the table not stretch if it is smaller than the viewport
  width: 1px;
  top: -14px;
`,
);

const TopBorder = styled(Box)({
  position: "absolute",
  top: "-2px",
  left: "0px",
  height: "3px",
  zIndex: "400",
  borderBottom: "1px solid lightGrey",
  marginBottom: 0,
  maxWidth: "calc(100vw - 48px)",
});

const LineBox = styled(Box)({
  zIndex: 7,
  background: "white",
});

const LineHeader = styled.div({
  padding: 0,
  margin: 0,
  display: "block",
  color: theme.colors.black,
  minWidth: columnWidth,
  maxWidth: columnWidth,
});

const PlaceholderBox = styled(Box)({
  maxWidth: "107px",
  minWidth: "107px",
  left: 0,
  top: 0,
  position: "sticky",
  zIndex: 10,
  background: "white",
  height: "56px",
  float: "left",
});

function renderAvailability({ column, row }) {
  return <AvailabilityCell row={row} column={column} />;
}

function renderLineHeader({ cellData }) {
  return (
    <Flex height="56px" alignItems="center" className="spec-line-name">
      <TruncatedText width="100px" fullWidth color="darkGrey" tooltipProps={{ placement: "right" }}>
        {cellData}
      </TruncatedText>
    </Flex>
  );
}

function matchingShift(shiftId, date) {
  return (lineShift) => lineShift.shift === shiftId && lineShift.date === date;
}

function lineShiftEnabled(date) {
  return (lineShift) => !lineShift.disabled && lineShift.date === date;
}

function buildColumns(cols, dates, shifts, headerFormatter) {
  dates.forEach((day) => {
    shifts.forEach((shift) => {
      cols.push({
        label: `${shift.name}-${day}`,
        dataKey: `${shift.id}-${day}`,
        headerFormatter,
        cellRenderer: renderAvailability,
        width: `${columnWidth(shifts.length)}px`,
        metadata: {
          shift: shift.id,
          date: day,
          shifts,
        },
      });
    });
  });
}

function buildRows(rows, lines, cols, availabilities) {
  lines.forEach((line) => {
    const innerLine = { line: line.name };
    cols.forEach((col) => {
      const avIndex = availabilities.findIndex(
        (av) => av.shift === col.metadata.shift && av.line === line.id && av.date === col.metadata.date,
      );
      innerLine[`${col.metadata.shift}-${col.metadata.date}`] = {
        ...availabilities[avIndex],
      };
    });
    rows.push(innerLine);
  });
}

export function AvailabilityOverridesTable({ dates, availabilities }) {
  const { data, loading } = useQuery(GetAllShifts);
  const { data: lineData, loading: loadingLines } = useQuery(GetAllLines, { variables: { activeOnly: true } });
  const { setFieldValue } = useFormikContext();

  if (loading || loadingLines) return null;

  const { shifts } = data;

  const { lines } = lineData;

  const setLineShiftChecked = ({ lineShift, checked }) =>
    setFieldValue(`availabilities[${lineShift.index}]['available']`, checked, false);
  const checkLineShift = (lineShift) => setLineShiftChecked({ lineShift, checked: true });
  const uncheckLineShift = (lineShift) => setLineShiftChecked({ lineShift, checked: false });

  const selectAllForShift = (shift, date) => {
    availabilities.filter(matchingShift(shift.id, date)).forEach(checkLineShift);
  };
  const deselectAllForShift = (shift, date) =>
    availabilities.filter(matchingShift(shift.id, date)).forEach(uncheckLineShift);
  const selectAllForDate = (date) => availabilities.filter(lineShiftEnabled(date)).forEach(checkLineShift);
  const deselectAllForDate = (date) => availabilities.filter(lineShiftEnabled(date)).forEach(uncheckLineShift);

  function findShift(shiftId) {
    return shifts.find((candidateShift) => candidateShift.id === shiftId);
  }

  function renderDateHeader(date, index) {
    return (
      <AvailabilityDateHeader
        key={index}
        date={date}
        shifts={shifts}
        index={index}
        selectAllForDate={selectAllForDate}
        deselectAllForDate={deselectAllForDate}
      />
    );
  }

  function lineHeaderFormatter() {
    return (
      <LineBox pt="42px">
        <LineHeader />
      </LineBox>
    );
  }

  function headerFormatter({ metadata }) {
    const { shift: shiftId, date } = metadata;
    const shift = findShift(shiftId);

    return (
      <AvailabilityShiftHeader
        shiftCount={shifts.length}
        shift={shift}
        date={date}
        deselectAllForShift={deselectAllForShift}
        selectAllForShift={selectAllForShift}
      />
    );
  }

  const rows = [];
  const cols = [
    {
      label: "Line",
      dataKey: "line",
      cellRenderer: renderLineHeader,
      headerFormatter: lineHeaderFormatter,
      metadata: {},
    },
  ];

  buildColumns(cols, dates, shifts, headerFormatter);
  buildRows(rows, lines, cols, availabilities);

  return (
    <>
      <TopBorder width={calculateTotalTableWidth(shifts, dates)} />
      <Container borderRight={`1px solid ${theme.colors.lightGrey}`}>
        <PlaceholderBox />
        <div style={{ clear: "both", top: "-56px", position: "relative" }}>
          <Flex style={{ position: "sticky", top: "0px", zIndex: 9, background: "white" }}>
            {dates.map((date, index) => renderDateHeader(date, index))}
          </Flex>
          <ScrollTable
            data-testid="table"
            shiftCount={shifts.length}
            maxHeight={200}
            columns={cols}
            rows={rows}
            rowHovers={false}
            keyField="line"
            compact
          />
        </div>
      </Container>
    </>
  );
}
