import React, { useState } from "react";
import { Box, Heading2, IconicButton, Page, Text, theme } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import DowntimeReason from "./DowntimeReason";
import NewDowntimeReason from "./NewDowntimeReason";
import useDeleteDowntimeReason from "hooks/apollo/downtimeReason/useDeleteDowntimeReason";
import { removeDowntimeReason } from "hooks/apollo/downtimeReason/helpers/handleUpdateDowntimeReasons";
import { GetDowntimeReasons } from "hooks/apollo/downtimeReason/downtimeReason.gql";
import useDeleteConfirmationModal from "hooks/state/useDeleteConfirmationModal";
import DeleteConfirmationModal from "components/common/DeleteConfirmationModal";
import Uuid from "utils/Uuid";
import { GridBox4 } from "components/common/EditableListStyledComponents";

export default function DowntimeReasons() {
  const { data, loading } = useQuery(GetDowntimeReasons);
  const [newDowntimeReasonIds, setNewDowntimeReasonIds] = useState([]);
  const { open: openModal } = useDeleteConfirmationModal();
  const deleteDowntimeReason = useDeleteDowntimeReason();

  const handleAddNewDowntimeReason = () => {
    setNewDowntimeReasonIds([...newDowntimeReasonIds, Uuid.generate()]);
  };

  const handleRemoveNewDowntimeReason = (downtimeReasonId) => {
    setNewDowntimeReasonIds(newDowntimeReasonIds.filter((value) => value !== downtimeReasonId));
  };

  const handleDelete = (downtimeReason) => {
    openModal(downtimeReason);
  };

  const onDelete = (downtimeReasonId) =>
    deleteDowntimeReason({
      variables: { id: downtimeReasonId },
      update: (proxy) => {
        removeDowntimeReason(downtimeReasonId, proxy);
      },
    });

  function renderContent() {
    if (loading) return null;

    const hasDowntimeReasons = newDowntimeReasonIds.length > 0 || data.downtimeReasons.length > 0;

    const addButtonLabel = hasDowntimeReasons ? "Add another row" : "Add a downtime reason";
    const deleteMessage =
      "This downtime reason will not be available for scheduling once deleted. However, blocks that it is already applied to will not be impacted.";

    return (
      <Box width="870px">
        <DeleteConfirmationModal title="Delete downtime reason" message={deleteMessage} onDelete={onDelete} />
        <IconicButton
          data-testid="add-button"
          type="button"
          icon="add"
          marginBottom="x2"
          onClick={handleAddNewDowntimeReason}
        >
          {addButtonLabel}
        </IconicButton>
        {hasDowntimeReasons && (
          <Box data-testid="downtime-reasons-table">
            <GridBox4>
              <Text>Reason</Text>
              <Text>Code</Text>
            </GridBox4>
            <hr style={{ marginBottom: "0px" }} />
            {newDowntimeReasonIds.map((newDowntimeReasonId) => (
              <NewDowntimeReason
                downtimeReasonId={newDowntimeReasonId}
                onRemoveNewDowntimeReason={handleRemoveNewDowntimeReason}
                key={newDowntimeReasonId}
              />
            ))}
            {data.downtimeReasons.map((downtimeReason) => (
              <DowntimeReason key={downtimeReason.id} downtimeReason={downtimeReason} onDelete={handleDelete} />
            ))}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Page>
      <Heading2 mb="x3">Downtime reasons</Heading2>
      <Box marginBottom="x3" color={theme.colors.darkGrey} maxWidth="600px">
        Manage your site’s downtime reasons on this page. Once a reason is created, you will have the option to apply it
        to the schedule.
      </Box>
      {renderContent()}
    </Page>
  );
}
