import React from "react";
import { Select } from "@nulogy/components";
import FilterLabel from "./FilterLabel";
import WorkOrder from "domain/WorkOrder";

const workOrderStatuses = [
  { label: "Open", value: WorkOrder.STATUS_TYPES.OPEN },
  { label: "Booked", value: WorkOrder.STATUS_TYPES.BOOKED },
  { label: "Closed", value: WorkOrder.STATUS_TYPES.CLOSED },
  { label: "Cancelled", value: WorkOrder.STATUS_TYPES.CANCELLED },
];

export default function StatusSelector({ selectedStatuses, onChange }) {
  return (
    <>
      <FilterLabel value="Work order status" />
      <Select
        value={selectedStatuses}
        className="spec-status-filter-multiselect"
        multiselect
        options={workOrderStatuses}
        onChange={onChange}
        placeholder="Select statuses..."
        closeMenuOnSelect={false}
      />
    </>
  );
}
