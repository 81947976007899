import React, { useState } from "react";
import { Alert, Box, DateRange, PrimaryButton, Select, Text } from "@nulogy/components";
import ExpandFiltersButton from "./ExpandFiltersButton";
import Form from "components/controls/Form";
import NDSDatePicker from "components/controls/NDSDatePicker";
import FieldRow from "components/common/FieldRow";
import ModalFooter from "components/common/ModalFooter";
import WorkOrder from "domain/WorkOrder";
import DateTime from "utils/DateTime";
import String from "utils/String";

const FORM_ID = "update-work-order-dates-modal";
const MAX_WORK_ORDER_COUNT = 1000;

export { MAX_WORK_ORDER_COUNT };

const initialStartDate = new Date();
initialStartDate.setHours(0, 0, 0, 0);
const initialValues = { startDate: initialStartDate };

export default function PublishWorkOrderDatesFormView({ onSave, response, ...contentProps }) {
  const formErrors = response.errors.length ? response.errors : null;

  return (
    <Form id={FORM_ID} onSave={onSave} errors={formErrors} initialValues={initialValues}>
      {({ isSubmitting }) => <Content response={response} isSubmitting={isSubmitting} {...contentProps} />}
    </Form>
  );
}

export function Content({
  customers,
  workOrders,
  isSubmitting,
  onFilterChange,
  filters,
  showWorkOrderCount,
  workOrderCountTotal,
  workOrderCountOnInactiveLines,
  response,
}) {
  const workOrderLimitExceeded = workOrderCountTotal > MAX_WORK_ORDER_COUNT;
  const submitEnabled = !response.success && workOrderCountTotal > 0 && !workOrderLimitExceeded && filters.startDate;
  const minDate = DateTime.timeAgo("2", "weeks");

  const [expandedFilters, setExpandedFilters] = useState(false);

  const customerOptions = customers.map((customer) => ({ label: customer, value: customer }));

  const handleStartDateChange = ({ value: startDate }) => {
    onFilterChange({
      startDate,
    });
  };

  const handleCustomersToUpdateChange = (updatedCustomers) => {
    onFilterChange({
      customers: updatedCustomers,
    });
  };

  const workOrderCodeOptions = workOrders.map((workOrder) => ({ label: workOrder.code, value: workOrder.code }));
  const handleCodesToUpdateChange = (codes) => {
    onFilterChange({
      codes,
    });
  };

  const { OPEN, BOOKED } = WorkOrder.STATUS_TYPES;
  const workOrderStatusOptions = [
    { label: "Open", value: OPEN },
    { label: "Booked", value: BOOKED },
  ];
  const handleStatusToUpdateChange = (statuses) => {
    onFilterChange({
      statuses,
    });
  };

  const handleDueDateRangeChange = (value) => {
    const { startDate: dueStartDate, endDate: dueEndDate } = value;
    onFilterChange({
      dueDateFrom: dueStartDate,
      dueDateTo: dueEndDate,
    });
  };

  const toggleExpandedFilters = () => {
    setExpandedFilters(!expandedFilters);
  };

  return (
    <>
      {response.success && (
        <Alert type="success">
          You successfully updated {workOrderCountTotal} {String.pluralize("work order", workOrderCountTotal)}
        </Alert>
      )}
      <Box marginBottom="x2">
        <Text>
          This action will update the corresponding planned start and planned end dates for each scheduled work order
          starting from the date selected below.
        </Text>
      </Box>
      <FieldRow>
        <NDSDatePicker
          field="startDate"
          inputProps={{ labelText: "Update From" }}
          onChange={handleStartDateChange}
          minDate={minDate}
          errorMessage={
            workOrderLimitExceeded
              ? `Only ${MAX_WORK_ORDER_COUNT} work orders can be updated at a time. Please reduce the selected date range and try again.`
              : null
          }
        />
      </FieldRow>
      {expandedFilters && (
        <>
          <FieldRow>
            <Select
              value={filters.customers}
              options={customerOptions}
              multiselect
              onChange={handleCustomersToUpdateChange}
              closeMenuOnSelect={false}
              labelText="Customer(s)"
              requirementText="(Optional)"
            />
          </FieldRow>
          <FieldRow>
            <Select
              value={filters.codes}
              options={workOrderCodeOptions}
              multiselect
              onChange={handleCodesToUpdateChange}
              closeMenuOnSelect={false}
              labelText="Work order code(s)"
              requirementText="(Optional)"
            />
          </FieldRow>
          <FieldRow>
            <Select
              value={filters.statuses}
              options={workOrderStatusOptions}
              multiselect
              onChange={handleStatusToUpdateChange}
              closeMenuOnSelect={false}
              labelText="Work order status(es)"
              requirementText="(Optional)"
            />
          </FieldRow>
          <FieldRow>
            <DateRange
              defaultStartDate={filters.dueDateFrom}
              defaultEndDate={filters.dueDateTo}
              labelProps={{ labelText: "Due date range", requirementText: "(Optional)" }}
              onRangeChange={handleDueDateRangeChange}
            />
          </FieldRow>
        </>
      )}
      <ExpandFiltersButton onClick={toggleExpandedFilters} expandedFilters={expandedFilters} />
      {workOrderCountOnInactiveLines > 0 && (
        <Alert type="warning" style={{ marginBottom: "16px" }}>
          This date range contains blocks scheduled on inactive lines. Their work order planned start and end dates will
          be updated, unless they are unscheduled.
        </Alert>
      )}
      <ModalFooter justifyContent="flex-start" alignItems="center">
        <Box marginRight="x2">
          <PrimaryButton type="submit" form={FORM_ID} disabled={!submitEnabled}>
            Update
          </PrimaryButton>
        </Box>
        {!isSubmitting && showWorkOrderCount && (
          <Text className="spec-update-work-order-dates-modal-work-order-count-message">
            {workOrderCountTotal} {String.pluralize("work order", workOrderCountTotal)} will be updated.{" "}
          </Text>
        )}
      </ModalFooter>
    </>
  );
}
