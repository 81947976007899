import React from "react";
import { useField, useFormikContext } from "formik";
import styled from "styled-components";
import { Input as NulogyInput, theme } from "@nulogy/components";

const StyledInput = styled(NulogyInput)`
  p {
    font-size: ${theme.fontSizes.small};
  }

  input[type="number"] {
    text-align: right;
    ${({ inputWidth }) => `width: ${inputWidth}`}
  }
`;

export default function Input({ field: name, onChange, validate, ...ndsProps }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField({ name, validate });

  const handleChange = (event) => {
    const value = Number.isNaN(event.target.valueAsNumber) ? event.target.value : event.target.valueAsNumber;

    if (onChange) {
      onChange({ value, setFieldValue });
    }
    helpers.setValue(value);
  };

  return <StyledInput value={field.value} errorMessage={meta.error} onChange={handleChange} {...ndsProps} />;
}
