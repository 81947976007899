import React from "react";
import renderToString from "./renderToString";
import WorkBlockFromSidebar from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkBlockFromSidebar";

export default function workOrderDraggedRenderer({ dragWorkOrder, dragApplicationContext }) {
  return renderToString(
    <WorkBlockFromSidebar
      schedule={this.schedule}
      dragWorkOrder={dragWorkOrder}
      dragApplicationContext={dragApplicationContext}
    />,
  );
}
