import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SyncSites } from "./syncSites.gql";
import SyncErrorMessage from "./SyncErrorMessage";
import { GetApplicationContext } from "hooks/apollo/applicationContext/applicationContext.gql";
import useGetBlocks from "hooks/apollo/blocks/useGetBlocks";
import useRefetchWorkOrders from "hooks/apollo/workOrder/useRefetchWorkOrders";
import { useNotificationState } from "hooks/state";

export default function useSyncSites() {
  const { setNotification } = useNotificationState();
  const { refetch: refetchWorkOrders } = useRefetchWorkOrders();
  const { refetch: refetchBlocks } = useGetBlocks();
  const [isSyncing, setSyncing] = useState(false);

  const [syncSites] = useMutation(SyncSites, {
    onCompleted: (data) => {
      refetchWorkOrders();
      refetchBlocks();

      const {
        syncSites: { errors },
      } = data;

      setNotification(result(errors));

      setSyncing(false);
    },
    onError: () => {
      setNotification({
        title: "Network error. Try again.",
        type: "danger",
      });
      setSyncing(false);
    },
  });

  const sync = ({ syncAllSites }) => {
    setSyncing(true);

    return syncSites({
      variables: { syncAllSites },
      update: (proxy, { data }) => {
        const { applicationContext } = proxy.readQuery({ query: GetApplicationContext });

        const { lastSyncedAt } = data.syncSites.syncResult;

        proxy.writeQuery({
          query: GetApplicationContext,
          data: { applicationContext: { ...applicationContext, site: { ...applicationContext.site, lastSyncedAt } } },
        });
      },
    });
  };

  return { sync, isSyncing };
}

function result(errors) {
  if (errors.length > 0) {
    return {
      title: "Unable to sync the following sites",
      type: "danger",
      message: SyncErrorMessage(errors),
    };
  } else {
    return {
      title: "Syncing completed",
      type: "success",
      message: "",
      isCloseable: true,
    };
  }
}
