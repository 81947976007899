import React from "react";
import { Box, FieldLabel, Flex, Text, theme } from "@nulogy/components";
import styled from "styled-components";
import Input from "components/controls/Input";
import FieldRow from "components/common/FieldRow";

const ProductionDetailField = styled(FieldLabel)`
  flex-grow: 1.8;
  ${({ overrideValue }) => overrideValue && `.field-value { color: ${theme.colors.grey}; }`}
`;

const PaddedFieldValue = styled.span`
  display: inline-block;
  padding: ${theme.space.x1} 0;
  font-size: ${theme.fontSizes.small};
`;

export default function ProductionDetail({
  label,
  value,
  overrideLabel,
  overrideValue,
  units,
  field,
  additionalInfo,
  specClassName,
  disabled,
  ...props
}) {
  return (
    <Box marginBottom="x2" {...props}>
      <FieldRow>
        <ProductionDetailField labelText={label} className={specClassName} overrideValue={overrideValue}>
          <PaddedFieldValue>{value}</PaddedFieldValue>
        </ProductionDetailField>
        <Input
          labelText={overrideLabel}
          field={`workBlock[${field}]`}
          type="number"
          step="any"
          inputWidth="100px"
          suffix={units}
          suffixWidth="110px"
          disabled={disabled}
        />
        <Flex alignItems="center">
          <Text fontSize="smaller" italic>
            <em>{additionalInfo || ""}</em>
          </Text>
        </Flex>
      </FieldRow>
    </Box>
  );
}
