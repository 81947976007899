import React, { useState } from "react";
import { Box, Flex, Heading3, IconicButton, theme } from "@nulogy/components";
import styled from "styled-components";

const HeaderContainer = styled(Flex)({
  cursor: "pointer",
  padding: theme.sizes.x3,
  alignItems: "center",
  justifyContent: "space-between",
});

const AccordionBox = styled(Box)({
  border: "1px",
  borderRadius: theme.sizes.half,
  borderColor: theme.colors.lightGrey,
  borderStyle: "solid",
  marginTop: theme.sizes.x3,
  marginBottom: theme.sizes.x3,
});

const Accordion = ({ children, heading, sideNote }) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded(!expanded);
  };

  const icon = expanded ? "downArrow" : "rightArrow";

  return (
    <AccordionBox>
      <HeaderContainer onClick={() => toggle()} className="spec-accordion-header">
        <Box p={0}>
          <Flex alignItems="center" width="100%">
            <IconicButton
              icon={icon}
              type="button"
              data-testid="accordion-toggle"
              color={theme.colors.black}
              paddingRight={theme.sizes.x1}
            />
            <Heading3 mb={0}>{heading}</Heading3>
          </Flex>
        </Box>
        <Box color={theme.colors.midGrey} fontWeight={theme.fontWeights.light} lineHeight={theme.sizes.x3}>
          {sideNote}
        </Box>
      </HeaderContainer>

      {expanded ? (
        <Box marginLeft={theme.sizes.x3} marginRight={theme.sizes.x3}>
          {children}
        </Box>
      ) : null}
    </AccordionBox>
  );
};

export default Accordion;
