import styled from "styled-components";
import { Link, theme } from "@nulogy/components";

const UnstyledLink = styled(Link)`
  font-weight: bold;
  color: ${theme.colors.blue};
  text-decoration: none;
  &:hover {
    color: ${theme.colors.darkBlue};
    text-decoration: underline;
  }
`;

export default UnstyledLink;
