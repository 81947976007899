import React from "react";
import { useField, useFormikContext } from "formik";
import ReactDatePicker from "react-datepicker";
import { Box } from "@nulogy/components";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.styles.scss";

function DatePicker({
  labelText,
  field: name,
  className,
  onChange,
  placeholderText,
  disabled,
  dateFormat = "MMMM d, yyyy h:mm a",
}) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name); // eslint-disable-line no-unused-vars

  const handleChange = (value) => {
    //  NOTE: This control assumes that date is mandatory
    if (!value) return;

    if (onChange) {
      onChange({ value, setFieldValue });
    }
    helpers.setValue(value);
  };

  return (
    <label className={className}>
      <Box className="datepicker-label" data-testid="datepicker-label" marginBottom="x1">
        {labelText}
      </Box>
      <ReactDatePicker
        name={name}
        selected={field.value ? new Date(field.value) : null}
        showTimeSelect
        timeFormat="h:mm a"
        timeIntervals={15}
        dateFormat={dateFormat}
        timeCaption="time"
        onChange={handleChange}
        disabled={disabled}
        popperProps={{ strategy: "fixed" }}
        placeholderText={placeholderText}
      />
    </label>
  );
}

export default React.memo(DatePicker);
