import React from "react";
import { Box } from "@nulogy/components";
import styled from "styled-components";

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
`;

function FieldRow({ children, marginBottom = "x2", ...boxProps }) {
  return (
    <Box {...boxProps} marginBottom={marginBottom}>
      <FlexRow>{children}</FlexRow>
    </Box>
  );
}

export default FieldRow;
