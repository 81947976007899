import React from "react";
import { useMutation } from "@apollo/client";
import CompanyEditView from "./CompanyEditView";
import useGoBack from "hooks/useGoBack";
import { CreateCompany, GetAllCompanies } from "hooks/apollo/company/company.gql";
import Company from "domain/Company";
import Uuid from "utils/Uuid";
import paths from "config/routePaths";

export default function CompanyEditContainer() {
  const { goBack } = useGoBack();

  const [createCompany] = useMutation(CreateCompany, { onCompleted: () => leavePage() });

  const handleCancel = () => leavePage();

  const handleSave = (args) => {
    const defaultCompany = {
      id: Uuid.generate(),
      name: "",
    };

    const company = { ...defaultCompany, ...args };

    return createCompany({ variables: company, refetchQueries: [{ query: GetAllCompanies }] });
  };

  const leavePage = () => {
    goBack(paths.companyIndex);
  };

  return <CompanyEditView company={Company.build()} onSave={handleSave} onCancel={handleCancel} />;
}
