import React from "react";
import { Box, Flex, Heading3, Text } from "@nulogy/components";
import { formatDateTime } from "components/helpers/format";
import DowntimeBlock from "domain/DowntimeBlock";

const Label = ({ children }) => (
  <Text fontSize="small" fontWeight="bold">
    {children}
  </Text>
);

export default function ReadOnlyDowntimeBlock({ blockData }) {
  const {
    block: { line, lockedOnSchedule, startsAt, endsAt },
  } = blockData;

  const reasonDescription = DowntimeBlock.reasonDescription(blockData.block);

  return (
    <>
      <Box marginBottom="x2" style={{ paddingTop: 15 }}>
        <Heading3>Scheduling details</Heading3>
      </Box>
      <Box marginBottom="x2">
        <Label>Reason</Label>
        <div style={{ paddingTop: 5 }}>{reasonDescription}</div>
      </Box>
      <Box marginBottom="x2">
        <Label>Line</Label>
        <div style={{ paddingTop: 5 }}>{line.name}</div>
      </Box>
      <Box marginBottom="x2">
        <Flex className="customDatePicker">
          <Box width={1 / 2} marginRight="x3">
            <Label>Starts at</Label>
            <div style={{ paddingTop: 5 }}>{formatDateTime(startsAt)}</div>
          </Box>
          <Box width={1 / 2} marginLeft="x3">
            <Label>Ends at</Label>
            <div style={{ paddingTop: 5 }}>{formatDateTime(endsAt)}</div>
          </Box>
        </Flex>
      </Box>
      <Box marginBottom="x2">{lockedOnSchedule && "Locked"}</Box>
    </>
  );
}
