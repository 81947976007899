import React from "react";
import { Box } from "@nulogy/components";
import Form from "./Form";

export default function SettingsForm(props) {
  const { children, ...otherProps } = props;

  return (
    <Box width="440px">
      <Form {...otherProps}>{children}</Form>
    </Box>
  );
}
