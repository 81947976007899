import React from "react";
import DowntimeBlockTooltip from "components/common/Scheduler/SchedulerRenderers/components/blocks/DowntimeBlockTooltip";
import WorkBlockTooltip from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkBlockTooltip";
import Block from "domain/Block";

export default function blockTooltipRenderer({
  applicationContext,
  blockData,
  startDate,
  endDate,
  displaySnappedRemainingDuration,
  showStatus,
}) {
  const { block } = blockData;

  switch (Block.type(block)) {
    case "DowntimeBlock":
      return <DowntimeBlockTooltip downtimeBlock={block} startDate={startDate} endDate={endDate} />;
    case "WorkBlock":
      return (
        <WorkBlockTooltip
          workBlock={block}
          startDate={startDate}
          endDate={endDate}
          displaySnappedRemainingDuration={displaySnappedRemainingDuration}
          applicationContext={applicationContext}
          showStatus={showStatus}
        />
      );
    default:
      throw new Error(`ERROR: Unidentified block type: ${Block.type(block)}`);
  }
}
