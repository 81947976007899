import React, { useState } from "react";
import { Box, IconicButton, TruncatedText } from "@nulogy/components";
import { useMutation } from "@apollo/client";
import DaysSelector from "./DaysSelector";
import Time from "utils/Time";
import DateTime from "utils/DateTime";
import { GridBox6, StyledInput, StyledText, StyledTimePicker } from "components/common/EditableListStyledComponents";
import FieldRow from "components/common/FieldRow";
import Form from "components/controls/Form";
import { UpdateShift } from "hooks/apollo/shift/shift.gql";

function displayNameForDay(day) {
  const map = {
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
    sunday: "Sun",
  };

  return map[day];
}

export default function Shift({ shift, onDelete }) {
  const [editing, setEditing] = useState(false);
  const [updateShift] = useMutation(UpdateShift, { onCompleted: () => setEditing(false) });

  const { id, name, startTime, endTime } = shift;
  const formattedStartTime = Time.to12Hour(startTime);
  const formattedEndTime = Time.to12Hour(endTime);
  const days = [];

  DateTime.DAYS_OF_WEEK.forEach((day) => {
    if (shift[day] === true) {
      days.push(day);
    }
  });

  const handleCancel = (onReset) => {
    onReset();
    setEditing(false);
  };

  const handleSave = (updatedShift) => {
    const updatedDays = {};
    DateTime.DAYS_OF_WEEK.forEach((day) => {
      updatedDays[day] = updatedShift.days.includes(day);
    });
    const updatedStartTime = Time.to24Hour(updatedShift.startTime);
    const updatedEndTime = Time.to24Hour(updatedShift.endTime);

    return updateShift({
      variables: {
        id: updatedShift.id,
        name: updatedShift.name,
        startTime: updatedStartTime,
        endTime: updatedEndTime,
        ...updatedDays,
      },
    });
  };

  return (
    <>
      {!editing && (
        <GridBox6 data-testid="shift" className={`spec-shift-${id}`}>
          <StyledText data-testid="shift-name" className="spec-shift-name">
            <TruncatedText fullWidth>{name}</TruncatedText>
          </StyledText>
          <StyledText data-testid="shift-days">{days.map(displayNameForDay).join(", ")}</StyledText>
          <StyledText data-testid="shift-start-time">{formattedStartTime}</StyledText>
          <StyledText data-testid="shift-end-time">{formattedEndTime}</StyledText>
          <IconicButton type="button" icon="edit" onClick={() => setEditing(true)}>
            Edit
          </IconicButton>
          <IconicButton type="button" icon="delete" onClick={() => onDelete(shift)}>
            Delete
          </IconicButton>
        </GridBox6>
      )}
      {editing && (
        <Form
          initialValues={{
            id,
            name,
            days,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
          }}
          onSave={handleSave}
        >
          {({ dirty, handleReset }) => (
            <FieldRow marginBottom="none" data-testid="shift-row-editing">
              <GridBox6>
                <StyledInput field="name" placeholder="Name" />
                <DaysSelector />
                <Box data-testid="start-time">
                  <StyledTimePicker field="startTime" placeholder="Start Time" className="spec-shift-start-time" />
                </Box>
                <Box data-testid="end-time">
                  <StyledTimePicker field="endTime" placeholder="End Time" className="spec-shift-end-time" />
                </Box>
                <Box paddingTop="x1">
                  <IconicButton type="submit" icon="check" disabled={!dirty}>
                    Save
                  </IconicButton>
                </Box>
                <Box paddingTop="x1">
                  <IconicButton type="button" icon="close" onClick={() => handleCancel(handleReset)}>
                    Cancel
                  </IconicButton>
                </Box>
              </GridBox6>
            </FieldRow>
          )}
        </Form>
      )}
    </>
  );
}
