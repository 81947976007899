import escape from "lodash/escape";
import { formatDuration } from "components/helpers/format";
import WorkBlock from "domain/WorkBlock";

export default function teardownTimeMenuItem({
  onCustomTeardownTime,
  onScheduleTeardownTime,
  teardownTimes,
  workBlock,
}) {
  return {
    text: "Schedule teardown time",
    name: "schedule-teardown-time",
    menu: teardownTimeSubMenuItems({ onCustomTeardownTime, onScheduleTeardownTime, teardownTimes, workBlock }),
    cls: "pendo-schedule-teardown-time-context-menu-item",
  };
}

function teardownTimeSubMenuItems({ onCustomTeardownTime, onScheduleTeardownTime, teardownTimes, workBlock }) {
  return [
    ...existingTeardownTimes({ onScheduleTeardownTime, teardownTimes, workBlock }),
    customTeardownTimeItem({ onCustomTeardownTime, workBlock }),
  ];
}

function existingTeardownTimes({ onScheduleTeardownTime, teardownTimes, workBlock }) {
  return teardownTimes.map(({ label, duration }) => {
    const formattedDuration = formatDuration(duration);
    const menuText = `${label} (${formattedDuration})`;

    return {
      text: escape(menuText),
      onItem: () => {
        const updatedBlock = {
          ...WorkBlock.updateTeardownTime(workBlock, duration),
          teardownTimeLabel: label,
        };

        return onScheduleTeardownTime(updatedBlock);
      },
    };
  });
}

function customTeardownTimeItem({ onCustomTeardownTime, workBlock }) {
  return {
    text: workBlock.teardownTimeDuration === 0 ? "Custom teardown time" : "Edit teardown time",
    onItem: onCustomTeardownTime,
  };
}
