import React from "react";
import styled from "styled-components";
import { Box, theme } from "@nulogy/components";

const StyledTag = styled(Box)`
  margin-right: ${theme.sizes.x1};
  padding-left: ${theme.sizes.x1};
  padding-right: ${theme.sizes.x1};
  background-color: white;
  font-size: 12px;
  display: inline-block;
  border-radius: 20px;
  color: ${theme.colors.darkBlue};
  ${({ tooltipTag }) => {
    if (!tooltipTag) return "";

    return `
      background-color: ${theme.colors.whiteGrey};
      line-height: normal;
    `;
  }}
`;

export default function Tag({ children, ...props }) {
  return (
    <StyledTag className="tag" {...props}>
      {children}
    </StyledTag>
  );
}
