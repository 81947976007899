import React from "react";
import renderToString from "./renderToString";
import DowntimeBlock from "components/common/Scheduler/SchedulerRenderers/components/blocks/DowntimeBlock";
import WorkBlock from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkBlock";
import DateTime from "utils/DateTime";
import Block from "domain/Block";
import Authorization from "policies/Authorization";

export default function blockRenderer({ applicationContext, policy, openWorkOrderDetails }) {
  const canUpdateDowntimeBlock = policy.canUpdate(Authorization.EntityTypes.DowntimeBlock);
  const canUpdateWorkBlock = policy.canUpdate(Authorization.EntityTypes.WorkBlock);

  return function render({ eventRecord, renderData }) {
    renderData.cls.add("block-cell");
    const blockData = eventRecord.data;
    const durationToPixels = (durationInSeconds) =>
      this.timeAxisViewModel.getDistanceForDuration(DateTime.secondsToMilliseconds(durationInSeconds));

    switch (Block.type(blockData.block)) {
      case "DowntimeBlock":
        return renderToString(<DowntimeBlock blockData={blockData} readonly={!canUpdateDowntimeBlock} />);
      case "WorkBlock": {
        const teardownWidth = durationToPixels(blockData.block.teardownTimeDuration);

        return renderToString(
          <WorkBlock
            blockData={blockData}
            applicationContext={applicationContext}
            readonly={!canUpdateWorkBlock}
            openWorkOrderDetails={openWorkOrderDetails}
            teardownWidth={teardownWidth}
          />,
        );
      }
      default:
        return "";
    }
  };
}
