import React from "react";
import { Box, Button, Heading2, Page, PrimaryButton, theme } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import uniqBy from "lodash/uniqBy";
import { useNavigate } from "react-router";
import DefaultAvailabilityTable from "./DefaultAvailabilityTable";
import AvailabilityEmptyState from "./AvailabilityEmptyState";
import ConfirmDefaultAvailabilityChangesModal from "./ConfirmDefaultAvailabilityChangesModal";
import UnstyledLink from "components/common/UnstyledLink";
import paths from "config/routePaths";
import useConfirmDefaultAvailabilityChangesModal from "hooks/state/useConfirmDefaultAvailabilityChangesModal";
import useNotificationState from "hooks/state/useNotificationState";
import useUpdateDefaultAvailabilities from "hooks/apollo/defaultAvailability/useUpdateDefaultAvailabilities";
import Form from "components/controls/Form";
import StickyFooter from "components/controls/StickyFooter";
import { GetDefaultAvailabilities } from "hooks/apollo/defaultAvailability/defaultAvailability.gql";
import { GetAllShifts } from "hooks/apollo/shift/shift.gql";

export default function DefaultAvailability() {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GetDefaultAvailabilities);
  const { data: shiftData, loading: shiftsLoading } = useQuery(GetAllShifts);

  const leavePage = () => {
    navigate(paths.home);
  };

  const updateDefaultAvailabilities = useUpdateDefaultAvailabilities();
  const { close: closeConfirmChangesModal, open: openConfirmChangesModal } =
    useConfirmDefaultAvailabilityChangesModal();
  const { setNotification } = useNotificationState();

  if (loading || shiftsLoading) return null;

  const { defaultAvailabilities } = data;
  const lines = uniqBy(
    defaultAvailabilities.map((defaultAvailability) => defaultAvailability.line),
    "id",
  );

  const { shifts } = shiftData;

  const handleSave = (formData) =>
    updateDefaultAvailabilities(formData.defaultAvailabilities)
      .then(() =>
        setNotification({
          title: "The schedule’s availability was successfully updated.",
          type: "success",
        }),
      )
      .catch(() =>
        setNotification({
          title: "The changes were not saved. Please try again.",
          type: "danger",
        }),
      )
      .finally(() => closeConfirmChangesModal());

  const renderContent = () => {
    if (defaultAvailabilities.length === 0) return <AvailabilityEmptyState />;

    return (
      <Form initialValues={data} onSave={handleSave}>
        <Box maxWidth="1040px" minWidth="1040px">
          <div data-testid="content">
            {shifts.map((shift) => (
              <DefaultAvailabilityTable
                defaultAvailabilities={defaultAvailabilities}
                key={shift.id}
                lines={lines}
                shift={shift}
              />
            ))}
          </div>

          <StickyFooter>
            <Box paddingBottom={theme.sizes.x2}>
              <PrimaryButton type="button" onClick={openConfirmChangesModal} data-testid="save-at-bottom" mr="x1">
                Save and apply to schedule
              </PrimaryButton>
              <Button type="button" onClick={leavePage}>
                Cancel
              </Button>
              <ConfirmDefaultAvailabilityChangesModal />
            </Box>
          </StickyFooter>
        </Box>
      </Form>
    );
  };

  return (
    <Page>
      <Heading2 mb="x3" data-testid="title">
        Default availability
      </Heading2>

      <Box color={theme.colors.darkGrey} maxWidth="600px">
        Set the default availability per line and shift. Unchecked slots will be reflected on the schedule as
        unavailable. To accommodate day to day variation, go to{" "}
        <UnstyledLink style={{ fontWeight: "normal" }} href={paths.availabilityOverrides}>
          Availability overrides
        </UnstyledLink>
        .
      </Box>

      {renderContent()}
    </Page>
  );
}
