import React from "react";
import { Box, Page } from "@nulogy/components";
import SiteIndexView from "components/pages/site/SiteIndexView";
import UserIndexView from "components/pages/user/UserIndexView";
import SettingsForm from "components/controls/SettingsForm";
import Input from "components/controls/Input";
import FormButtons from "components/controls/FormButtons";

export default function CompanyEditView({
  company,
  onSave,
  onCancel,
  onDelete,
  canCreateSite,
  canCreateUser,
  canUpdateCompany,
  createSitePath,
  createUserPath,
}) {
  const { id, name, sites, users } = company;
  const title = id ? `Update company - ${name}` : "Create company";

  return (
    <Page title={title} className="companyEditView spec-company-form">
      <SettingsForm initialValues={company} onSave={onSave}>
        <Input field="name" labelText="Name" />
        <FormButtons
          onCancel={onCancel}
          onDelete={id ? onDelete : null}
          deleteConfirmationMessage="Are you sure you want to delete this company? This action is irreversible."
        />
      </SettingsForm>

      {id && (
        <>
          <hr />
          <Box pt="x2">
            <SiteIndexView
              assignedSites={sites}
              canCreateSite={canCreateSite}
              canUpdateCompany={canUpdateCompany}
              createSitePath={createSitePath}
            />
          </Box>
          <hr />
          <Box pt="x2">
            <UserIndexView
              users={users}
              canCreateUser={canCreateUser}
              canUpdateCompany={canUpdateCompany}
              createUserPath={createUserPath}
            />
          </Box>
        </>
      )}
    </Page>
  );
}
