import React from "react";
import { Box } from "@nulogy/components";
import { StyledSelect } from "components/common/EditableListStyledComponents";
import DateTime from "utils/DateTime";

function displayNameForDay(day) {
  const map = {
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
    sunday: "Sun",
  };

  return map[day];
}
const dayOfWeekOptions = DateTime.DAYS_OF_WEEK.map((day) => ({ value: day, label: displayNameForDay(day) }));

export default function DaysSelector() {
  return (
    <Box data-testid="days">
      <StyledSelect
        options={dayOfWeekOptions}
        multiselect
        closeMenuOnSelect={false}
        field="days"
        placeholder="Days"
        className="spec-shift-days-select"
      />
    </Box>
  );
}
