import React from "react";
import Loading from "./common/Loading";
import GraphQLError from "./pages/errors/GraphQLError";

export default ({ queries, topLevel = false }) =>
  (ViewComponent) =>
  (props) => <ResultWrapper {...{ ViewComponent, queryResults: queries, topLevel }} {...props} />;

/**
 * This is a component that will accept a GQL query array and ensure all
 * queries are completed with the resulting data from the queries passed to the ViewComponent.
 */
function ResultWrapper({ queryResults, ViewComponent, topLevel, ...props }) {
  const isLoading = queryResults.some(({ loading }) => Boolean(loading));
  const hasError = queryResults.some(({ error }) => Boolean(error));
  const dataFromQueries = queryResults.reduce((accumulator, { data }) => Object.assign(accumulator, data), {});

  if (isLoading) {
    return <Loading />;
  } else if (hasError) {
    const { error: firstError } = queryResults.find(({ error }) => Boolean(error));

    return <GraphQLError error={firstError} topLevel={topLevel} />;
  } else {
    return <ViewComponent {...dataFromQueries} {...props} />;
  }
}
