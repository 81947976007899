import React from "react";
import { Flex, Heading3, PrimaryButton, theme } from "@nulogy/components";
import paths from "config/routePaths";

export default function AvailabilityEmptyState() {
  return (
    <Flex
      data-testid="empty-availabilities"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      marginTop={theme.sizes.x6}
    >
      <img width="240px" height="180px" src="./svg/components/pages/availability/empty-box.svg" alt="empty box" />
      <Heading3>Shifts and/or lines have not been set up yet.</Heading3>
      <Flex alignItems="center" justifyContent="center" marginTop={theme.sizes.x2}>
        <PrimaryButton size="medium" marginRight={theme.sizes.x1} asLink href={paths.shifts}>
          Create shift
        </PrimaryButton>
        <PrimaryButton size="medium" marginLeft={theme.sizes.x1} asLink href={paths.lines}>
          Create line
        </PrimaryButton>
      </Flex>
    </Flex>
  );
}
