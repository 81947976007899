import React, { useState } from "react";
import { Box, Button, Flex, Heading4, Modal, Text } from "@nulogy/components";
import ReadOnlyAvailabilityCheckbox from "./ReadOnlyAvailabilityCheckbox";

// HACK: This requires a 16px margin bottom, but we can't control the margin.
// Since there is already an 8px margin, we add 8px padding.
const Heading = ({ children: heading }) => <Heading4 paddingBottom="x1">{heading}</Heading4>;

const Item = ({ name, disabled, checked, overridden = false }) => (
  <Flex alignItems="center" paddingBottom="x2">
    <ReadOnlyAvailabilityCheckbox disabled={disabled} checked={checked} overridden={overridden} />
    <Text marginLeft="x2">{name}</Text>
  </Flex>
);

export default function AvailabilityOverridesLegend() {
  const [isLegendModalOpen, setLegendModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setLegendModalOpen(true)} data-testid="availability-legend-button">
        Legend
      </Button>
      <Modal
        title="Legend"
        isOpen={isLegendModalOpen}
        onRequestClose={() => setLegendModalOpen(false)}
        ariaHideApp={false}
        maxWidth="520px"
      >
        <Flex justifyContent="space-between" data-testid="availability-legend-statuses" marginBottom="x2">
          <Box>
            <Heading>Default Availability</Heading>
            <Item name="Available" disabled={false} checked />
            <Item name="Unavailable" disabled={false} checked={false} />
            <Item name="Inactive" disabled checked={false} />
          </Box>

          <Box>
            <Heading>Overridden Availability</Heading>
            <Item name="Overridden to available" disabled={false} checked overridden />
            <Item name="Overridden to unavailable" disabled={false} checked={false} overridden />
          </Box>
        </Flex>
      </Modal>
    </>
  );
}
