import filter from "lodash/filter";
import { GetAllLines } from "hooks/apollo/line/line.gql";

export function insertLine(newLine, proxy) {
  const lines = read(proxy);

  const updatedLines = [newLine].concat(filter(lines, (existingLine) => existingLine.id !== newLine.id));

  write(updatedLines, proxy);
}

export function removeLine(lineId, proxy) {
  const internalLine = proxy.identify({ id: lineId, __typename: "Line" });
  proxy.evict({ id: internalLine });
  proxy.gc();
}

function read(proxy) {
  const { lines } = proxy.readQuery({ query: GetAllLines });

  return lines;
}

function write(lines, proxy) {
  proxy.writeQuery({
    query: GetAllLines,
    data: { lines },
  });
}
