import React from "react";
import { Box, Flex, Heading2, Page, PrimaryButton } from "@nulogy/components";
import { formatDateTime } from "components/helpers/format";
import UnstyledLink from "components/common/UnstyledLink";
import IndexTable from "components/common/IndexTable";
import paths from "config/routePaths";

const COLUMNS = [
  { label: "Company name", dataKey: "name", cellRenderer: companyCellRenderer, numeric: true },
  { label: "Created", dataKey: "createdAt", cellFormatter: formatTime },
  { label: "Last updated", dataKey: "updatedAt", cellFormatter: formatTime },
  { label: "UUID", dataKey: "id" },
];

function formatTime({ cellData }) {
  return formatDateTime(cellData);
}

function companyCellRenderer({ row }) {
  return <UnstyledLink href={paths.companyEdit(row.id)}>{row.name}</UnstyledLink>;
}

export default function CompanyIndexView({ companies }) {
  return (
    <Page>
      <Flex className="spec-companies-index-page">
        <Heading2 paddingRight="x4">Companies</Heading2>
        <Box>
          <PrimaryButton asLink type="button" size="small" href={paths.companyNew} marginTop="x1">
            Create company
          </PrimaryButton>
        </Box>
      </Flex>
      <IndexTable columns={COLUMNS} rows={companies} keyField="id" initialSortColumn="name" />
    </Page>
  );
}
