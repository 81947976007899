import React from "react";
import { DropdownButton, QuietButton } from "@nulogy/components";

export default function Legend({ isInDropDown, closeMenu, setLegendModalOpen }) {
  return (
    <>
      {!isInDropDown && (
        <QuietButton onClick={() => setLegendModalOpen(true)} data-testid="scheduling-guide-button">
          Legend
        </QuietButton>
      )}
      {isInDropDown && (
        <DropdownButton
          onClick={(e) => {
            setLegendModalOpen(true);
            closeMenu(e);
          }}
        >
          Legend
        </DropdownButton>
      )}
    </>
  );
}
