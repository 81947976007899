const User = {
  build,
};

function build() {
  return {
    email: "",
    password: "",
    __typename: "User",
  };
}

export default User;
