import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useConfirmDefaultAvailabilityChangesModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.updateConfirmDefaultAvailabilityChangesModalState, ...args };
    updateGlobalState({ updateConfirmDefaultAvailabilityChangesModalState: newState });
  };

  return {
    open: () => setState({ isOpen: true }),
    close: () => setState({ isOpen: false }),
    isOpen: state.updateConfirmDefaultAvailabilityChangesModalState.isOpen,
  };
}
