import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { GetWorkOrderFiltered } from "./workOrder.gql";
import { insertWorkOrder, removeWorkOrder } from "./helpers/handleUpdateWorkOrders";
import buildFilterQueryParameters from "./helpers/buildFilterQueryParameters";
import { useSchedulerState } from "hooks/state";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useBryntumScheduler from "hooks/useBryntumScheduler";
import WorkOrderSearchContext from "contexts/WorkOrderSearchContext";

export default function useGetWorkOrderFiltered() {
  const client = useApolloClient();
  const {
    state: { visibleTimeRange },
  } = useSchedulerState();
  const applicationContext = useApplicationContext();
  const { getBryntumScheduler } = useBryntumScheduler();
  const { state: searchState } = useContext(WorkOrderSearchContext);
  const [updatedWorkOrder, setUpdatedWorkOrder] = useState({});

  const buildQueryParameters = (workOrderId) =>
    buildFilterQueryParameters({
      applicationContext,
      bryntumScheduler: getBryntumScheduler(),
      visibleTimeRange,
      ...searchState,
      id: workOrderId,
    });

  const [getWorkOrderFiltered] = useLazyQuery(GetWorkOrderFiltered, {
    variables: buildQueryParameters(updatedWorkOrder.id),
    fetchPolicy: "no-cache",
    onCompleted: ({ workOrderFiltered: { filteredOut } }) => {
      if (filteredOut) {
        removeWorkOrder(updatedWorkOrder.id, client);
      } else {
        insertWorkOrder(updatedWorkOrder, client);
      }
    },
  });

  return {
    getWorkOrderFiltered,
    setUpdatedWorkOrder,
  };
}
