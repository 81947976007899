import { DomHelper } from "@bryntum/scheduler";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import fillInGap from "./blocks/fillInGap";
import BLOCK_HEIGHT from "./blockHeight";
import DateTime from "utils/DateTime";
import Timespan from "utils/Timespan";

export default class GapFiller {
  constructor({ settings }) {
    window.addEventListener("keydown", (e) => {
      this.key = e.key;
      this._updateHighlight();
    });
    window.addEventListener("keyup", () => {
      this.key = null;
      this._updateHighlight();
    });

    this.proxy = null;
    this.schedule = null;
    this.settings = settings;
    this.line = null;
    this.active = false;
  }

  setSchedule(schedule) {
    this.schedule = schedule;
  }

  onTaskDrag({ line, cursorPositionX, blockToReschedule }) {
    this.startsAt = this._getDateAtCursorPosition(cursorPositionX);
    this.blockToReschedule = blockToReschedule;

    if (line) {
      this.line = line;
    }

    if (this.line) {
      this._updateHighlight();
    }
  }

  isActive() {
    return this.active;
  }

  timespanWithinGap() {
    if (!this._shouldFillInGap()) return null;

    return this._getFillInGapTimespan(this.startsAt);
  }

  clearDragState() {
    this.line = null;
    this._clearHighlight();
  }

  shouldAbortFillInGap() {
    if (!this.line) return false;

    return this.key === "Shift" && (this._isOverlapWithCurrentBlocks() || !this._isInsideShift());
  }

  _getDateAtCursorPosition(cursorPositionX) {
    return DateTime.momentDate(this.schedule.getDateFromCoordinate(cursorPositionX, "round", false));
  }

  _updateHighlight() {
    if (!this.schedule) return;

    if (this._shouldFillInGap()) {
      this._highlight();
    } else {
      this._clearHighlight();
    }
  }

  _highlight() {
    const startsAt = this._getStartsAt();
    const { startTime: newStartsAt, endTime: newEndsAt } = this._getFillInGapTimespan(startsAt);

    // In Bryntum, when `newEndsAt` is on the next day, `getTimeSpanDistance` returns negative width.
    const width = Math.abs(this.schedule.getTimeSpanDistance(newStartsAt, newEndsAt));
    const x = this.schedule.timeAxisViewModel.getPositionFromDate(newStartsAt);
    const { _y: y } = this.schedule.getResourceRegion(this.line, newStartsAt, newEndsAt);

    const style = `transform:translate(${x}px, ${y}px);width:${width}px;height:${BLOCK_HEIGHT}px`;

    this._getProxy().style = style;
    this.active = true;
  }

  _shouldFillInGap() {
    if (!this.line) return false;

    return this.key === "Shift" && !this._isOverlapWithCurrentBlocks() && this._isInsideShift();
  }

  _getFillInGapTimespan(timeToSchedule) {
    const currentBlocks = this._getCurrentBlocks();

    const { startsAt, endsAt } = fillInGap(timeToSchedule, currentBlocks.map(buildTimeRange), this.schedule.timeRanges);

    return new Timespan(startsAt, endsAt);
  }

  _clearHighlight() {
    this._getProxy().style = undefined;
    this.active = false;
  }

  _isOverlapWithCurrentBlocks() {
    return this._getCurrentBlocks().some((block) => Timespan.build(block).include(this._getStartsAt()));
  }

  _isInsideShift() {
    const startsAt = this._getStartsAt();

    return this._availableShiftInstances().some((timespan) => timespan.include(startsAt));
  }

  _availableShiftInstances() {
    return differenceWith(this._shiftInstances(), this._unavailabilities(), isEqual);
  }

  _shiftInstances() {
    return this.schedule.timeRanges.map((shiftInstance) => shiftInstance.data).map(Timespan.build);
  }

  _unavailabilities() {
    return this.schedule.resourceTimeRanges
      .map((unavailability) => unavailability.data)
      .filter((unavailability) => unavailability.resourceId === this.line.id)
      .map(Timespan.build);
  }

  _getCurrentBlocks() {
    return this.schedule.eventStore
      .getEventsForResource(this.line.id)
      .map((e) => e.data)
      .filter((blockData) => this.blockToReschedule?.id !== blockData.id)
      .filter((blockData) => blockData.block);
  }

  _getStartsAt() {
    return DateTime.momentDate(this.startsAt);
  }

  _getProxy() {
    if (!this.proxy) {
      this.proxy = DomHelper.createElement({
        parent: this.schedule.foregroundCanvas,
        className: "b-sch-dragcreator-proxy",
        // Prevent element from being recycled by DomHelper.sync()
        retainElement: true,
      });
    }

    return this.proxy;
  }
}

function buildTimeRange(block) {
  return {
    startsAt: block.startDate,
    endsAt: block.endDate,
  };
}
