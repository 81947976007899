import ServerAuthorizationPolicy from "./ServerAuthorizationPolicy";
import useGetPermissions from "hooks/apollo/permissions/useGetPermissions";

const EntityTypes = {
  ApplicationContext: "types::ApplicationContextType",
  AvailabilityOverride: "types::AvailabilityOverrideType",
  Company: "types::CompanyType",
  CustomWorkOrderField: "types::CustomWorkOrderFieldType",
  CustomerSettings: "types::CustomerSettingsType",
  DefaultAvailability: "types::DefaultAvailabilityType",
  DowntimeBlock: "types::DowntimeBlockType",
  DowntimeReason: "types::DowntimeReasonType",
  Line: "types::LineType",
  PublishedScheduleType: "types::PublishedScheduleType",
  PublishedWorkBlockType: "types::PublishedWorkBlockType",
  Role: "types::RoleType",
  Shift: "types::ShiftType",
  ShiftInstance: "types::ShiftInstanceType",
  Site: "types::SiteType",
  SyncResult: "types::SyncResultType",
  TeardownTime: "types::TeardownTimeType",
  TimeZone: "types::TimeZoneType",
  UsageData: "types::UsageDataType",
  User: "types::UserType",
  UserPreferences: "types::UserPreferencesType",
  WorkBlock: "types::WorkBlockType",
  WorkOrder: "types::WorkOrderType",
};

const Authorization = {
  EntityTypes,
  usePolicy,
};

function usePolicy() {
  const {
    permissions: { policy: rawPolicy },
  } = useGetPermissions();

  const policy = JSON.parse(rawPolicy);

  return new ServerAuthorizationPolicy(policy);
}

export default Authorization;
