import { useContext } from "react";
import useGetWorkOrders from "./useGetWorkOrders";
import WorkOrderSearchContext from "contexts/WorkOrderSearchContext";

export default function useRefetchWorkOrders() {
  const { state: existingFilter } = useContext(WorkOrderSearchContext);
  const { fetch: refetchWorkOrders } = useGetWorkOrders();

  return {
    refetch: (args) => {
      refetchWorkOrders({ ...existingFilter, ...args });
    },
  };
}
