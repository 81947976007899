const paths = {
  home: "/",

  adminDashboard: "/admin/dashboard",
  availabilityOverrides: "/availability-overrides",
  companyEdit: (id) => `/companies/${id}/edit`,
  companyIndex: "/companies",
  companyNew: "/companies/new",
  customWorkOrderFields: "/custom-work-order-fields",
  customerSettings: "/settings",
  defaultAvailability: "/default-availability",
  downtimeReasons: "/downtime-reasons",
  laborSummary: "/labor-summary",
  lines: "/lines",
  manageData: "/manage-data",
  preferences: "/preferences",
  shifts: "/shifts",
  siteEdit: (id) => `/sites/${id}/edit`,
  siteIndex: "/sites",
  siteNew: "/sites/new",
  siteNewForCompany: (companyId) => `/sites/new/${companyId}`,
  teardownTimes: "/teardown-times",
  userEdit: (id) => `/users/${id}/edit`,
  userIndex: "/users",
  userNew: "/users/new",
  userNewForCompany: (companyId) => `/users/new/${companyId}`,
  userNewForSite: (companyId, siteId) => `/users/new/${companyId}/${siteId}`,
  version: "/test/build_tag",
};

export default paths;
