import React from "react";
import { Flex, Table, TruncatedText, theme } from "@nulogy/components";
import pick from "lodash/pick";
import capitalize from "lodash/capitalize";
import styled from "styled-components";
import Accordion from "./Accordion";
import AvailabilityCheckbox from "./AvailabilityCheckbox";
import DateTime from "utils/DateTime";
import Time from "utils/Time";

const CenteredCell = styled(Flex)({
  paddingTop: theme.sizes.x2,
  paddingBottom: theme.sizes.x2,
  width: "108px",
  alignItems: "center",
  justifyContent: "center",
});

function renderDefaultAvailability({ column, row }) {
  const day = column.dataKey;
  const shiftAvailable = row.shiftAvailability[day];

  return (
    <CenteredCell>
      <AvailabilityCheckbox
        className={`spec-default-availability-${day}`}
        data-testid={`${row.id}-${day}`}
        field={`defaultAvailabilities[${row.defaultAvailabilityIndex}][${day}]`}
        disabled={!shiftAvailable}
      />
    </CenteredCell>
  );
}

function renderLineHeader({ cellData }) {
  return (
    <CenteredCell className="spec-line-name">
      <TruncatedText width="100px" textAlign="center" fullWidth>
        {cellData}
      </TruncatedText>
    </CenteredCell>
  );
}

function headerFormatter({ label }) {
  return <CenteredCell>{label}</CenteredCell>;
}

function dayColumn(dataKey) {
  return {
    dataKey,
    width: "108px",
    label: capitalize(dataKey),
    cellRenderer: renderDefaultAvailability,
    headerFormatter,
  };
}

const columns = [
  { dataKey: "name", width: "108px", cellRenderer: renderLineHeader, headerFormatter },
  dayColumn("monday"),
  dayColumn("tuesday"),
  dayColumn("wednesday"),
  dayColumn("thursday"),
  dayColumn("friday"),
  dayColumn("saturday"),
  dayColumn("sunday"),
];

export default function DefaultAvailabilityTable({ defaultAvailabilities, lines, shift }) {
  const rowsForShift = () =>
    lines.map((line) => {
      const defaultAvailabilityIndex = defaultAvailabilityIndexFor(line);

      return {
        ...line,
        ...pick(defaultAvailabilities[defaultAvailabilityIndex], DateTime.DAYS_OF_WEEK),
        shiftAvailability: pick(shift, DateTime.DAYS_OF_WEEK),
        defaultAvailabilityIndex,
      };
    });

  const defaultAvailabilityIndexFor = (line) =>
    defaultAvailabilities.findIndex(
      (defaultAvailability) => defaultAvailability.shift.id === shift.id && defaultAvailability.line.id === line.id,
    );

  const shiftTime = `${Time.to12Hour(shift.startTime)} - ${Time.to12Hour(shift.endTime)}`;

  return (
    <Accordion heading={shift.name} sideNote={shiftTime}>
      <Table
        data-testid="table"
        columns={columns}
        rows={rowsForShift()}
        compact
        style={{ marginBottom: theme.sizes.x3 }}
      />
    </Accordion>
  );
}
