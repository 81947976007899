import React from "react";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import CommonIndicator from "components/common/CustomWorkOrderFieldIndicator";

const Indicator = styled(CommonIndicator)`
  position: absolute;
  top: 14px;
  left: ${({ userCanUpdate }) => (userCanUpdate ? "27px" : "10px")};
`;

export default function CustomWorkOrderFieldIndicatorOld({ name, color, settings, userCanUpdate }) {
  if (isEmpty(name)) return null;
  if (!settings || !settings.showCustomWorkOrderFields) return null;

  return (
    <Indicator
      title={name}
      color={color}
      userCanUpdate={userCanUpdate}
      className="spec-custom-work-order-field-indicator"
      data-testid="custom-work-order-field-indicator"
    />
  );
}
