import React from "react";
import { Formik } from "formik";
import { Form as NulogyForm } from "@nulogy/components";
import FormErrors from "./FormErrors";
import GqlError from "utils/GqlError";

function Form({ onSave, ...optionalProps }) {
  const {
    children,
    formAttributes,
    formTitle,
    id,
    onAfterSave,
    onBeforeSave,
    onChange,
    initialValues = {},
    ...otherProps
  } = optionalProps;

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    let newValues = values;

    if (onBeforeSave) {
      newValues = onBeforeSave(values, actions);
    }

    return onSave(newValues, actions)
      .then(() => {
        actions.setSubmitting(false);
        if (onAfterSave) onAfterSave();
      })
      .catch((errorResponse) => {
        actions.setSubmitting(false);
        GqlError.handler(errorResponse, actions.setErrors);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} {...otherProps}>
      {(renderProps) => {
        const { errors, handleSubmit, isSubmitting } = renderProps;

        const allErrors = { ...errors };

        if (otherProps.errors) {
          if (!allErrors.base) {
            allErrors.base = [];
          }
          allErrors.base = allErrors.base.concat(otherProps.errors.map((error) => error.message));
        }

        if (onChange) onChange(renderProps);

        const submitForm = (event, ...args) => {
          event.preventDefault();

          if (isSubmitting) {
            return;
          }
          handleSubmit(...args);
        };

        return (
          <NulogyForm id={id} name={id} title={formTitle} onSubmit={submitForm} {...formAttributes}>
            <FormErrors errors={allErrors} />
            {typeof children === "function" ? children(renderProps) : children}
          </NulogyForm>
        );
      }}
    </Formik>
  );
}

export default Form;
