import styled from "styled-components";

const Block = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  font-size: 14px;
  padding-top: 4px;
  margin-left: -12px;
  max-width: fit-content;
  position: sticky;
  left: 0;
`;

export default Block;
