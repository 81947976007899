import { useLazyQuery } from "@apollo/client";
import { GetShiftInstanceByRange } from "./shiftInstance.gql";
import { useSchedulerState } from "hooks/state";
import useTimeZone from "hooks/useTimeZone";

export default function useFetchShiftInstances() {
  const {
    state: { dataTimeRange },
  } = useSchedulerState();
  const { overrideToSiteTimeZone } = useTimeZone();

  const [, { data, loading, error, fetchMore }] = useLazyQuery(GetShiftInstanceByRange, {
    variables: {
      scheduledStart: overrideToSiteTimeZone(dataTimeRange.startDate),
      scheduledEnd: overrideToSiteTimeZone(dataTimeRange.endDate),
    },
  });

  return {
    shiftInstances: data ? data.shiftInstances : [],
    refetch: ({ dataTimeRange: newDataTimeRange } = {}) => {
      const actualStart = newDataTimeRange?.startDate || dataTimeRange.startDate;
      const actualEnd = newDataTimeRange?.endDate || dataTimeRange.endDate;

      return fetchMore({
        variables: {
          scheduledStart: overrideToSiteTimeZone(actualStart),
          scheduledEnd: overrideToSiteTimeZone(actualEnd),
        },
      });
    },
    data,
    loading,
    error,
  };
}
