import React from "react";
import styled from "styled-components";
import { Box, Text } from "@nulogy/components";
import UnstyledLink from "components/common/UnstyledLink";
import TruncatedText from "components/common/TruncatedText";
import WorkOrder from "domain/WorkOrder";

const Wrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 290px;
  padding-left: ${({ readonly, showCustomWorkOrderFields }) => {
    if (readonly) {
      return "18px";
    }

    if (showCustomWorkOrderFields) {
      return "35px";
    }

    return "20px";
  }};
`;

function workOrderCardDescription({ workOrder, workOrderCardLayout }) {
  const { description, itemDescription } = workOrder;

  if (workOrderCardLayout === WorkOrder.ITEM_DESCRIPTION_LAYOUT) {
    return itemDescription || <em>No item description</em>;
  } else {
    return description || <em>No work order description</em>;
  }
}

export default function PrimaryDetails({ applicationContext, userCanUpdate, workOrder, onOpenWorkOrderDetails }) {
  const { id: workOrderId, code, customerName, itemCode } = workOrder;
  const {
    settings,
    customerSettings: { workOrderCardLayout },
  } = applicationContext;

  const description = workOrderCardDescription({ workOrder, workOrderCardLayout });

  return (
    <Wrapper
      readonly={!userCanUpdate}
      showCustomWorkOrderFields={settings.showCustomWorkOrderFields}
      data-testid="primary-details"
    >
      {/* eslint-disable-next-line */}
      <UnstyledLink draggable="false" onClick={onOpenWorkOrderDetails}>
        <TruncatedText
          inline
          fontSize="small"
          className={`spec-work-order-${workOrderId} pendo-view-work-order-details-work-order-card-code`}
          title={code}
        >
          {code}
        </TruncatedText>
      </UnstyledLink>
      <Text inline fontWeight="bold" marginX="4px">
        |
      </Text>
      <Text inline fontSize="small" title={customerName}>
        {customerName}
      </Text>
      <Box paddingRight="10px">
        <TruncatedText fontSize="small" title={description}>
          {description}
        </TruncatedText>
      </Box>
      <Box paddingRight="10px">
        <TruncatedText fontSize="small" title={itemCode}>
          {itemCode}
        </TruncatedText>
      </Box>
    </Wrapper>
  );
}
