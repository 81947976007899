import React from "react";
import { Box, Flex, Heading3 } from "@nulogy/components";

export default function Section({ title, controls, children, ...boxProps }) {
  return (
    <Box marginBottom="x3" {...boxProps}>
      <Flex justifyContent="space-between" alignItems="center" marginBottom="x1">
        <Heading3>{title}</Heading3>
        {controls && <Box>{controls}</Box>}
      </Flex>
      {children}
    </Box>
  );
}
