import filter from "lodash/filter";
import { GetWorkOrders } from "hooks/apollo/workOrder/workOrder.gql";
import { SIDE_BAR_LIMIT } from "config/appConfig";

function insertWorkOrder(newWorkOrder, proxy) {
  const currentWorkOrders = read(proxy);

  const updatedWorkOrders = [newWorkOrder].concat(filter(currentWorkOrders, (wo) => wo.id !== newWorkOrder.id));

  write(updatedWorkOrders, proxy);
}

function removeWorkOrder(workOrderId, proxy) {
  const currentWorkOrders = read(proxy);

  const updatedWorkOrders = currentWorkOrders.filter((workOrder) => workOrder.id !== workOrderId);

  write(updatedWorkOrders, proxy);
}

function read(proxy) {
  const { workOrders } = proxy.readQuery({
    query: GetWorkOrders,
    variables: { limit: SIDE_BAR_LIMIT },
  });

  return workOrders;
}

function write(workOrders, proxy) {
  proxy.writeQuery({
    query: GetWorkOrders,
    variables: { limit: SIDE_BAR_LIMIT },
    data: { workOrders },
  });
}

export { insertWorkOrder, removeWorkOrder };
