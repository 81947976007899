import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import SiteEditView from "./SiteEditView";
import paths from "config/routePaths";
import useGoBack from "hooks/useGoBack";
import { GetAllCompanies } from "hooks/apollo/company/company.gql";
import { CreateSite, GetAssignedSites } from "hooks/apollo/site/site.gql";
import { GetAllTimeZones } from "hooks/apollo/timeZone/timeZone.gql";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import Site from "domain/Site";
import Uuid from "utils/Uuid";

export default function SiteCreateContainer({ companyId }) {
  const { goBack } = useGoBack();
  const applicationContext = useApplicationContext();
  const { nulogyAdmin } = applicationContext;

  const { data: companiesData, loading: companiesLoading } = useQuery(GetAllCompanies);
  const { data: timezoneData, loading: timezonesLoading } = useQuery(GetAllTimeZones);
  const [error, setError] = useState(false);
  const leavePage = () => {
    goBack(paths.siteIndex);
  };
  const [createSite] = useMutation(CreateSite, { onCompleted: () => leavePage() });

  if (timezonesLoading || companiesLoading) return null;

  const handleCancel = () => leavePage();

  const handleSave = (args) => {
    const site = { id: Uuid.generate(), ...args, companyId: args.company?.id };

    return createSite({ variables: site, refetchQueries: [{ query: GetAssignedSites }] });
  };

  return (
    <SiteEditView
      timeZones={timezoneData?.timeZones}
      companies={companiesData?.companies || []}
      companyId={companyId}
      site={Site.build()}
      onCancel={handleCancel}
      onSave={handleSave}
      nulogyAdmin={nulogyAdmin}
      error={error}
      setError={setError}
    />
  );
}
