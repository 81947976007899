import React from "react";
import styled from "styled-components";

const FLAG_SIZE_LARGE = "large";
const FlagWrapper = styled.div(({ sizeInPixels }) => ({
  "line-height": `${sizeInPixels}px`,
  "margin-left": "-1px",
  padding: "0 4px 0 0",
}));

// If updating these SVGs please also update the css in frontend/src/components/common/Scheduler/Scheduler.styles.scss
const FlagA = ({ sizeInPixels }) => (
  <svg
    width={sizeInPixels}
    height={sizeInPixels}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "middle" }}
  >
    <path
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      fill="#EE5513"
    />
    <path
      d="M14.8489 18L13.9529 15.152H9.98494L9.10494 18H6.96094L10.7049 6.83203H13.3289L17.0409 18H14.8489ZM12.0009 8.75203H11.9209L10.4969 13.344H13.4409L12.0009 8.75203Z"
      fill="white"
    />
  </svg>
);

// If updating these SVGs please also update the css in frontend/src/components/common/Scheduler/Scheduler.styles.scss
const FlagB = ({ sizeInPixels }) => (
  <svg
    width={sizeInPixels}
    height={sizeInPixels}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "middle" }}
  >
    <circle cx="12" cy="12" r="10" fill="#FFBB00" />
    <path
      d="M8.00781 6.83203H13.1758C13.6345 6.83203 14.0451 6.90136 14.4078 7.04003C14.7811 7.1787 15.0958 7.3707 15.3518 7.61603C15.6078 7.86136 15.7998 8.16536 15.9278 8.52803C16.0665 8.88003 16.1358 9.26936 16.1358 9.69603C16.1358 10.1227 16.0771 10.4854 15.9598 10.784C15.8531 11.072 15.7038 11.312 15.5118 11.504C15.3305 11.696 15.1171 11.84 14.8718 11.936C14.6371 12.032 14.3918 12.0854 14.1358 12.096V12.192C14.3811 12.192 14.6425 12.24 14.9198 12.336C15.2078 12.432 15.4691 12.5867 15.7038 12.8C15.9491 13.0027 16.1518 13.2694 16.3118 13.6C16.4718 13.92 16.5518 14.32 16.5518 14.8C16.5518 15.248 16.4771 15.6694 16.3278 16.064C16.1891 16.448 15.9918 16.784 15.7358 17.072C15.4798 17.36 15.1758 17.5893 14.8238 17.76C14.4718 17.92 14.0878 18 13.6718 18H8.00781V6.83203ZM10.1198 16.208H13.0638C13.4692 16.208 13.7838 16.1067 14.0078 15.904C14.2318 15.6907 14.3438 15.3867 14.3438 14.992V14.448C14.3438 14.0534 14.2318 13.7494 14.0078 13.536C13.7838 13.3227 13.4692 13.216 13.0638 13.216H10.1198V16.208ZM10.1198 11.488H12.7278C13.1118 11.488 13.4105 11.3867 13.6238 11.184C13.8371 10.9707 13.9438 10.6774 13.9438 10.304V9.80803C13.9438 9.4347 13.8371 9.1467 13.6238 8.94403C13.4105 8.7307 13.1118 8.62403 12.7278 8.62403H10.1198V11.488Z"
      fill="#011E38"
    />
  </svg>
);

// If updating these SVGs please also update the css in frontend/src/components/common/Scheduler/Scheduler.styles.scss
const FlagC = ({ sizeInPixels }) => (
  <svg
    width={sizeInPixels}
    height={sizeInPixels}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "middle" }}
  >
    <circle cx="12" cy="12" r="10" fill="#008059" />
    <path
      d="M12.5152 18.192C11.7792 18.192 11.1126 18.0747 10.5152 17.84C9.92855 17.5947 9.42188 17.232 8.99522 16.752C8.57922 16.272 8.25922 15.68 8.03522 14.976C7.81122 14.2613 7.69922 13.4347 7.69922 12.496C7.69922 11.5573 7.81122 10.7253 8.03522 10C8.25922 9.26402 8.57922 8.65069 8.99522 8.16002C9.42188 7.65869 9.92855 7.28002 10.5152 7.02402C11.1126 6.76801 11.7792 6.64001 12.5152 6.64001C13.5179 6.64001 14.3499 6.85335 15.0112 7.28002C15.6726 7.70669 16.2006 8.35202 16.5952 9.21602L14.7872 10.176C14.6272 9.67469 14.3659 9.27469 14.0032 8.97602C13.6406 8.66669 13.1446 8.51202 12.5152 8.51202C11.7259 8.51202 11.0966 8.77869 10.6272 9.31202C10.1686 9.84535 9.93922 10.592 9.93922 11.552V13.312C9.93922 14.2827 10.1686 15.0293 10.6272 15.552C11.0966 16.064 11.7259 16.32 12.5152 16.32C13.1446 16.32 13.6566 16.1493 14.0512 15.808C14.4566 15.4667 14.7499 15.0453 14.9312 14.544L16.6432 15.552C16.2379 16.384 15.6992 17.0347 15.0272 17.504C14.3552 17.9627 13.5179 18.192 12.5152 18.192Z"
      fill="white"
    />
  </svg>
);

// If updating these SVGs please also update the css in frontend/src/components/common/Scheduler/Scheduler.styles.scss
const FlagD = ({ sizeInPixels }) => (
  <svg
    width={sizeInPixels}
    height={sizeInPixels}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "middle" }}
  >
    <circle cx="12" cy="12" r="10" fill="#8033CC" />
    <path
      d="M7.79688 6.83203H11.8609C12.5862 6.83203 13.2422 6.94936 13.8289 7.18403C14.4262 7.4187 14.9329 7.7707 15.3489 8.24003C15.7755 8.6987 16.1009 9.28003 16.3249 9.98403C16.5595 10.6774 16.6769 11.488 16.6769 12.416C16.6769 13.344 16.5595 14.16 16.3249 14.864C16.1009 15.5574 15.7755 16.1387 15.3489 16.608C14.9329 17.0667 14.4262 17.4133 13.8289 17.648C13.2422 17.8827 12.5862 18 11.8609 18H7.79688V6.83203ZM11.8609 16.128C12.6502 16.128 13.2742 15.8987 13.7329 15.44C14.2022 14.9707 14.4369 14.256 14.4369 13.296V11.536C14.4369 10.576 14.2022 9.8667 13.7329 9.40803C13.2742 8.9387 12.6502 8.70403 11.8609 8.70403H9.90888V16.128H11.8609Z"
      fill="white"
    />
  </svg>
);

// If updating these SVGs please also update the css in frontend/src/components/common/Scheduler/Scheduler.styles.scss
const FlagE = ({ sizeInPixels }) => (
  <svg
    width={sizeInPixels}
    height={sizeInPixels}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "middle" }}
  >
    <circle cx="12" cy="12" r="9.75" fill="#E9F7F2" stroke="#011E38" strokeWidth="0.5" />
    <path
      d="M8.51562 18V6.83203H15.8756V8.70403H10.6276V11.408H15.2676V13.28H10.6276V16.128H15.8756V18H8.51562Z"
      fill="#011E38"
    />
  </svg>
);

// If updating these SVGs please also update the css in frontend/src/components/common/Scheduler/Scheduler.styles.scss
const FlagF = ({ sizeInPixels }) => (
  <svg
    width={sizeInPixels}
    height={sizeInPixels}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "middle" }}
  >
    <circle cx="12" cy="12" r="9.75" fill="#FAE6EA" stroke="#011E38" strokeWidth="0.5" />
    <path d="M8.69531 18V6.83203H15.8793V8.70403H10.8073V11.408H15.2233V13.28H10.8073V18H8.69531Z" fill="#011E38" />
  </svg>
);

export default function WorkOrderFlag({ flag, size }) {
  if (!flag) return null;

  const sizeInPixels = size === FLAG_SIZE_LARGE ? "24" : "19";

  return (
    <FlagWrapper alt={`Flag ${flag}`} sizeInPixels={sizeInPixels} data-testid="flag">
      {flag === "A" && <FlagA sizeInPixels={sizeInPixels} />}
      {flag === "B" && <FlagB sizeInPixels={sizeInPixels} />}
      {flag === "C" && <FlagC sizeInPixels={sizeInPixels} />}
      {flag === "D" && <FlagD sizeInPixels={sizeInPixels} />}
      {flag === "E" && <FlagE sizeInPixels={sizeInPixels} />}
      {flag === "F" && <FlagF sizeInPixels={sizeInPixels} />}
    </FlagWrapper>
  );
}
