import { useQuery } from "@apollo/client";
import { GetSchedulerData } from "./schedulerData.gql";
import Uuid from "utils/Uuid";
import { useSchedulerState } from "hooks/state";
import useTimeZone from "hooks/useTimeZone";

let lastQueryId = 0;

const mostRecentFetchMore = ({ variables, fetchMore }) => {
  const queryId = Uuid.generate();
  lastQueryId = queryId;

  return fetchMore({
    variables,
    updateQuery: (previousResult, result) => {
      if (!result.fetchMoreResult || lastQueryId !== queryId) {
        return previousResult;
      }

      return result.fetchMoreResult;
    },
  });
};
export default function useFetchSchedulerData() {
  const {
    state: { dataTimeRange },
  } = useSchedulerState();
  const { overrideToSiteTimeZone } = useTimeZone();

  const { data, fetchMore, loading, error } = useQuery(GetSchedulerData, {
    variables: {
      startDate: overrideToSiteTimeZone(dataTimeRange.startDate),
      endDate: overrideToSiteTimeZone(dataTimeRange.endDate),
    },
  });

  return {
    shiftInstances: data ? data.shiftInstances : [],
    blocks: data ? data.blocks : { workBlocks: [], downtimeBlocks: [] },
    unavailabilities: data ? data.unavailabilities : [],
    refetch: ({ dataTimeRange: newDataTimeRange } = {}) => {
      const actualStart = newDataTimeRange?.startDate || dataTimeRange.startDate;
      const actualEnd = newDataTimeRange?.endDate || dataTimeRange.endDate;

      return mostRecentFetchMore({
        variables: {
          startDate: overrideToSiteTimeZone(actualStart),
          endDate: overrideToSiteTimeZone(actualEnd),
        },
        fetchMore,
      });
    },
    data,
    loading,
    error,
  };
}
