import React from "react";
import { Box, Flex, Icon, Text, Tooltip, theme } from "@nulogy/components";
import styled from "styled-components";
import { formatQuantity } from "components/helpers/format";
import TruncatedText from "components/common/TruncatedText";

const HelpIcon = styled(Icon)`
  position: relative;
  left: 20px;
  top: 8px;
`;

const EffectiveProductionRateHelp = () => {
  const tooltip =
    "Effective production rate is calculated by multiplying the production rate by the item performance, labor ratio, and line efficiency (if applicable).";

  return (
    <Flex flexFlow="flex-end">
      <Tooltip tooltip={tooltip}>
        <HelpIcon icon="help" />
      </Tooltip>
    </Flex>
  );
};

const Divider = styled(Box)`
  border-top: 1px solid ${theme.colors.grey};
`;

const ProductionDetailLabel = styled(Box)`
  width: 178px;
`;

const ProductionDetailValue = styled(Box)`
  text-align: right;
  width: 100px;
  margin-right: 4px;
`;

const AlignUOM = styled.span`
  width: 36px;
  display: inline-block;
`;

export default function ProductionDetailsSummary({ effectiveProductionRate, expectedQuantity, unitOfMeasure }) {
  return (
    <Box marginTop="x4" marginBottom="x2">
      <Flex justifyContent="flex-end">
        <EffectiveProductionRateHelp />
        <Divider width={5 / 7} marginLeft="x3">
          <Flex marginTop="x1" className="spec-effective-production-rate" justifyContent="space-between">
            <ProductionDetailLabel>
              <Text fontSize="small">Effective production rate</Text>
            </ProductionDetailLabel>
            <Flex justifyContent="flex-end">
              <ProductionDetailValue>
                <Tooltip tooltip={formatQuantity(effectiveProductionRate).toString()} showDelay="1000">
                  <TruncatedText fontSize="small" fontWeight="bold">
                    {formatQuantity(effectiveProductionRate).toString()}
                  </TruncatedText>
                </Tooltip>
              </ProductionDetailValue>
              <Box>
                <Text fontSize="small" fontWeight="bold">
                  {unitOfMeasure}/hour
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Flex marginTop="x1" className="spec-expected-quantity" justifyContent="space-between">
            <ProductionDetailLabel>
              <Text fontSize="small">Expected quantity on block</Text>
            </ProductionDetailLabel>
            <Flex justifyContent="flex-end">
              <ProductionDetailValue>
                <Tooltip tooltip={formatQuantity(expectedQuantity).toString()} showDelay="1000">
                  <TruncatedText fontSize="small" fontWeight="bold">
                    {formatQuantity(expectedQuantity).toString()}
                  </TruncatedText>
                </Tooltip>
              </ProductionDetailValue>
              <Box>
                <Text fontSize="small" fontWeight="bold">
                  {unitOfMeasure}
                  <AlignUOM />
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Divider>
      </Flex>
    </Box>
  );
}
