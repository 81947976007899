import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { UpdateCustomerSettings, UpdateLayoutSetting } from "./customerSettings.gql";
import { useNotificationState } from "hooks/state";
import GqlError from "utils/GqlError";

export default function useUpdateCustomerSettings({ onCompleted, customizeWorkOrderCards }) {
  const { setNotification } = useNotificationState();
  const mutation = customizeWorkOrderCards ? UpdateLayoutSetting : UpdateCustomerSettings;

  const [updateCustomerSettings] = useMutation(mutation, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to update layout settings",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted,
  });

  return (customerSettings) => {
    const { workBlockLayout, workOrderCardLayout } = customerSettings;

    let variables = { workBlockLayout };
    if (customizeWorkOrderCards) {
      // eslint-disable-next-line no-unused-vars
      const { __typename, ...layoutProps } = workOrderCardLayout;
      variables = { ...variables, ...layoutProps };
    } else {
      variables.workOrderCardLayout = workOrderCardLayout;
    }

    return updateCustomerSettings({
      variables,
    });
  };
}
