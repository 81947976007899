import { Box, Flex, Heading4, StatusIndicator, Text, theme } from "@nulogy/components";
import map from "lodash/map";
import React from "react";
import Field from "components/common/Field";
import { formatDate, formatDayOfWeek, formatDuration, formatTime } from "components/helpers/format";
import UnstyledLink from "components/common/UnstyledLink";
import Block from "domain/Block";

const WorkBlockDetails = ({ workBlock, onNavigateToWorkBlock }) => (
  <Box marginBottom="x1">
    <UnstyledLink
      key={`scheduled-block-${workBlock.id}`}
      className={`spec-work-block-link-${workBlock.id}`}
      onClick={() => onNavigateToWorkBlock(workBlock)}
      style={{ fontWeight: "normal" }}
      data-testid="work-block-item"
    >
      {formatWorkBlock(workBlock)}
    </UnstyledLink>
    <TeardownTimeDuration workBlock={workBlock} />
  </Box>
);

function formatWorkBlock(workBlock) {
  const dayOfWeek = formatDayOfWeek(workBlock.startsAt);
  const date = formatDate(workBlock.startsAt);
  const time = formatTime(workBlock.startsAt);
  const duration = formatDuration(Block.blockTime(workBlock).durationInSeconds());

  return (
    <>
      {dayOfWeek} {date} | {time} <strong>({duration})</strong>
    </>
  );
}

const TeardownTimeDuration = ({ workBlock }) =>
  workBlock.teardownTimeDuration > 0 && (
    <Text data-testid="teardown-time-duration">
      Includes {workBlock.teardownTimeLabel} <strong>({formatDuration(workBlock.teardownTimeDuration)})</strong>
    </Text>
  );

export default function ScheduledWorkBlockDetailsView({
  onNavigateToWorkBlock,
  scheduledDuration,
  linesWithWorkBlocks,
}) {
  return (
    <>
      {scheduledDuration > 0 ? (
        <>
          <Field data-testid="scheduled-duration" labelText="Scheduled duration">
            {formatDuration(scheduledDuration)}
          </Field>
          {map(linesWithWorkBlocks, (lineWithWorkBlocks) => {
            const { id, name, workBlocks, active } = lineWithWorkBlocks;

            return (
              <Box key={`line-${id}`} marginTop="x2" data-testid="line-group">
                <Heading4 className="spec-scheduled-work-block-line-name">
                  <Flex>
                    {name}
                    {!active && <StatusIndicator style={{ marginLeft: theme.sizes.half }}>Inactive</StatusIndicator>}
                  </Flex>
                </Heading4>
                {map(workBlocks, (workBlock) => (
                  <WorkBlockDetails
                    key={`container-scheduled-block-${workBlock.id}`}
                    onNavigateToWorkBlock={onNavigateToWorkBlock}
                    workBlock={workBlock}
                  />
                ))}
              </Box>
            );
          })}
        </>
      ) : (
        <Text fontStyle="italic" data-testid="no-schedule-blocks-message">
          No blocks scheduled
        </Text>
      )}
    </>
  );
}
