import React from "react";
import SyncSites from "./SyncSites";
import LastSyncedAt from "./LastSyncedAt";
import Authorization from "policies/Authorization";
import Codenames from "policies/Codenames";

export default function syncMenu({ applicationContext, policy }) {
  if (!policy.canSync(Authorization.EntityTypes.SyncResult)) return null;

  return {
    name: "Sync",
    items: [
      {
        name: "Sync",
        render: () => <SyncSites label={`Sync current site with the ${Codenames.Packmanager}`} />,
      },
      // There are enough sites that this operation always times out. We can re-enable this option once
      // we have background tasks or other functionality that makes success viable.
      // policy.canSyncAll(Authorization.EntityTypes.SyncResult) && {
      //   name: "Sync all sites",
      //   render: () => <SyncSites all label={`Sync all sites with the ${Codenames.Packmanager}`} />,
      // },
      {
        name: "Last synced at",
        render: () => <LastSyncedAt lastSyncedAt={applicationContext.site.lastSyncedAt} />,
      },
    ],
  };
}
