import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { blockRenderer, blockTooltipRenderer } from "components/common/Scheduler/SchedulerRenderers/renderers";
import DateTime from "utils/DateTime";
import Authorization from "policies/Authorization";
import { GetTeardownTimes } from "hooks/apollo/teardownTime/teardownTime.gql";
import useSlideOutState from "hooks/state/useSlideOutState";
import Block from "domain/Block";
import workBlockContextMenu from "components/common/Scheduler/contextMenu/workBlockContextMenu";
import useSplitWorkBlockAcrossShifts from "hooks/apollo/workBlock/useSplitWorkBlockAcrossShifts";
import usePublishWorkBlockModal from "hooks/state/usePublishWorkBlockModal";
import useNotesPopUp from "hooks/state/useNotesPopUp";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";
import useUnscheduleWorkOrder from "hooks/apollo/workOrder/useUnscheduleWorkOrder";
import useUnscheduleBlock from "hooks/apollo/blocks/useUnscheduleBlock";
import WorkBlock from "domain/WorkBlock";
import packmanagerJobLinkRenderer from "components/common/Scheduler/SchedulerRenderers/renderers/packmanagerJobLinkRenderer";
import ConfirmModalContext from "contexts/ConfirmModalContext";
import fillInGapTimespan from "components/pages/schedule/helpers/fillInGapTimespan";
import renderToString from "components/common/Scheduler/SchedulerRenderers/renderers/renderToString";
import BlockTooltip from "components/common/Scheduler/SchedulerRenderers/components/blocks/BlockTooltip";
import useTrackUsage from "hooks/apollo/usageData/useTrackUsage";
import useRescheduleBlocks from "hooks/apollo/blocks/useRescheduleBlocks";
import { useDowntimeShowModal, useWorkBlockEditModal, useWorkBlockShowModal } from "hooks/state";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useFullyScheduleWorkOrder from "hooks/apollo/workBlock/useFullyScheduleWorkOrder";

function workBlockReadOnlyMenu({ openWorkBlockShowModal, blockData }) {
  return {
    view: {
      text: "View details",
      onItem: () => openWorkBlockShowModal(blockData),
    },
  };
}

function downtimeReadOnlyMenu({ openDowntimeShowModal, blockData }) {
  return {
    view: {
      text: "View details",
      onItem: () => openDowntimeShowModal(blockData),
    },
  };
}

function downtimeMenu({ items, unscheduleBlock }) {
  return {
    editEvent: {
      ...items.editEvent,
      text: "Edit downtime block",
      icon: false,
    },
    deleteEvent: {
      text: "Delete downtime block",
      name: "unschedule-downtime",
      onItem: unscheduleBlock,
      icon: false,
    },
  };
}
export default function useEventFeatures() {
  const policy = Authorization.usePolicy();
  const {
    data: { teardownTimes },
  } = useQuery(GetTeardownTimes);

  const publishWorkBlockModal = usePublishWorkBlockModal();
  const splitWorkBlockAcrossShifts = useSplitWorkBlockAcrossShifts();

  const { openWorkOrderDetails } = useSlideOutState();
  const handleUpdateWorkOrder = useUpdateWorkOrder();
  const handleUnscheduleWorkOrder = useUnscheduleWorkOrder();
  const handleUnscheduleBlock = useUnscheduleBlock();
  const { confirmAction } = useContext(ConfirmModalContext);

  const onTrackUsage = useTrackUsage();
  const rescheduleBlocks = useRescheduleBlocks();
  const { open: onWorkBlockShowModal } = useWorkBlockShowModal();
  const { open: onDowntimeShowModal } = useDowntimeShowModal();
  const { open: onOpenEditWorkBlock } = useWorkBlockEditModal();
  const applicationContext = useApplicationContext();
  const canUpdate = policy.canUpdate(Authorization.EntityTypes.WorkBlock);
  const fullyScheduleWorkOrder = useFullyScheduleWorkOrder();
  const handleUnscheduleTeardownTime = ({ eventRecord: { data: blockData } }) => {
    const { block: workBlock } = blockData;

    const updatedWorkBlock = {
      ...WorkBlock.updateTeardownTime(workBlock, 0),
      teardownTimeLabel: "Teardown Time",
    };

    rescheduleBlocks([updatedWorkBlock]);
  };

  const handlePublishWorkBlock = ({ eventRecord: { data: blockData } }) => {
    const { block: workBlock } = blockData;

    publishWorkBlockModal.open(workBlock);
  };

  const handleSplitWorkBlockAcrossShifts = ({ eventRecord: { data: blockData } }) => {
    const { block: workBlock } = blockData;

    splitWorkBlockAcrossShifts(workBlock);
  };

  const onScheduleTeardownTime = (updatedBlock) => {
    onTrackUsage("schedule_teardown_time");
    rescheduleBlocks([updatedBlock]);
  };
  const openWorkBlockShowModal = (blockData) => {
    onWorkBlockShowModal({
      ...blockData,
      lineId: blockData.resourceId,
    });
  };

  const openDowntimeShowModal = (blockData) => {
    onDowntimeShowModal(blockData);
  };
  const { open: handleOpenNotesPopUp } = useNotesPopUp();
  const handleViewNotesPopUp = ({ eventRecord }) => {
    const {
      data: { block },
    } = eventRecord;

    handleOpenNotesPopUp({ workOrderId: block.workOrder.id, workBlockId: block.id });
  };

  const unscheduleWorkOrder = ({ eventRecord }) => {
    const {
      block: { workOrder },
    } = eventRecord.data;

    const message = `This action will unschedule all of the work order’s (${workOrder.code}) scheduled blocks. However, it will not delete its published jobs, if any.`;

    confirmAction(
      message,
      "Unschedule",
      () => handleUnscheduleWorkOrder({ workOrderId: workOrder.id }),
      "Unschedule work order",
    );
  };

  const unscheduleBlock = ({ eventRecord }) => {
    const {
      block: { jobId },
    } = eventRecord.data;

    if (!jobId) {
      handleUnscheduleBlock(eventRecord.data);

      return;
    }

    const jobLink = packmanagerJobLinkRenderer({ jobId, applicationContext, prefix: "Job " });

    const message = `This action will unschedule the published block, but will not delete its corresponding job. To delete its job, go to ${jobLink}`;

    confirmAction(message, "Unschedule", () => handleUnscheduleBlock(eventRecord.data), "Unschedule work block");
  };

  const handleFullyScheduleWorkOrder = ({ eventRecord: { data: blockData } }) => {
    const { block: workBlock } = blockData;

    fullyScheduleWorkOrder(workBlock);
  };
  const createMenu = ({ items, blockData }) => {
    const { block } = blockData;

    if (!canUpdate && Block.isDowntime(block)) return downtimeReadOnlyMenu({ openDowntimeShowModal, blockData });

    if (!canUpdate && Block.isWorkBlock(block)) return workBlockReadOnlyMenu({ openWorkBlockShowModal, blockData });

    if (Block.isDowntime(block)) return downtimeMenu({ items, unscheduleBlock });

    const canPublishWorkBlock = policy.canPublishWorkBlock(Authorization.EntityTypes.PublishedWorkBlockType);

    if (Block.isWorkBlock(block))
      return workBlockContextMenu({
        blockData,
        canPublishWorkBlock,
        items,
        onFullyScheduleWorkOrder: handleFullyScheduleWorkOrder,
        onOpenEditWorkBlock,
        onPublishWorkBlock: handlePublishWorkBlock,
        onScheduleTeardownTime,
        onSplitWorkBlockAcrossShifts: handleSplitWorkBlockAcrossShifts,
        onUnscheduleTeardownTime: handleUnscheduleTeardownTime,
        onUpdateWorkOrder: handleUpdateWorkOrder,
        onViewNotesPopUp: handleViewNotesPopUp,
        openWorkOrderDetails,
        teardownTimes,
        unscheduleBlock,
        unscheduleWorkOrder,
      });

    return {
      editEvent: false,
      deleteEvent: false,
    };
  };

  return {
    eventFeatures: (gapFiller) => ({
      eventRenderer: blockRenderer({ applicationContext, policy, openWorkOrderDetails }),

      eventCopyPasteFeature: false,
      eventMenuFeature: {
        processItems: ({ eventRecord, items }) => {
          const blockData = eventRecord.data;

          assign(items, createMenu({ items, blockData }));
        },
      },
      eventTooltipFeature: {
        template: ({ eventRecord, startDate, endDate }) =>
          blockTooltipRenderer({
            applicationContext,
            startDate,
            endDate,
            blockData: eventRecord.data,
            showStatus: true,
          }),
      },
      eventDragFeature: {
        constrainDragToTimeline: false,
        copyKey: "",
        tooltipTemplate: function getDragTipTemplate({ eventRecord, startDate, endDate, dragData }) {
          const resourceData = dragData.newResource?.data;
          if (isMultiScheduling(dragData) || !eventRecord) {
            return null;
          }

          const blockData = eventRecord.data;
          const efficiency = resourceData ? resourceData.efficiency : 0;
          const updatedBlockData = cloneDeep(blockData);

          updatedBlockData.block.startsAt = startDate;
          updatedBlockData.block.endsAt = endDate;

          if (DateTime.isInTheFuture(startDate)) {
            updatedBlockData.block.lineEfficiency = efficiency || blockData.block.lineEfficiency;
          }
          if (gapFiller._shouldFillInGap() && resourceData) {
            const { block } = blockData;
            const line = resourceData;

            const timespanWithinGap = fillInGapTimespan({
              block,
              gapFiller,
              line,
            });

            updatedBlockData.block.startsAt = timespanWithinGap.startTime;
            updatedBlockData.block.endsAt = timespanWithinGap.endTime;
          }

          return blockTooltipRenderer({
            applicationContext,
            blockData: updatedBlockData,
            startDate: updatedBlockData.block.startsAt,
            endDate: updatedBlockData.block.endsAt,
            displaySnappedRemainingDuration: false,
            showStatus: true,
          });
        },
      },
      eventResizeFeature: {
        tooltipTemplate: function getResizeTooltip({ record, startDate, endDate }) {
          if (!record) return null;

          const blockData = record.data;

          return blockTooltipRenderer({
            applicationContext,
            blockData,
            startDate,
            endDate,
            displaySnappedRemainingDuration: true,
            showStatus: true,
          });
        },
      },
      eventDragCreateFeature: {
        tooltipTemplate: function getDragCreateTipTemplate({ startDate, endDate }) {
          return renderToString(BlockTooltip({ startDate, endDate }));
        },
        lockLayout: true,
      },
    }),
  };
}

function isMultiScheduling(dragData) {
  return dragData.draggedEntities.length > 1;
}
