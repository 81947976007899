import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import FilterSidebarView from "./FilterSidebarView";
import useGetWorkOrders from "hooks/apollo/workOrder/useGetWorkOrders";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import WorkOrderSearchContext from "contexts/WorkOrderSearchContext";
import useSlideOutState from "hooks/state/useSlideOutState";

export default function FilterSidebarContainer() {
  const { isFilterMode } = useSlideOutState();
  const visible = isFilterMode();
  const applicationContext = useApplicationContext();
  const { fetch: filterWorkOrders } = useGetWorkOrders();
  const { state: searchState, setState: setSearchState } = useContext(WorkOrderSearchContext);

  const handleChangeFilter = (changedFilter) => filterWorkOrders(setSearchState({ filters: changedFilter }));
  const handleChangeSelect = (key) => (values) => handleChangeFilter({ [key]: isEmpty(values) ? null : values });
  const handleToggleFilter = (filterName) => handleChangeFilter({ [filterName]: !searchState.filters[filterName] });

  const handleToggleFilterByScheduleView = () => {
    handleChangeFilter({
      filterByScheduleView: !searchState.filters.filterByScheduleView,
      scheduledBetween: { startsAt: null, endsAt: null },
    });
  };

  const handleTextSearch = debounce((filter) => {
    handleChangeFilter(filter);
  }, 500);

  if (!visible) return null;

  return (
    <FilterSidebarView
      currentFilter={searchState.filters}
      onChangeToggleFilter={handleToggleFilter}
      onChangeFilter={handleChangeFilter}
      onChangeSelect={handleChangeSelect}
      applicationContext={applicationContext}
      onTextSearch={handleTextSearch}
      onToggleFilterByScheduleView={handleToggleFilterByScheduleView}
    />
  );
}
