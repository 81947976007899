import { Toggle as NDSToggle } from "@nulogy/components";
import styled from "styled-components";

const Toggle = styled(NDSToggle)`
  div {
    div {
      transform: scale(0.7);
    }

    p {
      margin-left: 0;
    }
  }
`;

export default Toggle;
