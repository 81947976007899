import React, { useState } from "react";
import ImportFormView from "./ImportFormView";
import useRefetchWorkOrders from "hooks/apollo/workOrder/useRefetchWorkOrders";
import useGetBlocks from "hooks/apollo/blocks/useGetBlocks";
import useFileImport from "hooks/useImportFile";

const MAX_IMPORT_SIZE = 8388608; // 8 MB

export default function ImportFormContainer({ endPoint, ...otherProps }) {
  const [file, setFile] = useState(null);
  const [importErrors, setImportErrors] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const { refetch: refetchWorkOrders } = useRefetchWorkOrders();
  const { refetch: refetchBlocks } = useGetBlocks();

  const onImportSuccess = (response) => {
    setWarnings(response.data.warnings);
    refetchWorkOrders();
    refetchBlocks();
  };

  const onImportFailure = (errorMessages) => setImportErrors(errorMessages);
  const [status, resetStatus, importFile] = useFileImport(onImportSuccess, onImportFailure);

  const validateFile = (inputFile) => {
    if (!inputFile) return true;

    return inputFile.size <= MAX_IMPORT_SIZE;
  };

  const handleFileSelected = (event) => {
    const inputFile = event.target.files[0];
    resetStatus();
    setFile(inputFile);
    setImportErrors([]);

    if (validateFile(inputFile)) {
      setValidationErrors([]);
    } else {
      setValidationErrors(["File size must be 8 MB or less."]);
    }
  };

  const handleImport = (event) => {
    event.preventDefault();
    setImportErrors([]);
    importFile(file, endPoint);
  };

  return (
    <ImportFormView
      endPoint={endPoint}
      status={status}
      importErrors={importErrors}
      validationErrors={validationErrors}
      warnings={warnings}
      onImport={handleImport}
      file={file}
      onFileSelected={handleFileSelected}
      {...otherProps}
    />
  );
}
