import { useMutation } from "@apollo/client";
import { useContext } from "react";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { CloseGap } from "./block.gql";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import { useNotificationState, useSchedulerState } from "hooks/state";
import TimeZone from "utils/TimeZone";
import GqlError from "utils/GqlError";
import String from "utils/String";
import NetworkStateContext from "contexts/NetworkStateContext";

export default function useCloseGap() {
  const { setNotification } = useNotificationState();
  const { trackLoadingState, isFetching } = useContext(NetworkStateContext);
  const [closeGap] = useMutation(CloseGap, {
    onCompleted: ({ closeGap: { blocks } }) => {
      const blockCount = blocks.workBlocks.length + blocks.downtimeBlocks.length;
      setNotification({
        title: "Gap closed successfully",
        message: `${blockCount} ${String.pluralize("block", blockCount)} shifted to the left.`,
        type: "success",
      });
    },
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: allErrors,
          message: "",
          type: "danger",
        });
      });
    },
  });
  const applicationContext = useApplicationContext();
  const {
    state: { dataTimeRange },
  } = useSchedulerState();

  const handleCloseGap = ({ date, lineId }) => {
    trackLoadingState("CLOSE_GAP", () =>
      closeGap({
        variables: {
          clickedTime: TimeZone.overrideZone(date, applicationContext.timeZone.name),
          startsAt: TimeZone.overrideZone(dataTimeRange.startDate, applicationContext.timeZone.name),
          endsAt: TimeZone.overrideZone(dataTimeRange.endDate, applicationContext.timeZone.name),
          lineId,
        },
      }),
    );
  };

  return {
    handleCloseGap,
    fetching: isFetching("CLOSE_GAP"),
  };
}
