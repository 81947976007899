import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

export default function WorkOrderCardWrapper({ children, workOrderId }) {
  return <Wrapper className={`spec-work-order-card-wrapper-${workOrderId}`}>{children}</Wrapper>;
}
