import React from "react";
import { AnimatedBox } from "@nulogy/components";
import styled from "styled-components";

import SidebarContainer from "./SidebarContainer";
import ScheduleHeaderContainer from "./ScheduleHeaderContainer";
import DragFromSidebarInitializer from "./DragFromSidebarInitializer";
import CreateBlockModalContainer from "./blocks/CreateBlockModalContainer";
import EditWorkBlockModalContainer from "./workBlocks/EditWorkBlockModalContainer";
import ReadOnlyWorkBlockModalContainer from "./workBlocks/ReadOnlyWorkBlockModalContainer";
import EditDowntimeBlockModal from "./downtimeBlocks/EditDowntimeBlockModal";
import ReadOnlyDowntimeBlockModal from "./downtimeBlocks/ReadOnlyDowntimeBlockModal";
import NotesPopUp from "./NotesPopUp";
import BryntumSchedulerContainer from "components/pages/schedule/BryntumSchedulerContainer";
import ScheduleLoadingAnimation from "components/controls/ScheduleLoadingAnimation";
import "components/common/Scheduler/Scheduler.styles.scss";

const SIDEBAR_WIDTH = 400;
const SIDEBAR_EXPANDER_WIDTH = 33;

const SchedulePageLayout = styled(AnimatedBox)`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: 45px auto;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 100px);
  padding-top: ${({ theme }) => theme.space.x3};
  padding-left: ${({ theme }) => theme.space.x3};

  @media only screen and (max-width: 825px) {
    grid-template-rows: 90px auto;
  }
`;

function SchedulePageView({ sidebarIsOpen, slideOutIsOpen, fetching }) {
  const gridWidth = `calc(100% - ${slideOutIsOpen && sidebarIsOpen ? SIDEBAR_WIDTH * 2 : SIDEBAR_WIDTH}px)`;
  const sideBarAnimationVariants = {
    open: {
      width: gridWidth,
      transition: {
        duration: 0.25,
      },
    },
    closed: {
      width: `calc(100% - ${SIDEBAR_EXPANDER_WIDTH}px)`,
      transition: {
        duration: 0.25,
      },
    },
  };

  return (
    <SchedulePageLayout
      variants={sideBarAnimationVariants}
      initial="open"
      animate={sidebarIsOpen || slideOutIsOpen ? "open" : "closed"}
    >
      <CreateBlockModalContainer />
      <EditWorkBlockModalContainer />
      <ReadOnlyWorkBlockModalContainer />
      <EditDowntimeBlockModal />
      <ReadOnlyDowntimeBlockModal />
      <NotesPopUp />
      <ScheduleLoadingAnimation fetching={fetching} />
      <DragFromSidebarInitializer />
      <ScheduleHeaderContainer />
      <BryntumSchedulerContainer />
      <SidebarContainer />
    </SchedulePageLayout>
  );
}

export default React.memo(SchedulePageView);
