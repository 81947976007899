import filter from "lodash/filter";
import { GetAllBlocks } from "hooks/apollo/blocks/block.gql";

function insertDowntimeBlock(newDowntimeBlock, proxy) {
  const blocks = read(proxy);

  const downtimeBlocks = [newDowntimeBlock].concat(filter(blocks.downtimeBlocks, (d) => d.id !== newDowntimeBlock.id));

  write({ ...blocks, downtimeBlocks }, proxy);
}

function removeDowntimeBlock(downtimeId, proxy) {
  const blocks = read(proxy);
  const downtimeBlocks = blocks.downtimeBlocks.filter((downtimeBlock) => downtimeBlock.id !== downtimeId);

  write({ ...blocks, downtimeBlocks }, proxy);
}

function read(proxy) {
  const { blocks } = proxy.readQuery({ query: GetAllBlocks });

  return blocks;
}

function write(blocks, proxy) {
  proxy.writeQuery({
    query: GetAllBlocks,
    data: { blocks },
  });
}

export { insertDowntimeBlock, removeDowntimeBlock };
