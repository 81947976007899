import { useContext } from "react";
import { useQuery } from "@apollo/client";
import SchedulePageView from "./SchedulePageView";
import waitForQueries from "components/waitForQueries";
import { GetAllCustomWorkOrderFields } from "hooks/apollo/customWorkOrderField/customWorkOrderField.gql";
import { GetAllLines } from "hooks/apollo/line/line.gql";
import { GetTeardownTimes } from "hooks/apollo/teardownTime/teardownTime.gql";
import useGetWorkOrders from "hooks/apollo/workOrder/useGetWorkOrders";
import useTrackUsage from "hooks/apollo/usageData/useTrackUsage";
import EventTypes from "hooks/apollo/usageData/EventTypes";
import useComponentDidMount from "hooks/useComponentDidMount";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useSlideOutState from "hooks/state/useSlideOutState";
import UIStateContext from "contexts/UIStateContext";
import useSubscriptions from "hooks/apollo/useSubscriptions";
import NetworkStateContext from "contexts/NetworkStateContext";

export default function SchedulePageContainer() {
  const trackUsage = useTrackUsage();
  useComponentDidMount(() => trackUsage(EventTypes.VIEW_SCHEDULE_PAGE));

  useSubscriptions();

  const { isFetching } = useContext(NetworkStateContext);
  const fetching = isFetching("CLOSE_GAP") || isFetching("GET_BLOCKS");

  const queries = [
    useApplicationContext(),
    useQuery(GetAllLines, { variables: { activeOnly: true } }),
    useQuery(GetAllCustomWorkOrderFields),
    useQuery(GetTeardownTimes),
    useGetWorkOrders(),
  ];
  const {
    state: { sidebarOpen },
  } = useContext(UIStateContext);
  const { isOpen } = useSlideOutState();

  return waitForQueries({ queries })(SchedulePageView)({
    sidebarIsOpen: sidebarOpen,
    slideOutIsOpen: isOpen(),
    fetching,
  });
}
