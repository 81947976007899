import React from "react";
import styled from "styled-components";
import { DropdownMenu, Flex, theme } from "@nulogy/components";
import useTimeZone from "../../../hooks/useTimeZone";
import PublishDropdown from "./publish/PublishDropdown";
import PublishScheduleModal from "./publish/PublishScheduleModal";
import PublishWorkOrderDatesModal from "./publish/PublishWorkOrderDatesModal";
import PublishWorkBlockModal from "./publish/PublishWorkBlockModal";
import Legend from "./Legend";
import ZoomPicker from "./ZoomPicker";
import LegendModal from "./LegendModal";
import ExportModal from "./ExportModal";
import PublishSummaryModal from "components/pages/schedule/publish/PublishSummaryModal";
import ExportScheduleContainer, { exportUrlBuilder } from "components/pages/exports/ExportScheduleContainer";

const Controls = styled(Flex)`
  > * {
    margin: 0 ${theme.space.x1};
  }

  @media only screen and (max-width: 1124px) {
    display: none;
  }
`;

const CollapsedControls = styled(Flex)`
  padding-right: ${theme.space.x1};
  display: inline;

  @media only screen and (min-width: 1124px) {
    display: none;
  }
`;

export default function ScheduleHeaderView(props) {
  const {
    disabled,
    onDateChange,
    onQuickNavigationChange,
    onZoomLevelChange,
    zoomLevel,
    canPublishSchedule,
    visibleTimeRange,
  } = props;

  const [isLegendModalOpen, setLegendModalOpen] = React.useState(false);
  const [isExportScheduleModalOpen, setExportScheduleModalOpen] = React.useState(false);

  const { overrideToSiteTimeZone } = useTimeZone();

  const handleExport = ({ dateRange, includeUnavailability }) => {
    const urlBuilder = exportUrlBuilder(overrideToSiteTimeZone);
    window.location = urlBuilder(dateRange, includeUnavailability);

    setExportScheduleModalOpen(false);
  };

  return (
    <Flex justifyContent="space-between">
      <PublishSummaryModal />
      <PublishScheduleModal />
      <PublishWorkBlockModal />
      <PublishWorkOrderDatesModal />
      <LegendModal isLegendModalOpen={isLegendModalOpen} setLegendModalOpen={setLegendModalOpen} />
      <ExportModal
        isExportScheduleModalOpen={isExportScheduleModalOpen}
        setExportScheduleModalOpen={setExportScheduleModalOpen}
        onExport={handleExport}
      />
      <ZoomPicker
        onQuickNavigationChange={onQuickNavigationChange}
        disabled={disabled}
        onDateChange={onDateChange}
        zoomLevel={zoomLevel}
        onZoomLevelChange={onZoomLevelChange}
        visibleTimeRange={visibleTimeRange}
      />

      <CollapsedControls>
        <DropdownMenu placement="bottom-end" showArrow={false} ml={theme.space.x1}>
          {({ closeMenu }) => (
            <>
              {canPublishSchedule && <PublishDropdown isInDropDown />}
              <ExportScheduleContainer
                isInDropDown
                closeMenu={closeMenu}
                setExportScheduleModalOpen={setExportScheduleModalOpen}
              />
              <Legend isInDropDown closeMenu={closeMenu} setLegendModalOpen={setLegendModalOpen} />
            </>
          )}
        </DropdownMenu>
      </CollapsedControls>

      <Controls alignItems="flex-start" marginX="x2">
        <Legend setLegendModalOpen={setLegendModalOpen} />
        {canPublishSchedule && <PublishDropdown />}
        <ExportScheduleContainer setExportScheduleModalOpen={setExportScheduleModalOpen} />
      </Controls>
    </Flex>
  );
}
