import React from "react";
import noop from "lodash/noop";
import WorkOrderCard from "./WorkOrderCard";

export default function MockWorkOrderCardContainer({
  applicationContext,
  expandedWorkOrders,
  layoutSettingData,
  workOrder,
}) {
  return (
    <WorkOrderCard
      workOrder={workOrder}
      expanded={expandedWorkOrders.includes(workOrder.id)}
      onToggleExpandWorkOrder={noop}
      layoutSettingData={layoutSettingData}
      applicationContext={applicationContext}
    />
  );
}
