import styled from "styled-components";
import { Box, Text, theme } from "@nulogy/components";
import Input from "components/controls/Input";
import Select from "components/controls/Select";
import TimePicker from "components/controls/TimePicker";

const GridBox4 = styled(Box)`
  display: grid;
  grid-template-columns: 450px 230px 100px 100px;
`;

const GridBox6 = styled(Box)`
  display: grid;
  grid-template-columns: 320px 320px 150px 150px 100px 100px;
`;

const GridBox7 = styled(Box)`
  display: grid;
  grid-template-columns: 40px 320px 320px 200px 200px 100px 100px;
`;

const StyledInput = styled(Input)`
  margin-bottom: ${theme.sizes.none} !important;
  padding: ${theme.sizes.x1} ${theme.sizes.x2} ${theme.sizes.x1} ${theme.sizes.none};
`;

const StyledSelect = styled(Select)`
  padding: ${theme.sizes.x1} ${theme.sizes.x2} ${theme.sizes.x1} ${theme.sizes.none};
`;

const StyledText = styled(Text)`
  padding: ${theme.sizes.x2} ${theme.sizes.x2} ${theme.sizes.x2} ${theme.sizes.none};
`;

const StyledTimePicker = styled(TimePicker)`
  padding: ${theme.sizes.x1} ${theme.sizes.x2} ${theme.sizes.x1} ${theme.sizes.none};
`;

export { GridBox4, GridBox6, GridBox7, StyledInput, StyledText, StyledTimePicker, StyledSelect };
