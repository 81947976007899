import styled from "styled-components";
import { Box, theme } from "@nulogy/components";
import React from "react";

const StyledExpandButton = styled(Box)({
  marginTop: theme.sizes.x1,
  marginBottom: theme.sizes.x3,
  color: theme.colors.blue,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.medium,
  cursor: "pointer",
  width: "fit-content",
});

export default function ExpandFiltersButton({ expandedFilters, onClick }) {
  return (
    <StyledExpandButton onClick={onClick} type="button">
      {expandedFilters ? "Less filters" : "More filters"}
    </StyledExpandButton>
  );
}
