import React from "react";
import Select from "components/controls/Select";

export default function LineSelect({ lines, disabled = false }) {
  const options = toOptions(lines);

  return (
    <Select
      className="spec-line-select"
      field="workBlock[line][id]"
      labelText="Line"
      options={options}
      disabled={disabled}
    />
  );
}

function toOptions(lines) {
  return lines.map(({ name, id }) => ({ label: name, value: id }));
}
