import React from "react";
import { Heading2, Modal } from "@nulogy/components";
import PublishWorkOrderDatesFormContainer from "./PublishWorkOrderDatesFormContainer";
import useUpdateWorkOrderDatesModal from "hooks/state/useUpdateWorkOrderDatesModal";

const Heading = () => (
  <Heading2 inline mb="none">
    Update work order dates
  </Heading2>
);

export default function PublishWorkOrderDatesModal() {
  const { close, isOpen } = useUpdateWorkOrderDatesModal();

  return (
    <Modal isOpen={isOpen} ariaHideApp={false} title={<Heading />} onRequestClose={close}>
      <PublishWorkOrderDatesFormContainer />
    </Modal>
  );
}
