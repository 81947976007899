import React, { useContext } from "react";
import { Box, Button, DangerButton, PrimaryButton } from "@nulogy/components";
import ConfirmModalContext from "contexts/ConfirmModalContext";

function FormButtons({
  cancelUrl = "/",
  saveButtonText = "Save",
  deleteConfirmationMessage,
  onCancel,
  onDelete,
  disabled,
}) {
  const { confirmAction } = useContext(ConfirmModalContext);

  const handleDelete = (e) => {
    const message = deleteConfirmationMessage || "Are you sure you want to delete this?";

    confirmAction(message, "Delete", () => onDelete(e), "Delete");
  };

  const cancelProps = onCancel ? { onClick: onCancel } : { asLink: true, href: cancelUrl };

  return (
    <Box marginBottom="x2">
      {onDelete && (
        <DangerButton data-testid="delete-button" type="button" onClick={handleDelete} mr="x1">
          Delete
        </DangerButton>
      )}
      <PrimaryButton data-testid="save-button" type="submit" mr="x1" disabled={disabled}>
        {saveButtonText}
      </PrimaryButton>
      <Button type="button" {...cancelProps}>
        Cancel
      </Button>
    </Box>
  );
}

export default FormButtons;
