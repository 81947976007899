import React from "react";
import { Alert } from "@nulogy/components";

export default function ImportMessage({ status, children }) {
  if (status === "idle") return null;

  let type;
  let defaultMessage;

  switch (status) {
    case "running":
      type = "warning";
      defaultMessage = "Importing. Please wait.";
      break;
    case "success":
      type = "success";
      defaultMessage = "Success!";
      break;
    case "failure":
      type = "danger";
      defaultMessage = "Error...";
      break;
    default:
      throw new Error(`Unknown status: ${status}`);
  }

  return <Alert type={type}>{children || defaultMessage}</Alert>;
}
