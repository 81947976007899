import { useApolloClient } from "@apollo/client";
import { GetAllLines } from "./line.gql";

export default function useGetLineFromCache() {
  const client = useApolloClient();

  return (lineId) => {
    const { lines } = client.readQuery({ query: GetAllLines, variables: { activeOnly: true } }, true);

    return lines.find((line) => line.id === lineId);
  };
}
