import isNil from "lodash/isNil";
import round from "lodash/round";
import DateTime from "utils/DateTime";
import Num from "utils/Num";

export function formatLabor(labor, asterisk = "") {
  if (isNil(labor)) return "--";

  const roundedLabor = round(labor, 2);
  const peopleLabel = roundedLabor === 1 ? "person" : "people";

  return `${roundedLabor}${asterisk} ${peopleLabel}`;
}

export function formatBoolean(boolean) {
  return boolean ? "Yes" : "No";
}

export function formatQuantity(value) {
  return round(value, 2);
}

export function formatProductionRate({ productionRate, unitOfMeasure = "ea" }) {
  if (isNil(productionRate)) return "--";

  return `${formatQuantity(productionRate)} ${unitOfMeasure}/hour`;
}

export function formatPerformance(performance) {
  if (isNil(performance)) return "--";

  return `${Num.decimalToPercent(performance)}% performance`;
}

export function formatEfficiency(efficiency) {
  if (isNil(efficiency)) return "--";

  return `${formatQuantity(efficiency * 100)}% efficiency`;
}

export function formatPercentage(percentage) {
  if (isNil(percentage)) return "--";

  return `${formatQuantity(percentage * 100)}%`;
}

export function formatDurationInHours(seconds) {
  return `${DateTime.secondsToHours(seconds)} hours`;
}

export function formatDuration(seconds) {
  const totalMinutes = Math.floor(Math.abs(seconds) / 60);
  const [hours, minutes] = Num.divmod(totalMinutes, 60);

  return `${hours}h ${minutes}m`;
}

export function formatDate(inputDate) {
  return maybeDate(inputDate, "MMM D, YYYY");
}

export function formatTime(inputDate) {
  return maybeDate(inputDate, "h:mm A");
}

export function format24HourDateTime(inputDate) {
  return maybeDate(inputDate, "Y-MM-DD HH.mm");
}

export function formatDateTime(inputDate) {
  return maybeDate(inputDate, "MMM D, YYYY h:mm A");
}

export function formatShortDateTime(inputDate) {
  return maybeDate(inputDate, "MMM D, h:mm A");
}

export function formatDayOfWeek(inputDate) {
  return maybeDate(inputDate, "ddd");
}

export function formatTeardownTimeInputInHours(seconds) {
  return round(DateTime.secondsToHours(seconds), 5);
}

function maybeDate(inputDate, format) {
  if (!inputDate) return null;

  return DateTime.format(inputDate, format);
}
