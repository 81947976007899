export default function unscheduleMenuItem(unscheduleCallbacks) {
  return {
    text: "Unschedule",
    name: "unschdeule",
    menu: unschduleSubMenuItems(unscheduleCallbacks),
    cls: "pendo-unschedule-menu-item",
  };
}

function unschduleSubMenuItems({ unscheduleBlock, unscheduleWorkOrder }) {
  return [
    {
      text: "Work block",
      name: "unschedule-work-block",
      onItem: unscheduleBlock,
      icon: false,
      cls: "pendo-unschedule-work-block-context-menu-item",
    },
    {
      text: "Work order",
      name: "unschedule-work-order",
      onItem: unscheduleWorkOrder,
      cls: "pendo-unschedule-work-order-context-menu-item",
    },
  ];
}
