export const ACTION_OPERATIONS = {
  CLOSE_GAP: "close_gap",
  EXPORT_SCHEDULE: "export_schedule",
  FILL_IN_GAP: "fill_in_gap",
  FULLY_SCHEDULE_WORK_ORDER: "fully_schedule_work_order",
  IMPORT_ITEMS: "import_items",
  IMPORT_WORK_ORDERS: "import_work_orders",
  PUBLISH_SCHEDULE: "publish_schedule",
  PUBLISH_WORK_BLOCK: "publish_work_block",
  PUBLISH_WORK_ORDER_DATES: "publish_work_order_dates",
  RESCHEDULE_BLOCK: "reschedule_block",
  RESCHEDULE_MULTIPLE_BLOCKS: "reschedule_multiple_blocks",
  SCHEDULE_DOWNTIME_BLOCK: "schedule_downtime_block",
  SCHEDULE_TEARDOWN_TIME: "schedule_teardown_time",
  SCHEDULE_WORK_BLOCK: "schedule_work_block",
  SPLIT_WORK_BLOCK_ACROSS_SHIFTS: "split_work_block_across_shifts",
  UNSCHEDULE_DOWNTIME_BLOCK: "unschedule_downtime_block",
  UNSCHEDULE_WORK_BLOCK: "unschedule_work_block",
  UNSCHEDULE_WORK_ORDER: "unschedule_work_order",
  UPDATE_DEFAULT_AVAILABILITIES: "update_default_availabilities",
  UPDATE_AVAILABILITY_OVERRIDES: "update_availability_overrides",
};

export const VIEW_OPERATIONS = {
  FOCUS_LINE_FILTER: "focus_line_filter",
  LABOR_SUMMARY: "labor_summary",
  VIEW_SCHEDULE_PAGE: "view_schedule_page",
};

export default {
  ...ACTION_OPERATIONS,
  ...VIEW_OPERATIONS,
};
