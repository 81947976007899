import styled from "styled-components";
import { Text } from "@nulogy/components";

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default TruncatedText;
