const DowntimeBlock = {
  build,
  updateBlockTime,
  reasonDescription,
};

function build({ line, startsAt, endsAt, reason, reasonCode }) {
  return {
    startsAt,
    endsAt,
    reason,
    reasonCode,
    line,
    lockedOnSchedule: false,
    __typename: "DowntimeBlock",
  };
}

function updateBlockTime(downtimeBlock, { startsAt, endsAt }) {
  return {
    ...downtimeBlock,
    startsAt,
    endsAt,
  };
}

function reasonDescription({ reason, reasonCode }) {
  if (reasonCode) {
    return `${reason} • ${reasonCode}`;
  } else {
    return reason;
  }
}

export default DowntimeBlock;
