import useFetchShiftInstances from "hooks/apollo/shiftInstance/useFetchShiftInstances";
import useScheduleWorkBlock from "hooks/apollo/workBlock/useScheduleWorkBlock";
import useScheduleDowntimeBlock from "hooks/apollo/downtimeBlock/useScheduleDowntimeBlock";
import Block from "domain/Block";

export default function useScheduleBlock() {
  const { refetch: refetchShiftInstances } = useFetchShiftInstances();
  const scheduleWorkBlock = useScheduleWorkBlock(refetchShiftInstances);
  const scheduleDowntime = useScheduleDowntimeBlock();

  function createBlock(block) {
    if (Block.isWorkBlock(block)) {
      scheduleWorkBlock(block);
    } else {
      scheduleDowntime(block);
    }
  }

  return (block) => {
    createBlock(block);
  };
}
