import { useQuery } from "@apollo/client";
import { GetAllCustomWorkOrderFields } from "./customWorkOrderField.gql";

export default function useGetAllCustomWorkOrderFields() {
  const { data, loading, error } = useQuery(GetAllCustomWorkOrderFields);

  const customWorkOrderFields = data?.customWorkOrderFields;

  return { customWorkOrderFields, data, loading, error };
}
