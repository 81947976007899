import { DomHelper, DragHelper, Tooltip } from "@bryntum/scheduler";
import BLOCK_HEIGHT from "./blockHeight";
import LineHighlighter from "./LineHighlighter";
import GapFiller from "./GapFiller";
import EventTypes from "hooks/apollo/usageData/EventTypes";
import Schedule from "domain/Schedule";
import WorkBlock from "domain/WorkBlock";
import WorkOrder from "domain/WorkOrder";
import DateTime from "utils/DateTime";
import blockTooltipRenderer from "components/common/Scheduler/SchedulerRenderers/renderers/blockTooltipRenderer";

export default class DragFromSidebar extends DragHelper {
  static get defaultConfig() {
    return {
      cloneTarget: true,
      mode: "translateXY",
      dropTargetSelector: ".b-timeline-subgrid",
      targetSelector: ".draggable",
    };
  }

  construct(config) {
    super.construct(config);

    this.on({
      dragstart: this.onTaskDragStart,
      drag: this.onTaskDrag,
      drop: this.onTaskDrop,
      thisObj: this,
    });

    this.settings = this.applicationContext.settings;
    this.onDrop = config.onDrop;
    this.draggedNodeRenderer = config.draggedNodeRenderer;
    this.lineHighlighter = new LineHighlighter();
    this.gapFiller = new GapFiller({ settings: this.settings });
    this.gapFiller.setSchedule(this.schedule);
    this.onTrackUsage = config.onTrackUsage;
  }

  onTaskDragStart({ context }) {
    const { element } = context;

    context.eventName = context.grabbed.innerText;
    this.tooltip = new Tooltip({
      forElement: element,
      hideDelay: false,
      align: "b-t",
    });

    const duration = this.getBlockDurationInMilliSeconds(element.dataset);
    const placeholderBlockDuration = DateTime.hoursToSeconds(1) * 1000;
    const workOrder = JSON.parse(element.dataset.dragWorkOrder);
    const renderDuration = WorkOrder.hasNonZeroProductionRate(workOrder) ? duration : placeholderBlockDuration;
    const width = this.schedule.timeAxisViewModel.getDistanceForDuration(renderDuration);

    document.body.style.userSelect = "none";
    document.body.style.cursor = "grabbing";
    element.style.height = `${BLOCK_HEIGHT}px`;
    element.innerHTML = this.draggedNodeRenderer(element.dataset);
    element.style.width = `${width}px`;
    this.enableScrolling();
  }

  onTaskDrag({ context, event }) {
    this.bryntumEvent = event;
    const {
      element,
      element: { dataset },
    } = context;
    const line = this.schedule.resolveResourceRecord(context.target);
    const timespanWithinGap = this.fillInGapTimespan(dataset);
    if (line) {
      this.line = line;
    }

    this.gapFiller.onTaskDrag({
      line,
      cursorPositionX: event.clientX,
    });

    if (this.line) {
      this.lineHighlighter.start(this.line.id);

      const durationInMilliSeconds =
        timespanWithinGap?.durationInSeconds() * 1000 || this.getBlockDurationInMilliSeconds(dataset);
      const placeholderWorkBlockDuration = DateTime.hoursToSeconds(1) * 1000;
      const duration = durationInMilliSeconds || placeholderWorkBlockDuration;
      const width = this.schedule.timeAxisViewModel.getDistanceForDuration(duration);

      element.style.width = `${width}px`;
    }

    this.centerNodeOnMouse({ context });

    if (context.valid) {
      const startDate = timespanWithinGap?.startTime || this.getStartsAt();
      const endDate =
        timespanWithinGap?.endTime ||
        DateTime.addDurationDate(startDate, this.getBlockDurationInMilliSeconds(element.dataset), "milliseconds");

      const workOrder = JSON.parse(element.dataset.dragWorkOrder);

      const fakeWorkBlock = WorkBlock.buildWithBlockTime({
        line: this.line,
        workOrder,
        standardProductionRate: workOrder.standardProductionRate,
        performance: workOrder.performance,
        unitOfMeasure: workOrder.unitOfMeasure,
        startsAt: DateTime.toISO8601(startDate),
        endsAt: DateTime.toISO8601(endDate),
        lockedOnSchedule: false,
      });

      this.tooltip.html = blockTooltipRenderer({
        applicationContext: this.applicationContext,
        blockData: { block: fakeWorkBlock },
        startDate,
        endDate,
        displaySnappedRemainingDuration: false,
        showStatus: false,
      });
      this.tooltip.showBy({ target: element });
      this.tooltip.realign();
    } else {
      this.tooltip.hide();
    }
  }

  // Drop callback after a mouse up, take action and transfer the unplanned task to the real EventStore (if it's valid)
  onTaskDrop({ context }) {
    const {
      valid,
      element: { dataset },
    } = context;

    this.tooltip.hide();
    this.lineHighlighter.stop();
    this.updateUIOnDrop();

    if (!valid || !this.line || this.gapFiller.shouldAbortFillInGap()) {
      this.abortDrop();

      return;
    }

    const timespanWithinGap = this.fillInGapTimespan(dataset);
    const timeSpan = timespanWithinGap || this.timespanForRemainingDuration(dataset);

    // `context.finalize()` needs to run before onDrop (or any mutation) to avoid bugs.
    context.finalize();

    this.onDrop({
      startDate: timeSpan.startTime,
      endDate: timeSpan.endTime,
      line: this.line.data,
      workOrder: JSON.parse(dataset.dragWorkOrder),
    });

    if (this.gapFiller.isActive()) {
      this.onTrackUsage(EventTypes.FILL_IN_GAP);
    }

    this.clearDragState();
  }

  fillInGapTimespan(dataset) {
    const fullShiftTimespan = this.gapFiller.timespanWithinGap();
    if (!fullShiftTimespan) {
      return null;
    }

    return Schedule.shrinkTimeSpanToWorkOrderRemaining({
      remainingBlockDuration: this.getBlockDurationInMilliSeconds(dataset) / 1000,
      timespan: fullShiftTimespan,
    });
  }

  timespanForRemainingDuration(dataset) {
    const durationInMilliSeconds = this.getBlockDurationInMilliSeconds(dataset);
    const startTime = this.getStartsAt();
    const endTime = startTime.clone().add(durationInMilliSeconds, "milliseconds");

    return { startTime, endTime };
  }

  getBlockDurationInMilliSeconds(dataset) {
    if (!this.line) {
      return Number(dataset.dragRemainingDuration) * 1000;
    }

    const workBlock = this.buildBlock(dataset);
    const settings = JSON.parse(dataset.dragSettings);

    return WorkBlock.calculateBlockDurationForLine(workBlock, this.line, settings) * 1000;
  }

  buildBlock(dataset) {
    return {
      quantity: dataset.dragUnscheduledQuantity,
      standardProductionRate: dataset.dragStandardProductionRate,
      performance: dataset.dragPerformance,
      teardownTimeDuration: 0,
    };
  }

  abortDrop() {
    this.abort();
    this.clearDragState();
  }

  clearDragState() {
    this.disableScrolling();
    this.line = null;
    this.gapFiller.clearDragState();
  }

  updateUIOnDrop() {
    document.body.style.userSelect = "auto";
    document.body.style.cursor = "auto";
  }

  enableScrolling() {
    const {
      schedule: { scrollManager, timeAxisSubGridElement },
    } = this.config;

    scrollManager.startMonitoring(timeAxisSubGridElement);
  }

  disableScrolling() {
    const {
      schedule: { scrollManager, timeAxisSubGridElement },
    } = this.config;

    scrollManager.stopMonitoring(timeAxisSubGridElement);
  }

  centerNodeOnMouse({ context }) {
    const { pageY: mouseY, pageX: mouseX, element } = context;

    DomHelper.setTranslateY(element, mouseY - BLOCK_HEIGHT / 2);
    DomHelper.setTranslateX(element, mouseX - 20);
  }

  getStartsAt() {
    return DateTime.momentDate(this.schedule.getDateFromCoordinate(this.bryntumEvent.clientX - 20, "round", false));
  }
}
