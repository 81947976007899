import React, { useState } from "react";
import { Box, Heading2, IconicButton, Page, Text, theme } from "@nulogy/components";
import { useMutation, useQuery } from "@apollo/client";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import NewLine from "./NewLine";
import Line from "./Line";
import LabelWithTooltip from "components/common/LabelWithTooltip";
import { reorderLines } from "hooks/apollo/line/helpers/handleReorderLines";
import DeleteConfirmationModal from "components/common/DeleteConfirmationModal";
import { removeLine } from "hooks/apollo/line/helpers/handleUpdateLines";
import useDeleteConfirmationModal from "hooks/state/useDeleteConfirmationModal";
import useDeleteLine from "hooks/apollo/line/useDeleteLine";
import Uuid from "utils/Uuid";
import { GetAllLines, ReorderLines } from "hooks/apollo/line/line.gql";
import { GridBox7 } from "components/common/EditableListStyledComponents";

export default function Lines() {
  const { data, loading } = useQuery(GetAllLines);
  const [newLineIds, setNewLineIds] = useState([]);
  const { open: openModal, data: deleteData } = useDeleteConfirmationModal();
  const deleteLine = useDeleteLine();
  const [reorderLinesMutation] = useMutation(ReorderLines);

  const handleAddNewLine = () => {
    setNewLineIds([...newLineIds, Uuid.generate()]);
  };

  const handleRemoveNewLine = (lineId) => {
    setNewLineIds(newLineIds.filter((value) => value !== lineId));
  };

  const handleDelete = (line) => {
    openModal(line);
  };

  const onDelete = (line) =>
    deleteLine({
      variables: { id: line.id },
      update: (proxy) => {
        removeLine(line.id, proxy);
      },
    });

  function renderStatusTooltip() {
    return (
      <>
        <p>
          <span style={{ fontWeight: "bold" }}>Active:</span> Line is shown on the schedule.
        </p>{" "}
        <p>
          <span style={{ fontWeight: "bold" }}>Inactive:</span> Line is hidden from the schedule. It’s data, including
          scheduled blocks, are retained but not shown.
        </p>
      </>
    );
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderedLines = reorder(data.lines, result.source.index, result.destination.index);
    reorderLinesMutation({
      variables: {
        lineOrder: orderedLines.map((line) => line.id),
      },
      update: (proxy, { data: newData }) => {
        reorderLines(newData.reorderLines.lines, proxy);
      },
      optimisticResponse: {
        reorderLines: {
          lines: orderedLines,
          __typename: "ReorderLinesPayload",
        },
      },
    });
  };

  function renderContent() {
    if (loading) return null;

    const hasLines = newLineIds.length > 0 || data.lines.length > 0;

    const addButtonLabel = "Create a new line";
    const deleteMessage = `This line’s (${deleteData.name}) scheduling data will be permanently deleted, including all blocks scheduled in the past. This action cannot be undone.`;

    return (
      <Box width="1286px">
        <DeleteConfirmationModal title="Delete line" message={deleteMessage} onDelete={onDelete} />
        <IconicButton data-testid="add-button" type="button" icon="add" marginBottom="x2" onClick={handleAddNewLine}>
          {addButtonLabel}
        </IconicButton>
        {hasLines && (
          <Box data-testid="lines-table">
            <GridBox7>
              <Text />
              <Text>Name</Text>
              <Text>Type</Text>
              <Text>Efficiency %</Text>
              <Text>
                <LabelWithTooltip label="Status" tooltip={renderStatusTooltip()} />
              </Text>
            </GridBox7>
            <hr style={{ marginBottom: "0px" }} />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {newLineIds.map((newLineId) => (
                      <NewLine lineId={newLineId} onRemoveNewLine={handleRemoveNewLine} key={newLineId} />
                    ))}
                    {data.lines.map((line, index) => (
                      <Line key={line.id} line={line} onDelete={handleDelete} index={index} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Page className="spec-lines-index-page">
      <Heading2 mb="x3">Lines</Heading2>
      <Box marginBottom="x3" color={theme.colors.darkGrey} maxWidth="600px">
        Create, edit, or delete your site’s lines on this page.
      </Box>
      {renderContent()}
    </Page>
  );
}
