import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import { Icon, Card as NDSCard, theme } from "@nulogy/components";
import ProductionDetails from "./ProductionDetails";
import PrimaryDetails from "./PrimaryDetails";
import LineTypePreferences from "./LineTypePreferences";
import CustomWorkOrderFieldIndicatorOld from "components/pages/schedule/workOrderCards/CustomWorkOrderFieldIndicatorOld";
import NotesIndicator from "components/pages/schedule/NotesIndicator";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import WorkOrder from "domain/WorkOrder";

const DragIcon = styled.div(({ schedulable }) => {
  const color = schedulable ? theme.colors.darkBlue : theme.colors.grey;

  return {
    position: "absolute",
    top: "11px",
    left: "4px",
    color,
  };
});

const Card = styled(NDSCard)(({ workOrder, userCanUpdate }) => {
  const draggable = workOrder.schedulable && userCanUpdate;

  return {
    padding: "7px",
    [!draggable && "&:hover, &:active"]: { cursor: "default" },
    [WorkOrder.isMissingData(workOrder) && "color"]: theme.colors.darkGrey,
    border: `solid 3px ${WorkOrder.isMissingData(workOrder) ? theme.colors.lightGrey : "transparent"}`,
    backgroundColor: theme.colors.whiteGrey,
  };
});

export default function WorkOrderCardDetails({
  onOpenWorkOrderDetails,
  workOrder,
  workOrderForDetailsViewId,
  userCanUpdate,
}) {
  const {
    id: workOrderId,
    code,
    customWorkOrderFieldName,
    customWorkOrderFieldColor,
    remainingDuration,
    schedulable,
    standardProductionRate,
    performance,
    unscheduledQuantity,
    notes,
  } = workOrder;

  const applicationContext = useApplicationContext();
  if (applicationContext.loading) return null;

  const { settings } = applicationContext;
  const draggability = schedulable && userCanUpdate ? "draggable" : "non-draggable";
  const focused = workOrderId === workOrderForDetailsViewId ? "focus" : null;
  const workOrderCodeKebabCase = code.replace(/\s/g, "-").toLowerCase();

  return (
    <div
      data-drag-remaining-duration={remainingDuration || ""}
      data-drag-standard-production-rate={standardProductionRate}
      data-drag-performance={performance}
      data-drag-unscheduled-quantity={unscheduledQuantity}
      data-drag-settings={JSON.stringify(settings)}
      data-work-order-id={workOrderId}
      data-drag-work-order={JSON.stringify(workOrder)}
      data-drag-application-context={JSON.stringify(applicationContext)}
      className={classNames(
        "spec-drag-root",
        draggability,
        `pendo-work-order-card-${workOrderCodeKebabCase}`,
        "pendo-work-order-card",
      )}
      data-testid="work-order-card"
    >
      <Card workOrder={workOrder} boxShadow={focused} userCanUpdate={userCanUpdate}>
        {userCanUpdate && (
          <DragIcon schedulable={schedulable}>
            <Icon icon="drag" />
          </DragIcon>
        )}
        <CustomWorkOrderFieldIndicatorOld
          settings={settings}
          name={customWorkOrderFieldName}
          color={customWorkOrderFieldColor}
          userCanUpdate={userCanUpdate}
        />
        <PrimaryDetails
          applicationContext={applicationContext}
          userCanUpdate={userCanUpdate}
          workOrder={workOrder}
          onOpenWorkOrderDetails={onOpenWorkOrderDetails}
        />
        <ProductionDetails workOrder={workOrder} />
        <LineTypePreferences workOrder={workOrder} />
        {notes && <NotesIndicator />}
      </Card>
    </div>
  );
}
