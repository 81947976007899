import { useMutation } from "@apollo/client";
import { Box, IconicButton } from "@nulogy/components";
import React from "react";
import { GridBox4, StyledInput } from "components/common/EditableListStyledComponents";
import Form from "components/controls/Form";
import FieldRow from "components/common/FieldRow";
import { CreateTeardownTime } from "hooks/apollo/teardownTime/teardownTime.gql";
import DateTime from "utils/DateTime";
import { insertTeardownTime } from "hooks/apollo/teardownTime/helpers/handleUpdateTeardownTimes";

export default function CreateNewTeardownTime({ newTeardownTimeId, onRemoveNewTeardownTimeId }) {
  const [createTeardownTime] = useMutation(CreateTeardownTime);

  const handleCreate = ({ id, label, duration }) =>
    createTeardownTime({
      variables: { id, label, duration: DateTime.hoursToSeconds(duration) },
      update: (proxy, { data: newData }) => {
        const newTeardownTime = newData.createTeardownTime.teardownTime;

        insertTeardownTime(newTeardownTime, proxy);
      },
    });

  return (
    <Form
      onSave={handleCreate}
      onAfterSave={() => onRemoveNewTeardownTimeId(newTeardownTimeId)}
      initialValues={{ id: newTeardownTimeId, label: "", duration: "" }}
    >
      {({ dirty }) => (
        <FieldRow marginBottom="0">
          <GridBox4>
            <StyledInput
              field="label"
              placeholder="Teardown Time"
              className="spec-create-teardown-time-label-input"
              data-testid="create-teardown-time-label-input"
            />
            <StyledInput
              field="duration"
              type="number"
              style={{ textAlign: "left" }}
              className="spec-create-teardown-time-duration-input"
              data-testid="create-teardown-time-duration-input"
            />
            <Box paddingTop="x1">
              <IconicButton type="submit" icon="check" className="spec-save-teardown-time-icon" disabled={!dirty}>
                Save
              </IconicButton>
            </Box>
            <Box paddingTop="x1">
              <IconicButton type="button" icon="close" onClick={() => onRemoveNewTeardownTimeId(newTeardownTimeId)}>
                Cancel
              </IconicButton>
            </Box>
          </GridBox4>
        </FieldRow>
      )}
    </Form>
  );
}
