import useUnscheduleWorkBlock from "hooks/apollo/workBlock/useUnscheduleWorkBlock";
import useUnscheduleDowntimeBlock from "hooks/apollo/downtimeBlock/useUnscheduleDowntimeBlock";
import Block from "domain/Block";

export default function useUnscheduleBlock() {
  const handleUnscheduleWorkBlock = useUnscheduleWorkBlock();
  const handleUnscheduleDowntimeBlock = useUnscheduleDowntimeBlock();

  return ({ block }) => {
    if (Block.isWorkBlock(block)) {
      return handleUnscheduleWorkBlock(block);
    } else {
      return handleUnscheduleDowntimeBlock(block);
    }
  };
}
