import { Icon, IconicButton, Text, theme } from "@nulogy/components";
import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Section from "components/common/Section";
import { format24HourDateTime } from "components/helpers/format";
import endpoints from "config/endpoints";
import useGetItemMetadata from "hooks/apollo/item/useGetItemMetadata";
import useGetWorkOrderMetadata from "hooks/apollo/workOrder/useGetWorkOrderMetadata";

const FILENAMES = {
  items: "Items",
  workOrders: "Work orders",
};

const MetaData = styled(Text)`
  color: ${theme.colors.darkGrey};
  font-size: ${theme.fontSizes.smaller};
  line-height: ${theme.sizes.x2};
  margin-top: ${theme.space.x1};
`;

function downloadFile(body, filename) {
  const newUrl = window.URL.createObjectURL(new Blob([body]));
  const link = document.createElement("a");
  link.href = newUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default function Exports() {
  const [downloading, setDownloading] = useState({ items: false, workOrders: false });
  const { data: itemMetadata, loading: itemMetadataLoading } = useGetItemMetadata();
  const { data: workOrderMetadata, loading: workOrderMetadataLoading } = useGetWorkOrderMetadata();

  const handleExport = (url, type) => {
    setDownloading({ ...downloading, [type]: true });

    axios.get(url, { withCredentials: true }).then(({ data }) => {
      setDownloading({ ...downloading, [type]: false });
      const timestamp = format24HourDateTime(new Date());
      const filename = `${FILENAMES[type]} ${timestamp}.csv`;
      downloadFile(data, filename);
    });
  };

  const itemMetadataAvailable = itemMetadata && !itemMetadataLoading;
  const itemCount = itemMetadata?.itemMetadata.count;
  const itemExportDisabled = !itemMetadataAvailable || !itemCount;

  const itemMetadataText = () => {
    if (!itemMetadataAvailable) {
      return "Fetching...";
    } else if (!itemCount) {
      return "There are no items to export yet. To start, go to the imports tab.";
    } else {
      return `Current item count: ${itemCount}`;
    }
  };

  const workOrderMetadataAvailable = workOrderMetadata && !workOrderMetadataLoading;
  const workOrderCount = workOrderMetadata?.workOrderMetadata.count;
  const workOrderExportDisabled = !workOrderMetadataAvailable || !workOrderCount;

  const workOrderMetadataText = () => {
    if (!workOrderMetadataAvailable) {
      return "Fetching...";
    } else if (!workOrderCount) {
      return "There are no work orders to export yet. To start, go to the imports tab.";
    } else {
      return `Current work order count: ${workOrderCount}`;
    }
  };

  return (
    <>
      <Section title="Items" marginTop="x3">
        <Text>Export a .csv file of all items in Production Scheduling.</Text>
        <MetaData>{itemMetadataText()}</MetaData>
        <IconicButton
          icon="getApp"
          onClick={() => handleExport(endpoints.exportItems, "items")}
          disabled={itemExportDisabled || downloading.items}
        >
          Export items
        </IconicButton>
        {downloading.items && <Icon icon="loading" marginLeft="x1" />}
      </Section>
      <Section title="Work orders" marginTop="x3">
        <Text>Export a .csv file of all work orders in Production Scheduling.</Text>
        <MetaData>{workOrderMetadataText()}</MetaData>
        <IconicButton
          icon="getApp"
          onClick={() => handleExport(endpoints.exportWorkOrders, "workOrders")}
          disabled={workOrderExportDisabled || downloading.workOrders}
        >
          Export work orders
        </IconicButton>
        {downloading.workOrders && <Icon icon="loading" marginLeft="x1" />}
      </Section>
    </>
  );
}
