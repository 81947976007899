import round from "lodash/round";

const Num = {
  divmod,
  decimalToPercent,
  percentToDecimal,
};

function divmod(dividend, divisor) {
  const quotient = Math.floor(dividend / divisor);
  const remainder = dividend % divisor;

  return [quotient, remainder];
}

function decimalToPercent(value) {
  return round(value * 100, 3);
}

function percentToDecimal(value) {
  return value / 100;
}

export default Num;
