import DragFromSidebar from "./helpers/DragFromSidebar";
import workOrderDraggedRenderer from "components/common/Scheduler/SchedulerRenderers/renderers/workOrderDraggedRenderer";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useScheduleBlock from "hooks/apollo/blocks/useScheduleBlock";
import useTrackUsage from "hooks/apollo/usageData/useTrackUsage";
import DateTime from "utils/DateTime";
import WorkBlock from "domain/WorkBlock";
import useBryntumScheduler from "hooks/useBryntumScheduler";

let dragHelperInstance = null;
export default function DragFromSidebarInitializer() {
  const applicationContext = useApplicationContext();
  const scheduleBlock = useScheduleBlock();
  const trackUsage = useTrackUsage();
  const { getBryntumScheduler } = useBryntumScheduler();

  if (!getBryntumScheduler()) return null;
  if (dragHelperInstance) return null;

  const handleDrop = ({ startDate, endDate, workOrder, line }) => {
    const newWorkBlock = WorkBlock.buildWithBlockTime({
      line,
      workOrder,
      startsAt: DateTime.toISO8601(startDate),
      endsAt: DateTime.toISO8601(endDate),
      lockedOnSchedule: false,
    });

    scheduleBlock(newWorkBlock);
  };

  dragHelperInstance = new DragFromSidebar({
    schedule: getBryntumScheduler(),
    constrain: false,
    draggedNodeRenderer: workOrderDraggedRenderer,
    onDrop: handleDrop,
    onTrackUsage: trackUsage,
    applicationContext,
  });

  return null;
}
