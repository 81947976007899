import React from "react";
import styled from "styled-components";
import { Alert, List, ListItem, PrimaryButton, QuietButton } from "@nulogy/components";
import isEmpty from "lodash/isEmpty";
import ImportMessage from "./ImportMessage";

const ErrorsTitle = styled.div`
  padding-bottom: 10px;
  padding-top: 3px;
`;

const ErrorMessages = styled(List)`
  max-height: 250px;
  overflow-y: auto;
`;

const Input = styled.input`
  margin: 15px 0;
`;

export default function ImportFormView({
  file,
  endPoint,
  onCancel,
  onImport,
  onFileSelected,
  status,
  importErrors,
  validationErrors,
  warnings,
}) {
  const permitInput = status === "idle" || status === "failure";
  const importButtonEnabled = Boolean(file) && permitInput && validationErrors.length === 0;

  const renderErrors = (errors) => {
    if (isEmpty(errors)) return null;

    return (
      <div>
        <ErrorsTitle>Errors:</ErrorsTitle>
        {renderIssues(errors)}
      </div>
    );
  };

  const renderWarnings = () => {
    if (isEmpty(warnings)) return null;

    return (
      <div>
        <ErrorsTitle>Success with warnings:</ErrorsTitle>
        {renderIssues(warnings)}
      </div>
    );
  };

  const renderIssues = (issues) => (
    <ErrorMessages>
      {issues.map((issue) => (
        <ListItem key={issue}>{issue}</ListItem>
      ))}
    </ErrorMessages>
  );

  return (
    <div id="import-form">
      {validationErrors.length > 0 && <Alert type="danger">{renderErrors(validationErrors)}</Alert>}
      <ImportMessage status={status}>{renderErrors(importErrors) || renderWarnings()}</ImportMessage>

      <form encType="multipart/form-data" method="post" action={endPoint} onSubmit={onImport}>
        <Input
          type="file"
          name="importFile"
          accept=".csv"
          onChange={onFileSelected}
          className="spec-select-file"
          data-testId="fileSelector"
          disabled={!permitInput}
        />
        <div style={{ textAlign: "right" }}>
          <QuietButton onClick={onCancel} style={{ marginRight: "10px" }}>
            Close
          </QuietButton>
          <PrimaryButton id="import-button" disabled={!importButtonEnabled}>
            Import
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}
