import React from "react";
import { useQuery } from "@apollo/client";
import UserIndexView from "./UserIndexView";
import { GetUsers } from "hooks/apollo/user/user.gql";
import paths from "config/routePaths";
import Authorization from "policies/Authorization";

export default function UserIndexContainer() {
  const { data, loading } = useQuery(GetUsers);
  const policy = Authorization.usePolicy();

  if (loading) return null;

  const canCreateUser = policy.canCreate(Authorization.EntityTypes.User);
  const canUpdateCompany = policy.canUpdate(Authorization.EntityTypes.Company);
  const createUserPath = paths.userNew;

  const { users } = data;

  return (
    <UserIndexView
      users={users}
      canCreateUser={canCreateUser}
      canUpdateCompany={canUpdateCompany}
      createUserPath={createUserPath}
    />
  );
}
