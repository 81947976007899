import React from "react";
import NotificationView from "./NotificationView";
import paths from "config/routePaths";
import useNotificationState from "hooks/state/useNotificationState";

const RAISED_NOTIFICATION_PATHS = [paths.availabilityOverrides, paths.defaultAvailability];
const RAISED_TOAST_HEIGHT = 97; // 40 px (height) + 2x16px(padding) + 1px (border) + 24px (target)

export default function NotificationContainer() {
  const { state: notification, clearNotification } = useNotificationState();
  const path = window.location.pathname;
  const height = RAISED_NOTIFICATION_PATHS.includes(path) ? RAISED_TOAST_HEIGHT : undefined;

  const props = {
    type: notification.type,
    title: notification.title,
    message: notification.message,
    isOpen: notification.isPresent,
    isCloseable: notification.isCloseable,
    closeModal: clearNotification,
    height,
  };

  return <NotificationView {...props} />;
}
