import { useMutation } from "@apollo/client";
import { UnscheduleWorkBlock } from "./workBlock.gql";
import { removeWorkBlock } from "./helpers/handleUpdateWorkBlocks";
import useFetchShiftInstances from "hooks/apollo/shiftInstance/useFetchShiftInstances";

export default function useUnscheduleWorkBlock() {
  const { refetch: refetchShiftInstances } = useFetchShiftInstances();
  const [unscheduleWorkBlock] = useMutation(UnscheduleWorkBlock, {
    onCompleted: () => {
      refetchShiftInstances();
    },
  });

  return ({ id }) =>
    unscheduleWorkBlock({
      variables: { id },
      update: (proxy) => {
        removeWorkBlock(id, proxy);
      },
    });
}
