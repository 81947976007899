import React from "react";
import { Box, Icon, Tab, Tabs } from "@nulogy/components";
import fromPairs from "lodash/fromPairs";
import PublishErrorTabContent from "./PublishErrorTabContent";
import PublishSuccessTabContent from "./PublishSuccessTabContent";

function TabHeader({ color, icon, label }) {
  return (
    <>
      <Icon color={color} icon={icon} title={label} style={{ verticalAlign: "middle" }} />
      <Box display="inline-block" verticalAlign="middle" marginLeft="x1">
        {label}
      </Box>
    </>
  );
}

export default function PublishSummaryModalView({ summaryData }) {
  const { createdIds, updatedIds, workBlocks } = summaryData;
  if (!workBlocks) return null;

  const errors = fromPairs(summaryData.errors.map((error) => [error.path[2], error.message]));

  const workBlockResults = workBlocks.map((block, index) => ({
    id: block.id,
    job: block.jobId,
    line: block.line.name,
    error: errors[index],
    startTime: block.startsAt,
    workOrder: block.workOrder.code,
  }));

  const createErrorRows = workBlockResults.filter((block) => block.error && !block.job);
  const updateErrorRows = workBlockResults.filter((block) => block.error && block.job);
  const createSuccessRows = workBlockResults.filter((block) => !block.error && createdIds.includes(block.id));
  const updateSuccessRows = workBlockResults.filter((block) => !block.error && updatedIds.includes(block.id));

  const createTotal = createErrorRows.length + createSuccessRows.length;
  const updateTotal = updateErrorRows.length + updateSuccessRows.length;
  const errorTotal = createErrorRows.length + updateErrorRows.length;

  const defaultSelectedTabIndex = errorTotal > 0 ? 0 : 1;

  return (
    <Box mb="x1">
      <Tabs fitted defaultSelectedIndex={defaultSelectedTabIndex}>
        <Tab label={<TabHeader color="red" icon="error" label="Errors" />}>
          <PublishErrorTabContent
            createErrorRows={createErrorRows}
            updateErrorRows={updateErrorRows}
            createTotal={createTotal}
            updateTotal={updateTotal}
          />
        </Tab>
        <Tab label={<TabHeader color="green" icon="checkCircleOutline" label="Success" />}>
          <PublishSuccessTabContent
            updateSuccessRows={updateSuccessRows}
            createSuccessRows={createSuccessRows}
            updateTotal={updateTotal}
            createTotal={createTotal}
          />
        </Tab>
      </Tabs>
    </Box>
  );
}
