import React from "react";
import { Loader, Wrapper } from "./Loading.styles";

function Loading() {
  return (
    <Wrapper>
      <Loader>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </Loader>
    </Wrapper>
  );
}

export default Loading;
