import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useDowntimeEditModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.showDowntimeModalState, ...args };
    updateGlobalState({ showDowntimeModalState: newState });
  };

  return {
    open: (block) => {
      setState({ isOpen: true, block });
    },
    close: () => {
      setState({ isOpen: false, block: null });
    },
    block: state.showDowntimeModalState.block,
    isOpen: state.showDowntimeModalState.isOpen,
  };
}
