import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useNotificationState() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.notificationState, ...args };
    updateGlobalState({ notificationState: newState });
  };

  const setNotification = (args) => setState({ ...args, isPresent: true });

  const clearNotification = () => setState({ isPresent: false });

  return { state: state.notificationState, setNotification, clearNotification };
}
