import { useState } from "react";
import { useQuery } from "@apollo/client";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";
import uniq from "lodash/uniq";
import AdminDashboardView from "./AdminDashboardView";
import DateTime from "utils/DateTime";
import Enum from "utils/Enum";
import useUsageData from "hooks/apollo/usageData/useUsageData";
import StackedAreaChart from "components/charts/StackedAreaChart";
import EventTypes, { ACTION_OPERATIONS } from "hooks/apollo/usageData/EventTypes";
import { GetAllCompaniesWithSites } from "hooks/apollo/company/company.gql";
import waitForQueries from "components/waitForQueries";

const CHARTS = [
  { label: "All usage", eventNames: Object.values(ACTION_OPERATIONS) },
  { label: "Export schedule usage", eventNames: [EventTypes.EXPORT_SCHEDULE] },
  { label: "Focus line filter", eventNames: [EventTypes.FOCUS_LINE_FILTER] },
  { label: "Publish schedule usage", eventNames: [EventTypes.PUBLISH_SCHEDULE, EventTypes.PUBLISH_WORK_BLOCK] },
  { label: "Publish work order dates usage", eventNames: [EventTypes.PUBLISH_WORK_ORDER_DATES] },
  { label: "Reschedule multiple blocks", eventNames: [EventTypes.RESCHEDULE_MULTIPLE_BLOCKS] },
  { label: "Schedule teardown time", eventNames: [EventTypes.SCHEDULE_TEARDOWN_TIME] },
  { label: "Update default availabilities", eventNames: [EventTypes.UPDATE_DEFAULT_AVAILABILITIES] },
  { label: "Update availability overrides", eventNames: [EventTypes.UPDATE_AVAILABILITY_OVERRIDES] },
];

export default function AdminDashboardContainer() {
  const [siteId, setSiteId] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: DateTime.timeAgo(30, "days"),
    endDate: new Date(),
  });

  const handleRangeChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  const handleSiteChange = (selectedSiteId) => {
    setSiteId(selectedSiteId);
  };

  const queries = [useQuery(GetAllCompaniesWithSites)];

  const chartTypes = map(CHARTS, ({ label, eventNames }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const usageData = useUsageData({
      eventNames,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      siteId,
    });

    return {
      label,
      ChartComponent: StackedAreaChart,
      data: getRechartCompatibleData(usageData),
      keys: getKeys(usageData),
    };
  });

  const props = {
    charts: [...chartTypes],
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    handleRangeChange,
    handleSiteChange,
  };

  return waitForQueries({ queries })(AdminDashboardView)(props);
}

function getRechartCompatibleData(usageData) {
  if (!usageData) return [];

  return Enum.chain(usageData)
    .groupBy("date")
    .map((rows, date) => ({ name: date, ...getUsageBySite(rows) }))
    .value();
}

function getUsageBySite(rows) {
  return fromPairs(map(rows, (row) => [getSiteKey(row), row.value]));
}

function getKeys(data) {
  if (!data) return [];
  if (data.length === 0) return [];

  return uniq(data.map(getSiteKey));
}

function getSiteKey(row) {
  return `${row.companyName} - ${row.siteName}`;
}
