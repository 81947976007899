import React from "react";
import { Button, DropdownButton, DropdownMenu, Icon } from "@nulogy/components";
import styled from "styled-components";
import usePublishScheduleModal from "hooks/state/usePublishScheduleModal";
import useUpdateWorkOrderDatesModal from "hooks/state/useUpdateWorkOrderDatesModal";

const MenuItems = ({ openPublishSchedule, openPublishWorkOrderDates }) => (
  <>
    <DropdownButton onClick={openPublishSchedule}>Publish to jobs</DropdownButton>
    <DropdownButton onClick={openPublishWorkOrderDates}>Update work order dates</DropdownButton>
  </>
);

const ArrowIcon = styled(Icon)`
  vertical-align: middle;
  margin-bottom: 4px;
  padding-right: 0;
`;

export default function PublishDropdown({ isInDropDown }) {
  const { open: openPublishSchedule } = usePublishScheduleModal();
  const { open: openPublishWorkOrderDates } = useUpdateWorkOrderDatesModal();

  if (isInDropDown) {
    return (
      <DropdownMenu
        openOnHover
        placement="left-start"
        trigger={() => (
          <DropdownButton>
            Publish <ArrowIcon icon="rightArrow" size="x2" />
          </DropdownButton>
        )}
        width="250px"
      >
        <MenuItems openPublishWorkOrderDates={openPublishWorkOrderDates} openPublishSchedule={openPublishSchedule} />
      </DropdownMenu>
    );
  }

  return (
    <DropdownMenu trigger={() => <Button icon="downArrow">Publish</Button>}>
      <MenuItems openPublishWorkOrderDates={openPublishWorkOrderDates} openPublishSchedule={openPublishSchedule} />
    </DropdownMenu>
  );
}
