import React from "react";

export default function SyncErrorMessage(errors) {
  return (
    <div>
      {errors.map((error) => (
        <p>
          {error.siteName}: {error.message}
        </p>
      ))}
    </div>
  );
}
