export default function generateHexCode(str) {
  const hash = hashCode(str);

  return `#${hashToHexCode(hash)}`;
}

/* eslint-disable */
const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
};

const hashToHexCode = (int) => {
  const c = (int & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};

/* eslint-enable */
