import React from "react";
import { Select } from "@nulogy/components";
import memoizeOne from "memoize-one";
import FilterLabel from "./FilterLabel";
import useGetCustomers from "hooks/apollo/customer/useGetCustomers";

const memoizedCustomers = memoizeOne(buildCustomerOptions);

function buildCustomerOptions(customers) {
  return customers.map((customer) => ({
    label: customer,
    value: customer,
  }));
}

export default function CustomerSelector({ onChange, selectedCustomers }) {
  const { data, refetch: refetchCustomers } = useGetCustomers();
  const customers = data?.customers || [];

  const customerFilterOptions = memoizedCustomers(customers);

  return (
    <>
      <FilterLabel value="Customer" />
      <Select
        value={selectedCustomers}
        className="spec-customers-filter-multiselect"
        multiselect
        options={customerFilterOptions}
        onMenuOpen={refetchCustomers}
        onChange={onChange}
        placeholder="Select customers..."
        closeMenuOnSelect={false}
      />
    </>
  );
}
