import React, { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import UserEditView from "./UserEditView";
import paths from "config/routePaths";
import useGoBack from "hooks/useGoBack";
import { CreateUser, GetUsers } from "hooks/apollo/user/user.gql";
import { GetAuthenticationUser } from "hooks/apollo/authenticationUser/authenticationUser.gql";
import { GetAllRoles } from "hooks/apollo/role/role.gql";
import { GetAllCompanies } from "hooks/apollo/company/company.gql";
import { GetAssignableSites } from "hooks/apollo/site/site.gql";
import User from "domain/User";
import Uuid from "utils/Uuid";

export default function UserCreateContainer({ companyId, siteId }) {
  const { goBack } = useGoBack();
  const [createUser] = useMutation(CreateUser, { onCompleted: () => leavePage() });
  const { data: roleData, loading: rolesLoading } = useQuery(GetAllRoles);
  const { data: companiesData, loading: companiesLoading } = useQuery(GetAllCompanies);
  const initialRole = companyId ? "planner" : undefined;
  const initialCompanyId = companyId;
  const {
    data: sitesData,
    loading: sitesLoading,
    refetch: refetchSites,
  } = useQuery(GetAssignableSites, {
    variables: { role: initialRole, companyId: initialCompanyId },
  });
  const [authenticationUserExists, setAuthenticationUserExists] = useState(false);
  const [getAuthenticationUser] = useLazyQuery(GetAuthenticationUser, {
    fetchPolicy: "network-only",
    onCompleted: ({ authenticationUser: { id: authenticationUserId } }) => {
      if (authenticationUserId) {
        setAuthenticationUserExists(true);
      } else {
        setAuthenticationUserExists(false);
      }
    },
  });

  const loading = rolesLoading || companiesLoading || sitesLoading;

  const handleCancel = () => leavePage();

  const handleSave = (args) => {
    const defaultUser = {
      id: Uuid.generate(),
      email: "",
      password: "",
      role: "",
    };

    const userToSave = { ...defaultUser, ...args, companyId: args.company?.id };

    return createUser({ variables: userToSave, refetchQueries: [{ query: GetUsers }] });
  };

  const fetchAuthenticationUser = (event) => {
    const email = event.target.value;

    getAuthenticationUser({ variables: { email } });
  };

  const leavePage = () => {
    goBack(paths.userIndex);
  };

  if (loading) return null;

  return (
    <UserEditView
      user={User.build()}
      refetchSites={refetchSites}
      onCancel={handleCancel}
      onSave={handleSave}
      companies={companiesData?.companies || []}
      assignableSites={sitesData?.assignableSites || []}
      roles={roleData?.roles || []}
      initialCompanyId={initialCompanyId}
      initialRole={initialRole}
      siteId={siteId}
      fetchAuthenticationUser={fetchAuthenticationUser}
      authenticationUserExists={authenticationUserExists}
    />
  );
}
