import React from "react";
import { useField, useFormikContext } from "formik";
import { DateRange as NulogyDateRange } from "@nulogy/components";

const validateDateRange = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return null;

  if (startDate > endDate) {
    return "End date is before start date";
  }

  return null;
};

export default function DateRange({
  onChange,
  field: name,
  defaultStartDate,
  startDateInputProps,
  endDateInputProps,
  showTimes,
}) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField({ name, validate: validateDateRange }); // eslint-disable-line no-unused-vars

  const handleRangeChange = (value) => {
    if (onChange) {
      onChange({ value, setFieldValue });
    }
    helpers.setValue(value);
  };

  return (
    <NulogyDateRange
      showTimes={showTimes}
      labelProps={{ labelText: "" }}
      disableRangeValidation
      onRangeChange={handleRangeChange}
      errorMessage={meta.error}
      defaultStartDate={defaultStartDate}
      startDateInputProps={startDateInputProps}
      endDateInputProps={endDateInputProps}
    />
  );
}
