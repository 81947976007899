import React from "react";
import { StatusIndicator } from "@nulogy/components";

export default function LineStatusIndicator({ value, label }) {
  const booleanValue = value !== undefined ? value : label === "Active";

  return (
    <>
      {booleanValue ? (
        <StatusIndicator type="success">Active</StatusIndicator>
      ) : (
        <StatusIndicator>Inactive</StatusIndicator>
      )}
    </>
  );
}
