import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { DeleteDowntimeReason } from "./downtimeReason.gql";
import { useNotificationState } from "hooks/state";
import GqlError from "utils/GqlError";

export default function useDeleteDowntimeReason() {
  const { setNotification } = useNotificationState();
  const [deleteDowntimeReason] = useMutation(DeleteDowntimeReason, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to delete downtime reason",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: ({ deleteDowntimeReason: { code, reason } }) => {
      setNotification({
        title: `${reason} \u00B7 ${code} was successfully deleted.`,
        type: "success",
      });
    },
  });

  return (options) => deleteDowntimeReason(options);
}
