const Company = {
  build,
};

function build() {
  return {
    name: "",
    __typename: "Company",
  };
}

export default Company;
