import React from "react";
import { Tab, Tabs } from "@nulogy/components";
import ModalFormButtons from "components/controls/ModalFormButtons";
import CreateDowntimeBlockForm from "components/pages/schedule/downtimeBlocks/CreateDowntimeBlockForm";
import CreateWorkBlockForm from "components/pages/schedule/workBlocks/CreateWorkBlockForm";
import BlockModal from "components/pages/schedule/styles/BlockModal";

const WORK_BLOCK_TAB = 0;
const DOWNTIME_TAB = 1;

export default function CreateBlockModalView({
  blockData,
  isOpen,
  onClose,
  onSave,
  tab,
  onTabClick,
  lines,
  workOrders,
  workBlockErrors,
  setWorkBlockErrors,
  downtimeErrors,
  setDowntimeErrors,
  applicationContext,
  workBlockModalUIState,
}) {
  const formId = tab === WORK_BLOCK_TAB ? "createWorkBlock" : "createDowntime";
  const pendoTagClass =
    tab === WORK_BLOCK_TAB ? "pendo-create-work-block-modal-save" : "pendo-create-downtime-modal-save";

  const hasWorkBlockErrors = tab === WORK_BLOCK_TAB && workBlockErrors.base.length > 0;
  const hasDowntimeErrors = tab === DOWNTIME_TAB && downtimeErrors.base.length > 0;
  const hasNoWorkOrders = tab === WORK_BLOCK_TAB && workOrders.length === 0;

  const disabled = hasWorkBlockErrors || hasDowntimeErrors || hasNoWorkOrders;

  return (
    <BlockModal
      title="Create block"
      isOpen={isOpen}
      onRequestClose={onClose}
      className="spec-block-create-modal"
      footerContent={
        <ModalFormButtons formId={formId} onClose={onClose} disabled={disabled} pendoTagClass={pendoTagClass} />
      }
    >
      <Tabs fitted selectedIndex={tab} onTabClick={onTabClick}>
        <Tab label="Work block" className="pendo-create-work-block-tab">
          <CreateWorkBlockForm
            onClose={onClose}
            lines={lines}
            workOrders={workOrders}
            onSave={onSave}
            blockData={blockData}
            errors={workBlockErrors}
            setErrors={setWorkBlockErrors}
            applicationContext={applicationContext}
            workBlockModalUIState={workBlockModalUIState}
          />
        </Tab>
        <Tab label="Downtime block" className="pendo-create-downtime-tab">
          <CreateDowntimeBlockForm
            onClose={onClose}
            lines={lines}
            onSave={onSave}
            blockData={blockData}
            errors={downtimeErrors}
            setErrors={setDowntimeErrors}
          />
        </Tab>
      </Tabs>
    </BlockModal>
  );
}
