import React from "react";
import ReadOnlyDowntimeBlock from "./ReadOnlyDowntimeBlock";
import BlockModal from "components/pages/schedule/styles/BlockModal";
import useDowntimeShowModal from "hooks/state/useDowntimeShowModal";

export default function ReadOnlyDowntimeBlockModal() {
  const { block: blockData, isOpen, close } = useDowntimeShowModal();

  return (
    <BlockModal title="Downtime block" isOpen={isOpen} onRequestClose={close} className="spec-block-modal">
      {blockData && <ReadOnlyDowntimeBlock onClose={close} blockData={blockData} />}
    </BlockModal>
  );
}
