import React from "react";
import { Box, Breadcrumbs, Flex, Heading2, Link, LoadingAnimation, Page, Text, Toggle } from "@nulogy/components";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import UpdateUserPreferences from "hooks/apollo/user/userPreferences.gql";
import GetApplicationContext from "hooks/apollo/applicationContext/applicationContext.gql";
import { useNotificationState, useSchedulerState } from "hooks/state";

const Description = styled(Text)`
  margin-left: 56px;
`;

export default function UserPreferences() {
  const { user } = useApplicationContext();
  const [updateUserPreferencesMutation, { loading }] = useMutation(UpdateUserPreferences);
  const { extendedViewChanged } = useSchedulerState();
  const { setNotification } = useNotificationState();

  const updateUserPreferences = () => {
    const toValue = !user.userPreferences.extendedView;

    updateUserPreferencesMutation({
      variables: { extendedView: toValue },
      refetchQueries: [{ query: GetApplicationContext }],
      onCompleted: () => {
        extendedViewChanged({ extendedView: toValue });
        setNotification({ message: "Preferences updated", type: "success" });
      },
    });
  };

  if (!user) return <LoadingAnimation />;

  return (
    <Page
      breadcrumbs={
        <Breadcrumbs>
          <Link href="/" underline={false}>
            Home
          </Link>
        </Breadcrumbs>
      }
      title="Preferences"
    >
      <Flex justifyContent="center" width="100%">
        <Box boxShadow="small" p="x2" width="720px">
          <Heading2>Schedule</Heading2>
          <Toggle
            toggled={user.userPreferences.extendedView}
            onClick={updateUserPreferences}
            disabled={loading}
            onText="Extended view"
            offText="Extended view"
          />
          <Description fontSize="small" disabled={loading}>
            This enables you to scroll indefinitely on the schedule.
          </Description>
        </Box>
      </Flex>
    </Page>
  );
}
