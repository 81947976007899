import DateTime from "./DateTime";

const DateRange = {
  getDateRangeMonthText,
};

function getDateRangeMonthText(dateRange) {
  const monthsDiffer = dateRange.startDate.getMonth() !== dateRange.endDate.getMonth();
  const yearsDiffer = dateRange.startDate.getFullYear() !== dateRange.endDate.getFullYear();

  if (yearsDiffer) {
    const formattedStart = DateTime.format(dateRange.startDate, "MMM YYYY");
    const formattedEnd = DateTime.format(dateRange.endDate, "MMM YYYY");

    return `${formattedStart} - ${formattedEnd}`;
  } else if (monthsDiffer) {
    const formattedStart = DateTime.format(dateRange.startDate, "MMM");
    const formattedEnd = DateTime.format(dateRange.endDate, "MMM YYYY");

    return `${formattedStart} - ${formattedEnd}`;
  } else {
    return DateTime.format(dateRange.startDate, "MMMM YYYY");
  }
}

export default DateRange;
