import WorkBlock from "./WorkBlock";
import WorkOrder from "./WorkOrder";
import Timespan from "utils/Timespan";
import DateTime from "utils/DateTime";

const Schedule = {
  remainingDuration,
  resizedRemainingDuration,
  resizedQuantity,
  shrinkTimeSpanToWorkOrderRemaining,
  snapRemainingDuration,
};

export const SNAPPING_THRESHOLD = 10 * 60;

function remainingDuration(workBlock) {
  const {
    workOrder,
    workOrder: { unscheduledQuantity },
  } = workBlock;

  if (_isCompletedOrCancelled(workOrder)) return 0;

  return (unscheduledQuantity / WorkBlock.effectiveProductionRate(workBlock)) * DateTime.hoursToSeconds(1);
}

function resizedRemainingDuration(workBlock, newWorkTimeStartsAt, newWorkTimeEndsAt) {
  const { workTimeStartsAt, workTimeEndsAt, workOrder } = workBlock;

  const unscheduledDuration = remainingDuration(workBlock);

  const oldWorkTimeDuration = remainingDurationAfterProductionPosted(workOrder, workTimeStartsAt, workTimeEndsAt);
  const newWorkTimeDuration = remainingDurationAfterProductionPosted(workOrder, newWorkTimeStartsAt, newWorkTimeEndsAt);

  const oldFullWorkTimeDuration = unscheduledDuration + oldWorkTimeDuration;

  return oldFullWorkTimeDuration - newWorkTimeDuration;
}

function resizedQuantity(workBlock, newWorkTimeStartsAt, newWorkTimeEndsAt) {
  const updatedWorkblock = {
    ...workBlock,
    workTimeStartsAt: newWorkTimeStartsAt,
    workTimeEndsAt: newWorkTimeEndsAt,
  };

  return WorkBlock.quantity(updatedWorkblock);
}

function snapRemainingDuration(inputRemainingDuration) {
  if (inputRemainingDuration >= -SNAPPING_THRESHOLD && inputRemainingDuration <= SNAPPING_THRESHOLD) {
    return 0;
  }

  return inputRemainingDuration;
}

function remainingDurationAfterProductionPosted(workOrder, workTimeStartsAt, workTimeEndsAt) {
  const { productionLastUpdatedAt: rawProductionLastUpdatedAt } = workOrder;
  const productionLastUpdatedDate = rawProductionLastUpdatedAt ? new Date(rawProductionLastUpdatedAt) : null;
  const workTimeStartDate = new Date(workTimeStartsAt);
  const workTimeEndDate = new Date(workTimeEndsAt);

  if (_isCompletedOrCancelled(workOrder)) return 0;

  return new Timespan(workTimeStartDate, workTimeEndDate).shrinkStartTo(productionLastUpdatedDate).durationInSeconds();
}

function shrinkTimeSpanToWorkOrderRemaining({ remainingBlockDuration, timespan, existingBlock }) {
  const remainingDurationOnWorkOrder = remainingBlockDuration;
  const overscheduled = remainingDurationOnWorkOrder < 0;
  const remainingSchedulableDuration = remainingDurationOnWorkOrder + (existingBlock?.teardownTimeDuration || 0);
  let { endTime } = timespan;

  if (existingBlock && overscheduled) {
    endTime = DateTime.addDurationDate(timespan.startTime, WorkBlock.workTimeDuration(existingBlock), "seconds");
  }

  if (timespan.durationInSeconds() > remainingSchedulableDuration && !overscheduled) {
    endTime = DateTime.addDurationDate(timespan.startTime, remainingSchedulableDuration, "seconds");
  }

  return new Timespan(timespan.startTime, endTime);
}

function _isCompletedOrCancelled(workOrder) {
  return WorkOrder.isCompleted(workOrder) || WorkOrder.isCancelled(workOrder);
}

export default Schedule;
