import React from "react";
import PackmanagerLink from "./PackmanagerLink";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";

export default function PackmanagerWorkOrderLink({ externalUuid, code }) {
  const applicationContext = useApplicationContext();
  const packmanagerWorkorderPath = externalUuid && `work_orders/${externalUuid}`;

  return (
    <PackmanagerLink packmanagerPath={packmanagerWorkorderPath} applicationContext={applicationContext}>
      {code}
    </PackmanagerLink>
  );
}
