import React from "react";
import { Box, Flex, Text, theme } from "@nulogy/components";
import RemainingDuration from "./RemainingDuration";
import BlockTooltip from "./BlockTooltip";
import WorkOrderFlag from "./WorkOrderFlag";
import ExpectedUnits from "./ExpectedUnits";
import Tag from "components/pages/schedule/Tag";
import WorkOrderCardStatus from "components/pages/schedule/workOrderCards/WorkOrderCardStatus";
import WorkBlock from "domain/WorkBlock";
import DateTime from "utils/DateTime";
import Schedule from "domain/Schedule";

const blockDescription = ({ workOrder, workBlockLayout }) => {
  const { description, itemDescription, externalId, customerName } = workOrder;

  switch (workBlockLayout) {
    case WorkBlock.CUSTOMER_LAYOUT:
      return customerName || "No customer";

    case WorkBlock.ITEM_CODE_LAYOUT:
    case WorkBlock.ITEM_DESCRIPTION_LAYOUT:
      return itemDescription || "No item description";

    case WorkBlock.WORK_ORDER_DESCRIPTION_LAYOUT:
      return description || "No work order description";

    case WorkBlock.WORK_ORDER_ID_LAYOUT:
      return externalId || "No work order ID";

    default:
      throw new Error(`Unknown work block layout: ${workBlockLayout}`);
  }
};

export default function WorkBlockTooltip({
  workBlock,
  startDate,
  endDate,
  displaySnappedRemainingDuration,
  applicationContext,
  showStatus,
}) {
  const { workTimeStartsAt, workTimeEndsAt } = WorkBlock.updateBlockTime(workBlock, {
    startsAt: DateTime.toISO8601(startDate),
    endsAt: DateTime.toISO8601(endDate),
  });
  const remainingDuration = Schedule.resizedRemainingDuration(workBlock, workTimeStartsAt, workTimeEndsAt);

  const {
    jobId,
    workOrder,
    workOrder: { code: workOrderCode, flag, itemCode },
  } = workBlock;
  const {
    customerSettings: { workBlockLayout },
  } = applicationContext;
  const description = blockDescription({ workOrder, workBlockLayout });
  const remainingDurationInSeconds = displaySnappedRemainingDuration
    ? Schedule.snapRemainingDuration(remainingDuration)
    : remainingDuration;
  const jobIdMessage = `Job ${jobId}`;

  return (
    <div
      className="b-sch-event-tooltip spec-work-block-tooltip"
      style={{ fontSize: `${theme.fontSizes.smaller} !important` }}
    >
      <Flex justifyContent="space-between" style={{ lineHeight: theme.lineHeights.smallTextBase }}>
        <Box>
          <Text fontSize="small" lineHeight="smallTextBase" fontWeight="medium" data-testid="work-order-code">
            {workOrderCode}
          </Text>
        </Box>
        {showStatus && (
          <Box>
            <WorkOrderCardStatus workOrder={workOrder} inline />
          </Box>
        )}
      </Flex>
      <Text fontSize="smaller" data-testid="description">
        {description}
      </Text>
      <Text mb="x1" fontSize="smaller" lineHeight="smallTextBase" data-testid="item-code">
        {itemCode}
      </Text>
      <BlockTooltip
        startDate={startDate}
        endDate={endDate}
        teardownTimeDuration={workBlock.teardownTimeDuration}
        teardownTimeLabel={workBlock.teardownTimeLabel}
      />
      <RemainingDuration remainingDurationInSeconds={remainingDurationInSeconds} />
      <ExpectedUnits workBlock={workBlock} workTimeStartsAt={workTimeStartsAt} workTimeEndsAt={workTimeEndsAt} />
      <div style={{ paddingTop: "8px", display: "flex" }}>
        <WorkOrderFlag flag={flag} data-testid="flag" />
        <div>
          {jobId ? (
            <Tag data-testid="job-tag" tooltipTag>
              {jobIdMessage}
            </Tag>
          ) : null}
        </div>
      </div>
    </div>
  );
}
