import React from "react";
import { Icon, Text, theme } from "@nulogy/components";
import styled from "styled-components";
import BlockCellName from "./BlockCellName";
import Heading from "./Heading";

const WorkBlockPreviewContainer = styled.div({
  border: `solid 1px ${theme.colors.darkBlue}`,
  borderRadius: theme.sizes.half,
  padding: theme.sizes.half,
  fontSize: theme.fontSizes.smaller,
  minWidth: 280,
  position: "relative",
});

const BlockMenu = styled(Icon)({
  position: "absolute",
  top: theme.sizes.x1,
  right: theme.sizes.x1,
});

export default function WorkBlockPreview({ heading }) {
  return (
    <WorkBlockPreviewContainer>
      <BlockCellName>
        <Heading>{heading} | </Heading>
        <Text fontSize="smaller" inline>
          Work order code
        </Text>
      </BlockCellName>
      <Text fontSize="smaller">Due date</Text>
      <Text fontSize="smaller">Labor</Text>
      <BlockMenu icon="more" />
    </WorkBlockPreviewContainer>
  );
}
