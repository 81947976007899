import React, { useState } from "react";
import CreateBlockModalView from "./CreateBlockModalView";
import useGetAllLines from "hooks/apollo/line/useGetLines";
import useGetWorkOrders from "hooks/apollo/workOrder/useGetWorkOrders";
import useBlockCreateModal from "hooks/state/useBlockCreateModal";
import useScheduleBlock from "hooks/apollo/blocks/useScheduleBlock";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";
import useWorkBlockModal from "hooks/state/useWorkBlockModal";
import Block from "domain/Block";

export default function CreateBlockModalContainer() {
  const applicationContext = useApplicationContext();
  const { block: blockData, isOpen, close } = useBlockCreateModal();
  const { lines } = useGetAllLines({ activeOnly: true });
  const { workOrders } = useGetWorkOrders();
  const scheduleBlock = useScheduleBlock();
  const updateWorkOrder = useUpdateWorkOrder();
  const workBlockModalUIState = useWorkBlockModal();
  const handleSave = (block) => {
    const newBlock = Block.buildWithBlockTime(block);
    scheduleBlock(newBlock);
    if (block.__typename === "WorkBlock") {
      updateWorkOrder({ id: block.workOrder.id, notes: block.workOrder.notes });
    }
  };
  const [tab, setTab] = useState(0);
  const handleTabClick = (_event, index) => {
    setTab(index);
  };
  const [workBlockErrors, setWorkBlockErrors] = useState({ base: [] });
  const [downtimeErrors, setDowntimeErrors] = useState({ base: [] });

  if (!blockData) return null;

  const handleClose = () => {
    setWorkBlockErrors({ base: [] });
    setDowntimeErrors({ base: [] });
    close();
  };

  return (
    <CreateBlockModalView
      isOpen={isOpen}
      blockData={blockData}
      lines={lines}
      workOrders={workOrders}
      onClose={handleClose}
      onSave={handleSave}
      tab={tab}
      onTabClick={handleTabClick}
      workBlockErrors={workBlockErrors}
      setWorkBlockErrors={setWorkBlockErrors}
      downtimeErrors={downtimeErrors}
      setDowntimeErrors={setDowntimeErrors}
      applicationContext={applicationContext}
      workBlockModalUIState={workBlockModalUIState}
    />
  );
}
