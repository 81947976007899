import React from "react";
import { Box, theme } from "@nulogy/components";

const HEIGHT_BEHIND_FOOTER = theme.sizes.x6;

export default function StickyFooter({ children }) {
  return (
    <>
      <Box height={HEIGHT_BEHIND_FOOTER} />
      <Box
        position="fixed"
        bottom={0}
        left={0}
        paddingTop="x2"
        paddingLeft="x2"
        width="100%"
        backgroundColor="white"
        boxShadow="0px -3px 5px 0px rgba(1, 30, 56, 0.1)"
      >
        {children}
      </Box>
    </>
  );
}
