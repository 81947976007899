import React from "react";
import { useMutation } from "@apollo/client";
import { Box, Icon, IconicButton, theme } from "@nulogy/components";
import LineStatusSelect from "./LineStatusSelect";
import Num from "utils/Num";
import { insertLine } from "hooks/apollo/line/helpers/handleUpdateLines";
import { GridBox7, StyledInput, StyledText } from "components/common/EditableListStyledComponents";
import FieldRow from "components/common/FieldRow";
import Form from "components/controls/Form";
import { CreateLine } from "hooks/apollo/line/line.gql";

export default function NewLine({ lineId, onRemoveNewLine }) {
  const [createLine] = useMutation(CreateLine);

  const handleSave = ({ id, name, lineType, efficiency, active }) => {
    const efficiencyDecimal = efficiency === "" ? null : Num.percentToDecimal(efficiency);

    return createLine({
      variables: { id, name, lineType, efficiency: efficiencyDecimal, active },
      update: (proxy, { data }) => {
        const newLine = data.createLine.line;

        insertLine(newLine, proxy);
      },
    });
  };

  const removeNewLine = () => {
    onRemoveNewLine(lineId);
  };

  return (
    <Form
      onSave={handleSave}
      onAfterSave={removeNewLine}
      initialValues={{ id: lineId, name: "", lineType: "", efficiency: 100, active: true }}
    >
      {({ dirty }) => (
        <FieldRow marginBottom="0" data-testid="new-line-row">
          <GridBox7>
            <StyledText>
              <Icon icon="drag" color={theme.colors.darkBlue} style={{ opacity: 0.5 }} />
            </StyledText>
            <StyledInput field="name" placeholder="Name" />
            <StyledInput field="lineType" placeholder="Type" />
            <StyledInput
              field="efficiency"
              type="number"
              step="any"
              placeholder="Efficiency"
              style={{ width: "112px" }}
            />
            <LineStatusSelect />

            <Box paddingTop="x1">
              <IconicButton type="submit" icon="check" disabled={!dirty}>
                Save
              </IconicButton>
            </Box>
            <Box paddingTop="x1">
              <IconicButton type="button" icon="close" onClick={removeNewLine}>
                Cancel
              </IconicButton>
            </Box>
          </GridBox7>
        </FieldRow>
      )}
    </Form>
  );
}
