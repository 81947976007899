import React from "react";
import { Box, BrandedNavBar } from "@nulogy/components";
import endpoints from "config/endpoints";
import GqlError from "utils/GqlError";

const menuData = {
  primaryMenu: [],
  secondaryMenu: [{ name: "Logout", href: endpoints.logout }],
};

export default function GraphQLError({ error, topLevel }) {
  const message = getFirstErrorMessage(error);

  return (
    <div className="appContainer">
      {topLevel && <BrandedNavBar menuData={menuData} />}
      <Box data-testid="message" padding="x3" flexDirection="column">
        {message}
      </Box>
    </div>
  );
}

function getFirstErrorMessage(error) {
  let errorMessage =
    "There was a server error. Please try again. If this problem persists, please contact Nulogy Support.";
  GqlError.handler(error, (errorObj) => {
    [[, errorMessage]] = Object.entries(errorObj);
  });

  return errorMessage;
}
