import React from "react";
import { Box, Flex, Heading2, Page, PrimaryButton } from "@nulogy/components";
import omit from "lodash/omit";
import { formatDateTime } from "components/helpers/format";
import UnstyledLink from "components/common/UnstyledLink";
import IndexTable from "components/common/IndexTable";
import paths from "config/routePaths";

const ROLE_LABELS = {
  nulogy_admin: "Nulogy Admin",
  nulogy_restricted: "Nulogy Restricted",
  planner: "Planner",
  readonly: "Read-only",
};

function renderColumns(canUpdateCompany) {
  return [
    {
      label: "Company name",
      dataKey: "companyName",
      cellRenderer: ({ row }) => companyCellRenderer({ row, canUpdateCompany }),
      numeric: true,
    },
    { label: "User email", dataKey: "email", cellRenderer: emailCellRenderer, numeric: true },
    { label: "Role", dataKey: "role", cellFormatter: labelForRole },
    { label: "Created", dataKey: "createdAt", cellFormatter: formatTime },
    { label: "Last updated", dataKey: "updatedAt", cellFormatter: formatTime },
  ];
}

function labelForRole({ cellData: role }) {
  const label = ROLE_LABELS[role];
  if (!label) throw new Error(`Unhandled role: ${role}`);

  return label;
}

function formatTime({ cellData }) {
  return formatDateTime(cellData);
}

function companyCellRenderer({ row, canUpdateCompany }) {
  if (canUpdateCompany) {
    return (
      <UnstyledLink className="spec-company-name" href={paths.companyEdit(row.companyId)}>
        {row.companyName}
      </UnstyledLink>
    );
  }

  return <div className="spec-company-name">{row.companyName}</div>;
}

function emailCellRenderer({ row }) {
  return <UnstyledLink href={paths.userEdit(row.id)}>{row.email}</UnstyledLink>;
}

export default function UserIndexView({ users, canCreateUser, canUpdateCompany, createUserPath }) {
  const flattenedUsers = users.map((user) => ({
    ...omit(user, "allowedSites"),
    companyName: user.company?.name,
    companyId: user.company?.id,
  }));

  return (
    <Page>
      <Flex className="spec-user-index-page">
        <Heading2 paddingRight="x4" data-testid="title">
          Users
        </Heading2>
        {canCreateUser && (
          <Box>
            <PrimaryButton asLink type="button" size="small" href={createUserPath} marginTop="x1">
              Create user
            </PrimaryButton>
          </Box>
        )}
      </Flex>
      <IndexTable
        columns={renderColumns(canUpdateCompany)}
        rows={flattenedUsers}
        keyField="id"
        initialSortColumn="email"
      />
    </Page>
  );
}
