import { Box, Text } from "@nulogy/components";
import React from "react";

export default function NoResultsFound() {
  return (
    <Box marginTop="96px">
      <Text textAlign="center" fontSize="medium" color="black" fontWeight="bold" marginBottom="x1">
        No results found
      </Text>
      <Text textAlign="center" fontSize="small" color="black">
        Please refine search term or filters
      </Text>
    </Box>
  );
}
