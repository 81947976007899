import React from "react";
import { useMutation } from "@apollo/client";
import { Box, IconicButton } from "@nulogy/components";
import { GridBox4, StyledInput } from "components/common/EditableListStyledComponents";
import FieldRow from "components/common/FieldRow";
import Form from "components/controls/Form";
import { CreateDowntimeReason } from "hooks/apollo/downtimeReason/downtimeReason.gql";
import { insertDowntimeReason } from "hooks/apollo/downtimeReason/helpers/handleUpdateDowntimeReasons";

export default function NewDowntimeReason({ downtimeReasonId, onRemoveNewDowntimeReason }) {
  const [createDowntimeReason] = useMutation(CreateDowntimeReason);

  const handleSave = ({ id, reason, code }) =>
    createDowntimeReason({
      variables: { id, reason, code },
      update: (proxy, { data }) => {
        const newDowntimeReason = data.createDowntimeReason.downtimeReason;

        insertDowntimeReason(newDowntimeReason, proxy);
      },
    });

  const removeNewDowntimeReason = () => {
    onRemoveNewDowntimeReason(downtimeReasonId);
  };

  return (
    <Form
      onSave={handleSave}
      onAfterSave={removeNewDowntimeReason}
      initialValues={{ id: downtimeReasonId, reason: "", code: "" }}
    >
      {({ dirty }) => (
        <FieldRow marginBottom="0" data-testid="new-downtime-reason-row">
          <GridBox4>
            <StyledInput field="reason" placeholder="Reason" />
            <StyledInput field="code" placeholder="Code" />
            <Box paddingTop="x1">
              <IconicButton type="submit" icon="check" disabled={!dirty}>
                Save
              </IconicButton>
            </Box>
            <Box paddingTop="x1">
              <IconicButton type="button" icon="close" onClick={removeNewDowntimeReason}>
                Cancel
              </IconicButton>
            </Box>
          </GridBox4>
        </FieldRow>
      )}
    </Form>
  );
}
