import React from "react";
import BaseAvailabilityCheckbox from "./BaseAvailabilityCheckbox";

export default function ReadOnlyAvailabilityCheckbox({ checked, disabled, overridden }) {
  return (
    <BaseAvailabilityCheckbox overridden={overridden} interactive={false}>
      <input type="checkbox" checked={checked} disabled={disabled} onChange={() => {}} />
      <label role="presentation" htmlFor="checkbox" />
    </BaseAvailabilityCheckbox>
  );
}
