import React from "react";
import styled from "styled-components";
import { theme } from "@nulogy/components";

const Icon = styled.div`
  border-radius: 3px;
  background-color: ${theme.colors.lightGrey};
  color: ${theme.colors.darkGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: 12px;
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 6px;
`;

const Text = styled.span`
  position: relative;
  top: -7px;
`;

export default function ReadonlyIcon() {
  return (
    <Icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        style={{
          fill: theme.colors.darkGrey,
          display: "inline",
          position: "relative",
          left: "-3px",
          padding: "0px",
        }}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
      </svg>{" "}
      <Text>READ-ONLY</Text>
    </Icon>
  );
}
