import React, { useRef } from "react";
import { useField, useFormikContext } from "formik";
import BaseAvailabilityCheckbox from "./BaseAvailabilityCheckbox";

function isOverridden(defaultAvailability, field) {
  if (defaultAvailability === undefined) return false;

  return field.value !== defaultAvailability;
}

export default function AvailabilityCheckbox({ field: name, onChange, defaultAvailability, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field, , helpers] = useField(name);
  const checkboxInput = useRef(null);
  const overridden = isOverridden(defaultAvailability, field);

  const handleChange = () => {
    const { checked, disabled } = checkboxInput.current;

    if (!disabled) {
      if (onChange) {
        onChange({ checked: !checked, setFieldValue });
      }
      helpers.setValue(!checked);
    }
  };

  return (
    <BaseAvailabilityCheckbox overridden={overridden}>
      <input
        type="checkbox"
        id={`checkbox-${field}`}
        ref={checkboxInput}
        onChange={handleChange}
        checked={field.value}
        {...props}
      />
      <label
        role="presentation"
        onClick={() => {
          handleChange(field);
        }}
        htmlFor="checkbox"
      />
    </BaseAvailabilityCheckbox>
  );
}
