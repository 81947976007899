import { useMutation } from "@apollo/client";
import { UnscheduleDowntimeBlock } from "./downtimeBlock.gql";
import { removeDowntimeBlock } from "./helpers/handleUpdateDowntimeBlocks";

export default function useUnscheduleDowntimeBlock() {
  const [unscheduleDowntimeBlock] = useMutation(UnscheduleDowntimeBlock);

  return ({ id }) =>
    unscheduleDowntimeBlock({
      variables: {
        id,
      },
      update: (proxy) => {
        removeDowntimeBlock(id, proxy);
      },
    });
}
