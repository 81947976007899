import React from "react";
import { Box, Select as NDSSelect } from "@nulogy/components";
import styled from "styled-components";
import flatMap from "lodash/flatMap";
import endpoints from "config/endpoints";

const Wrapper = styled(Box)`
  width: 250px;
`;

const Select = styled(NDSSelect)`
  > div {
    width: 250px;
    font-size: 16px;
  }
`;

export default function SiteSwitcherView({ companies, currentSiteId, ...props }) {
  const options = flatMap(companies, (sites, companyName) =>
    sites.map((site) => ({ value: site.id, label: siteLabel(companies, companyName, site.name) })),
  );

  return (
    <Wrapper>
      <Select
        className="spec-site-switcher"
        options={options}
        value={currentSiteId}
        onChange={handleChange}
        {...props}
      />
    </Wrapper>
  );
}

function siteLabel(companies, companyName, siteName) {
  if (Object.keys(companies).length > 1) {
    return `${companyName} - ${siteName}`;
  } else {
    return siteName;
  }
}

const handleChange = (siteId) => {
  window.location.href = siteLink(siteId);
};

function siteLink(siteId) {
  return `${endpoints.switchSite(siteId)}?redirect_url=${window.location.origin}`;
}
