import React, { useState } from "react";
import { IconicButton, Link, Text, theme } from "@nulogy/components";
import styled from "styled-components";
import ImportModal from "./ImportModal";
import Section from "components/common/Section";
import { formatDateTime } from "components/helpers/format";
import useGetItemMetadata from "hooks/apollo/item/useGetItemMetadata";
import useGetWorkOrderMetadata from "hooks/apollo/workOrder/useGetWorkOrderMetadata";

const MetaData = styled(Text)`
  color: ${theme.colors.darkGrey};
  font-size: ${theme.fontSizes.smaller};
  line-height: ${theme.sizes.x2};
  margin-top: ${theme.space.x1};
`;

export default function Imports() {
  const { data: itemMetadata, loading: itemMetadataLoading } = useGetItemMetadata();
  const { data: workOrderMetadata, loading: workOrderMetadataLoading } = useGetWorkOrderMetadata();

  const itemMetadataText = () => {
    const itemMetadataAvailable = itemMetadata && !itemMetadataLoading;
    const itemCount = itemMetadata?.itemMetadata.count;
    const itemLastImportedAt = itemMetadata?.itemMetadata.lastImportedAt;

    if (!itemMetadataAvailable) return "Fetching...";

    let metadataText = `Current item count: ${itemCount}`;

    if (itemLastImportedAt) {
      metadataText += ` | Last imported at: ${formatDateTime(itemLastImportedAt)}`;
    }

    return metadataText;
  };

  const workOrderMetadataText = () => {
    const workOrderMetadataAvailable = workOrderMetadata && !workOrderMetadataLoading;
    const workOrderCount = workOrderMetadata?.workOrderMetadata.count;
    const workOrderLastImportedAt = workOrderMetadata?.workOrderMetadata.lastImportedAt;

    if (!workOrderMetadataAvailable) return "Fetching...";

    let metadataText = `Current work order count: ${workOrderCount}`;

    if (workOrderLastImportedAt) {
      metadataText += ` | Last imported at: ${formatDateTime(workOrderLastImportedAt)}`;
    }

    return metadataText;
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [importKey, setImportKey] = useState("");

  const openModal = (key) => {
    setModalIsOpen(true);
    setImportKey(key);
  };

  return (
    <>
      <Section title="Items" marginTop="x3">
        <Text>
          An item import template can be found{" "}
          <Link href="./templates/Items Template.csv" download>
            here
          </Link>
          . Items must be imported before work orders.
        </Text>
        <MetaData>{itemMetadataText()}</MetaData>
        <IconicButton onClick={() => openModal("items")} icon="publish">
          Items
        </IconicButton>
      </Section>
      <Section title="Work orders">
        <Text>
          A work order import template can be found{" "}
          <Link href="./templates/Work Orders Template.csv" download>
            here
          </Link>
          . Work orders must be associated with an item.
        </Text>
        <MetaData>{workOrderMetadataText()}</MetaData>
        <IconicButton onClick={() => openModal("workOrders")} icon="publish">
          Work orders
        </IconicButton>
      </Section>
      <ImportModal
        importKey={importKey}
        modalIsOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
      />
    </>
  );
}
