import { useQuery } from "@apollo/client";
import { GetDashboardData } from "./usageData.gql";
import useTimeZone from "hooks/useTimeZone";

export default function useUsageData({ eventNames, startDate, endDate, siteId }) {
  const { overrideToSiteTimeZone } = useTimeZone();

  const { data } = useQuery(GetDashboardData, {
    variables: {
      eventNames,
      siteId,
      startDate: overrideToSiteTimeZone(startDate),
      endDate: overrideToSiteTimeZone(endDate),
    },
  });

  return data?.usageData;
}
