import { useApolloClient } from "@apollo/client";
import workOrderInfo from "./workOrderInfo.gql";

export default function useGetWorkOrderFromCache() {
  const client = useApolloClient();

  return (workOrderId) =>
    client.readFragment({
      id: `WorkOrder:${workOrderId}`,
      fragment: workOrderInfo,
    });
}
