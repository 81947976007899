import React from "react";
import styled from "styled-components";
import { Toast as NulogyToast } from "@nulogy/components";

const Toast = styled(NulogyToast)`
  max-height: 200px;
  overflow-y: auto;
`;

const ZIndexOverride = styled.div`
  z-index: 999;
  position: absolute;

  > div {
    ${({ height }) => (height ? `transform: translateY(-${height}px) !important;` : "")}
  }
`;

export default function NotificationView({
  type,
  title,
  message,
  isOpen,
  closeModal,
  isCloseable = type !== "success",
  height,
}) {
  if (!type) return null;

  return (
    <ZIndexOverride height={height}>
      <Toast type={type} title={title} triggered={isOpen} isCloseable={isCloseable} onHide={closeModal}>
        {message}
      </Toast>
    </ZIndexOverride>
  );
}
