import filter from "lodash/filter";
import { GetAllShifts } from "hooks/apollo/shift/shift.gql";

export function insertShift(newShift, proxy) {
  const shifts = read(proxy);

  const updatedShifts = [newShift].concat(filter(shifts, (existingShift) => existingShift.id !== newShift.id));

  write(updatedShifts, proxy);
}

export function removeShift(shiftId, proxy) {
  const internalShift = proxy.identify({ id: shiftId, __typename: "Shift" });
  proxy.evict({ id: internalShift });
  proxy.gc();
}

function read(proxy) {
  const { shifts } = proxy.readQuery({ query: GetAllShifts });

  return shifts;
}

function write(shifts, proxy) {
  proxy.writeQuery({
    query: GetAllShifts,
    data: { shifts },
  });
}
