import React, { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import { Select as NulogySelect } from "@nulogy/components";

export default function Select({ field: name, onChange, className, ...ndsProps }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  const handleChange = useMemo(
    () => (value) => {
      if (onChange) {
        onChange({ value, setFieldValue });
      }
      helpers.setValue(value);
    },
    [onChange, helpers, setFieldValue],
  );

  return (
    <NulogySelect
      value={field.value}
      onChange={handleChange}
      className={className}
      errorMessage={meta.error}
      {...ndsProps}
    />
  );
}
