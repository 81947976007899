import React from "react";
import { useField, useFormikContext } from "formik";
import { Box, Flex, Icon, Toggle as NulogyToggle, theme } from "@nulogy/components";

export default function LockToggle({ onChange, field: name }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name); // eslint-disable-line no-unused-vars

  const handleChange = (event) => {
    const { checked } = event.target;
    if (onChange) {
      onChange({ checked, setFieldValue });
    }
    helpers.setValue(checked);
  };

  return (
    <Flex textAlign="right" alignItems="center">
      <Box>
        <Icon size="28px" mr="x1" pt="4px" icon={field.value ? "lock" : "unlock"} color={theme.colors.darkBlue} />
      </Box>
      <Box>
        <NulogyToggle className="pendo-lock-toggle spec-toggle-locked" onChange={handleChange} checked={field.value} />
      </Box>
    </Flex>
  );
}
