import React, { useContext } from "react";
import sortBy from "lodash/sortBy";
import ScheduledWorkBlockDetailsView from "./ScheduledWorkBlockDetailsView";
import useRefetchSchedule from "hooks/apollo/schedule/useRefetchSchedule";
import { useNotificationState, useSchedulerState } from "hooks/state";
import UIStateContext from "contexts/UIStateContext";
import { timeIncrement } from "config/zoomConfig";

import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useBryntumScheduler from "hooks/useBryntumScheduler";
import Bryntum from "domain/Bryntum";
import DateTime from "utils/DateTime";

export default function ScheduledWorkBlockDetailsContainer({ workOrder }) {
  const {
    changeVisibleStartDate,
    state: { zoomLevel },
  } = useSchedulerState();
  const applicationContext = useApplicationContext();
  const { getBryntumScheduler } = useBryntumScheduler();
  const { setNotification } = useNotificationState();
  const refetchSchedule = useRefetchSchedule();

  const { setState: updateUIState, getState: getUIState } = useContext(UIStateContext);

  const { scheduledDuration, workBlocks } = workOrder;
  const sortedWorkBlocks = sortBy(workBlocks, ["startsAt", "endsAt"]);

  const { lineOrder } = applicationContext.site;

  const linesBuilder = {};
  sortedWorkBlocks.forEach((workBlock) => {
    if (!linesBuilder[workBlock.line.id]) {
      linesBuilder[workBlock.line.id] = { ...workBlock.line, workBlocks: [workBlock] };
    } else {
      linesBuilder[workBlock.line.id].workBlocks.push(workBlock);
    }
  });

  // Prioritise entries in line_order above other lines
  const linesWithWorkBlocks = sortBy(Object.values(linesBuilder), [
    (line) => {
      const lineIndex = lineOrder.indexOf(line.id);
      if (lineIndex >= 0) return lineIndex;

      return linesBuilder.length;
    },
    "name",
  ]);

  const handleNavigateToWorkBlock = (workBlock) => {
    const { id: lineId, name: lineName, active } = workBlock.line;
    if (!active) {
      setNotification({
        title: "This block is scheduled on an inactive line",
        message: "To view this block, change its line’s status via Settings > Lines.",
        type: "danger",
      });

      return;
    }

    if (Bryntum.isLineFiltered(getBryntumScheduler(), lineId)) {
      setNotification({
        title: `This block is scheduled on ${lineName}, which has been filtered out`,
        message: "Adjust the line type filter to view this block.",
        type: "danger",
      });

      return;
    }

    const { startsAt, id } = workBlock;

    const adjustedStart = DateTime.subtractDuration(startsAt, timeIncrement(zoomLevel), "minutes");

    refetchSchedule(
      changeVisibleStartDate({
        startDate: adjustedStart,
        useStartOfDay: false,
      }),
    ).then(() => {
      const currentUIState = getUIState();

      updateUIState({
        ...currentUIState,
        selectedWorkBlock: {
          id,
          selectedAt: new Date(),
        },
      });
    });
  };

  return (
    <ScheduledWorkBlockDetailsView
      onNavigateToWorkBlock={handleNavigateToWorkBlock}
      scheduledDuration={scheduledDuration}
      linesWithWorkBlocks={linesWithWorkBlocks}
    />
  );
}
