import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import PublishScheduleFormView from "./PublishScheduleFormView";
import useGetAllLines from "hooks/apollo/line/useGetLines";
import usePublishSummaryModal from "hooks/state/usePublishSummaryModal";
import usePublishScheduleModal from "hooks/state/usePublishScheduleModal";
import { PublishSchedule, PublishScheduleDryRun } from "hooks/apollo/publishSchedule.gql";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useTimeZone from "hooks/useTimeZone";

const DEFAULT_RESPONSE_STATE = { success: null, errors: [] };
const DEFAULT_FILTERS_STATE = {
  dateRange: { startDate: null, endDate: null },
  linesToPublish: [],
  lineTypesToPublish: [],
};
const DEFAULT_COUNTS_STATE = { total: 0, toCreate: 0, toUpdate: 0, onInactiveLines: 0 };

export default function PublishScheduleFormContainer() {
  const { overrideToSiteTimeZone } = useTimeZone();
  const applicationContext = useApplicationContext();
  const { lines } = useGetAllLines({ activeOnly: true });

  const [response, setResponse] = useState(DEFAULT_RESPONSE_STATE);
  const resetResponse = () => setResponse(DEFAULT_RESPONSE_STATE);
  const [filters, setFilters] = useState(DEFAULT_FILTERS_STATE);
  const [jobCounts, setJobCounts] = useState(DEFAULT_COUNTS_STATE);
  const { open: openSummaryModal } = usePublishSummaryModal();
  const { close: closePublishModal } = usePublishScheduleModal();

  const {
    dateRange: { startDate, endDate },
  } = filters;

  const [publishSchedule, { loading: schedulePublishing }] = useMutation(PublishSchedule);
  const [getJobCounts, { loading: dryRunLoading }] = useLazyQuery(PublishScheduleDryRun, {
    fetchPolicy: "network-only",
    onCompleted: ({
      publishScheduleDryRun: {
        count: total,
        jobsToCreateCount: toCreate,
        jobsToUpdateCount: toUpdate,
        blocksOnInactiveLinesCount: onInactiveLines,
      },
    }) => {
      setJobCounts({ total, toCreate, toUpdate, onInactiveLines });
    },
  });

  useEffect(() => {
    if (startDate && endDate)
      getJobCounts({
        variables: publishScheduleInformation(filters, applicationContext, overrideToSiteTimeZone),
      });
  }, [filters]); // eslint-disable-line

  const showJobCount = !dryRunLoading && startDate && endDate;

  const onSave = () => {
    resetResponse();

    return publishSchedule({
      variables: publishScheduleInformation(filters, applicationContext, overrideToSiteTimeZone),
      update: (_proxy, { data }) => {
        const publishResponse = data.publishSchedule.publishedSchedule;
        const configurationErrors = publishResponse.errors.filter((error) => error.path.length !== 3);
        if (configurationErrors.length) {
          setResponse(publishResponse);
        } else {
          closePublishModal();
          openSummaryModal(publishResponse);
        }
      },
    });
  };

  const handleFilterChange = (updatedFields) => {
    resetResponse();
    setFilters((prevState) => ({ ...prevState, ...updatedFields }));
  };

  return (
    <PublishScheduleFormView
      onSave={onSave}
      onFilterChange={handleFilterChange}
      filters={filters}
      lines={lines}
      jobCounts={jobCounts}
      showJobCount={showJobCount}
      response={response}
      schedulePublishing={schedulePublishing || dryRunLoading}
    />
  );
}

function publishScheduleInformation(
  { dateRange: { startDate, endDate }, linesToPublish: lineIds, lineTypesToPublish: lineTypes },
  applicationContext,
  overrideToSiteTimeZone,
) {
  return {
    startsAt: overrideToSiteTimeZone(startDate),
    endsAt: overrideToSiteTimeZone(endDate),
    lineIds,
    lineTypes,
  };
}
