import React from "react";
import { useField, useFormikContext } from "formik";
import { Radio as NulogyRadio } from "@nulogy/components";

export default function Radio({ labelText, field: name, onChange, value, ...ndsProps }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (onChange) {
      onChange({ checked, setFieldValue });
    }
    helpers.setValue(value);
  };

  return (
    <NulogyRadio
      labelText={labelText}
      checked={field.value === value}
      onChange={handleChange}
      error={meta.error}
      {...ndsProps}
    />
  );
}
