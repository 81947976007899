import React from "react";
import styled from "styled-components";
import { Box } from "@nulogy/components";
import LineTypeTag from "components/pages/schedule/LineTypeTag";

const Pill = styled(LineTypeTag)`
  display: inline-block;
`;

export default function LineTypePreferences({ workOrder: { lineTypePreferencePrimary, lineTypePreferenceSecondary } }) {
  return (
    <Box>
      {lineTypePreferencePrimary && (
        <Pill data-testid="line-type-preference-primary">1. {lineTypePreferencePrimary}</Pill>
      )}
      {lineTypePreferenceSecondary && (
        <Pill data-testid="line-type-preference-secondary">2. {lineTypePreferenceSecondary}</Pill>
      )}
    </Box>
  );
}
