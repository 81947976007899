import { useContext } from "react";
import UIStateContext from "contexts/UIStateContext";

export default function useWorkBlockModal() {
  const { state, setState: updateUIState } = useContext(UIStateContext);
  const {
    workBlockModal: { productionDetailsOpen },
  } = state;

  const setState = (args) => {
    const newState = { ...state.workBlockModal, ...args };
    updateUIState({ workBlockModal: newState });

    return newState;
  };

  return {
    productionDetailsOpen,
    toggleProductionDetails: () => setState({ productionDetailsOpen: !productionDetailsOpen }),
  };
}
