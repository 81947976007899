import React from "react";
import WorkOrderCardStatus from "./WorkOrderCardStatus";
import WorkOrderCardDetails from "./WorkOrderCardDetails";
import WorkOrderCardWrapper from "./WorkOrderCardWrapper";
import ProductionProgressBarOld from "components/pages/schedule/workOrderCards/ProductionProgressBarOld";
import WorkOrderCardMenuOld from "components/pages/schedule/workOrderCards/WorkOrderCardMenuOld";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";
import useSlideOutState from "hooks/state/useSlideOutState";
import useDeleteWorkOrder from "hooks/apollo/workOrder/useDeleteWorkOrder";
import useUnscheduleWorkOrder from "hooks/apollo/workOrder/useUnscheduleWorkOrder";
import Authorization from "policies/Authorization";

export default function WorkOrderCardOld({ workOrder }) {
  const removeWorkOrder = useDeleteWorkOrder();
  const handleUnscheduleWorkOrder = useUnscheduleWorkOrder();
  const updateWorkOrder = useUpdateWorkOrder();
  const policy = Authorization.usePolicy();
  const slideOutState = useSlideOutState();

  const handleOpenWorkOrderDetails = () => {
    slideOutState.openWorkOrderDetails(workOrder.id);
  };

  const handleRemoveWorkOrder = ({ workOrderId }) => {
    const areDetailsOpen = workOrderId === slideOutState.workOrderId;
    if (areDetailsOpen) {
      slideOutState.closeSlideOut();
    }

    removeWorkOrder({ workOrderId });
  };

  const userCanUpdate = policy.canUpdate(Authorization.EntityTypes.WorkOrder);

  return (
    <WorkOrderCardWrapper workOrderId={workOrder.id}>
      <WorkOrderCardDetails
        onOpenWorkOrderDetails={handleOpenWorkOrderDetails}
        workOrder={workOrder}
        workOrderForDetailsViewId={slideOutState.workOrderId}
        userCanUpdate={userCanUpdate}
      />
      <WorkOrderCardStatus workOrder={workOrder} />
      <ProductionProgressBarOld {...workOrder} />
      <WorkOrderCardMenuOld
        onOpenWorkOrderDetails={handleOpenWorkOrderDetails}
        onUnscheduleWorkOrder={handleUnscheduleWorkOrder}
        onRemoveWorkOrder={handleRemoveWorkOrder}
        updateWorkOrder={updateWorkOrder}
        workOrder={workOrder}
        userCanUpdate={userCanUpdate}
      />
    </WorkOrderCardWrapper>
  );
}
