import React from "react";
import { Box, Flex, Text } from "@nulogy/components";
import DatePicker from "components/controls/DatePicker";
import {
  formatDateTime,
  formatEfficiency,
  formatLabor,
  formatPerformance,
  formatProductionRate,
} from "components/helpers/format";
import WorkOrderSelect from "components/controls/WorkOrderSelect";
import LineSelect from "components/controls/LineSelect";
import ProductionDetail from "components/pages/schedule/workBlocks/ProductionDetail";
import ProductionDetailsSummary from "components/pages/schedule/workBlocks/ProductionDetailsSummary";
import Form from "components/controls/Form";
import Section from "components/common/Section";
import LockToggle from "components/controls/LockToggle";
import FormErrors from "components/controls/FormErrors";
import Input from "components/controls/Input";
import FieldRow from "components/common/FieldRow";
import Textarea from "components/controls/Textarea";
import CollapsibleSection from "components/common/CollapsibleSection";
import Field from "components/common/Field";
import DateTime from "utils/DateTime";
import Num from "utils/Num";
import WorkBlock from "domain/WorkBlock";

export default function CreateWorkBlockForm(props) {
  const {
    onClose,
    onSave,
    lines,
    workOrders,
    errors,
    setErrors,
    blockData: { block, startDate, endDate },
    applicationContext,
    workBlockModalUIState,
  } = props;
  const { settings, customerSettings } = applicationContext;
  const { workBlockLayout } = customerSettings;
  const initialWorkOrder = workOrders[0];
  const { line: selectedLine } = block;

  const initialValues = {
    workBlock: {
      ...WorkBlock.buildWithWorkTime({
        line: selectedLine,
        workOrder: initialWorkOrder,
        workTimeStartsAt: DateTime.toISO8601(startDate),
        workTimeEndsAt: DateTime.toISO8601(endDate),
        lockedOnSchedule: false,
        productionRateOverride: "",
        performanceOverride: "",
        efficiencyOverride: "",
        laborOverride: "",
      }),
      teardownTimeDurationHours: 0,
    },
  };

  const { productionDetailsOpen, toggleProductionDetails } = workBlockModalUIState;

  function findSelectedWorkOrder(id) {
    return workOrders.find((workOrder) => workOrder.id === id);
  }

  function findSelectedLine(id) {
    return lines.find((line) => line.id === id) || selectedLine;
  }

  const validate = (data) => {
    const validationErrors = [];

    if (new Date(data.workBlock.workTimeStartsAt) >= new Date(data.workBlock.workTimeEndsAt)) {
      validationErrors.push("End time must be after start time");
    }

    const {
      workBlock: {
        productionRateOverride: inputProductionRateOverride,
        performanceOverride: inputPerformanceOverride,
        efficiencyOverride: inputEfficiencyOverride,
        laborOverride: inputLaborOverride,
        teardownTimeDurationHours: inputTeardownTimeDurationHours,
      },
    } = data;

    if (
      inputProductionRateOverride !== null &&
      inputProductionRateOverride <= 0 &&
      inputProductionRateOverride !== ""
    ) {
      validationErrors.push("Production rate override must be greater than 0");
    }

    if (inputPerformanceOverride !== null && inputPerformanceOverride <= 0 && inputPerformanceOverride !== "") {
      validationErrors.push("Performance override must be greater than 0");
    }

    if (inputEfficiencyOverride !== null && inputEfficiencyOverride <= 0 && inputEfficiencyOverride !== "") {
      validationErrors.push("Efficiency override must be greater than 0");
    }

    if (inputLaborOverride !== null && inputLaborOverride <= 0 && inputLaborOverride !== "") {
      validationErrors.push("Labor override must be greater than 0");
    }

    if (
      inputTeardownTimeDurationHours !== null &&
      inputTeardownTimeDurationHours < 0 &&
      inputTeardownTimeDurationHours !== ""
    ) {
      validationErrors.push("Teardown time must be greater than or equal to 0");
    }

    setErrors({ base: validationErrors });
  };

  const handleSave = (data) =>
    new Promise(() => {
      onSave({
        ...data.workBlock,
        productionRateOverride: data.workBlock.productionRateOverride || null,
        performanceOverride: Num.percentToDecimal(data.workBlock.performanceOverride) || null,
        efficiencyOverride: Num.percentToDecimal(data.workBlock.efficiencyOverride) || null,
        laborOverride: data.workBlock.laborOverride || null,
        __typename: "WorkBlock",
      });
      onClose();
    });

  const onTeardownTimeChange = ({ value, setFieldValue }) => {
    setFieldValue("workBlock[teardownTimeDuration]", DateTime.hoursToSeconds(value));
  };

  const onWorkOrderChange = ({ value: id, setFieldValue }) => {
    const workOrder = findSelectedWorkOrder(id);
    setFieldValue("workBlock[workOrder][notes]", workOrder.notes);
  };

  const calculateEffectiveProductionRate = (values) => {
    const { workBlock } = values;
    const workOrder = findSelectedWorkOrder(workBlock.workOrder.id);
    const line = findSelectedLine(workBlock.line.id);

    return WorkBlock.effectiveProductionRate(
      {
        ...workOrder,
        ...workBlock,
        performanceOverride: Num.percentToDecimal(workBlock.performanceOverride),
        efficiencyOverride: Num.percentToDecimal(workBlock.efficiencyOverride),
        line,
      },
      settings,
    );
  };

  const calculateExpectedQuantity = (values) => {
    const { workBlock } = values;
    const workOrder = findSelectedWorkOrder(workBlock.workOrder.id);
    const line = findSelectedLine(workBlock.line.id);

    return WorkBlock.quantity({
      ...workOrder,
      ...workBlock,
      performanceOverride: Num.percentToDecimal(workBlock.performanceOverride),
      efficiencyOverride: Num.percentToDecimal(workBlock.efficiencyOverride),
      line,
    });
  };

  const calculateLineEfficiency = (values) => {
    const { workBlock } = values;
    const line = findSelectedLine(workBlock.line.id);

    return WorkBlock.lineEfficiency({ line });
  };

  const getUnitOfMeasure = (values) => {
    const { workBlock } = values;
    const workOrder = findSelectedWorkOrder(workBlock.workOrder.id);

    return workOrder.unitOfMeasure;
  };

  const getProductionRateDependsOnLabor = (values) => {
    const { workBlock } = values;
    const workOrder = findSelectedWorkOrder(workBlock.workOrder.id);

    return workOrder.productionRateDependsOnLabor;
  };

  const getStandardProductionRate = (values) => {
    const { workBlock } = values;
    const workOrder = findSelectedWorkOrder(workBlock.workOrder.id);

    return workOrder.standardProductionRate;
  };

  const getPerformance = (values) => {
    const { workBlock } = values;
    const workOrder = findSelectedWorkOrder(workBlock.workOrder.id);

    return workOrder.performance;
  };

  const getStandardLabor = (values) => {
    const { workBlock } = values;
    const workOrder = findSelectedWorkOrder(workBlock.workOrder.id);

    return workOrder.standardLabor;
  };

  const formattedStandardLabor = (values) => formatLabor(getStandardLabor(values));
  const formattedLineEfficiency = (values) => formatEfficiency(calculateLineEfficiency(values));

  if (!workOrders.length) {
    return (
      <Flex backgroundColor="whiteGrey" padding="10px" minHeight="300px" justifyContent="center" alignItems="center">
        <Text fontWeight="bold" fontSize="large">
          No work orders in search results to schedule
        </Text>
      </Flex>
    );
  }

  return (
    <Form id="createWorkBlock" validate={validate} initialValues={initialValues} onSave={handleSave}>
      {({
        values,
        values: {
          workBlock: { lockedOnSchedule },
        },
      }) => (
        <>
          <Section title="Scheduling details" controls={<LockToggle field="workBlock[lockedOnSchedule]" />}>
            {errors.base.length > 0 && <FormErrors errors={errors} />}
            <FieldRow>
              <WorkOrderSelect
                workOrders={workOrders}
                onChange={onWorkOrderChange}
                workBlockLayout={workBlockLayout}
                disabled={lockedOnSchedule}
              />
            </FieldRow>
            <FieldRow>
              <LineSelect lines={lines} disabled={lockedOnSchedule} />
            </FieldRow>
            <FieldRow>
              <Flex className="customDatePicker">
                <Box width={1 / 2} marginRight="x3">
                  <DatePicker
                    field="workBlock[workTimeStartsAt]"
                    labelText="Work starts at"
                    className="spec-work-time-starts-at"
                    disabled={lockedOnSchedule}
                  />
                </Box>
                <Box width={1 / 2} marginLeft="x3">
                  <DatePicker
                    field="workBlock[workTimeEndsAt]"
                    labelText="Work ends at"
                    className="spec-work-time-ends-at"
                    disabled={lockedOnSchedule}
                  />
                </Box>
              </Flex>
            </FieldRow>
          </Section>
          <Section title="Teardown time" className="spec-teardown-time-section">
            <FieldRow>
              <Box marginRight="x3">
                <Input
                  field="workBlock[teardownTimeLabel]"
                  labelText="Teardown label"
                  className="spec-teardown-time-label"
                  disabled={lockedOnSchedule}
                />
              </Box>
              <Input
                data-testid="teardown-time-duration"
                field="workBlock[teardownTimeDurationHours]"
                onChange={onTeardownTimeChange}
                labelText="Teardown duration"
                type="number"
                step="any"
                marginLeft="x3"
                inputWidth="100px"
                suffix="hours"
                disabled={lockedOnSchedule}
              />
            </FieldRow>
            {values.workBlock.teardownTimeDurationHours > 0 && (
              <Flex>
                <Box width={1 / 2} marginRight="x3">
                  <Field labelText="Teardown time starts at">{formatDateTime(values.workBlock.workTimeEndsAt)}</Field>
                </Box>
                <Box width={1 / 2} marginLeft="x3">
                  <Field labelText="Teardown time ends at">
                    {formatDateTime(
                      DateTime.addDuration(
                        values.workBlock.workTimeEndsAt,
                        values.workBlock.teardownTimeDuration,
                        "seconds",
                      ),
                    )}
                  </Field>
                </Box>
              </Flex>
            )}
          </Section>
          <CollapsibleSection
            isOpen={productionDetailsOpen}
            onToggle={toggleProductionDetails}
            title="Production details"
          >
            <ProductionDetail
              label="Standard production rate"
              value={formatProductionRate({
                productionRate: getStandardProductionRate(values),
                unitOfMeasure: getUnitOfMeasure(values),
              })}
              overrideLabel="Production rate override"
              overrideValue={values.workBlock.productionRateOverride}
              units={`${getUnitOfMeasure(values)}/hour`}
              field="productionRateOverride"
              specClassName="spec-base-production-rate"
              disabled={lockedOnSchedule}
            />
            <ProductionDetail
              label="Performance"
              value={formatPerformance(getPerformance(values))}
              overrideLabel="Performance override"
              overrideValue={values.workBlock.performanceOverride}
              units="% performance"
              field="performanceOverride"
              specClassName="spec-performance"
              disabled={lockedOnSchedule}
            />
            <ProductionDetail
              data-testid="standard-labor-detail"
              label="Standard labor"
              value={formattedStandardLabor(values)}
              overrideLabel="Labor override"
              overrideValue={values.workBlock.laborOverride}
              units="people"
              field="laborOverride"
              specClassName="spec-standard-labor"
              disabled={lockedOnSchedule}
              additionalInfo={
                getProductionRateDependsOnLabor(values) ? (
                  <span>Changing labor will affect production rate</span>
                ) : (
                  <span>
                    Changing labor will <strong>not</strong> affect production rate
                  </span>
                )
              }
            />
            <ProductionDetail
              label="Efficiency on line"
              value={formattedLineEfficiency(values)}
              overrideLabel="Efficiency override"
              overrideValue={values.workBlock.efficiencyOverride}
              units="% efficiency"
              field="efficiencyOverride"
              specClassName="spec-line-efficiency"
              disabled={lockedOnSchedule}
            />
            <ProductionDetailsSummary
              effectiveProductionRate={calculateEffectiveProductionRate(values)}
              expectedQuantity={calculateExpectedQuantity(values)}
              unitOfMeasure={getUnitOfMeasure(values)}
            />
          </CollapsibleSection>

          <Section title="Work order notes">
            <Textarea field="workBlock[workOrder][notes]" rows={5} />
          </Section>
        </>
      )}
    </Form>
  );
}
