import React from "react";
import { DatePicker } from "@nulogy/components";
import { useField, useFormikContext } from "formik";

export default function NDSDatePicker({ field: name, onChange, ...ndsProps }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name); // eslint-disable-line no-unused-vars

  const handleChange = (value) => {
    if (onChange) {
      onChange({ value, setFieldValue });
    }
    helpers.setValue(value);
  };

  return (
    <DatePicker
      onChange={handleChange}
      selected={field.value}
      dateFormat="MMMM d, yyyy"
      errorMessage={meta.error}
      {...ndsProps}
    />
  );
}
