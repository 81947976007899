import Block from "domain/Block";

export default function openModal({ onOpenCreateBlock, onOpenEditDowntime, onOpenEditWorkBlock }) {
  return ({ eventRecord, resourceRecord }) => {
    const blockData = eventRecord.data;

    blockData.endDate = eventRecord.endDate;
    blockData.startDate = eventRecord.startDate;

    switch (Block.type(blockData.block)) {
      case "WorkBlock":
        openBlockModal({ eventRecord, blockData, resourceRecord, open: onOpenEditWorkBlock });
        break;
      case "DowntimeBlock":
        openBlockModal({ eventRecord, blockData, resourceRecord, open: onOpenEditDowntime });
        break;
      default:
        openBlockModal({
          blockData: { ...blockData, id: undefined },
          resourceRecord,
          eventRecord,
          open: onOpenCreateBlock,
        });
    }

    return false;
  };
}

function openBlockModal({ blockData, resourceRecord, open, eventRecord }) {
  open({
    ...blockData,
    eventRecord,
    block: {
      ...blockData.block,
      line: resourceRecord.data,
    },
  });
}
