export const QuickNavigationOption = {
  FORWARD: 1,
  BACK: 2,
  TODAY: 3,
  WEEK_START: 4,
  NOW: 5,
};

export const QuickNavigationUserOptionValue = {
  TODAY: QuickNavigationOption.TODAY,
  NOW: QuickNavigationOption.NOW,
  WEEK_START: QuickNavigationOption.WEEK_START,
};

export const QuickNavigationUserOptions = [
  { label: "Week start", value: QuickNavigationUserOptionValue.WEEK_START },
  { label: "Today", value: QuickNavigationUserOptionValue.TODAY },
  { label: "Now", value: QuickNavigationUserOptionValue.NOW },
];
