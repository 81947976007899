import { useState } from "react";
import useApplicationContext from "./apollo/applicationContext/useApplicationContext";

export default function usePendo() {
  const { user, site, settings, role, pendoEnabled, company } = useApplicationContext();
  const [initialized, setInitialized] = useState(false);

  if (pendoEnabled && !initialized) {
    // eslint-disable-next-line
    pendo.initialize({
      visitor: {
        id: user?.id,
        role,
        screenHeight: window.screen.height,
        screenAvailableHeight: window.screen.availHeight,
        screenWidth: window.screen.width,
        screenAvailableWidth: window.screen.availWidth,
      },

      account: {
        id: site?.id,
        companyName: company?.name,
        siteId: site?.id,
        siteName: site?.name,
        siteCreatedAt: site?.createdAt,
        productLedGrowthCustomer: Boolean(settings?.productLedGrowthCustomer),
      },
    });

    setInitialized(true);
  }
}
