import React from "react";
import styled from "styled-components";
import { Box, theme } from "@nulogy/components";

const Message = styled(Box)`
  // This message will only be shown while dragging a block.
  // See '.fill-in-gap-tip' style override.
  display: none;
  position: fixed;
  bottom: -24px;
  left: -3px;
  color: ${theme.colors.black};
  font-size: 12px;
  font-style: italic;
  background: ${theme.colors.white};
  border-radius: 25px;
  padding: 2px 5px;
`;

export default function FillInGapTip() {
  return <Message className="fill-in-gap-tip">Hold &apos;Shift&apos; to use fill-in-the-gap mode</Message>;
}
