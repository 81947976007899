import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import UserEditView from "./UserEditView";
import paths from "config/routePaths";
import useGoBack from "hooks/useGoBack";
import { DeleteUser, GetUser, GetUsers, UpdateUser } from "hooks/apollo/user/user.gql";
import { GetAllRoles } from "hooks/apollo/role/role.gql";
import { GetAllCompanies } from "hooks/apollo/company/company.gql";
import { GetAssignableSites } from "hooks/apollo/site/site.gql";
import Uuid from "utils/Uuid";
import Authorization from "policies/Authorization";

export default function UserEditContainer({ id, siteId }) {
  const { goBack } = useGoBack();
  const [updateUser] = useMutation(UpdateUser, { onCompleted: () => leavePage() });
  const [deleteUser] = useMutation(DeleteUser, { onCompleted: () => leavePage() });
  const { data: userData, loading: userLoading } = useQuery(GetUser, { variables: { id } });
  const { data: roleData, loading: rolesLoading } = useQuery(GetAllRoles);
  const { data: companiesData, loading: companiesLoading } = useQuery(GetAllCompanies);
  const user = userData?.user;
  const initialRole = user?.role;
  const initialCompanyId = user?.company?.id;
  const {
    data: sitesData,
    loading: sitesLoading,
    refetch: refetchSites,
  } = useQuery(GetAssignableSites, {
    variables: { role: initialRole, companyId: initialCompanyId },
  });

  const policy = Authorization.usePolicy();
  const canUpdateCompany = policy.canUpdate(Authorization.EntityTypes.Company);

  const loading = userLoading || rolesLoading || companiesLoading || sitesLoading;

  const handleDelete = () => deleteUser({ variables: { id }, refetchQueries: [{ query: GetUsers }] });

  const handleCancel = () => leavePage();

  const handleSave = (args) => {
    const defaultUser = {
      id: Uuid.generate(),
      email: "",
      password: "",
      role: "",
    };

    const userToSave = { ...defaultUser, ...userData?.user, ...args, companyId: args.company?.id };

    return updateUser({ variables: userToSave, refetchQueries: [{ query: GetUsers }] });
  };

  const leavePage = () => {
    goBack(paths.userIndex);
  };

  if (loading) return null;

  return (
    <UserEditView
      user={user}
      refetchSites={refetchSites}
      onCancel={handleCancel}
      onDelete={handleDelete}
      onSave={handleSave}
      companies={companiesData?.companies || []}
      assignableSites={sitesData?.assignableSites || []}
      roles={roleData?.roles || []}
      initialCompanyId={initialCompanyId}
      initialRole={initialRole}
      siteId={siteId}
      canUpdateCompany={canUpdateCompany}
    />
  );
}
