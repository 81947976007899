import React from "react";
import styled from "styled-components";
import { Icon, theme } from "@nulogy/components";

const ExpanderButton = styled.button`
  align-self: stretch;
  display: block;
  cursor: pointer;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
  width: 35px;
  height: 100%;
  border: 1px solid ${theme.colors.lightGrey};
  z-index: 5;
  &:hover {
    background-color: ${theme.colors.lightBlue};
  }
  & > svg {
    margin-left: -3px;
  }
`;

export default function SidebarExpander({ onToggleSidebar }) {
  return (
    <ExpanderButton type="button" onClick={onToggleSidebar} className="spec-expand-sidebar">
      <Icon icon="leftArrow" />
    </ExpanderButton>
  );
}
