import React from "react";
import { Alert, Box, Heading2, Page, Text } from "@nulogy/components";
import get from "lodash/get";
import UnblockUserButton from "./UnblockUserButton";
import FormButtons from "components/controls/FormButtons";
import Input from "components/controls/Input";
import Select from "components/controls/Select";
import SettingsForm from "components/controls/SettingsForm";

const TURKISH_CHARACTERS = "İÖÜŞÇĞıöüşçğ";
const SUPPORTED_CHARACTERS = [TURKISH_CHARACTERS];

export default function UserEditView({
  refetchSites,
  onCancel,
  onDelete,
  onSave,
  user,
  roles,
  companies,
  assignableSites,
  siteId,
  initialRole,
  initialCompanyId,
  canUpdateCompany,
  fetchAuthenticationUser,
  authenticationUserExists,
}) {
  const initialAllowedSiteIds = siteId ? [siteId] : (user.allowedSites || []).map((site) => site.id);
  const initialValues = {
    ...user,
    confirmPassword: "",
    role: initialRole,
    company: { id: initialCompanyId },
    allowedSiteIds: initialAllowedSiteIds,
  };
  const newRecord = !get(user, "id", null);
  const title = `${newRecord ? "Create" : "Update"} user`;
  const companyOptions = companies.map((company) => ({ value: company.id, label: company.name }));

  const assignableSitesOptions = assignableSites.map((site) => ({
    value: site.id,
    label: `${site.company.name} - ${site.name}`,
  }));

  const handleCompanyChange =
    (role) =>
    ({ value: newCompanyId, setFieldValue }) => {
      setFieldValue("allowedSiteIds", []);
      refetchSites({ role, companyId: newCompanyId });
    };

  const handleRoleChange =
    (companyId) =>
    ({ value: newRole, setFieldValue }) => {
      switch (newRole) {
        case "nulogy_admin":
        case "nulogy_restricted":
          setFieldValue("company[id]", null);
          setFieldValue("allowedSiteIds", []);
          refetchSites({ role: newRole });
          break;
        case "planner":
        case "readonly":
          setFieldValue("company[id]", companyId);
          refetchSites({ role: newRole, companyId });
          break;
        default:
          throw new Error(`Unhandled role: ${newRole}`);
      }
    };

  const emailRegex = `[${SUPPORTED_CHARACTERS.join()}A-Za-z0-9_!#$%&'*+/=?\`{|}~^.-]+@[A-Za-z0-9.-]+`;

  return (
    <Page className="userEditView spec-user-create-edit-page">
      <Heading2>{title}</Heading2>
      <SettingsForm {...{ initialValues, onSave }}>
        {({ values }) => {
          const passwordRequired = !isNulogyUser(values.email) && !authenticationUserExists;

          return (
            <>
              {newRecord && !passwordRequired && (
                <Alert type="informative">
                  <Text paddingBottom="x2">
                    This account already exists in Auth0, or the email you input is a @nulogy address. The user must use
                    their existing password.
                  </Text>

                  <Text>The user will be created, but will not receive a confirmation email.</Text>
                </Alert>
              )}
              <Input
                field="email"
                labelText="Email"
                type="text"
                title="Please provide a valid email address"
                pattern={emailRegex}
                disabled={!newRecord}
                onBlur={fetchAuthenticationUser}
              />
              <Box marginBottom="x3">
                <Select
                  field="role"
                  labelText="Role"
                  className="spec-user-role"
                  onChange={handleRoleChange(values.company.id)}
                  options={roles}
                />
              </Box>
              <Box marginBottom="x2" style={{ width: "440px" }} data-testid="user-company">
                {shouldDisplayCompanySelector(values.role) && (
                  <Select
                    labelText="Company"
                    options={companyOptions}
                    onChange={handleCompanyChange(values.role)}
                    field="company[id]"
                    className="spec-user-company"
                    disabled={!canUpdateCompany && !newRecord}
                  />
                )}
              </Box>
              <Box marginBottom="x3">
                {shouldDisplaySitesSelector(values.role, values.company.id) && (
                  <Select
                    field="allowedSiteIds"
                    labelText="Assigned sites"
                    className="spec-assigned-sites-multiselect"
                    multiselect
                    options={assignableSitesOptions}
                    placeholder="Assign sites..."
                  />
                )}
              </Box>
              <FormButtons
                onCancel={onCancel}
                onDelete={newRecord ? null : onDelete}
                deleteConfirmationMessage="Are you sure you want to delete this user? This action is irreversible."
              />
            </>
          );
        }}
      </SettingsForm>
      {!newRecord && (
        <>
          <hr />
          <Heading2 mt="x2">Authentication</Heading2>
          <UnblockUserButton user={user} />
        </>
      )}
    </Page>
  );
}

function shouldDisplayCompanySelector(role) {
  switch (role) {
    case "nulogy_admin":
    case "nulogy_restricted":
    case undefined:
      return false;
    case "planner":
    case "readonly":
      return true;
    default:
      throw new Error(`Unhandled role: ${role}`);
  }
}

function shouldDisplaySitesSelector(role, companyId) {
  switch (role) {
    case "nulogy_admin":
    case undefined:
      return false;
    case "nulogy_restricted":
      return true;
    case "planner":
    case "readonly":
      return companyId;
    default:
      throw new Error(`Unhandled role: ${role}`);
  }
}

function isNulogyUser(email) {
  return (email || "").endsWith("@nulogy.com");
}
