import React from "react";
import styled from "styled-components";
import { Box, Heading3, Text, theme } from "@nulogy/components";
import WorkOrderCardPreview from "components/pages/schedule/workOrderCards/WorkOrderCardPreview";
import WorkBlockPreview from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkBlockPreview";
import LabelWithTooltip from "components/common/LabelWithTooltip";
import Radio from "components/controls/Radio";
import WorkOrder from "domain/WorkOrder";
import WorkBlock from "domain/WorkBlock";

const SubHeader = styled(Text)`
  font-size: 16px;
  font-weight: ${theme.fontWeights.normal};
  margin-bottom: 4px;
`;

export default function LayoutSettingOld() {
  return (
    <>
      <Box marginBottom="x4">Customize the layout of work blocks and work order cards on your production schedule.</Box>
      <Box marginBottom="x4" data-testid="work-order-card-layout-setting">
        <Heading3 mb="x2">Work orders</Heading3>
        <SubHeader>Select what should be displayed on the second line of a work order card in the sidebar.</SubHeader>
        {WorkOrder.LAYOUTS.map((layout) => {
          const workOrderCardPreview = <WorkOrderCardPreview description={layout.label} />;
          const labelComponent = (
            <LabelWithTooltip label={layout.label} tooltip={workOrderCardPreview} tooltipPlacement="right" />
          );

          return (
            <Radio
              key={`work-order-card-layout-${layout.value}`}
              labelText={labelComponent}
              field="workOrderCardLayout"
              value={layout.value}
              tooltip={workOrderCardPreview}
              tooltipPlacement="right"
              className={`spec-work-order-card-layout-${layout.value}`}
            />
          );
        })}
      </Box>
      <Box marginBottom="x2" data-testid="work-block-layout-settings">
        <Heading3 mb="x2">Work blocks</Heading3>
        <SubHeader>Select what should be displayed on the first line of a work block.</SubHeader>
        {WorkBlock.LAYOUTS.map((layout) => {
          const blockPreview = <WorkBlockPreview heading={layout.label} />;
          const labelComponent = (
            <LabelWithTooltip label={layout.label} tooltip={blockPreview} tooltipPlacement="right" />
          );

          return (
            <Radio
              key={`work-block-layout-${layout.value}`}
              labelText={labelComponent}
              field="workBlockLayout"
              value={layout.value}
              tooltip={blockPreview}
              tooltipPlacement="right"
              className={`spec-work-block-layout-${layout.value}`}
            />
          );
        })}
      </Box>
    </>
  );
}
