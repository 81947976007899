import Block from "domain/Block";

export default function handleEventClick(fullyScheduleWorkOrder, handleOpenNotesPopUp, readonly) {
  return ({ event, eventRecord, source }) => {
    if (openMenu({ event, eventRecord, source })) return;

    if (readonly) return;

    const { block } = eventRecord.data;
    if (Block.isWorkBlock(block)) {
      toggleQuickActions({ event, eventRecord, fullyScheduleWorkOrder, handleOpenNotesPopUp });
    }
  };
}

export function openMenu({ event, eventRecord, source }) {
  if (event.target.classList.contains("js-block-menu")) {
    source.features.eventMenu.showContextMenuFor(eventRecord, {
      targetElement: event.target,
    });

    return true;
  }

  return false;
}

function toggleQuickActions({ event, eventRecord, fullyScheduleWorkOrder, handleOpenNotesPopUp }) {
  const { block } = eventRecord.data;
  const workBlockLocked = block.lockedOnSchedule;
  const workBlockId = block.id;
  const workOrderId = block.workOrder.id;
  const quickActions = document.getElementById(`work-block-${workBlockId}-quick-actions`);
  const quickActionsAreHidden = quickActions.classList.contains("hidden");

  hideAllVisibleQuickActions();

  const fullyScheduleIconClicked =
    event.target.classList.contains("fully-schedule-icon") ||
    event.composedPath()[1]?.classList.contains("fully-schedule-icon");

  const editNotesIconClicked =
    event.target.classList.contains("edit-notes-icon") ||
    event.composedPath()[1]?.classList.contains("edit-notes-icon");

  if (quickActionsAreHidden && !workBlockLocked) {
    quickActions.classList.remove("hidden");
  } else if (fullyScheduleIconClicked) {
    fullyScheduleWorkOrder({ eventRecord });
  } else if (editNotesIconClicked) {
    handleOpenNotesPopUp({ workOrderId, workBlockId });
  }
}

function hideAllVisibleQuickActions() {
  Array.from(document.getElementsByClassName("quick-actions")).forEach((el) => {
    el.classList.add("hidden");
  });
}
