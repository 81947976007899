import React from "react";
import { Box, Text } from "@nulogy/components";

export default function MoreResultsMessage() {
  return (
    <Box marginTop="x4">
      <Text textAlign="center" fontSize="small">
        There may be more work orders.
      </Text>
      <Text textAlign="center" fontSize="small">
        Use search to find more.
      </Text>
    </Box>
  );
}
