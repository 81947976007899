import React, { useRef } from "react";
import { Button, ButtonGroup, Modal, PrimaryButton } from "@nulogy/components";
import useAvailabilityOverridesUnsavedChangesModal from "hooks/state/useAvailabilityOverridesUnsavedChangesModal";

export default function AvailabilityOverridesUnsavedChangesModal({ onClose }) {
  const { close: closeModal, isOpen, startDate, endDate } = useAvailabilityOverridesUnsavedChangesModal();
  const submitButton = useRef();

  const submit = () => {
    submitButton.current.click();
  };

  const close = () => {
    closeModal();
    onClose(startDate, endDate);
  };

  const footerContent = (
    <ButtonGroup>
      <PrimaryButton onClick={submit} className="spec-save-changes-in-modal">
        Save and apply to schedule
      </PrimaryButton>
      <Button onClick={close}>Discard changes</Button>
    </ButtonGroup>
  );

  return (
    <>
      <input type="submit" style={{ display: "none" }} ref={submitButton} />
      <Modal
        title="Unsaved changes"
        footerContent={footerContent}
        isOpen={isOpen}
        onRequestClose={close}
        ariaHideApp={false}
      >
        Changes will be discarded if you navigate to a different date before saving.
      </Modal>
    </>
  );
}
