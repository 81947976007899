import { useContext } from "react";
import * as Sentry from "@sentry/react";
import useApplicationContext from "../apollo/applicationContext/useApplicationContext";
import UIStateContext from "contexts/UIStateContext";
import DateTime from "utils/DateTime";
import ApplicationContext from "domain/ApplicationContext";
import { QuickNavigationOption, QuickNavigationUserOptionValue } from "config/quickNavigationOptions";
import { timeIncrement, zoomLevelDurationInHours } from "config/zoomConfig";

export default function useSchedulerState() {
  const { state, setState: updateUIState, getState } = useContext(UIStateContext);
  const applicationContext = useApplicationContext();

  const setState = (args) => {
    const newState = { ...state.scheduler, ...args };

    updateUIState({ scheduler: newState });
  };

  const getLatestState = () => {
    const latestState = getState().scheduler;

    return {
      ...latestState,
      quickNavigationOption: normalizeQuickNavigationOption(latestState.quickNavigationOption),
      extendedView: applicationContext.user?.userPreferences.extendedView,
    };
  };

  const updateStateAfter = (action) => (args) => {
    const currentState = getLatestState();
    const newState = action({ applicationContext, ...currentState, ...args });

    setState(newState);

    return newState;
  };

  return {
    state: getLatestState(),
    setState,
    getLatestState,
    changeVisibleStartDate: updateStateAfter(doChangeVisibleStartDate),
    changeDataTimeRange: updateStateAfter(doChangeDataTimeRange),
    changeVisibleTimeRange: updateStateAfter(doChangeVisibleTimeRange),
    changeZoomLevel: updateStateAfter(doChangeZoomLevel),
    extendedViewChanged: updateStateAfter(doExtendedViewChanged),
    quickNavigate: updateStateAfter(doMoveTimeRange),
    zoomIn: updateStateAfter(doZoomIn),
  };

  // Expects { extendedView: TRUE | FALSE }
  function doExtendedViewChanged(props) {
    const { extendedView, visibleTimeRange } = props;

    if (!extendedView) {
      return {
        ...props,
        dataTimeRange: {
          startDate: new Date(visibleTimeRange.startDate),
          endDate: calculateEndDate({ startDate: new Date(visibleTimeRange.startDate), zoomLevel: props.zoomLevel }),
        },
      };
    }

    return {
      ...props,
    };
  }

  // Expects { startDate: date, useStartOfDay: TRUE | FALSE }
  function doChangeVisibleStartDate(props) {
    const { startDate, zoomLevel, useStartOfDay = true } = props;

    const newStartDate = useStartOfDay
      ? DateTime.toISO8601(ApplicationContext.startOfWorkDay(applicationContext, startDate))
      : startDate;
    const hours = zoomLevelDurationInHours(zoomLevel);

    const newEndDate = DateTime.addDuration(newStartDate, hours, "hours");

    return {
      ...props,
      visibleTimeRange: { startDate: new Date(newStartDate), endDate: new Date(newEndDate) },
      dataTimeRange: {
        startDate: new Date(newStartDate),
        endDate: calculateEndDate({ startDate: newStartDate, zoomLevel: props.zoomLevel }),
      },
    };
  }

  // Expects { dataTimeRange: { startDate: date, endDate: date } }
  function doChangeDataTimeRange(props) {
    const {
      dataTimeRange: { startDate, endDate },
    } = props;

    return {
      ...props,
      dataTimeRange: {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      },
    };
  }

  // Expects { visibleTimeRange: { startDate: date, endDate: date } }
  function doChangeVisibleTimeRange(props) {
    return {
      ...props,
      visibleTimeRange: {
        startDate: new Date(props.visibleTimeRange.startDate),
        endDate: new Date(props.visibleTimeRange.endDate),
      },
    };
  }

  // Expects { zoomLevel: number }
  function doChangeZoomLevel(props) {
    const {
      zoomLevel,
      dataTimeRange: { startDate },
    } = props;

    const newStartDate = normalizeDate(startDate, zoomLevel);
    const newEndDate = calculateEndDate({ startDate: newStartDate, zoomLevel });

    return {
      ...props,
      dataTimeRange: {
        startDate: newStartDate,
        endDate: newEndDate,
      },
      zoomLevel,
    };
  }

  // Expects { quickNavigationOption: QuickNavigationOption }
  function doMoveTimeRange(props) {
    const { quickNavigationOption, zoomLevel } = props;
    const {
      quickNavigationOption: currentQuickNavigationOption,
      visibleTimeRange: { startDate: currentVisibleStartDate },
    } = getLatestState();

    let newQuickNavigationOption = currentQuickNavigationOption;
    if (Object.values(QuickNavigationUserOptionValue).includes(quickNavigationOption)) {
      newQuickNavigationOption = quickNavigationOption;
    }

    const newStartDate = calculateStartDateToMoveTo({
      currentVisibleStartDate,
      quickNavigationOption,
      zoomLevel,
    });

    return {
      ...props,
      quickNavigationOption: newQuickNavigationOption,
      visibleTimeRange: {
        startDate: newStartDate,
        endDate: calculateVisibleEndDate({ startDate: newStartDate, zoomLevel }),
      },
      dataTimeRange: {
        startDate: newStartDate,
        endDate: calculateEndDate({ startDate: newStartDate, zoomLevel }),
      },
    };
  }

  // Expects { startDate: date }
  function doZoomIn(props) {
    const { startDate, zoomLevel } = props;
    const newZoomLevel = zoomLevel - 1;

    const newStartDate = DateTime.toISO8601(ApplicationContext.startOfWorkDay(applicationContext, startDate));

    return {
      ...props,
      zoomLevel: newZoomLevel,
      visibleTimeRange: { startDate: new Date(newStartDate), endDate: new Date(newStartDate) },
      dataTimeRange: {
        startDate: newStartDate,
        endDate: calculateEndDate({ startDate: newStartDate, zoomLevel: newZoomLevel }),
      },
    };
  }

  function normalizeDate(returnDate, zoomLevel) {
    return DateTime.roundDownToNearestIncrement(returnDate, timeIncrement(zoomLevel));
  }

  // Expects { quickNavigationOption: QuickNavigationOption }
  function calculateStartDateToMoveTo({ currentVisibleStartDate, quickNavigationOption, zoomLevel }) {
    let returnDate;
    const hours = zoomLevelDurationInHours(zoomLevel);

    switch (quickNavigationOption) {
      case QuickNavigationOption.FORWARD:
        returnDate = new Date(DateTime.addDuration(currentVisibleStartDate, hours, "hours"));
        break;
      case QuickNavigationOption.BACK:
        returnDate = new Date(DateTime.subtractDuration(currentVisibleStartDate, hours, "hours"));
        break;
      case QuickNavigationOption.NOW:
        returnDate = new Date(DateTime.now());
        break;
      case QuickNavigationOption.WEEK_START:
        returnDate = new Date(ApplicationContext.startOfWorkWeek(applicationContext, DateTime.now()));
        break;
      case QuickNavigationOption.TODAY:
        returnDate = new Date(ApplicationContext.startOfWorkDay(applicationContext, DateTime.now()));
        break;
      default:
        Sentry.captureMessage(`Unexpected quickNavigationOption: ${quickNavigationOption}`, {});
        returnDate = new Date(DateTime.now());
    }

    return normalizeDate(returnDate, zoomLevel);
  }

  function calculateEndDate({ startDate }) {
    return new Date(DateTime.addDuration(startDate, "14", "days"));
  }

  function calculateVisibleEndDate({ startDate, zoomLevel }) {
    return new Date(DateTime.addDuration(startDate, zoomLevelDurationInHours(zoomLevel), "hours"));
  }

  function normalizeQuickNavigationOption(quickNavigationOption) {
    switch (quickNavigationOption) {
      case QuickNavigationUserOptionValue.WEEK_START:
      case QuickNavigationUserOptionValue.TODAY:
      case QuickNavigationUserOptionValue.NOW:
        return quickNavigationOption;
      default:
        return QuickNavigationUserOptionValue.TODAY;
    }
  }
}
