import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useBryntumScheduler() {
  const { state, setState: updateGlobalState, getState: getGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.bryntumState, ...args };
    updateGlobalState({ bryntumState: newState });
  };

  const setBryntumScheduler = (schedulerInstance) => setState({ schedulerInstance });

  const getBryntumScheduler = () => getGlobalState().bryntumState.schedulerInstance;

  return {
    getBryntumScheduler,
    setBryntumScheduler,
  };
}
