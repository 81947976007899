import React from "react";
import styled from "styled-components";
import { theme } from "@nulogy/components";

const ProgressBar = styled.div`
  height: 3px;
  z-index: 0;
  background-color: ${theme.colors.green};
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
  width: ${({ producedProgressBarWidth }) => `${producedProgressBarWidth}%`};
`;

export default function ProductionProgressBarOld({ producedQuantity, quantity }) {
  const producedProgressBarWidth = Math.min((producedQuantity / quantity) * 100, 100);

  return <ProgressBar producedProgressBarWidth={producedProgressBarWidth} />;
}
