import React from "react";
import { LoadingAnimation } from "@nulogy/components";
import styled from "styled-components";
import Delay from "react-delay";

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const FadedBackground = styled.div`
  background: white;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LoadingPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 240px;
  margin: auto;
`;

export default function ApolloLoadingAnimation({ fetching }) {
  if (!fetching) return null;

  return (
    <Delay wait={500}>
      <LoadingWrapper>
        <FadedBackground />
        <LoadingPosition>
          <LoadingAnimation />
        </LoadingPosition>
      </LoadingWrapper>
    </Delay>
  );
}
