import WorkBlock from "./WorkBlock";
import DowntimeBlock from "./DowntimeBlock";
import Timespan from "utils/Timespan";

const Block = {
  blockTime,
  buildWithBlockTime,
  isDowntime,
  isWorkBlock,
  type,
  updateBlockTime,
};

function isDowntime(block) {
  return block.__typename === "DowntimeBlock";
}

function isWorkBlock(block) {
  return block.__typename === "WorkBlock";
}

function type(block) {
  if (!block) return null;

  return block.__typename;
}

function blockTime(block) {
  const { startsAt, endsAt } = block;

  return new Timespan(new Date(startsAt), new Date(endsAt));
}

function buildWithBlockTime(block) {
  switch (block.__typename) {
    case "DowntimeBlock":
      return block;
    case "WorkBlock":
      return WorkBlock.buildWithBlockTime(block);
    default:
      throw new Error(`Invalid type specified of ${block.__typename}`);
  }
}

function updateBlockTime(block, { startsAt, endsAt }) {
  switch (block.__typename) {
    case "DowntimeBlock":
      return DowntimeBlock.updateBlockTime(block, { startsAt, endsAt });
    case "WorkBlock":
      return WorkBlock.updateBlockTime(block, { startsAt, endsAt });
    default:
      throw new Error(`Invalid type specified of ${block.__typename}`);
  }
}

export default Block;
