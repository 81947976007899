import React from "react";
import { Box, Icon, Card as NDSCard, Text, theme } from "@nulogy/components";
import styled from "styled-components";
import TruncatedText from "components/common/TruncatedText";

const BlockMenu = styled(Icon)({
  position: "absolute",
  top: theme.sizes.x1,
  right: theme.sizes.x1,
});

const Card = styled(NDSCard)`
  padding: 4px 4px 4px 8px;
  min-width: 300px;
`;

const Wrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 290px;
  padding-left: 20px;
`;

const DragIcon = styled.div`
  position: absolute;
  top: 8px;
  left: 4px;
  color: ${theme.colors.darkBlue};
`;

export default function WorkOrderCardPreview({ description }) {
  return (
    <Card>
      <DragIcon>
        <Icon icon="drag" />
      </DragIcon>
      <Wrapper>
        <Text inline color="blue" fontSize="small" fontWeight="bold">
          Work order code
        </Text>
        <Text inline fontWeight="bold" marginX="4px">
          |
        </Text>
        <Text inline fontSize="small">
          Customer
        </Text>
        <Box paddingRight="10px">
          <TruncatedText fontSize="small">{description}</TruncatedText>
        </Box>
        <Box paddingRight="10px">
          <TruncatedText fontSize="small">Item code</TruncatedText>
        </Box>
      </Wrapper>
      <BlockMenu icon="more" />
    </Card>
  );
}
