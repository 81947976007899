import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { DeleteShift } from "./shift.gql";
import { useNotificationState } from "hooks/state";
import GqlError from "utils/GqlError";

export default function useDeleteShift() {
  const { setNotification } = useNotificationState();
  const [deleteShift] = useMutation(DeleteShift, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to delete shift",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: ({ deleteShift: { name } }) => {
      setNotification({
        title: `${name} was successfully deleted.`,
        type: "success",
      });
    },
  });

  return (options) => deleteShift(options);
}
