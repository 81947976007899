import { CartesianGrid, Line, LineChart, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts";
import React from "react";
import { theme } from "@nulogy/components";

export default function LaborChart(props) {
  const { data } = props;

  const yAxisLabel = (
    <Text x={-30} y={0} dx={50} dy={180} offset={0} angle={-90}>
      Number of people
    </Text>
  );

  return (
    <div>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 50,
            left: 50,
            bottom: 250,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" angle={-45} textAnchor="end" />
          <YAxis label={yAxisLabel} />
          <Tooltip />
          <Line type="monotone" dataKey="Max labor" key="Max labor" stroke={theme.colors.red} />
          <Line type="monotone" dataKey="Starting labor" key="Starting labor" stroke={theme.colors.blue} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
