import React from "react";
import * as Sentry from "@sentry/react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  Observable,
  createHttpLink,
  split,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import mapValues from "lodash/mapValues";
import { ConnectionMonitor, createConsumer } from "@rails/actioncable";
import { getMainDefinition } from "@apollo/client/utilities"; // eslint-disable-line import/no-extraneous-dependencies
import ActionCableLink from "./ActionCableLink";
import TimeZone from "utils/TimeZone";
import endpoints from "config/endpoints";

ConnectionMonitor.staleThreshold = 30;
const cable = createConsumer(endpoints.cable);

const onDisconnect = (props) => {
  if (!props.willAttemptReconnect) {
    document.location.replace(endpoints.login);
  }
};

const actionCableLink = new ActionCableLink({ cable, onDisconnect });
const mergeOverriddenTimeZone = (existing, incoming) => TimeZone.overrideToBrowserTime(incoming);

const TIME_FIELDS = {
  Company: ["createdAt", "updatedAt"],
  DowntimeBlock: ["startsAt", "endsAt"],
  ShiftInstance: ["scheduledStart", "scheduledEnd"],
  Site: ["lastSyncedAt", "createdAt", "updatedAt"],
  SyncResult: ["lastSyncedAt"],
  Unavailability: ["startsAt", "endsAt"],
  User: ["createdAt", "updatedAt"],
  WorkBlock: ["startsAt", "endsAt", "workTimeStartsAt", "workTimeEndsAt"],
  WorkOrder: ["due", "productionLastUpdatedAt"],
};

const typePolicies = mapValues(TIME_FIELDS, (fields) => ({
  fields: fields.reduce((acc, field) => {
    acc[field] = { merge: mergeOverriddenTimeZone };

    return acc;
  }, {}),
}));

typePolicies.ShiftInstance.keyFields = ["shiftId", "scheduledStart"];

const cache = new InMemoryCache({ typePolicies });

const isValidationError = (response) => response.errors.every((error) => error.message === "Validation Error");
const isAuthError = (error) => error.statusCode === 401 || error.statusCode === 403;

const handleError = (error) => {
  if (isAuthError(error)) {
    document.location.replace(endpoints.login);

    return Observable.of();
  }

  return undefined;
};

const authLink = onError(({ networkError, operation, response }) => {
  if (networkError) return handleError(networkError);
  if (isValidationError(response)) return undefined;

  Sentry.captureMessage(`GraphQL Error: ${operation.operationName}`, {
    extra: {
      operationName: operation.operationName,
      variables: operation.variables,
      errors: response.errors.map(({ message }) => message),
    },
  });

  return undefined;
});

const httpLink = createHttpLink({ credentials: "include", uri: endpoints.graphql });

function isGraphqlSubscription({ query }) {
  const definition = getMainDefinition(query);

  return definition.kind === "OperationDefinition" && definition.operation === "subscription";
}

const splitLink = split(isGraphqlSubscription, actionCableLink, httpLink);

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, splitLink]),
});

function GqlProviderWrapper(props) {
  return (
    <ApolloProvider {...{ client }} {...{ cache }}>
      {props.children}
    </ApolloProvider>
  );
}

export default GqlProviderWrapper;
