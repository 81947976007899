import { useMutation } from "@apollo/client";
import omit from "lodash/omit";
import { UpdateDefaultAvailabilities } from "./defaultAvailability.gql";

export default function useUpdateDefaultAvailabilities() {
  const [updateDefaultAvailabilities] = useMutation(UpdateDefaultAvailabilities);

  return (defaultAvailabilities) => {
    const defaultAvailabilitiesInput = defaultAvailabilities.map((defaultAvailability) => {
      const { shift, line, ...days } = defaultAvailability;

      return {
        lineId: line.id,
        shiftId: shift.id,
        ...omit(days, "__typename"),
      };
    });

    return updateDefaultAvailabilities({
      variables: { defaultAvailabilities: defaultAvailabilitiesInput },
    });
  };
}
