import filter from "lodash/filter";
import { GetDowntimeReasons } from "hooks/apollo/downtimeReason/downtimeReason.gql";

export function insertDowntimeReason(newDowntimeReason, proxy) {
  const downtimeReasons = read(proxy);

  const updatedDowntimeReasons = [newDowntimeReason].concat(
    filter(downtimeReasons, (existingDowntimeReason) => existingDowntimeReason.id !== newDowntimeReason.id),
  );

  write(updatedDowntimeReasons, proxy);
}

export function removeDowntimeReason(downtimeReasonId, proxy) {
  const internalDowntimeReason = proxy.identify({ id: downtimeReasonId, __typename: "DowntimeReason" });
  proxy.evict({ id: internalDowntimeReason });
  proxy.gc();
}

function read(proxy) {
  const { downtimeReasons } = proxy.readQuery({ query: GetDowntimeReasons });

  return downtimeReasons;
}

function write(downtimeReasons, proxy) {
  proxy.writeQuery({
    query: GetDowntimeReasons,
    data: { downtimeReasons },
  });
}
