import EventTypes from "hooks/apollo/usageData/EventTypes";
import fillInGapTimespan from "components/pages/schedule/helpers/fillInGapTimespan";
import Block from "domain/Block";
import WorkBlock from "domain/WorkBlock";
import DateTime from "utils/DateTime";

export default function dropToReschedule({ gapFiller, onRescheduleBlocks, onTrackUsage }) {
  return ({ eventRecords, valid, context }) => {
    if (!valid) {
      gapFiller.clearDragState();

      return;
    }

    if (isMultiScheduling({ eventRecords })) {
      gapFiller.clearDragState();
    }

    const blocks = eventRecords.map((eventRecord) => {
      const { data: blockData } = eventRecord;
      const draggedEntity = context.draggedEntities.find((entity) => entity.resourceId === blockData.resourceId);
      const line = draggedEntity?.resource;

      if (!line) {
        gapFiller.clearDragState();

        return null;
      }

      blockData.endDate = calculateEndDate({ gapFiller, blockData, line });

      const updatedBlockData = adjustTimespan({ gapFiller, blockData, line });

      const lineId = line.id;
      const updatedBlock = Block.updateBlockTime(blockData.block, {
        startsAt: DateTime.toISO8601(updatedBlockData.startDate),
        endsAt: DateTime.toISO8601(updatedBlockData.endDate),
      });

      eventRecord.setStartDate(updatedBlockData.startDate);
      eventRecord.setEndDate(updatedBlockData.endDate);
      eventRecord.commitAsync();

      return {
        ...updatedBlock,
        line: { id: lineId },
        lineEfficiency: line.efficiency,
      };
    });

    onRescheduleBlocks(blocks);

    if (gapFiller.isActive()) {
      onTrackUsage(EventTypes.FILL_IN_GAP);
    }

    gapFiller.clearDragState();
  };
}

function isMultiScheduling({ eventRecords }) {
  return eventRecords.length > 1;
}

function calculateEndDate({ gapFiller, blockData: { block, startDate, endDate }, line }) {
  if (!Block.isWorkBlock(block)) return endDate;
  const durationInSeconds = blockDurationForLine(block, line, gapFiller);

  return new Date(startDate.getTime() + durationInSeconds * 1000);
}

function adjustTimespan({ gapFiller, blockData, line }) {
  const { block, startDate: startTime, endDate: endTime } = blockData;
  const timespanWithinBlock = {
    startTime,
    endTime,
  };

  const timespanWithinGap = fillInGapTimespan({ block, gapFiller, line: line.data });

  const timespan = timespanWithinGap || timespanWithinBlock;

  return { ...blockData, startDate: timespan.startTime, endDate: timespan.endTime };
}

function blockDurationForLine(block, line, gapFiller) {
  let durationInSeconds = WorkBlock.calculateBlockDurationForLine(block, line.data, gapFiller._shouldFillInGap());

  // HACK: Ensure valid block endDate so that backend mutation can generate
  // a validation error. This is for the odd edge case of when the workBlock
  // has an effectiveProductionRate of 0. This is considered to be a hack
  // because the frontend has all the information it needs to generate the
  // validation. We just haven't put in the time to implement the validation
  // on the frontend.
  if (Number.isNaN(durationInSeconds)) {
    durationInSeconds = 0;
  }

  return durationInSeconds;
}
