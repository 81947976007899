import { theme } from "@nulogy/components";
import styled from "styled-components";
import Modal from "components/common/Modal";

const BlockModal = styled(Modal)`
  box-shadow: ${theme.shadows.small};

  div[class*="Modal__ModalContent-"] {
    padding-bottom: 25px;
    margin-bottom: 0;
  }

  /* controls padding around the up/down arrow on number fields */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: 4px;
  }
`;

export default BlockModal;
