import React from "react";
import CustomWorkOrderFieldsView from "./CustomWorkOrderFieldsView";
import useGetAllCustomWorkOrderFields from "hooks/apollo/customWorkOrderField/useGetCustomWorkOrderFields";
import useCreateOrUpdateCustomWorkOrderField from "hooks/apollo/customWorkOrderField/useCreateOrUpdateCustomWorkOrderField";
import routePaths from "config/routePaths";

function CustomWorkOrderFieldsContainer() {
  const { customWorkOrderFields, loading } = useGetAllCustomWorkOrderFields();
  const createOrUpdateCustomWorkOrderField = useCreateOrUpdateCustomWorkOrderField();

  if (loading) return null;

  const handleSave = async (fields) => {
    const promises = [];

    Object.entries(fields).forEach(([, field]) => {
      Object.entries(field).forEach(([name, color]) => {
        promises.push(createOrUpdateCustomWorkOrderField({ name, color }));
      });
    });

    await Promise.all(promises);
    window.location.href = routePaths.home;
  };

  return <CustomWorkOrderFieldsView handleSave={handleSave} customWorkOrderFields={customWorkOrderFields} />;
}

export default CustomWorkOrderFieldsContainer;
