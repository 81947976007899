import styled from "styled-components";
import { theme } from "@nulogy/components";

const UnstyledAnchor = styled.a`
  font-weight: bold;
  color: ${theme.colors.blue} !important;
  text-decoration: none;
  &:hover {
    color: ${theme.colors.darkBlue} !important;
    text-decoration: underline;
  }
`;

export default UnstyledAnchor;
