import React from "react";
import * as Sentry from "@sentry/react";
import Server from "config/Server";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    const sentryDsn = Server.enableSentry()
      ? "https://ede0893f7c8c4ce29b07f32493120148@o449074.ingest.sentry.io/5431421"
      : "";

    const beforeBreadcrumb = (breadcrumb, hint) => {
      if (breadcrumb.category === "fetch" && hint.input[0].endsWith("backend/graphql")) {
        const { operationName } = JSON.parse(hint.input[1].body);
        const data = { ...breadcrumb.data, operationName };

        return { ...breadcrumb, data };
      }

      return breadcrumb;
    };

    Sentry.init({
      beforeBreadcrumb,
      dsn: sentryDsn,
      environment: Server.environmentName(),
      // The ignoreErrors list comes from the Sentry article here:
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      ignoreErrors: [
        // HACK: Disable errors caused by Bryntum until we can diagnose and resolve.
        // Reference: https://forum.bryntum.com/viewtopic.php?t=23738
        "Cannot read properties of null (reading 'hasLinks')",
        // This error gets thrown when we subscribe because actioncable returns an empty data object
        // Subscriptions work fine, so we can ignore this error for now
        // Reference: https://github.com/apollographql/apollo-client/issues/8677
        // Actioncable comment: https://github.com/apollographql/apollo-client/issues/8677#issuecomment-2038479343
        /Missing field .* while writing result .*\w+/,
      ],
    });
  }

  componentDidCatch(error, _info) {
    this.setState({ hasError: true });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1>There was a server error. Please try again. If this problem persists, please contact Nulogy Support.</h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
