import useGetLineFromCache from "hooks/apollo/line/useGetLineFromCache";
import useGetWorkOrderFromCache from "hooks/apollo/workOrder/useGetWorkOrderFromCache";
import useGetWorkBlockFromCache from "hooks/apollo/workBlock/useGetWorkBlockFromCache";

export default function useBlockFactory() {
  const workBlockBuilder = useWorkBlockBuilder();
  const downtimeBuilder = useDowntimeBuilder();

  return (block) => {
    if (block.__typename === "WorkBlock") {
      return workBlockBuilder(block);
    } else {
      return downtimeBuilder(block);
    }
  };
}

function useWorkBlockBuilder() {
  const getWorkBlockFromCache = useGetWorkBlockFromCache();
  const getWorkOrderFromCache = useGetWorkOrderFromCache();
  const getLineFromCache = useGetLineFromCache();

  return (block) => {
    const cachedWorkBlock = getWorkBlockFromCache(block.id);
    const workOrder = getWorkOrderFromCache(block.workOrder.id);

    return {
      ...block,
      workOrder,
      line: getLineFromCache(block.line.id),
      previousWorkTimeStartsAt: cachedWorkBlock?.workTimeStartsAt,
      previousWorkTimeEndsAt: cachedWorkBlock?.workTimeEndsAt,
      __typename: "WorkBlock",
    };
  };
}

function useDowntimeBuilder() {
  const getLineFromCache = useGetLineFromCache();

  return (block) => ({
    ...block,
    line: getLineFromCache(block.line.id),
    __typename: "DowntimeBlock",
  });
}
