import { useQuery } from "@apollo/client";
import ApplicationLayoutView from "./ApplicationLayoutView";
import waitForQueries from "components/waitForQueries";
import useGetPermissions from "hooks/apollo/permissions/useGetPermissions";
import { GetApplicationContext } from "hooks/apollo/applicationContext/applicationContext.gql";

function ApplicationLayoutContainer(props) {
  const queries = [useQuery(GetApplicationContext), useGetPermissions()];

  return waitForQueries({ queries, topLevel: true })(ApplicationLayoutView)(props);
}

export default ApplicationLayoutContainer;
