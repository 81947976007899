import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useBlockCreateModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.createBlockModalState, ...args };
    updateGlobalState({ createBlockModalState: newState });
  };

  return {
    open: (block) => setState({ isOpen: true, block }),
    close: () => {
      if (state.createBlockModalState.block.eventRecord) {
        state.createBlockModalState.block.eventRecord.remove();
      }

      setState({ isOpen: false, block: null, eventRecord: null });
    },
    block: state.createBlockModalState.block,
    isOpen: state.createBlockModalState.isOpen,
  };
}
