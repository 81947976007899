function calculateColumnWidths(shifts) {
  const shiftsColumnWidths = shifts.length * (columnWidth(shifts.length) + 6) - 8;
  const firstColumnOffset = -2 * (shifts.length - 1) + 2;
  const normalColumnWidth = 2 * shifts.length + 1 + shiftsColumnWidths;
  const firstColumnWidth = shiftsColumnWidths - firstColumnOffset;

  return [firstColumnWidth, normalColumnWidth];
}

function calculateTotalTableWidth(shifts, dates) {
  const [firstColumnWidth, normalColumnWidth] = calculateColumnWidths(shifts);
  const leftSpacing = 107;

  return (dates.length - 1) * normalColumnWidth + firstColumnWidth + leftSpacing;
}

function calculateDateWidthFromShifts(index, shifts) {
  const first = index === 0;
  const [firstColumnWidth, normalColumnWidth] = calculateColumnWidths(shifts);

  return first ? firstColumnWidth : normalColumnWidth;
}

function columnWidth(shiftCount) {
  return shiftCount === 1 ? 120 : 80;
}

export { calculateTotalTableWidth, calculateDateWidthFromShifts, columnWidth };
