import React from "react";
import styled from "styled-components";
import Authorization from "policies/Authorization";
import paths from "config/routePaths";
import UnstyledAnchor from "components/common/UnstyledAnchor";

const Tooltip = styled.div`
  max-width: 100%;
  overflow: auto;
  word-wrap: break-word;
  display: inline-block;
  font-size: 12px;
`;

const AvailabilityOverridesLink = styled(UnstyledAnchor)({
  fontWeight: "normal",
});

export default function UnavailabilityTooltip({ activeTarget, policy }) {
  const [, line, shift] = activeTarget.dataset.eventId.match(/resourcetimerange-\$(.*)\$(.*)\$-(.*)/);

  const renderDefaultAvailabilityLink = () => {
    if (!policy.canUpdate(Authorization.EntityTypes.DefaultAvailability)) {
      return null;
    }

    return (
      <>
        To change its availability, go to{" "}
        <AvailabilityOverridesLink
          data-testid="availability-overrides-link"
          target="_blank"
          href={paths.availabilityOverrides}
        >
          Availability overrides
        </AvailabilityOverridesLink>
        .
      </>
    );
  };

  return (
    <Tooltip data-testid="tooltip">
      {shift} on {line} is not available. {renderDefaultAvailabilityLink()}
    </Tooltip>
  );
}
