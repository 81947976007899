import React from "react";
import { TruncatedText } from "@nulogy/components";
import { ExecutionSummary, PublishResultSection } from "./PublishSummaryModalComponents";
import PackmanagerJobLink from "components/pages/schedule/PackmanagerJobLink";
import { formatShortDateTime } from "components/helpers/format";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";

function formatTime({ cellData }) {
  return formatShortDateTime(cellData);
}

function renderWorkOrder({ cellData }) {
  return <TruncatedText pl="x2">{cellData}</TruncatedText>;
}

export default function PublishErrorTabContent({ createErrorRows, updateErrorRows, createTotal, updateTotal }) {
  const applicationContext = useApplicationContext();

  if (createErrorRows.length === 0 && updateErrorRows.length === 0)
    return <ExecutionSummary content="All jobs were published successfully." />;

  const createErrorSummary = `Failed to create ${createErrorRows.length} of ${createTotal} jobs.`;
  const updateErrorSummary = `Failed to update ${updateErrorRows.length} of ${updateTotal} jobs.`;

  const createErrorColumns = [
    {
      dataKey: "workOrder",
      label: "Work order",
      width: "25%",
      cellRenderer: renderWorkOrder,
    },
    {
      dataKey: "line",
      label: "Line",
      width: "20%",
    },
    {
      dataKey: "startTime",
      label: "Start time",
      cellFormatter: formatTime,
      width: "20%",
    },
    {
      dataKey: "error",
      label: "Reason",
      width: "35%",
    },
  ];

  const updateErrorColumns = [
    {
      dataKey: "workOrder",
      label: "Work order",
      width: "20%",
      cellRenderer: renderWorkOrder,
    },
    {
      dataKey: "job",
      label: "Job",
      cellRenderer: renderJob,
      width: "15.1%",
    },
    {
      dataKey: "line",
      label: "Line",
      width: "17.4%",
    },
    {
      dataKey: "startTime",
      label: "Start time",
      cellFormatter: formatTime,
      width: "12.5%",
    },
    {
      dataKey: "error",
      label: "Reason",
      width: "35%",
    },
  ];

  function renderJob({ cellData: jobId }) {
    return <PackmanagerJobLink applicationContext={applicationContext} jobId={jobId} />;
  }

  return (
    <>
      <PublishResultSection
        rows={createErrorRows}
        title="Create"
        testIdPrefix="create-error"
        summary={createErrorSummary}
        columns={createErrorColumns}
      />

      <PublishResultSection
        rows={updateErrorRows}
        title="Update"
        testIdPrefix="update-error"
        summary={updateErrorSummary}
        columns={updateErrorColumns}
      />
    </>
  );
}
