import { useQuery } from "@apollo/client";
import { GetWorkOrderMetadata } from "./workOrder.gql";

export default function useGetWorkOrderMetadata() {
  const { data, loading, refetch } = useQuery(GetWorkOrderMetadata);

  return {
    data,
    loading,
    refetch,
  };
}
