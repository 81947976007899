import React from "react";
import styled, { css } from "styled-components";
import { Box, theme } from "@nulogy/components";
import ReadOnlyName from "./ReadOnlyName";
import { formatEfficiency } from "components/helpers/format";
import LineTypeTag from "components/pages/schedule/LineTypeTag";

const evenLineLabel = css`
  background-color: white;
`;

const oddLineLabel = css`
  background-color: #f4f5f7;
`;

const LineLabel = styled.div`
  align-self: start;
  height: 100%;
  width: 100%;
  ${({ index }) => (index % 2 === 0 ? evenLineLabel : oddLineLabel)};
`;

const Efficiency = styled.div`
  font-size: ${theme.fontSizes.smaller};
  font-weight: ${theme.fontWeights.normal};
`;

export default function Line({ cellElement, record }) {
  cellElement.className = `${cellElement.className} schedule-line-cell`; // eslint-disable-line no-param-reassign

  if (record.data.id === "shifts") {
    return null;
  }

  const { efficiency } = record.data;

  return (
    <LineLabel index={record.parentIndex} className={`line-label line-${record.id} spec-line-${record.id}`}>
      <Box marginTop="8px" marginLeft="5px">
        <Box paddingBottom="2px">
          <ReadOnlyName data-testid="line">{record.name}</ReadOnlyName>
        </Box>

        <Box paddingBottom="2px">
          <LineTypeTag>{record.lineType}</LineTypeTag>
        </Box>
        <Box paddingBottom="2px">
          <Efficiency>{formatEfficiency(efficiency)}</Efficiency>
        </Box>
      </Box>
    </LineLabel>
  );
}
