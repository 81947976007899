import useApplicationContext from "./apollo/applicationContext/useApplicationContext";
import ApplicationContext from "domain/ApplicationContext";

export default function useTimeZone() {
  const applicationContext = useApplicationContext();

  return {
    overrideToSiteTimeZone: (dateTime) => ApplicationContext.overrideToSiteTimeZone(applicationContext, dateTime),
  };
}
