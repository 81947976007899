import React from "react";
import styled from "styled-components";
import compact from "lodash/compact";
import { BrandedNavBar, theme } from "@nulogy/components";
import isEmpty from "lodash/isEmpty";
import ReadonlyIcon from "./ReadonlyIcon";
import syncMenu from "./syncMenu";
import SiteSwitcherContainer from "components/common/SiteSwitcherContainer";
import paths from "config/routePaths";
import endpoints from "config/endpoints";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import Authorization from "policies/Authorization";

const AppNavBar = styled(BrandedNavBar)`
  div[class*="MenuText"] {
    // for SiteSwitcher
    padding: 0 16px;
  }
`;

// HACK: These styles are copied from NDS and should be replaced when they export/provide these styles for use
const NavBarButton = styled.button(() => ({
  display: "block",
  color: theme.colors.darkBlue,
  textDecoration: "none",
  border: "none",
  backgroundColor: "transparent",
  verticalAlign: "middle",
  lineHeight: theme.lineHeights.base,
  fontSize: `${theme.fontSizes.medium}`,
  fontWeight: `${theme.fontWeights.medium}`,
  padding: `${theme.space.x1} ${theme.space.x2}`,
  borderRadius: theme.radii.medium,
  transition: ".2s",
  "&:hover, &:focus": {
    outline: "none",
    color: theme.colors.blackBlue,
    backgroundColor: theme.colors.whiteGrey,
    cursor: "pointer",
  },
  "&:disabled": {
    opacity: ".5",
  },
  "&:focus": {
    boxShadow: theme.shadows.focus,
  },
}));

export function linksMenu({ policy }) {
  return [
    {
      name: "Production Scheduling",
      href: "/",
    },
    {
      name: "Labor",
      href: paths.laborSummary,
    },
    policy.canImport(Authorization.EntityTypes.WorkOrder) && {
      name: "Manage data",
      href: paths.manageData,
    },
    !policy.canUpdate(Authorization.EntityTypes.WorkBlock) && {
      name: "READ-ONLY",
      render: () => <ReadonlyIcon />,
    },
  ].filter(Boolean);
}

function ApplicationNavBar({ environment }) {
  const applicationContext = useApplicationContext();
  const policy = Authorization.usePolicy();

  const menuData = {
    primaryMenu: linksMenu({ policy }),
  };

  menuData.secondaryMenu = compact([
    {
      name: <SiteSwitcherContainer />,
    },
    helpMenu(),
    pendoMenu(),
    syncMenu({ applicationContext, policy }),
    settingsMenu({ applicationContext, policy }),
    authenticationMenu({ applicationContext }),
  ]);

  return (
    <AppNavBar
      className="spec-nav-bar"
      brandingLinkHref={brandingLinkHref({ applicationContext })}
      menuData={menuData}
      environment={environment || null}
    />
  );
}

export default React.memo(ApplicationNavBar, () => true);

function brandingLinkHref({ applicationContext }) {
  if (applicationContext.site.externalId) {
    return applicationContext.packmanagerUrl;
  }

  return "#";
}

function helpMenu() {
  return {
    name: "HelpDesk",
    render: () => (
      <NavBarButton
        onClick={() => {
          zE("webWidget", "show"); // eslint-disable-line no-undef
          document.getElementById("launcher").contentWindow.document.querySelector("button").click();
        }}
      >
        HelpDesk
      </NavBarButton>
    ),
  };
}

function pendoMenu() {
  return {
    name: "Guided tours",
    render: () => <NavBarButton id="guided-tours">Guided tours</NavBarButton>,
  };
}

function availabilitySubmenu(policy) {
  const result = [];

  if (policy.canUpdate(Authorization.EntityTypes.DefaultAvailability)) {
    result.push({ name: "Default availability", href: paths.defaultAvailability });
  }

  if (policy.canUpdate(Authorization.EntityTypes.AvailabilityOverride)) {
    result.push({ name: "Availability overrides", href: paths.availabilityOverrides });
  }

  return result;
}

export function settingsMenu({ applicationContext, policy }) {
  const menu = {
    name: "Settings",
    items: [],
  };

  if (policy.canUpdate(Authorization.EntityTypes.Shift)) {
    menu.items.push({
      name: "Shifts",
      href: paths.shifts,
    });
  }

  if (policy.canUpdate(Authorization.EntityTypes.Line)) {
    menu.items.push({
      name: "Lines",
      href: paths.lines,
    });
  }

  if (availabilitySubmenu(policy).length) {
    menu.items.push({ name: "Availability", items: availabilitySubmenu(policy) });
  }

  if (
    applicationContext.settings.showCustomWorkOrderFields &&
    policy.canUpdate(Authorization.EntityTypes.CustomWorkOrderField)
  ) {
    menu.items.push({ name: "Custom work order fields", href: paths.customWorkOrderFields });
  }

  if (policy.canUpdate(Authorization.EntityTypes.DowntimeReason)) {
    menu.items.push({
      name: "Downtime reasons",
      href: paths.downtimeReasons,
    });
  }

  if (policy.canUpdate(Authorization.EntityTypes.TeardownTime)) {
    menu.items.push({ name: "Teardown times", href: paths.teardownTimes });
  }

  if (policy.canUpdate(Authorization.EntityTypes.CustomerSettings)) {
    menu.items.push({ name: "Layout", href: paths.customerSettings });
  }

  if (policy.canUpdate(Authorization.EntityTypes.Site)) {
    menu.items.push({ name: "Edit current site", href: paths.siteEdit(applicationContext.site.id) });
  }

  if (policy.canUpdate(Authorization.EntityTypes.Company)) {
    menu.items.push({ name: "Companies", href: paths.companyIndex });
  }

  if (policy.canUpdate(Authorization.EntityTypes.Site)) {
    menu.items.push({ name: "Sites", href: paths.siteIndex });
  }

  if (policy.canUpdate(Authorization.EntityTypes.User)) {
    menu.items.push({ name: "Users", href: paths.userIndex });
  }

  if (policy.canShow(Authorization.EntityTypes.UsageData)) {
    menu.items.push({ name: "Admin dashboard", href: paths.adminDashboard });
  }

  return isEmpty(menu.items) ? null : menu;
}

export function authenticationMenu({ applicationContext: { user } }) {
  if (!user?.email) return { name: "Login", href: endpoints.login };

  const items = [
    { name: "Preferences", href: paths.preferences },
    { name: "Logout", href: endpoints.logout },
  ];

  return { name: user.email, items };
}
