import React from "react";
import Select from "components/controls/Select";
import WorkBlock from "domain/WorkBlock";

export default function WorkOrderSelect({ workOrders, onChange, workBlockLayout, disabled = false }) {
  const options = toOptions(workOrders, workBlockLayout);

  return (
    <Select
      className="spec-work-order-select"
      field="workBlock[workOrder][id]"
      labelText="Work order"
      options={options}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

function toOptions(workOrders, workBlockLayout) {
  return workOrders.map((workOrder) => {
    const { id, code } = workOrder;

    const description = WorkBlock.description({ workOrder }, workBlockLayout);
    let label = description ? `${description} | ` : "";
    label += code;

    return { label, value: id };
  });
}
