function transformAvailabilityOverrideData(data) {
  return data.map((entry) => ({
    lineId: entry.line,
    shiftId: entry.shift,
    overrideDate: entry.date,
    available: entry.available,
  }));
}

export { transformAvailabilityOverrideData };
