import { FLAG_OPTIONS, NO_FLAG } from "./flagsConfig";

export default function flagsMenuItem(onUpdateWorkOrder) {
  const updateFlag =
    (flag) =>
    ({ eventRecord }) => {
      const {
        data: {
          block: { workOrder },
        },
      } = eventRecord;
      onUpdateWorkOrder({ id: workOrder.id, flag });
    };

  const flagsSubMenuItems = Object.keys(FLAG_OPTIONS).map((flagOption) => ({
    text: flagOption,
    onItem: updateFlag(flagOption),
    cls: `pendo-add-flag-context-menu-item flag-menu-item flag-${flagOption}`,
  }));
  flagsSubMenuItems.push({
    text: "Remove flag",
    onItem: updateFlag(NO_FLAG),
    cls: "pendo-remove-flag-context-menu-item",
  });

  return {
    text: "Flags",
    name: "flags",
    menu: flagsSubMenuItems,
  };
}
