import React from "react";
import isEmpty from "lodash/isEmpty";
import CommonIndicator from "components/common/CustomWorkOrderFieldIndicator";

export default function CustomWorkOrderFieldIndicator({ name, color, settings, userCanUpdate }) {
  if (isEmpty(name)) return null;
  if (!settings || !settings.showCustomWorkOrderFields) return null;

  return (
    <CommonIndicator
      title={name}
      color={color}
      userCanUpdate={userCanUpdate}
      className="spec-custom-work-order-field-indicator"
      data-testid="custom-work-order-field-indicator"
    />
  );
}
