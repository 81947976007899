import React, { useState } from "react";
import { Box } from "@nulogy/components";
import EditWorkBlockModalView from "./EditWorkBlockModalView";
import ModalFormButtons from "components/controls/ModalFormButtons";
import BlockModal from "components/pages/schedule/styles/BlockModal";
import useEditWorkBlockState from "hooks/state/useWorkBlockEditModal";
import useRescheduleBlocks from "hooks/apollo/blocks/useRescheduleBlocks";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import WorkBlock from "domain/WorkBlock";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";
import useWorkBlockModal from "hooks/state/useWorkBlockModal";
import Num from "utils/Num";

export default function EditWorkBlockModalContainer() {
  const applicationContext = useApplicationContext();
  const {
    customerSettings: { workBlockLayout },
  } = applicationContext;
  const { block: blockData, isOpen, close } = useEditWorkBlockState();
  const workBlockModalUIState = useWorkBlockModal();
  const rescheduleBlocks = useRescheduleBlocks();
  const updateWorkOrder = useUpdateWorkOrder();

  const [workBlockErrors, setWorkBlockErrors] = useState({ base: [] });

  if (!blockData) return null;

  const { block } = blockData;

  const selectedWorkOrder = block.workOrder;
  const selectedLine = block.line;

  const effectiveProductionRateFor = (workBlock) =>
    WorkBlock.effectiveProductionRate({
      ...block,
      ...workBlock,
      performanceOverride: Num.percentToDecimal(workBlock.performanceOverride),
      efficiencyOverride: Num.percentToDecimal(workBlock.efficiencyOverride),
      line: selectedLine,
    });

  const expectedQuantityFor = (workBlock) =>
    WorkBlock.quantity({
      ...block,
      ...workBlock,
      performanceOverride: Num.percentToDecimal(workBlock.performanceOverride),
      efficiencyOverride: Num.percentToDecimal(workBlock.efficiencyOverride),
      line: selectedLine,
    });

  async function handleSave(workBlock) {
    const updatedWorkBlock = WorkBlock.buildWithWorkTime(workBlock);
    await rescheduleBlocks([updatedWorkBlock]);
    updateWorkOrder({ id: workBlock.workOrder.id, notes: workBlock.workOrder.notes });
  }

  const disabled = workBlockErrors?.base?.length > 0;

  const handleClose = () => {
    setWorkBlockErrors({ base: [] });
    close();
  };

  return (
    <BlockModal
      title="Edit work block"
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="spec-block-modal"
      footerContent={
        <ModalFormButtons
          formId="editWorkBlock"
          onClose={handleClose}
          disabled={disabled}
          pendoTagClass="pendo-edit-work-block-modal-save"
        />
      }
    >
      <Box position="relative">
        <EditWorkBlockModalView
          blockData={blockData}
          onClose={handleClose}
          onSave={handleSave} // eslint-disable-line react/jsx-no-bind
          errors={workBlockErrors}
          setErrors={setWorkBlockErrors}
          selectedWorkOrder={selectedWorkOrder}
          selectedLine={selectedLine}
          effectiveProductionRateFor={effectiveProductionRateFor}
          expectedQuantityFor={expectedQuantityFor}
          workBlockLayout={workBlockLayout}
          workBlockModalUIState={workBlockModalUIState}
        />
      </Box>
    </BlockModal>
  );
}
