import React from "react";
import { useMutation } from "@apollo/client";
import { Box, IconicButton } from "@nulogy/components";
import DaysSelector from "./DaysSelector";
import Time from "utils/Time";
import DateTime from "utils/DateTime";
import { insertShift } from "hooks/apollo/shift/helpers/handleUpdateShifts";
import { CreateShift } from "hooks/apollo/shift/shift.gql";
import { GridBox6, StyledInput, StyledTimePicker } from "components/common/EditableListStyledComponents";
import FieldRow from "components/common/FieldRow";
import Form from "components/controls/Form";

export default function NewShift({ shiftId, onRemoveNewShift }) {
  const [createShift] = useMutation(CreateShift);

  const handleSave = ({ id, name, days, startTime, endTime }) => {
    const updatedStartTime = Time.to24Hour(startTime);
    const updatedEndTime = Time.to24Hour(endTime);
    const updatedDays = {};
    DateTime.DAYS_OF_WEEK.forEach((day) => {
      updatedDays[day] = days.includes(day);
    });

    return createShift({
      variables: {
        id,
        name,
        startTime: updatedStartTime,
        endTime: updatedEndTime,
        ...updatedDays,
      },
      update: (proxy, { data }) => {
        const newShift = data.createShift.shift;

        insertShift(newShift, proxy);
      },
    });
  };

  const removeNewShift = () => {
    onRemoveNewShift(shiftId);
  };

  return (
    <Form
      onSave={handleSave}
      onAfterSave={removeNewShift}
      initialValues={{
        id: shiftId,
        name: "",
        days: [],
        startTime: null,
        endTime: null,
      }}
    >
      {({ dirty }) => (
        <FieldRow marginBottom="0" data-testid="new-shift-row">
          <GridBox6>
            <StyledInput field="name" placeholder="Name" />
            <DaysSelector />
            <Box data-testid="start-time">
              <StyledTimePicker field="startTime" placeholder="Start Time" className="spec-shift-start-time" />
            </Box>
            <Box data-testid="end-time">
              <StyledTimePicker field="endTime" placeholder="End Time" className="spec-shift-end-time" />
            </Box>

            <Box paddingTop="x1">
              <IconicButton type="submit" icon="check" disabled={!dirty}>
                Save
              </IconicButton>
            </Box>
            <Box paddingTop="x1">
              <IconicButton type="button" icon="close" onClick={removeNewShift}>
                Cancel
              </IconicButton>
            </Box>
          </GridBox6>
        </FieldRow>
      )}
    </Form>
  );
}
