import React, { useState } from "react";
import { Box, IconicButton, TruncatedText } from "@nulogy/components";
import { useMutation } from "@apollo/client";
import { GridBox4, StyledInput, StyledText } from "components/common/EditableListStyledComponents";
import FieldRow from "components/common/FieldRow";
import Form from "components/controls/Form";
import { UpdateDowntimeReason } from "hooks/apollo/downtimeReason/downtimeReason.gql";

export default function DowntimeReason({ downtimeReason, onDelete }) {
  const [editing, setEditing] = useState(false);
  const [updateDowntimeReason] = useMutation(UpdateDowntimeReason, { onCompleted: () => setEditing(false) });

  const { id, reason, code } = downtimeReason;

  const handleCancel = (onReset) => {
    onReset();
    setEditing(false);
  };

  const handleSave = (updatedDowntimeReason) =>
    updateDowntimeReason({
      variables: {
        id: updatedDowntimeReason.id,
        reason: updatedDowntimeReason.reason,
        code: updatedDowntimeReason.code,
      },
    });

  return (
    <>
      {!editing && (
        <GridBox4 data-testid="downtime-reason">
          <StyledText data-testid="downtime-reason-reason">
            <TruncatedText fullWidth>{reason}</TruncatedText>
          </StyledText>
          <StyledText data-testid="downtime-reason-code">
            <TruncatedText fullWidth>{code}</TruncatedText>
          </StyledText>
          <IconicButton type="button" icon="edit" onClick={() => setEditing(true)}>
            Edit
          </IconicButton>
          <IconicButton type="button" icon="delete" onClick={() => onDelete(downtimeReason.id)}>
            Delete
          </IconicButton>
        </GridBox4>
      )}
      {editing && (
        <Form initialValues={{ id, reason, code }} onSave={handleSave}>
          {({ dirty, handleReset }) => (
            <FieldRow marginBottom="none" data-testid="downtime-reason-row-editing">
              <GridBox4>
                <StyledInput field="reason" placeholder="Reason" />
                <StyledInput field="code" placeholder="Code" />
                <Box paddingTop="x1">
                  <IconicButton type="submit" icon="check" disabled={!dirty}>
                    Save
                  </IconicButton>
                </Box>
                <Box paddingTop="x1">
                  <IconicButton type="button" icon="close" onClick={() => handleCancel(handleReset)}>
                    Cancel
                  </IconicButton>
                </Box>
              </GridBox4>
            </FieldRow>
          )}
        </Form>
      )}
    </>
  );
}
