import React from "react";
import { Box, Icon, Tooltip, theme } from "@nulogy/components";
import styled from "styled-components";
import { formatDuration } from "components/helpers/format";
import TruncatedText from "components/common/TruncatedText";
import WorkOrder from "domain/WorkOrder";

const ErrorWrapper = styled.div`
  color: ${theme.colors.red};
`;

const ErrorSpan = styled.span`
  position: relative;
  bottom: 5px;
  margin-left: 3px;
`;

const ErrorTooltip = styled(Tooltip)({
  whiteSpace: "normal",
  textAlign: "left",
});

const ProductionRateError = () => (
  <ErrorWrapper>
    <ErrorTooltip tooltip="This work order is missing data and cannot be scheduled.">
      <Icon icon="error" size="20px" />
    </ErrorTooltip>
    <ErrorSpan>Production rate is 0</ErrorSpan>
  </ErrorWrapper>
);

export default function Duration({ workOrder }) {
  const { unscheduledQuantity, unitOfMeasure, remainingDuration } = workOrder;
  const round = (number) => Math.round(number * 100) / 100;
  const roundedUnscheduledQuantity = round(unscheduledQuantity);

  const fullyScheduledDurationDetails = () => ({
    quantityLabel: `0 ${unitOfMeasure}`,
    timeRemaining: "0h 0m",
    suffix: "remaining",
  });

  const remainingDurationDetails = () => ({
    quantityLabel: `${Math.abs(roundedUnscheduledQuantity)} ${unitOfMeasure}`,
    timeRemaining: formatDuration(remainingDuration),
    suffix: WorkOrder.isOverscheduled(workOrder) ? "overscheduled" : "remaining",
  });

  const { quantityLabel, timeRemaining, suffix } = WorkOrder.isScheduledCompletedOrCancelled(workOrder)
    ? fullyScheduledDurationDetails()
    : remainingDurationDetails();
  const title = `${quantityLabel} (${timeRemaining}) ${suffix}`;

  return (
    <Box data-testid="remaining-quantity">
      {WorkOrder.hasNonZeroProductionRate(workOrder) ? (
        <TruncatedText title={title} fontSize="smaller">
          {quantityLabel} <strong>({timeRemaining})</strong> {suffix}
        </TruncatedText>
      ) : (
        <ProductionRateError />
      )}
    </Box>
  );
}
