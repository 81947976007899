import React, { useState } from "react";
import debounce from "lodash/debounce";
import ScheduleHeaderView from "./ScheduleHeaderView";
import useRefetchSchedule from "hooks/apollo/schedule/useRefetchSchedule";
import { useSchedulerState } from "hooks/state";
import Authorization from "policies/Authorization";

export default function ScheduleHeaderContainer() {
  const [disabled, setDisabled] = useState(false);

  const {
    quickNavigate,
    changeVisibleStartDate,
    changeZoomLevel,
    state: { zoomLevel, visibleTimeRange },
  } = useSchedulerState();
  const refetchSchedule = useRefetchSchedule();
  const policy = Authorization.usePolicy();
  const canPublishSchedule = policy.canPublishSchedule(Authorization.EntityTypes.PublishedScheduleType);

  const disableNavigation = (func) => {
    setDisabled(true);
    func();
    debounce(() => setDisabled(false), 500)();
  };

  const handleZoomLevelChange = (newZoomLevel) => {
    disableNavigation(() => {
      changeZoomLevel({ zoomLevel: Number(newZoomLevel) });
    });
  };

  const handleDateChange = (date) => {
    if (!date) return null;

    return refetchSchedule(changeVisibleStartDate({ startDate: date }));
  };
  const handleQuickNavigationChange = (option) => {
    disableNavigation(() => {
      refetchSchedule(quickNavigate({ quickNavigationOption: option }));
    });
  };

  return (
    <ScheduleHeaderView
      disabled={disabled}
      onDateChange={handleDateChange}
      onQuickNavigationChange={handleQuickNavigationChange}
      onZoomLevelChange={handleZoomLevelChange}
      zoomLevel={zoomLevel}
      canPublishSchedule={canPublishSchedule}
      visibleTimeRange={visibleTimeRange}
    />
  );
}
