import React from "react";
import { theme } from "@nulogy/components";
import styled from "styled-components";
import { formatDuration } from "components/helpers/format";

const Wrapper = styled.div`
  padding-top: 8px;
  font-size: 12px;
  line-height: ${theme.lineHeights.smallTextBase};
`;

const Time = styled.span`
  font-weight: bold;
`;

const renderTimeRemainingMessage = (remainingDurationInSeconds) => {
  if (!Number.isFinite(remainingDurationInSeconds))
    return "Remaining duration cannot be calculated with a production rate of 0.";

  const timeRemaining = formatDuration(remainingDurationInSeconds);
  const suffix = remainingDurationInSeconds < 0 ? "overscheduled" : "remaining to fully schedule work order";

  return (
    <>
      <Time>{timeRemaining}</Time> {suffix}
    </>
  );
};

export default function RemainingDuration({ remainingDurationInSeconds }) {
  return <Wrapper data-testid="remaining-duration">{renderTimeRemainingMessage(remainingDurationInSeconds)}</Wrapper>;
}
