import React, { Component } from "react";

const NetworkStateContext = React.createContext({});

export class NetworkStateProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      GET_WORK_ORDERS: 0,
      CLOSE_GAP: 0,
      GET_BLOCKS: 0,
    };
  }

  setFetching = (key) => {
    this.setState((state) => ({ [key]: state[key] + 1 }));
  };

  setNotFetching = (key) => {
    this.setState((state) => ({ [key]: state[key] - 1 }));
  };

  isFetching = (key) => this.state[key] > 0;

  trackLoadingState = async (key, fnc) => {
    this.setFetching(key);

    try {
      await fnc();
    } finally {
      this.setNotFetching(key);
    }
  };

  contextValue = () => ({ isFetching: this.isFetching, trackLoadingState: this.trackLoadingState });

  render() {
    return (
      <NetworkStateContext.Provider value={this.contextValue()}>{this.props.children}</NetworkStateContext.Provider>
    );
  }
}

export default NetworkStateContext;
