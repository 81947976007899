import React from "react";
import { createRoot } from "react-dom/client";
import whyDidYouRender from "@welldone-software/why-did-you-render";
import App from "./components/App";

if (process.env.NODE_ENV === "development") {
  whyDidYouRender(React, {
    trackAllPureComponents: false,
  });
}

createRoot(document.getElementById("root")).render(App);
