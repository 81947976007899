import React from "react";
import { Box, DateRange, Flex, Page, Select } from "@nulogy/components";
import min from "lodash/min";
import flatMap from "lodash/flatMap";
import EngagedSites from "./EngagedSites";

export default function AdminDashboardView({
  charts,
  startDate,
  endDate,
  handleRangeChange,
  handleSiteChange,
  companies,
}) {
  const options = siteOptionsForSelect(companies);

  return (
    <Page title="Administrator dashboard">
      <Flex alignItems="center" justifyContent="space-between">
        <Box paddingRight="x4" width="650px">
          <DateRange
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            onRangeChange={handleRangeChange}
            labelProps={{ labelText: "" }}
          />
        </Box>
        <Select onChange={handleSiteChange} options={options} defaultValue={null} placeholder="Select site..." />
      </Flex>
      <Flex flexDirection="row" flexWrap="wrap" justifyContent="flex-start">
        {charts.map(({ ChartComponent, data, label, keys }) => (
          <Box key={label} width={1 / min([1, charts.length])}>
            <Box boxShadow="large" margin="x3">
              <ChartComponent data={data} title={label} keys={keys} />
            </Box>
          </Box>
        ))}
        <EngagedSites startDate={startDate} endDate={endDate} />
      </Flex>
    </Page>
  );
}

function siteOptionsForSelect(companiesWithSites) {
  const allOption = [{ label: "All sites", value: null }];
  const companyOptions = flatMap(companiesWithSites, ({ name: companyName, sites }) =>
    sites.map(({ name: siteName, id }) => ({ label: `${companyName} - ${siteName}`, value: id })),
  );

  return [...allOption, ...companyOptions];
}
