const GqlError = {
  handler,
};

function handler(errorResponse, setErrors) {
  const { graphQLErrors, networkError } = errorResponse;
  if (graphQLErrors && graphQLErrors[0]) {
    const { extensions, message } = graphQLErrors[0];
    if (extensions && !extensions.problems) {
      setErrors(extensions);
    } else {
      setErrors({ gqlError: message });
    }
  } else {
    console.error(errorResponse); // eslint-disable-line
    if (networkError) {
      const { statusCode } = networkError;
      if (statusCode === 401) {
        setErrors({ gqlError: "Not logged in" });
      } else if (statusCode >= 400) {
        setErrors({
          gqlError:
            "There was a server error. Please try again. If this problem persists, please contact Nulogy Support.",
        });
      } else {
        setErrors({ gqlError: "Network Error. Is your internet working?" });
      }
    } else {
      throw errorResponse;
    }
  }
}

export default GqlError;
