import React, { useState } from "react";
import { Box, Heading2, IconicButton, Page, Text, theme } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import CreateNewTeardownTime from "./CreateNewTeardownTime";
import TeardownTime from "./TeardownTime";
import useDeleteTeardownTime from "hooks/apollo/teardownTime/useDeleteTeardownTime";
import DeleteConfirmationModal from "components/common/DeleteConfirmationModal";
import { removeTeardownTime } from "hooks/apollo/teardownTime/helpers/handleUpdateTeardownTimes";
import { GetTeardownTimes } from "hooks/apollo/teardownTime/teardownTime.gql";
import useDeleteConfirmationModal from "hooks/state/useDeleteConfirmationModal";
import { GridBox4 } from "components/common/EditableListStyledComponents";
import Uuid from "utils/Uuid";

export default function TeardownTimes() {
  const { data, loading } = useQuery(GetTeardownTimes);
  const [newTeardownTimeIds, setNewTeardownTimeIds] = useState([]);
  const { open: openModal } = useDeleteConfirmationModal();
  const deleteTeardownTime = useDeleteTeardownTime();

  const handleAddNewTeardownTimeId = () => {
    setNewTeardownTimeIds([...newTeardownTimeIds, Uuid.generate()]);
  };

  const handleRemoveNewTeardownTimeId = (id) => {
    setNewTeardownTimeIds(newTeardownTimeIds.filter((value) => value !== id));
  };

  const handleDelete = (teardownTimeId) => {
    openModal(teardownTimeId);
  };

  const onDelete = (teardownTimeId) =>
    deleteTeardownTime({
      variables: { id: teardownTimeId },
      update: (proxy) => {
        removeTeardownTime(teardownTimeId, proxy);
      },
    });

  function renderContent() {
    if (loading) return null;

    const showTeardownTimes = !isEmpty(data.teardownTimes) || !isEmpty(newTeardownTimeIds);

    const addButtonText = showTeardownTimes ? "Add another row" : "Add a teardown time";
    const deleteMessage =
      "This teardown time will not be available for scheduling once deleted. However, blocks that it is already applied to will not be impacted.";

    return (
      <Box width="870px">
        <DeleteConfirmationModal title="Delete teardown time" message={deleteMessage} onDelete={onDelete} />
        <IconicButton
          type="button"
          icon="add"
          marginBottom="x2"
          onClick={() => handleAddNewTeardownTimeId()}
          className="spec-add-new-teardown-time-icon"
          data-testid="add-teardown-time-button"
        >
          {addButtonText}
        </IconicButton>
        {showTeardownTimes && (
          <>
            <GridBox4>
              <Text>Label</Text>
              <Text>Duration (hours)</Text>
            </GridBox4>
            <hr style={{ marginBottom: "0px" }} />
            {newTeardownTimeIds.map((newTeardownTimeId) => (
              <CreateNewTeardownTime
                newTeardownTimeId={newTeardownTimeId}
                onRemoveNewTeardownTimeId={handleRemoveNewTeardownTimeId}
                key={newTeardownTimeId}
              />
            ))}
            {data.teardownTimes.map((teardownTime) => (
              <TeardownTime key={teardownTime.id} teardownTime={teardownTime} onDelete={handleDelete} />
            ))}
          </>
        )}
      </Box>
    );
  }

  return (
    <Page className="spec-teardown-time-create-edit-page">
      <Heading2 mb="x3" data-testid="title">
        Teardown times
      </Heading2>
      <Box marginBottom="x3" color={theme.colors.darkGrey} maxWidth="600px">
        Manage your site’s teardown times on this page. Once a teardown time is created, you will have the option to
        apply it to scheduled work blocks.
      </Box>
      {renderContent()}
    </Page>
  );
}
