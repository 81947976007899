import { useMutation } from "@apollo/client";
import { UpdateWorkOrder } from "./workOrder.gql";

export default function useUpdateWorkOrder() {
  const [updateWorkOrder] = useMutation(UpdateWorkOrder);

  return ({ id, flag, notes }) =>
    updateWorkOrder({
      variables: {
        id,
        flag,
        notes,
      },
    });
}
