import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { BryntumScheduler } from "@bryntum/scheduler-react";
import assign from "lodash/assign";
import { Tooltip } from "@bryntum/scheduler";
import renderToString from "components/common/Scheduler/SchedulerRenderers/renderers/renderToString";
import handleEventClick from "components/bryntum/handleEventClick";
import clickOnDateHeader from "components/bryntum/listeners/clickOnDateHeader";
import dragToReschedule from "components/bryntum/listeners/dragToReschedule";
import dropToReschedule from "components/bryntum/listeners/dropToReschedule";
import openModal from "components/bryntum/listeners/openModal";
import resizeToReschedule from "components/bryntum/listeners/resizeToReschedule";
import filterHeaderRenderer from "components/common/Scheduler/SchedulerRenderers/renderers/filterHeaderRenderer";
import lineRenderer from "components/common/Scheduler/SchedulerRenderers/renderers/lineRenderer";
import ShiftInstanceTooltip from "components/common/Scheduler/tooltips/ShiftInstanceTooltip";
import BLOCK_HEIGHT from "components/pages/schedule/helpers/blockHeight";
import GapFiller from "components/pages/schedule/helpers/GapFiller";
import { defaultViewPresetId, getTickWidth, presetId, viewPresets } from "config/zoomConfig";
import WorkOrderSearchContext from "contexts/WorkOrderSearchContext";
import UIStateContext from "contexts/UIStateContext";
import ApplicationContext from "domain/ApplicationContext";
import Bryntum from "domain/Bryntum";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useCloseGap from "hooks/apollo/blocks/useCloseGap";
import useRescheduleBlocks from "hooks/apollo/blocks/useRescheduleBlocks";
import useFetchSchedulerData from "hooks/apollo/useFetchSchedulerData";
import useGetLines from "hooks/apollo/line/useGetLines";
import useFullyScheduleWorkOrder from "hooks/apollo/workBlock/useFullyScheduleWorkOrder";
import useGetWorkOrders from "hooks/apollo/workOrder/useGetWorkOrders";
import useTrackUsage from "hooks/apollo/usageData/useTrackUsage";
import useRefetchSchedule from "hooks/apollo/schedule/useRefetchSchedule";
import useEventFeatures from "hooks/schedule/useEventFeatures";
import useBlockCreateModal from "hooks/state/useBlockCreateModal";
import useDowntimeEditModal from "hooks/state/useDowntimeEditModal";
import useDowntimeShowModal from "hooks/state/useDowntimeShowModal";
import useHighlightWorkBlock from "hooks/state/useHighlightWorkBlock";
import useSchedulerState from "hooks/state/useSchedulerState";
import useWorkBlockEditModal from "hooks/state/useWorkBlockEditModal";
import useWorkBlockShowModal from "hooks/state/useWorkBlockShowModal";
import useNotesPopUp from "hooks/state/useNotesPopUp";
import useBryntumScheduler from "hooks/useBryntumScheduler";
import Authorization from "policies/Authorization";
import DateTime from "utils/DateTime";
import UnavailabilityTooltip from "components/common/Scheduler/tooltips/UnavailabilityTooltip";

const RESIZE_DELAY = 500;

// Reference:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function handleMultiSelectMode({ selection }) {
  if (selection.length > 1) {
    this.currentElement.classList.add("multi-select-mode");
  } else {
    this.currentElement.classList.remove("multi-select-mode");
  }
}

const SchedulerContainer = styled.div`
  grid-column: 1 / 2;
  grid-row: 2;
  overflow: hidden;
  padding: 10px 0 0 0;
`;

export default function BryntumSchedulerContainer() {
  const schedulerRef = React.createRef();
  const resizeTimer = useRef(null);

  const policy = Authorization.usePolicy();
  const applicationContext = useApplicationContext();
  const onTrackUsage = useTrackUsage();
  const { eventFeatures } = useEventFeatures();
  const { setBryntumScheduler, getBryntumScheduler } = useBryntumScheduler();
  const { getState: getExistingSearchState } = useContext(WorkOrderSearchContext);
  const { highlightWorkBlock } = useHighlightWorkBlock();
  const {
    state: { zoomLevel, extendedView, visibleTimeRange, dataTimeRange },
    changeDataTimeRange,
    changeVisibleTimeRange,
    zoomIn,
  } = useSchedulerState();
  const {
    state: { selectedWorkBlock, sidebarOpen },
  } = useContext(UIStateContext);

  const refetchSchedule = useRefetchSchedule();
  const rescheduleBlocks = useRescheduleBlocks();
  const fullyScheduleWorkOrder = useFullyScheduleWorkOrder();
  const { handleCloseGap } = useCloseGap();
  const { lines } = useGetLines({ activeOnly: true });
  const { fetch: refetchWorkOrders } = useGetWorkOrders();
  const {
    blocks: { workBlocks, downtimeBlocks },
    shiftInstances,
    unavailabilities,
  } = useFetchSchedulerData();

  const { settings } = applicationContext;
  // This is generally bad practice! useMemo is supposed to be called with dependencies.
  // It is being done here to keep the number of global event listeners from growing on each re-render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gapFiller = useMemo(() => new GapFiller({ settings }), []);
  const readonly = ApplicationContext.isReadonly(applicationContext);
  const canUpdate = !readonly && policy.canUpdate(Authorization.EntityTypes.WorkBlock);

  const { open: onWorkBlockShowModal } = useWorkBlockShowModal();
  const { open: onDowntimeShowModal } = useDowntimeShowModal();
  const { open: handleOpenNotesPopUp } = useNotesPopUp();
  const { open: onOpenEditWorkBlock } = useWorkBlockEditModal();
  const { open: onOpenCreateBlock } = useBlockCreateModal();
  const { open: onOpenEditDowntime } = useDowntimeEditModal();
  const onOpenModal = openModal({ onOpenCreateBlock, onOpenEditDowntime, onOpenEditWorkBlock });
  const onOpenShowModal = openModal({
    onOpenEditDowntime: onDowntimeShowModal,
    onOpenEditWorkBlock: onWorkBlockShowModal,
  });

  const [columns] = useState([
    {
      showImage: false,
      htmlEncodeHeaderText: false,
      text: filterHeaderRenderer(),
      tooltip: {
        html: "To filter for multiple lines and/or line types, use a comma-separated search (e.g. Line 1, Line 2, Type Mix)",
        align: "l-r",
      },
      width: 130,
      htmlEncode: false,
      cls: "line-header-cell",
      renderer: lineRenderer(),
      filterable: ({ value, record }) => {
        const terms = value
          .split(",")
          .map((term) => escapeRegExp(term.trim()))
          .join("|");
        const regex = RegExp(`.*(${terms}).*`, "i");

        return record.lineType.match(regex) || record.name.match(regex);
      },
    },
  ]);
  const onInfiniteScroll = (props) => {
    refetchSchedule(changeDataTimeRange({ dataTimeRange: { ...props.new } }));
  };

  const handleFullyScheduleWorkOrder = ({ eventRecord: { data: blockData } }) => {
    const { block: workBlock } = blockData;

    fullyScheduleWorkOrder(workBlock);
  };

  const closeGap = ({ date, resourceRecord: { data } }) => {
    handleCloseGap({ date, lineId: data.id });
  };

  const handleRefetchWorkOrders = () => {
    const existingState = getExistingSearchState();
    const {
      filters: { filterByScheduleView },
    } = existingState;

    if (filterByScheduleView) refetchWorkOrders({ ...existingState });
  };

  const visibleDateRangeChanged = (props) => {
    changeVisibleTimeRange({ visibleTimeRange: props.new });
    const existingState = getExistingSearchState();
    const {
      filters: { filterByScheduleView },
    } = existingState;

    if (filterByScheduleView) refetchWorkOrders({ ...existingState, visibleTimeRange: props.new });
  };

  const resizePresets = () => {
    const scheduler = getBryntumScheduler();

    if (!scheduler) return;

    viewPresets.forEach((preset) => {
      const newPreset = { ...preset, tickWidth: getTickWidth(preset.ticks, sidebarOpen) };
      scheduler.presets.add(newPreset);
    });
    const newStartDate = extendedView ? new Date(visibleTimeRange.startDate) : dataTimeRange.startDate;

    scheduler.zoomTo({
      preset: presetId(zoomLevel),
      zoomLevel,
      visibleDate: { date: new Date(visibleTimeRange.startDate), block: "start" },
      startDate: newStartDate,
      endDate: DateTime.addDuration(newStartDate, 14, "days"),
    });
  };

  const handleTimedWindowResize = () => {
    clearTimeout(resizeTimer.current);
    resizeTimer.current = setTimeout(() => {
      resizePresets();
    }, RESIZE_DELAY);
  };

  const [config] = useState({
    rowHeight: BLOCK_HEIGHT + 2,
    eventLayout: "none", // Prevents events from stacking and making the row higher (we bump so no need)
    stripeFeature: true,
    multiEventSelect: true,
    createEventOnDblClick: false,
    barMargin: 2,
    useInitialAnimation: false,
    transitionDuration: 0,
    startDate: visibleTimeRange.startDate,
    endDate: visibleTimeRange.endDate,
    infiniteScroll: extendedView,
    // Reference:
    // https://bryntum.com/products/scheduler/docs/api/Scheduler/view/mixin/TimelineScroll#config-infiniteScroll
    bufferCoef: 3,
    bufferThreshold: 0.1,
    resourceTimeRangesFeature: true,
    filterBarFeature: true,
    cellEditFeature: false,
    cellMenuFeature: {
      items: {
        removeRow: false,
      },
    },
    rowCopyPasteFeature: false,
    headerMenuFeature: false,
    contextMenuFeature: false,
    timeRangesFeature: {
      showCurrentTimeLine: true,
      showHeaderElements: true,
      headerRenderer({ timeRange }) {
        return timeRange.name;
      },
      tooltipTemplate: ShiftInstanceTooltip,
    },
    scheduleMenuFeature: {
      processItems: ({ items }) => {
        if (!canUpdate) return false;

        return assign(items, {
          addEvent: false,
          closeGap: {
            text: "Close gap",
            onItem: closeGap,
          },
        });
      },
    },
    ...eventFeatures(gapFiller),
    timeAxisHeaderMenuFeature: false,
    scheduleTooltipFeature: false,
    viewPreset: zoomLevel ? presetId(zoomLevel) : defaultViewPresetId,
    columns,
    resources: lines || [],
    events: [],
    timeRanges: [],
    resourceTimeRanges: [],
    onDateRangeChange: onInfiniteScroll,
    emptyText: "No results found",
    enableDeleteKey: false,
    nonWorkingTime: true,
    zoomOnMouseWheel: false,
    zoomOnTimeAxisDoubleClick: false,
    minZoomLevel: 0,
    maxZoomLevel: viewPresets.length - 1,
    callOnFunctions: true,
    focusCls: "event-focused", // custom focused class to disable default focused styling
    displayDateFormat: "MMM D, YYYY hh:mm A",
    readOnly: !canUpdate,
    scrollManager: {
      startScrollDelay: 0,
    },
    onEventClick: handleEventClick(handleFullyScheduleWorkOrder, handleOpenNotesPopUp, readonly),
  });

  const [listeners] = useState({
    beforeEventEdit: readonly ? onOpenShowModal : onOpenModal,
    eventDrag: dragToReschedule({ gapFiller, settings }),
    afterEventDrop: dropToReschedule({ gapFiller, onRescheduleBlocks: rescheduleBlocks, onTrackUsage }),
    eventResizeEnd: resizeToReschedule(rescheduleBlocks),
    timeAxisHeaderClick: clickOnDateHeader(zoomIn),
    eventSelectionChange: handleMultiSelectMode,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  // A lot of these "depend" on functions that get recreated on render which cause infinite loops if
  // they are in the dependencies
  useEffect(() => {
    window.addEventListener("resize", handleTimedWindowResize);
  }, []); // Empty dependency array ensures this effect runs once on mount

  useEffect(() => {
    resizePresets();
  }, [sidebarOpen]);

  useEffect(() => {
    if (schedulerRef.current.instance.viewPreset.id === presetId(zoomLevel)) return;

    schedulerRef.current.instance.zoomTo({
      preset: presetId(zoomLevel),
      zoomLevel,
      visibleDate: { date: visibleTimeRange.startDate, block: "start" },
    });
  }, [schedulerRef.current, zoomLevel]);

  useEffect(() => {
    if (extendedView) {
      schedulerRef.current.instance.scrollToDate(new Date(visibleTimeRange.startDate), { block: "start" }).then(() => {
        highlightWorkBlock();
      });
    }
  }, [extendedView, schedulerRef.current, visibleTimeRange]);

  useEffect(() => {
    if (!extendedView) {
      const newStartDate = new Date(visibleTimeRange.startDate);

      schedulerRef.current.instance.zoomTo({
        preset: presetId(zoomLevel),
        zoomLevel,
        visibleDate: { date: new Date(visibleTimeRange.startDate), block: "start" },
        startDate: newStartDate,
        endDate: DateTime.addDuration(newStartDate, 14, "days"),
      });

      highlightWorkBlock();
    }
  }, [dataTimeRange, extendedView, schedulerRef.current]);

  useEffect(() => {
    highlightWorkBlock();
  }, [selectedWorkBlock]);

  useEffect(() => {
    setBryntumScheduler(schedulerRef.current.instance);
    gapFiller.setSchedule(schedulerRef.current.instance);

    // This listener has to be added here and not in the listeners array so we can add a buffer to it
    // If we add the buffer to the listeners array, it will be added to all of the listeners and that makes
    // the default Bryntum Edit and Create popups show up.
    // This method is recommended by Bryntum for things needing buffers
    schedulerRef.current.instance.addListener({
      thisObj: this,
      buffer: 300,
      visibleDateRangeChange: visibleDateRangeChanged,
    });

    schedulerRef.current.instance.resourceStore.on("filter", handleRefetchWorkOrders);

    schedulerRef.current.instance.rangeTooltip = new Tooltip({
      hideDelay: 100,
      hoverDelay: 100,
      allowOver: true,
      showOnHover: true,
      forSelector: ".b-sch-resourcetimerange",
      getHtml: ({ activeTarget }) =>
        renderToString(<UnavailabilityTooltip activeTarget={activeTarget} policy={policy} />),
    });
  }, [schedulerRef.current, gapFiller]);

  useEffect(() => {
    const workBlockEvents = Bryntum.buildWorkBlockEvents(workBlocks);
    const builtShiftTimeRanges = Bryntum.buildShiftTimeRanges(shiftInstances);
    const downtimeBlockEvents = Bryntum.buildDowntimeBlockEvents(downtimeBlocks);
    const builtUnavailabilityTimeRanges = Bryntum.buildUnavailabilityTimeRanges(unavailabilities);

    schedulerRef.current.instance.events = workBlockEvents.concat(downtimeBlockEvents);
    schedulerRef.current.instance.timeRanges = builtShiftTimeRanges;
    schedulerRef.current.instance.resourceTimeRanges = builtUnavailabilityTimeRanges;
  }, [shiftInstances, downtimeBlocks, workBlocks, unavailabilities]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <SchedulerContainer id="bryntum-scheduler">
      <BryntumScheduler ref={schedulerRef} {...config} listeners={listeners} presets={viewPresets} />
    </SchedulerContainer>
  );
}
