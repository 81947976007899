import React from "react";
import isEmpty from "lodash/isEmpty";
import assign from "lodash/assign";
import map from "lodash/map";
import { Box, Flex, Heading2, Page, Text } from "@nulogy/components";
import Form from "components/controls/Form";
import Select from "components/controls/Select";
import FormButtons from "components/controls/FormButtons";
import CustomWorkOrderFieldIndicator from "components/common/CustomWorkOrderFieldIndicator";
import StickyFooter from "components/controls/StickyFooter";

const colors = [
  { label: "blank", value: "blank" },
  { label: "red", value: "red" },
  { label: "green", value: "green" },
  { label: "yellow", value: "yellow" },
  { label: "orange", value: "orange" },
  { label: "blue", value: "blue" },
  { label: "gray", value: "gray" },
];

export default function CustomWorkOrderFieldView({ handleSave, customWorkOrderFields }) {
  const initialValues = {
    customWorkOrderField: assign({}, ...customWorkOrderFields.map((field) => ({ [field.name]: field.color }))),
  };

  return (
    <Page className="spec-custom-work-order-fields-page">
      <Heading2>Custom work order fields</Heading2>
      {isEmpty(customWorkOrderFields) ? (
        <Text marginTop="x1">No custom work order fields</Text>
      ) : (
        <Form initialValues={initialValues} onSave={handleSave}>
          {({ values: { customWorkOrderField } }) => (
            <>
              {map(customWorkOrderFields, ({ name }) => (
                <CustomWorkOrderField name={name} color={customWorkOrderField[name]} colors={colors} key={name} />
              ))}
              <StickyFooter>
                <FormButtons />
              </StickyFooter>
            </>
          )}
        </Form>
      )}
    </Page>
  );
}

function CustomWorkOrderField({ name, color }) {
  return (
    <Flex key={name} marginBottom="x3">
      <Box width={1 / 8} mr="x3">
        <Select field={`customWorkOrderField[${name}]`} options={colors} />
      </Box>
      <Flex width="18px" mr="x3" alignItems="center">
        <CustomWorkOrderFieldIndicator color={color} />
      </Flex>
      <Flex width={1 / 3} mr="x3" alignItems="center">
        <Text>{name}</Text>
      </Flex>
    </Flex>
  );
}
