import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useDowntimeShowModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.editDowntimeModalState, ...args };
    updateGlobalState({ editDowntimeModalState: newState });
  };

  return {
    open: (block) => {
      setState({ isOpen: true, block });
    },
    close: () => {
      setState({ isOpen: false, block: null });
    },
    block: state.editDowntimeModalState.block,
    isOpen: state.editDowntimeModalState.isOpen,
  };
}
