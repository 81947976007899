import { useQuery } from "@apollo/client";
import CompanyIndexView from "./CompanyIndexView";
import waitForQueries from "components/waitForQueries";
import { GetAllCompanies } from "hooks/apollo/company/company.gql";

export default function CompanyIndexContainer() {
  const queries = [useQuery(GetAllCompanies)];

  return waitForQueries({ queries })(CompanyIndexView)();
}
