import React from "react";
import { Box, SelectOption, theme } from "@nulogy/components";
import styled from "styled-components";
import LineStatusIndicator from "./LineStatusIndicator";
import Select from "components/controls/Select";

const StyledBox = styled(Box)`
  width: 184px;
  padding-top: ${theme.sizes.x1};
`;

export default function LineStatusSelect() {
  return (
    <StyledBox>
      <Select
        className="spec-line-status-select"
        components={{
          SingleValue: ({ children }) => <LineStatusIndicator label={children} />,
          Option: ({ children, ...props }) => (
            <SelectOption {...props} className={`spec-line-${children}`}>
              <LineStatusIndicator label={children} />
            </SelectOption>
          ),
        }}
        options={[
          { value: true, label: "Active" },
          { value: false, label: "Inactive" },
        ]}
        field="active"
      />
    </StyledBox>
  );
}
