import React from "react";
import { useField, useFormikContext } from "formik";
import { Checkbox as NulogyCheckbox } from "@nulogy/components";

export default function Checkbox({ labelText, field: name, onChange, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (onChange) {
      onChange({ checked, setFieldValue });
    }
    helpers.setValue(checked);
  };

  return (
    <NulogyCheckbox labelText={labelText} onChange={handleChange} checked={field.value} error={meta.error} {...props} />
  );
}
