import React from "react";
import styled from "styled-components";
import Timespan from "utils/Timespan";
import { formatDateTime, formatDuration } from "components/helpers/format";

const Tooltip = styled.table`
  border-spacing: 0;

  td {
    padding-left: 0;
  }

  tr {
    line-height: 1em;
  }
`;

const DateTimeLabel = styled.td`
  font-size: 12px;
  padding-right: 4px;
`;

const DateTime = styled.div`
  font-size: 12px;
  display: inline;
`;

const Duration = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: inline;
`;

const TeardownTime = styled.td`
  font-size: 12px;
`;

export default function BlockTooltip({ startDate, endDate, teardownTimeDuration, teardownTimeLabel }) {
  const duration = new Timespan(startDate, endDate).durationInSeconds();
  const formattedDuration = formatDuration(duration);
  const formattedTeardownTimeDuration = formatDuration(teardownTimeDuration);

  return (
    <Tooltip>
      <tbody>
        <tr>
          <DateTimeLabel>Starts at:</DateTimeLabel>
          <td>
            <DateTime data-testid="starts-at">{formatDateTime(startDate)}</DateTime>
          </td>
        </tr>
        <tr>
          <DateTimeLabel>Ends at:</DateTimeLabel>
          <td>
            <DateTime data-testid="ends-at">{formatDateTime(endDate)}</DateTime>
            &nbsp;
            <Duration data-testid="duration">({formattedDuration})</Duration>
          </td>
        </tr>
        {teardownTimeDuration > 0 && (
          <tr>
            <TeardownTime colSpan="2">
              Includes {teardownTimeLabel} <strong>({formattedTeardownTimeDuration})</strong>
            </TeardownTime>
          </tr>
        )}
      </tbody>
    </Tooltip>
  );
}
