export default class SessionStorage {
  constructor(serializer = DefaultSeriazlier) {
    this.serializer = serializer;
  }

  write(key, rawData) {
    sessionStorage.setItem(key, this.serializer.serialize(rawData));
  }

  read(key) {
    const serializedData = sessionStorage.getItem(key);

    return serializedData ? this.serializer.deserialize(serializedData) : {};
  }
}

export class DefaultSeriazlier {
  static serialize(rawData) {
    return JSON.stringify(rawData);
  }

  static deserialize(serializedData) {
    return JSON.parse(serializedData);
  }
}
