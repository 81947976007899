import WorkOrder from "./WorkOrder";
import WorkBlockFactory from "./WorkBlockFactory";
import DateTime from "utils/DateTime";
import Timespan from "utils/Timespan";

const SECONDS_IN_HOUR = 3600;

const ITEM_DESCRIPTION_LAYOUT = "item_description";
const ITEM_CODE_LAYOUT = "item_code";
const WORK_ORDER_DESCRIPTION_LAYOUT = "work_order_description";
const WORK_ORDER_ID_LAYOUT = "work_order_id";
const CUSTOMER_LAYOUT = "customer";

const LAYOUTS = [
  { label: "Item description", value: ITEM_DESCRIPTION_LAYOUT },
  { label: "Item code", value: ITEM_CODE_LAYOUT },
  { label: "Work order description", value: WORK_ORDER_DESCRIPTION_LAYOUT },
  { label: "Work order ID", value: WORK_ORDER_ID_LAYOUT },
  { label: "Customer", value: CUSTOMER_LAYOUT },
];

const WorkBlock = {
  addWorkTimeDuration,
  buildWithBlockTime: WorkBlockFactory.buildWithBlockTime,
  buildWithWorkTime: WorkBlockFactory.buildWithWorkTime,
  standardProductionRate,
  calculateBlockDurationForLine,
  description,
  effectiveProductionRate,
  efficiency,
  hasLaborOverride,
  isPastDue,
  laborRatio,
  quantity,
  updateBlockTime: WorkBlockFactory.updateBlockTime,
  updateTeardownTime: WorkBlockFactory.updateTeardownTime,
  updateWorkTime: WorkBlockFactory.updateWorkTime,
  lineEfficiency,
  workTime,
  workTimeDuration,
  CUSTOMER_LAYOUT,
  ITEM_DESCRIPTION_LAYOUT,
  ITEM_CODE_LAYOUT,
  WORK_ORDER_DESCRIPTION_LAYOUT,
  WORK_ORDER_ID_LAYOUT,
  LAYOUTS,
};

function isPastDue(workBlock) {
  const cancelled = WorkOrder.isCancelled(workBlock.workOrder);
  const completed = WorkOrder.isCompleted(workBlock.workOrder);
  const pastDue = workBlock.endsAt > DateTime.endOfDay(workBlock.workOrder.due).format();

  return !cancelled && !completed && pastDue;
}

// @keep_effective_production_rate_calculations_in_sync
function effectiveProductionRate(workBlock) {
  return standardProductionRate(workBlock) * efficiency(workBlock) * laborRatio(workBlock) * performance(workBlock);
}

function performance(workBlock) {
  if (workBlock?.performanceOverride > 0) {
    return workBlock.performanceOverride;
  } else {
    return workBlock.performance;
  }
}

function standardProductionRate(workBlock) {
  if (workBlock?.productionRateOverride > 0) {
    return workBlock.productionRateOverride;
  } else {
    return workBlock.standardProductionRate;
  }
}

function efficiency(workBlock) {
  return workBlock.efficiencyOverride || lineEfficiency(workBlock);
}

function lineEfficiency(workBlock) {
  if (workBlock.lineEfficiency) {
    return workBlock.lineEfficiency;
  } else {
    return workBlock.line?.efficiency;
  }
}

function laborRatio(workBlock) {
  if (hasLaborOverride(workBlock)) {
    return workBlock.laborOverride / workBlock.standardLabor;
  } else {
    return 1;
  }
}

function hasLaborOverride(workBlock) {
  return workBlock.productionRateDependsOnLabor && workBlock.laborOverride && workBlock.standardLabor;
}

function description({ workOrder }, workBlockLayout) {
  return {
    [ITEM_DESCRIPTION_LAYOUT]: workOrder.itemDescription,
    [ITEM_CODE_LAYOUT]: workOrder.itemCode,
    [WORK_ORDER_DESCRIPTION_LAYOUT]: workOrder.description,
    [WORK_ORDER_ID_LAYOUT]: workOrder.externalId ? `${workOrder.externalId}` : null,
    [CUSTOMER_LAYOUT]: workOrder.customerName,
  }[workBlockLayout];
}

function quantity(workBlock) {
  const calculatedQuantity = WorkBlock.effectiveProductionRate(workBlock) * workTime(workBlock).durationInHours();
  const rawQuantity = workBlock.quantity;
  if (!rawQuantity) return calculatedQuantity;

  const difference = Math.abs(calculatedQuantity - rawQuantity);
  const percentageDifference = difference / rawQuantity;

  return percentageDifference < 0.0001 ? rawQuantity : calculatedQuantity;
}

function calculateBlockDurationForLine(workBlock, line, newLineEfficiency = false) {
  const { teardownTimeDuration } = workBlock;
  const composedWorkBlock = {
    ...workBlock,
    line,
    lineEfficiency: newLineEfficiency ? line.efficiency : workBlock.lineEfficiency,
  };
  const rate = WorkBlock.effectiveProductionRate(composedWorkBlock);

  return teardownTimeDuration + (workBlock.quantity / rate) * SECONDS_IN_HOUR;
}

function workTimeDuration(workBlock) {
  return workTime(workBlock).durationInSeconds();
}

function workTime(workBlock) {
  const { workTimeStartsAt, workTimeEndsAt } = workBlock;

  return new Timespan(new Date(workTimeStartsAt), new Date(workTimeEndsAt));
}

function addWorkTimeDuration(workBlock, duration) {
  const { workTimeStartsAt, workTimeEndsAt } = workBlock;

  return WorkBlock.updateWorkTime(workBlock, {
    workTimeStartsAt,
    workTimeEndsAt: DateTime.addDuration(workTimeEndsAt, duration, "seconds"),
  });
}

export default WorkBlock;
