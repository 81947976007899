import { Box, Flex, Heading3, IconicButton } from "@nulogy/components";
import React, { useState } from "react";

export default function CollapsibleSection({ title, children, className, isOpen, onToggle }) {
  const [sectionOpen, setSectionState] = useState(isOpen);

  const toggle = () => {
    setSectionState(!sectionOpen);
    onToggle();
  };

  const icon = sectionOpen ? "downArrow" : "upArrow";

  return (
    <Box marginBottom="x3" className={className}>
      <Flex alignItems="center" marginBottom="x1">
        <Heading3 marginRight="x1" mb="half">
          {title}
        </Heading3>
        <IconicButton onClick={() => toggle()} icon={icon} type="button" data-testid="collapsible-section-toggle" />
      </Flex>
      {sectionOpen && <Box>{children}</Box>}
    </Box>
  );
}
