import React from "react";
import { Box, Card, Flex, PrimaryButton, QuietButton, theme } from "@nulogy/components";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import Form from "components/controls/Form";
import Textarea from "components/controls/Textarea";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";
import useNotesPopUp from "hooks/state/useNotesPopUp";
import { GetWorkOrder } from "hooks/apollo/workOrder/workOrder.gql";

const NAVBAR_HEIGHT = 56;
const FOOTER_HEIGHT = 40;
const POP_UP_HEIGHT = 120;
const POP_UP_WIDTH = 240;
const DEFAULT_POP_UP_TOP_OFFSET = 4;
const POP_UP_DISPLAY_HORIZONTAL_OFFSET = 61;
const LINE_RESIZE_HANDLE_WIDTH = 5;

function calculatePopUpLeftPosition({ workBlockLocation, teardownTimeWidth, scheduleLocation, lineLabelWidth }) {
  const popUpLeftPosition = workBlockLocation.right - teardownTimeWidth;

  const popUpRightEdge = popUpLeftPosition + POP_UP_WIDTH - POP_UP_DISPLAY_HORIZONTAL_OFFSET;
  const scheduleRightEdge = scheduleLocation.right;

  const scheduleLeftEdge = scheduleLocation.left + lineLabelWidth + LINE_RESIZE_HANDLE_WIDTH;
  const workBlockSpansEntireSchedule =
    workBlockLocation.left <= scheduleLeftEdge && workBlockLocation.right >= scheduleLocation.right;

  if (workBlockSpansEntireSchedule) {
    return scheduleLocation.width / 2;
  }

  if (popUpRightEdge > scheduleRightEdge) {
    return workBlockLocation.left - POP_UP_WIDTH + POP_UP_DISPLAY_HORIZONTAL_OFFSET;
  }

  return popUpLeftPosition - POP_UP_DISPLAY_HORIZONTAL_OFFSET;
}

function calculatePopUpTopPosition({ workBlockLocation, scheduleLocation }) {
  const popUpTopPosition = workBlockLocation.bottom - NAVBAR_HEIGHT;
  let popUpTopOffset = DEFAULT_POP_UP_TOP_OFFSET;

  const popUpBottomEdge = popUpTopPosition + POP_UP_HEIGHT;
  const scheduleBottomEdge = scheduleLocation.bottom - FOOTER_HEIGHT;

  if (popUpBottomEdge > scheduleBottomEdge) {
    popUpTopOffset = POP_UP_HEIGHT + workBlockLocation.height;
  }

  return popUpTopPosition - popUpTopOffset;
}

const NotesCard = styled(Card)`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  left: ${({ leftPosition }) => `${leftPosition}px`};
  top: ${({ topPosition }) => `${topPosition}px`};
  position: absolute;
  z-index: 10;
  background-color: ${theme.colors.white};
  padding: ${theme.space.x1};
  min-width: ${POP_UP_WIDTH}px;
  min-height: ${POP_UP_HEIGHT}px;
`;

export default function NotesPopUp() {
  const { workOrderId, workBlockId, isOpen, close } = useNotesPopUp();
  const updateWorkOrder = useUpdateWorkOrder();
  const { data } = useQuery(GetWorkOrder, {
    variables: { id: workOrderId },
    skip: !workOrderId,
  });

  if (!data) return null;

  const workBlockElement = document.getElementsByClassName(`work-block-${workBlockId}`)[0];
  if (!workBlockElement) return null;

  const workBlockLocation = workBlockElement.getBoundingClientRect();
  const teardownTimeElement = workBlockElement.getElementsByClassName("teardown-time")[0];
  const teardownTimeWidth = teardownTimeElement ? teardownTimeElement.getBoundingClientRect().width : 0;
  const lineLabelWidth = document.getElementsByClassName("schedule-line-cell")[0].getBoundingClientRect().width;
  const scheduleLocation = document.getElementById("bryntum-scheduler").getBoundingClientRect();
  const leftPosition = calculatePopUpLeftPosition({
    workBlockLocation,
    teardownTimeWidth,
    scheduleLocation,
    lineLabelWidth,
  });
  const topPosition = calculatePopUpTopPosition({ workBlockLocation, scheduleLocation });

  const handleSave = ({ notes }) => {
    updateWorkOrder({ id: workOrderId, notes });
    close();
  };

  const handleBeforeSave = (values, actions) => {
    actions.setFieldValue("notes", values.notes.trim());

    return values;
  };

  return (
    <NotesCard isOpen={isOpen} leftPosition={leftPosition} topPosition={topPosition} className="spec-edit-notes-pop-up">
      <Form
        initialValues={{ notes: data.workOrder.notes || "" }}
        onSave={handleSave}
        onBeforeSave={handleBeforeSave}
        enableReinitialize
      >
        <Box marginBottom="x1">
          <Textarea
            id="work-order-details-notes"
            field="notes"
            rows={3}
            style={{
              color: theme.colors.black,
              borderColor: theme.colors.grey,
              fontSize: theme.fontSizes.small,
              lineHeight: theme.lineHeights.smallTextCompressed,
              minWidth: "224px",
              minHeight: "72px",
            }}
          />
        </Box>
        <Flex>
          <PrimaryButton size="small" type="submit" mr="half">
            Save
          </PrimaryButton>
          <QuietButton size="small" type="button" onClick={close}>
            Close
          </QuietButton>
        </Flex>
      </Form>
    </NotesCard>
  );
}
