import React, { useState } from "react";
import { Box, PrimaryButton, Text, theme } from "@nulogy/components";
import { useMutation } from "@apollo/client";
import JobCount from "./JobCount";
import usePublishSummaryModal from "hooks/state/usePublishSummaryModal";
import usePublishWorkBlockModal from "hooks/state/usePublishWorkBlockModal";
import { PublishWorkBlock } from "hooks/apollo/publishWorkBlock.gql";
import Form from "components/controls/Form";
import ModalFooter from "components/common/ModalFooter";

const FORM_ID = "publish-work-block-modal";
const DEFAULT_RESPONSE_STATE = { success: null, errors: [] };

function formErrors(response) {
  const configurationErrors = response.errors.filter((error) => error.path.length !== 3);

  return configurationErrors.length ? configurationErrors : null;
}

function jobCreatedOrUpdatedMessage({ isUpdating }) {
  const verb = isUpdating ? "updated" : "created";

  return (
    <Box fontSize={theme.fontSizes.smaller} lineHeight={theme.lineHeights.smallerText} marginBottom={theme.sizes.half}>
      Jobs to be {verb}: <JobCount>1</JobCount>
    </Box>
  );
}

export default function PublishWorkBlockModalForm({ workBlock }) {
  const isUpdating = workBlock && !!workBlock.jobId;

  const [response, setResponse] = useState(DEFAULT_RESPONSE_STATE);
  const resetResponse = () => setResponse(DEFAULT_RESPONSE_STATE);
  const [publishWorkBlock, { loading: scheduleWorkBlock }] = useMutation(PublishWorkBlock);
  const submitEnabled = !scheduleWorkBlock;
  const { open: openSummaryModal } = usePublishSummaryModal();
  const { close: closePublishModal } = usePublishWorkBlockModal();

  const onSave = () => {
    resetResponse();

    return publishWorkBlock({
      variables: { workBlockId: workBlock.id },
      update: (_proxy, { data }) => {
        const publishResponse = data.publishWorkBlock.publishedWorkBlock;
        const configurationErrors = publishResponse.errors.filter((error) => error.path.length !== 3);
        if (configurationErrors.length) {
          setResponse(publishResponse);
        } else {
          closePublishModal();
          openSummaryModal({
            createdIds: [publishResponse.createdId],
            updatedIds: [publishResponse.updatedId],
            workBlocks: [publishResponse.workBlock],
            ...publishResponse,
          });
        }
      },
    });
  };

  return (
    <Form id={FORM_ID} onSave={onSave} errors={formErrors(response)}>
      <Text mb="x2">
        Publishing a block will create a corresponding job under its work order. If a block was previously published,
        then updates will be reflected in its existing job.
      </Text>
      <ModalFooter justifyContent="flex-start" alignItems="center">
        <Box marginRight="x2">
          <PrimaryButton type="submit" form={FORM_ID} disabled={!submitEnabled} className="pendo-create-job">
            Publish
          </PrimaryButton>
        </Box>
        <Text data-testid="job-count-message" className="spec-publish-modal-job-count-message">
          {jobCreatedOrUpdatedMessage({ isUpdating })}
        </Text>
      </ModalFooter>
    </Form>
  );
}
