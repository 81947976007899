import React from "react";
import { Box, Text } from "@nulogy/components";

export default function FilterLabel({ value, marginTop = "x2" }) {
  return (
    <Box marginTop={marginTop}>
      <Text fontSize="small" fontWeight="bold">
        {value}
      </Text>
    </Box>
  );
}
