const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 3600;

export default class Timespan {
  constructor(startTime, endTime) {
    this.startTime = startTime;
    this.endTime = endTime;
  }

  static build(object) {
    return new Timespan(object.startDate, object.endDate);
  }

  shrinkStartTo(time) {
    if (!time || time < this.startTime) {
      return this;
    } else if (this.startTime <= time && time <= this.endTime) {
      return new Timespan(time, this.endTime);
    } else {
      return new Timespan(this.endTime, this.endTime);
    }
  }

  shrinkEndTo(time) {
    if (!time || this.endTime < time) {
      return this;
    } else if (this.startTime <= time && time <= this.endTime) {
      return new Timespan(this.startTime, time);
    } else {
      return new Timespan(this.startTime, this.startTime);
    }
  }

  durationInHours() {
    return this.durationInSeconds() / SECONDS_IN_HOUR;
  }

  durationInMinutes() {
    return this.durationInSeconds() / SECONDS_IN_MINUTE;
  }

  durationInSeconds() {
    return (this.endTime - this.startTime) / MILLISECONDS_IN_SECOND;
  }

  include(time) {
    return this.startTime <= time && time < this.endTime;
  }
}
