import { useApolloClient, useSubscription } from "@apollo/client";
import {
  OnDowntimeBlockCreated,
  OnDowntimeBlockDeleted,
  OnDowntimeBlockUpdated,
  OnDowntimeBlocksUpdated,
} from "./downtimeBlock/downtimeBlock.gql";
import { OnShiftCreated, OnShiftDeleted, OnShiftUpdated } from "./shift/shift.gql";
import {
  OnWorkBlockCreated,
  OnWorkBlockDeleted,
  OnWorkBlockUpdated,
  OnWorkBlocksUpdated,
} from "./workBlock/workBlock.gql";
import { OnWorkOrderCreated, OnWorkOrderDeleted, OnWorkOrderUpdated } from "./workOrder/workOrder.gql";
import { insertDowntimeBlock, removeDowntimeBlock } from "./downtimeBlock/helpers/handleUpdateDowntimeBlocks";
import { insertWorkBlock, removeWorkBlock } from "./workBlock/helpers/handleUpdateWorkBlocks";
import { insertWorkOrder, removeWorkOrder } from "./workOrder/helpers/handleUpdateWorkOrders";
import useFetchShiftInstances from "./shiftInstance/useFetchShiftInstances";
import useGetWorkOrderFiltered from "./workOrder/useGetWorkOrderFiltered";

export default function useSubscriptions() {
  const client = useApolloClient();
  const { refetch: refetchShiftInstances } = useFetchShiftInstances();
  const { getWorkOrderFiltered, setUpdatedWorkOrder } = useGetWorkOrderFiltered();

  const addDowntimeToCache = ({ data: { data } }) => {
    if (!data || !data.downtimeBlockCreated) return;

    insertDowntimeBlock(data.downtimeBlockCreated.downtimeBlock, client);
  };

  const removeDowntimeFromCache = ({ data: { data } }) => {
    if (!data || !data.downtimeBlockDeleted) return;

    removeDowntimeBlock(data.downtimeBlockDeleted.downtimeBlock.id, client);
  };

  const updateShiftInstanceCache = ({ data: { data } }) => {
    if (!data || !(data.shiftCreated || data.shiftDeleted || data.shiftUpdated)) return;

    refetchShiftInstances();
  };

  const addWorkBlockToCache = ({ data: { data } }) => {
    if (!data || !data.workBlockCreated) return;

    insertWorkBlock(data.workBlockCreated.workBlock, client);
  };

  const removeWorkBlockFromCache = ({ data: { data } }) => {
    if (!data || !data.workBlockDeleted) return;

    removeWorkBlock(data.workBlockDeleted.workBlock.id, client);
  };

  const addWorkOrderToCache = ({ data: { data } }) => {
    if (!data || !data.workOrderCreated) return;

    insertWorkOrder(data.workOrderCreated.workOrder, client);
  };

  const removeWorkOrderFromCache = ({ data: { data } }) => {
    if (!data || !data.workOrderDeleted) return;

    removeWorkOrder(data.workOrderDeleted.workOrder.id, client);
  };

  const updateFilteredWorkOrders = ({ data: { data } }) => {
    if (!data || !data.workOrderUpdated) return;

    setUpdatedWorkOrder(data.workOrderUpdated.workOrder);
    getWorkOrderFiltered();
  };

  useSubscription(OnDowntimeBlockCreated, { onData: addDowntimeToCache });
  useSubscription(OnDowntimeBlockDeleted, { onData: removeDowntimeFromCache });
  useSubscription(OnDowntimeBlockUpdated);
  useSubscription(OnDowntimeBlocksUpdated);
  useSubscription(OnShiftCreated, { onData: updateShiftInstanceCache });
  useSubscription(OnShiftDeleted, { onData: updateShiftInstanceCache });
  useSubscription(OnShiftUpdated, { onData: updateShiftInstanceCache });
  useSubscription(OnWorkBlockCreated, { onData: addWorkBlockToCache });
  useSubscription(OnWorkBlockDeleted, { onData: removeWorkBlockFromCache });
  useSubscription(OnWorkBlockUpdated);
  useSubscription(OnWorkBlocksUpdated);
  useSubscription(OnWorkOrderCreated, { onData: addWorkOrderToCache });
  useSubscription(OnWorkOrderDeleted, { onData: removeWorkOrderFromCache });
  useSubscription(OnWorkOrderUpdated, { onData: updateFilteredWorkOrders });
}
