import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import SiteEditView from "./SiteEditView";
import NotFound from "components/pages/notFound/NotFound";
import { GetAllCompanies } from "hooks/apollo/company/company.gql";
import { DeleteSite, GetAssignedSites, GetSite, UpdateSite } from "hooks/apollo/site/site.gql";
import { GetAllTimeZones } from "hooks/apollo/timeZone/timeZone.gql";
import paths from "config/routePaths";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import Authorization from "policies/Authorization";
import useGoBack from "hooks/useGoBack";

export default function SiteEditContainer({ id, companyId }) {
  const { goBack } = useGoBack();
  const policy = Authorization.usePolicy();
  const applicationContext = useApplicationContext();
  const { nulogyAdmin } = applicationContext;
  const leavePage = () => {
    goBack(paths.siteIndex);
  };
  const [updateSite] = useMutation(UpdateSite, { onCompleted: () => leavePage() });
  const [removeSite] = useMutation(DeleteSite, { onCompleted: () => leavePage() });
  const { data: companiesData, loading: companiesLoading } = useQuery(GetAllCompanies);
  const { data, loading, error: queryError } = useQuery(GetSite, { variables: { id } });
  const { data: timezoneData, loading: timezonesLoading } = useQuery(GetAllTimeZones);
  const [error, setError] = useState(false);

  if (queryError?.message === "Site not found") return <NotFound />;
  if (loading || timezonesLoading || companiesLoading) return null;

  const canCreateUser = policy.canCreate(Authorization.EntityTypes.User);
  const canUpdateCompany = policy.canUpdate(Authorization.EntityTypes.Company);
  const createUserPath = paths.userNewForSite(data?.site?.company?.id, id);

  const canDelete = policy.canDelete(Authorization.EntityTypes.Site);
  const handleDelete =
    canDelete && (() => removeSite({ variables: { id }, refetchQueries: [{ query: GetAssignedSites }] }));

  const handleCancel = () => leavePage();

  const handleSave = (args) => {
    const defaultSite = {
      name: "",
    };

    const site = { ...defaultSite, ...data?.site, ...args, companyId: args.company?.id };

    return updateSite({ variables: site, refetchQueries: [{ query: GetAssignedSites }] });
  };

  return (
    <SiteEditView
      timeZones={timezoneData?.timeZones}
      companies={companiesData?.companies || []}
      companyId={companyId}
      site={data?.site}
      onCancel={handleCancel}
      onDelete={handleDelete}
      onSave={handleSave}
      canCreateUser={canCreateUser}
      canUpdateCompany={canUpdateCompany}
      createUserPath={createUserPath}
      nulogyAdmin={nulogyAdmin}
      error={error}
      setError={setError}
    />
  );
}
