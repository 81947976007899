import { useMutation } from "@apollo/client";

import { UpdateAvailabilityOverrides } from "./availabilityOverride.gql";
import { transformAvailabilityOverrideData } from "./helpers/transformAvailabilityOverrideData";

export default function useUpdateAvailabilityOverrides() {
  const [updateAvailabilityOverrides, { loading }] = useMutation(UpdateAvailabilityOverrides);

  return [
    (availabilityOverrides, startDate, endDate) =>
      updateAvailabilityOverrides({
        variables: {
          availabilityOverrides: transformAvailabilityOverrideData(availabilityOverrides),
          startDate,
          endDate,
        },
      }),
    { loading },
  ];
}
