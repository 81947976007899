import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PublishWorkOrderDatesFormView from "./PublishWorkOrderDatesFormView";
import ApplicationContext from "domain/ApplicationContext";
import WorkOrder from "domain/WorkOrder";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useGetCustomers from "hooks/apollo/customer/useGetCustomers";
import { PublishWorkOrderDates, PublishWorkOrderDatesDryRun } from "hooks/apollo/publishWorkOrderDates.gql";
import { GetWorkOrders } from "hooks/apollo/workOrder/workOrder.gql";
import useTimeZone from "hooks/useTimeZone";
import DateTime from "utils/DateTime";
import String from "utils/String";

const DEFAULT_RESPONSE_STATE = { success: null, errors: [] };
const DEFAULT_START_DATE_STATE = DateTime.startOfDay(new Date()).toDate();
const DEFAULT_FILTER_STATE = {
  startDate: DEFAULT_START_DATE_STATE,
  codes: [],
  customers: [],
  statuses: [],
  dueDateFrom: null,
  dueDateTo: null,
};

export default function PublishWorkOrderDatesFormContainer() {
  const { overrideToSiteTimeZone } = useTimeZone();
  const applicationContext = useApplicationContext();

  const [response, setResponse] = useState(DEFAULT_RESPONSE_STATE);
  const resetResponse = () => setResponse(DEFAULT_RESPONSE_STATE);
  const [workOrderCountTotal, setWorkOrderCountTotal] = useState(0);
  const [workOrderCountOnInactiveLines, setWorkOrderCountOnInactiveLines] = useState(0);
  const [filters, setFilters] = useState(DEFAULT_FILTER_STATE);

  const [publishWorkOrderDates] = useMutation(PublishWorkOrderDates);
  const [getWorkOrderCounts, { loading }] = useLazyQuery(PublishWorkOrderDatesDryRun, {
    fetchPolicy: "network-only",
    onCompleted: ({ publishWorkOrderDatesDryRun: { count, workOrdersOnInactiveLines } }) => {
      setWorkOrderCountTotal(count);
      setWorkOrderCountOnInactiveLines(workOrdersOnInactiveLines);
    },
  });

  useEffect(() => {
    if (filters.startDate) {
      getWorkOrderCounts({
        variables: publishWorkOrderDatesInformation(filters, applicationContext, overrideToSiteTimeZone),
      });
    }
  }, [filters]); // eslint-disable-line

  const { data: customerData } = useGetCustomers();
  const customers = customerData?.customers || [];
  const { OPEN, BOOKED } = WorkOrder.STATUS_TYPES;

  const { data: workOrderData } = useQuery(GetWorkOrders, {
    variables: { filters: { statuses: [OPEN, BOOKED], showScheduledCompletedOrCancelled: true } },
    fetchPolicy: "no-cache",
  });

  const workOrders = workOrderData?.workOrders || [];

  const showWorkOrderCount = !loading && filters.startDate;

  const handleSave = () => {
    resetResponse();

    if (!confirmed(workOrderCountTotal)) return Promise.resolve();

    return publishWorkOrderDates({
      variables: publishWorkOrderDatesInformation(filters, applicationContext, overrideToSiteTimeZone),
      update: (_proxy, { data }) => {
        setResponse(data.publishWorkOrderDates.publishedWorkOrderDates);
      },
    });
  };

  const handleFilterChange = (updatedFields) => {
    resetResponse();
    setFilters((prevState) => ({ ...prevState, ...updatedFields }));
  };

  return (
    <PublishWorkOrderDatesFormView
      onSave={handleSave}
      onFilterChange={handleFilterChange}
      filters={filters}
      customers={customers}
      workOrders={workOrders}
      workOrderCountTotal={workOrderCountTotal}
      workOrderCountOnInactiveLines={workOrderCountOnInactiveLines}
      showWorkOrderCount={showWorkOrderCount}
      response={response}
    />
  );
}

function publishWorkOrderDatesInformation(filters, applicationContext, overrideToSiteTimeZone) {
  return {
    startsAt: startOfDay(filters.startDate, applicationContext, overrideToSiteTimeZone),
    customers: filters.customers,
    codes: filters.codes,
    statuses: filters.statuses,
    dueDateFrom: startOfDay(filters.dueDateFrom, applicationContext, overrideToSiteTimeZone),
    dueDateTo: endOfDay(filters.dueDateTo, applicationContext, overrideToSiteTimeZone),
  };
}

function startOfDay(date, applicationContext, overrideToSiteTimeZone) {
  if (!date) return null;

  return overrideToSiteTimeZone(ApplicationContext.startOfWorkDay(applicationContext, date));
}

function endOfDay(date, applicationContext, overrideToSiteTimeZone) {
  if (!date) return null;

  return overrideToSiteTimeZone(ApplicationContext.endOfWorkDay(applicationContext, date));
}

function confirmed(workOrderCountTotal) {
  // eslint-disable-next-line
  return window.confirm(
    `Are you sure you want to update ${workOrderCountTotal} ${String.pluralize("work order", workOrderCountTotal)}?`,
  );
}
