import React from "react";
import { formatLabor } from "components/helpers/format";

export default function ShiftInstanceTooltip({ timeRange }) {
  const [shiftName, maxLabor, startingLabor] = timeRange.id.split("$");

  return (
    <>
      <span style={{ fontWeight: "bold" }}>{shiftName}</span>
      <br />
      Max labor: {formatLabor(maxLabor)}
      <br />
      Starting labor: {formatLabor(startingLabor)}
    </>
  );
}
