/*
  We are not using the ActionCableLink from
  "graphql-ruby-client/subscriptions/ActionCableLink"
  because it does not implement the disconnect method for the subscriptions

  We took the file inline and implemented the method
 */
import { ApolloLink, Observable } from "@apollo/client/core";
import { print } from "graphql";

class ActionCableLink extends ApolloLink {
  constructor({ cable, channelName, actionName, connectionParams, onDisconnect }) {
    super();
    this.cable = cable;
    this.channelName = channelName || "GraphqlChannel";
    this.actionName = actionName || "execute";
    this.connectionParams = connectionParams || {};
    this.onDisconnect = onDisconnect;
  }

  // Interestingly, this link does _not_ call through to `next` because
  // instead, it sends the request to ActionCable.
  request(operation, _next) {
    return new Observable((observer) => {
      const channelId = Math.round(Date.now() + Math.random() * 100000).toString(16);
      const { actionName } = this;
      const connectionParams =
        typeof this.connectionParams === "function" ? this.connectionParams(operation) : this.connectionParams;

      const { onDisconnect } = this;
      const channel = this.cable.subscriptions.create(
        {
          channel: this.channelName,
          channelId,
          ...connectionParams,
        },
        {
          connected() {
            channel.perform(actionName, {
              query: operation.query ? print(operation.query) : null,
              variables: operation.variables,
              operationName: operation.operationName,
            });
          },
          disconnected(props) {
            if (onDisconnect) {
              onDisconnect(props);
            }
          },
          received(payload) {
            if (payload.result.data || payload.result.errors) {
              observer.next(payload.result);
            }

            if (!payload.more) {
              observer.complete();
            }
          },
        },
      );

      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(channel, { closed: false });
    });
  }
}

export default ActionCableLink;
