import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useAvailabilityOverridesUnsavedChangesModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.updateAvailabilityOverridesUnsavedChangesModalState, ...args };
    updateGlobalState({ updateAvailabilityOverridesUnsavedChangesModalState: newState });
  };

  return {
    open: (startDate, endDate) => setState({ isOpen: true, startDate, endDate }),
    close: () => setState({ isOpen: false, startDate: null, endDate: null }),
    isOpen: state.updateAvailabilityOverridesUnsavedChangesModalState.isOpen,
    startDate: state.updateAvailabilityOverridesUnsavedChangesModalState.startDate,
    endDate: state.updateAvailabilityOverridesUnsavedChangesModalState.endDate,
  };
}
