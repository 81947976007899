import React, { useState } from "react";
import { Box, Heading2, IconicButton, Page, Text, theme } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import NewShift from "./NewShift";
import Shift from "./Shift";
import { removeShift } from "hooks/apollo/shift/helpers/handleUpdateShifts";
import useDeleteShift from "hooks/apollo/shift/useDeleteShift";
import DeleteConfirmationModal from "components/common/DeleteConfirmationModal";
import useDeleteConfirmationModal from "hooks/state/useDeleteConfirmationModal";
import Uuid from "utils/Uuid";
import { GetAllShifts } from "hooks/apollo/shift/shift.gql";
import { GridBox6 } from "components/common/EditableListStyledComponents";

export default function Shifts() {
  const { data, loading } = useQuery(GetAllShifts);
  const [newShiftIds, setNewShiftIds] = useState([]);
  const { open: openModal, data: deleteData } = useDeleteConfirmationModal();
  const deleteShift = useDeleteShift();

  const handleAddNewShift = () => {
    setNewShiftIds([...newShiftIds, Uuid.generate()]);
  };

  const handleRemoveNewShift = (shiftId) => {
    setNewShiftIds(newShiftIds.filter((value) => value !== shiftId));
  };

  const handleDelete = (shift) => {
    openModal(shift);
  };

  const onDelete = (shift) =>
    deleteShift({
      variables: { id: shift.id },
      update: (proxy) => {
        removeShift(shift.id, proxy);
      },
    });

  function renderContent() {
    if (loading) return null;

    const hasShifts = newShiftIds.length > 0 || data.shifts.length > 0;

    const addButtonLabel = "Create a new shift";
    const deleteMessage = `This shift (${deleteData.name}) will be permanently deleted; however, its scheduled blocks will not be impacted.`;

    return (
      <Box width="1140px">
        <DeleteConfirmationModal title="Delete shift" message={deleteMessage} onDelete={onDelete} />
        <IconicButton data-testid="add-button" type="button" icon="add" marginBottom="x2" onClick={handleAddNewShift}>
          {addButtonLabel}
        </IconicButton>
        {hasShifts && (
          <Box data-testid="shifts-table">
            <GridBox6>
              <Text>Name</Text>
              <Text>Days</Text>
              <Text>Start time</Text>
              <Text>End time</Text>
            </GridBox6>
            <hr style={{ marginBottom: "0px" }} />
            {newShiftIds.map((newShiftId) => (
              <NewShift shiftId={newShiftId} onRemoveNewShift={handleRemoveNewShift} key={newShiftId} />
            ))}
            {data.shifts.map((shift, index) => (
              <Shift key={shift.id} shift={shift} onDelete={handleDelete} index={index} />
            ))}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Page className="spec-shifts-index-page">
      <Heading2 mb="x3">Shifts</Heading2>
      <Box marginBottom="x3" color={theme.colors.darkGrey} maxWidth="600px">
        Create, edit, or delete your site’s shifts on this page.
      </Box>
      {renderContent()}
    </Page>
  );
}
