import Modal from "react-modal";
import React from "react";
import ImportFormContainer from "./ImportFormContainer";
import endpoints from "config/endpoints";

const MODAL_STYLES = {
  content: {
    fontFamily: "system-ui",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 901,
  },
};

const IMPORT_TYPES = {
  items: {
    modalHeading: "Import items",
    endpoint: endpoints.importItems,
  },
  workOrders: {
    modalHeading: "Import work orders",
    endpoint: endpoints.importWorkOrders,
  },
};

export default function ImportModal({ importKey, modalIsOpen, onClose }) {
  const importType = IMPORT_TYPES[importKey];

  const renderImportForm = () => (
    <>
      <h2>{importType.modalHeading}</h2>
      <ImportFormContainer endPoint={importType.endpoint} onCancel={onClose} />
    </>
  );

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={onClose} style={MODAL_STYLES}>
      {importType && renderImportForm()}
    </Modal>
  );
}
