import React from "react";
import { Icon, Text } from "@nulogy/components";
import renderToString from "./renderToString";

export default function filterHeaderRenderer() {
  return renderToString(
    <div>
      <Text inline fontSize="small" fontWeight="medium" style={{ verticalAlign: "middle" }}>
        Filter line(s)
      </Text>
      <Icon icon="help" color="darkBlue" size="16px" ml="half" style={{ verticalAlign: "middle" }} />
    </div>,
  );
}
