import React from "react";
import { Modal as NDSModal } from "@nulogy/components";

export default function Modal({ children, ...modalProps }) {
  return (
    <NDSModal overlayClassName="transparent-modal-overlay" ariaHideApp={false} {...modalProps}>
      {children}
    </NDSModal>
  );
}
