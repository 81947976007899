import { useLazyQuery } from "@apollo/client";
import { GetEngagedSites } from "./usageData.gql";
import useTimeZone from "hooks/useTimeZone";

export default function useGetEngagedSites({ eventNames, startDate, endDate, threshold, onCompleted }) {
  const { overrideToSiteTimeZone } = useTimeZone();

  const [fetch] = useLazyQuery(GetEngagedSites, {
    variables: {
      eventNames,
      startDate: overrideToSiteTimeZone(startDate),
      endDate: overrideToSiteTimeZone(endDate),
      threshold,
    },
    onCompleted,
  });

  return { fetch };
}
