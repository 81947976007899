const isDevServer = process.env.NODE_ENV === "development" && process.env.REACT_APP_E2E_TEST_MODE !== "local";
const isLocalEndToEndTests = process.env.NODE_ENV === "development" && process.env.REACT_APP_E2E_TEST_MODE === "local";

let urlPrefix = "";
if (isDevServer) {
  urlPrefix = "http://localhost:8001";
} else if (isLocalEndToEndTests) {
  urlPrefix = "http://localhost:8081";
}

const endpoints = {
  cable: `${urlPrefix}/backend/cable`,
  graphql: `${urlPrefix}/backend/graphql`,
  login: `${urlPrefix}/backend/login`,
  logout: `${urlPrefix}/backend/logout`,
  importItems: `${urlPrefix}/backend/import-items`,
  importWorkOrders: `${urlPrefix}/backend/import-work-orders`,
  exportItems: `${urlPrefix}/backend/export-items`,
  exportWorkOrders: `${urlPrefix}/backend/export-work-orders`,
  exportSchedule: `${urlPrefix}/backend/export-schedule`,
  switchSite: (id) => `${urlPrefix}/backend/switch-site/${id}`,
};

export default endpoints;
