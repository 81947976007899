import { useMutation } from "@apollo/client";
import { DeleteWorkOrder } from "./workOrder.gql";
import { removeWorkOrder } from "./helpers/handleUpdateWorkOrders";
import { GetShiftInstanceByRange } from "hooks/apollo/shiftInstance/shiftInstance.gql";
import { useSchedulerState } from "hooks/state";
import { removeWorkBlocksForWorkOrder } from "hooks/apollo/workBlock/helpers/handleUpdateWorkBlocks";
import useTimeZone from "hooks/useTimeZone";

export default function useDeleteWorkOrder(props) {
  const {
    state: { dataTimeRange },
  } = useSchedulerState();
  const [deleteWorkOrder] = useMutation(DeleteWorkOrder, props);
  const { overrideToSiteTimeZone } = useTimeZone();

  return ({ workOrderId }) => {
    const start = overrideToSiteTimeZone(dataTimeRange.startDate);
    const end = overrideToSiteTimeZone(dataTimeRange.endDate);

    return deleteWorkOrder({
      variables: {
        id: workOrderId,
      },
      refetchQueries: [
        {
          query: GetShiftInstanceByRange,
          variables: {
            scheduledStart: start,
            scheduledEnd: end,
          },
        },
      ],
      update: (proxy) => {
        removeWorkBlocksForWorkOrder(workOrderId, proxy);
        removeWorkOrder(workOrderId, proxy);
      },
    });
  };
}
