import React from "react";
import styled from "styled-components";
import { Box, Flex, Icon, theme } from "@nulogy/components";
import Quantity from "./Quantity";
import Duration from "./Duration";
import { formatDate, formatQuantity } from "components/helpers/format";
import WorkOrderFlag from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkOrderFlag";
import WorkOrder from "domain/WorkOrder";

const Info = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  line-height: 1.8;
  padding: 0 16px 5px 6px;
  font-size: 12px;
`;

const InfoBox = styled(Box)`
  overflow: hidden;
`;

const InfoLeft = styled.div`
  height: 20px;
  font-weight: ${theme.fontWeights.medium};
`;

const InfoRight = styled.div`
  height: 20px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${({ color }) => `color: ${color};` || ""}
`;

const PastDue = styled(InfoLeft)`
  color: ${theme.colors.red};
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const PastDueSpan = styled.span`
  position: relative;
  margin-left: 2px;
`;

export default function ProductionDetails({ workOrder }) {
  const { due, pastDue, producedQuantity, quantity, unitOfMeasure, flag } = workOrder;

  const dueDate = formatDate(due);
  const roundedQuantity = formatQuantity(quantity);
  const roundedProducedQuantity = formatQuantity(producedQuantity);
  const workOrderFullyProduced = WorkOrder.isFullyProduced(workOrder);

  return (
    <Info>
      <Box>
        <InfoLeft> &nbsp; </InfoLeft>
        <InfoLeft> &nbsp; </InfoLeft>
        <Flex alignItems="center" data-testid="due-date">
          <WorkOrderFlag flag={flag} />
          {pastDue ? (
            <PastDue className="spec-due-date">
              <Icon icon="error" size="16px" />
              <PastDueSpan>Due {dueDate || "N/A"}</PastDueSpan>
            </PastDue>
          ) : (
            <InfoLeft className="spec-due-date">Due {dueDate || "N/A"}</InfoLeft>
          )}
        </Flex>
      </Box>
      <InfoBox>
        <InfoRight>
          <Quantity {...workOrder} />
        </InfoRight>
        <InfoRight color={workOrderFullyProduced ? theme.colors.green : null} data-testid="produced-quantity">
          {roundedProducedQuantity}/{roundedQuantity} {unitOfMeasure} produced
        </InfoRight>
        <InfoRight>
          <Duration workOrder={workOrder} />
        </InfoRight>
      </InfoBox>
    </Info>
  );
}
