import isEmpty from "lodash/isEmpty";
import flagsMenuItem from "components/common/Scheduler/flagsMenuItem";
import teardownTimeMenuItem from "components/common/Scheduler/teardownTimeMenuItem";
import unscheduleMenuItem from "components/common/Scheduler//unscheduleMenuItem";

export default function workBlockContextMenu({
  blockData,
  canPublishWorkBlock,
  items,
  onFullyScheduleWorkOrder,
  onOpenEditWorkBlock,
  onPublishWorkBlock,
  onScheduleTeardownTime,
  onSplitWorkBlockAcrossShifts,
  onUnscheduleTeardownTime,
  onUpdateWorkOrder,
  onViewNotesPopUp,
  openWorkOrderDetails,
  teardownTimes,
  unscheduleBlock,
  unscheduleWorkOrder,
}) {
  const { block } = blockData;

  const viewWorkOrderDetails = ({ eventRecord }) => {
    const { data } = eventRecord;

    openWorkOrderDetails(data.block.workOrder.id);
  };

  const handleEditTeardownTime = ({ eventRecord }) => {
    onOpenEditWorkBlock(eventRecord);
    setTimeout(() => {
      document.getElementById("teardown-time-label")?.focus();
    }, 0);
  };

  const splitWorkBlockAcrossShifts = {
    text: "Split across shifts",
    name: "split-across-shifts",
    onItem: onSplitWorkBlockAcrossShifts,
    cls: "pendo-override-production-rate-context-menu-item",
  };

  const editWorkOrderNotesMenuItem = {
    text: "Edit work order notes",
    name: "edit-work-order-notes",
    onItem: onViewNotesPopUp,
    cls: "pendo-edit-notes-context-menu-item",
  };

  const fullyScheduleWorkOrderMenuItem = {
    text: "Fully schedule",
    name: "fully-schedule",
    onItem: onFullyScheduleWorkOrder,
    cls: "pendo-fully-schedule-context-menu-item",
  };

  const publishWorkBlock = {
    text: "Publish",
    name: "publish",
    onItem: onPublishWorkBlock,
    cls: "pendo-publish-work-block-context-menu-item",
  };

  return {
    edit: {
      ...items.editEvent,
      text: "Edit work block",
      icon: false,
      cls: "pendo-edit-work-block-context-menu-item",
    },
    splitWorkBlockAcrossShifts,
    scheduleTeardownTimeMenu:
      !block.lockedOnSchedule && !isEmpty(teardownTimes)
        ? teardownTimeMenuItem({
            onCustomTeardownTime: handleEditTeardownTime,
            onScheduleTeardownTime,
            teardownTimes,
            workBlock: block,
          })
        : false,
    scheduleTeardownTime:
      !block.lockedOnSchedule && isEmpty(teardownTimes)
        ? {
            text: block.teardownTimeDuration === 0 ? "Schedule teardown time" : "Edit teardown time",
            name: "schedule-teardown-time",
            onItem: handleEditTeardownTime,
            cls: "pendo-schedule-unconfigured-teardown-time-context-menu-item",
          }
        : false,
    unscheduleTeardownTime:
      !block.lockedOnSchedule && block.teardownTimeDuration > 0
        ? {
            text: "Unschedule teardown time",
            name: "unschedule-teardown-time",
            onItem: onUnscheduleTeardownTime,
            cls: "pendo-unschedule-teardown-time-context-menu-item",
          }
        : false,
    editWorkOrderNotes: block.lockedOnSchedule ? false : editWorkOrderNotesMenuItem,
    viewWorkOrderDetails: {
      text: "View work order details",
      name: "view-work-order-details",
      onItem: viewWorkOrderDetails,
      cls: "pendo-view-work-order-details-context-menu-item",
    },
    flags: flagsMenuItem(onUpdateWorkOrder),
    fullyScheduleWorkOrder: block.lockedOnSchedule ? false : fullyScheduleWorkOrderMenuItem,
    unschedule: unscheduleMenuItem({ unscheduleBlock, unscheduleWorkOrder }),
    publishWorkBlock: canPublishWorkBlock ? publishWorkBlock : false,
    editEvent: false,
    deleteEvent: false,
  };
}
