import React, { Component } from "react";
import deepmerge from "deepmerge";
import omit from "lodash/omit";
import pick from "lodash/pick";
import { DEFAULT_ZOOM_HOURS, defaultZoomLevel } from "config/zoomConfig";
import LocalStorage from "utils/LocalStorage";
import SessionStorage from "utils/SessionStorage";
import DateTime from "utils/DateTime";
import { QuickNavigationOption } from "config/quickNavigationOptions";

const UIStateContext = React.createContext({});

const DEFAULT_UI_STATE = {
  scheduler: {
    visibleTimeRange: {
      startDate: DateTime.now().startOf("day").format(),
      endDate: DateTime.now().startOf("day").add(DEFAULT_ZOOM_HOURS, "hours").format(),
    },
    dataTimeRange: {
      startDate: DateTime.now().startOf("day").format(),
      endDate: DateTime.now().startOf("day").add(DEFAULT_ZOOM_HOURS, "hours").format(),
    },
    zoomLevel: defaultZoomLevel,
    quickNavigationOption: QuickNavigationOption.TODAY,
  },
  selectedWorkBlock: {
    id: null,
    selectedAt: null,
  },
  sidebarOpen: true,
  workOrderDetailsSectionsOpen: {
    scheduledBlocks: true,
    workOrderInformation: true,
    productionInformation: true,
  },
  slideOutState: {
    mode: "none",
    workOrderId: null,
  },
  workBlockModal: {
    productionDetailsOpen: false,
  },
};

const SESSIONS_STORAGE_VALUES = ["scheduler.visibleTimeRange", "scheduler.dataTimeRange"];

export class UIStateProvider extends Component {
  constructor(props) {
    super(props);

    const { sessionIdentifier } = props;
    this.dataStoreKey = `${sessionIdentifier}-uiState`;

    this.localStorage = new LocalStorage();
    this.sessionStorage = new SessionStorage();

    /* eslint-disable react/no-unused-state */
    this.state = deepmerge.all([
      DEFAULT_UI_STATE,
      omit(this.localStorage.read(this.dataStoreKey), SESSIONS_STORAGE_VALUES),
      pick(this.sessionStorage.read(this.dataStoreKey), SESSIONS_STORAGE_VALUES),
    ]);
    /* eslint-enable react/no-unused-state */

    this.currentState = this.state;
  }

  // Fetches current application state synchronously.
  // This is useful if you need to access current application state before setState async updates
  getCurrentState = () => this.currentState;

  updateState = (newState) => {
    const oldState = this.currentState;

    this.currentState = {
      ...oldState,
      ...newState,
    };

    this.localStorage.write(this.dataStoreKey, this.currentState);
    this.sessionStorage.write(this.dataStoreKey, this.currentState);

    this.setState(this.currentState);
  };

  contextValue = () => ({ state: this.state, setState: this.updateState, getState: this.getCurrentState });

  render() {
    return <UIStateContext.Provider value={this.contextValue()}>{this.props.children}</UIStateContext.Provider>;
  }
}

export default UIStateContext;
