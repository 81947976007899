import { Flex, Icon, Text, Tooltip } from "@nulogy/components";
import styled from "styled-components";
import React from "react";

const Label = styled(Flex)`
  align-items: center;
  transform: none !important;
  -webkit-transform: none !important;
`;

export default function LabelWithTooltip({ label, tooltip, tooltipPlacement = "bottom" }) {
  return (
    <Label>
      <Text>{label}</Text>
      <Tooltip placement={tooltipPlacement} tooltip={tooltip}>
        <Icon icon="help" color="darkBlue" size="16px" ml="half" />
      </Tooltip>
    </Label>
  );
}
