import React from "react";
import { Icon } from "@nulogy/components";
import BlockContainer from "./BlockContainer";
import Block from "./Block";
import BlockCellName from "./BlockCellName";
import LockIcon from "./LockIcon";
import Heading from "./Heading";
import BlockCellMenu from "./BlockCellMenu";
import DowntimeBlockDomain from "domain/DowntimeBlock";

export default function DowntimeBlock({ blockData, readonly }) {
  const {
    block: { id, lockedOnSchedule },
  } = blockData;

  const reasonDescription = DowntimeBlockDomain.reasonDescription(blockData.block);

  return (
    <BlockContainer readonly={readonly} className={`spec-downtime-${id}`}>
      <Block>
        <BlockCellName>
          <LockIcon>{lockedOnSchedule && <Icon icon="lock" size="14px" />}</LockIcon>
          <Heading>{reasonDescription}</Heading>
        </BlockCellName>
      </Block>
      <BlockCellMenu className="b-fa b-fa-ellipsis-v js-block-menu spec-downtime-menu" />
    </BlockContainer>
  );
}
