import { useContext } from "react";
import GlobalStateContext from "contexts/GlobalStateContext";

export default function useDeleteConfirmationModal() {
  const { state, setState: updateGlobalState } = useContext(GlobalStateContext);

  const setState = (args) => {
    const newState = { ...state.deleteConfirmationModalState, ...args };
    updateGlobalState({ deleteConfirmationModalState: newState });
  };

  return {
    open: (data) => setState({ isOpen: true, data }),
    close: () => setState({ isOpen: false }),
    isOpen: state.deleteConfirmationModalState.isOpen,
    data: state.deleteConfirmationModalState.data,
  };
}
