import React, { useContext } from "react";
import { DropdownButton } from "@nulogy/components";
import styled from "styled-components";
import useSyncSites from "hooks/apollo/syncWithPackmanager/useSyncSites";
import ConfirmModalContext from "contexts/ConfirmModalContext";
import Codenames from "policies/Codenames";

const SyncButton = styled(DropdownButton)`
  border-left: none;
`;

export default function SyncSites({ label, all = false }) {
  const { isSyncing, sync } = useSyncSites();
  const { confirmAction } = useContext(ConfirmModalContext);

  const syncAllSites = () => {
    const message = (
      <>
        <strong>
          You are about to sync all sites connected to the {Codenames.Packmanager}. Are you sure you want to proceed?
        </strong>
        <br />
        NOTE: This is a long running operation. Site update failures will be reported to Sentry.
      </>
    );
    confirmAction(message, "Sync all sites", () => sync({ syncAllSites: true }), "Sync all sites");
  };

  const doSync = all ? syncAllSites : sync;

  return (
    <SyncButton onClick={doSync} disabled={isSyncing}>
      {isSyncing ? "Syncing..." : label}
    </SyncButton>
  );
}
