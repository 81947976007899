import React from "react";
import styled from "styled-components";
import eventBuilderStyles from "domain/bryntum/eventBuilders.module.scss";

const Indicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  border-radius: 3px 0 0 0;
  overflow: hidden;
  svg {
    transform: rotate(180deg);
  }
`;

export default function NotesIndicator() {
  return (
    <Indicator>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none" className={eventBuilderStyles.workBlockIcon}>
        <polygon points="40,100 100,40 100,100" />
      </svg>
    </Indicator>
  );
}
