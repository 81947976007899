import { useQuery } from "@apollo/client";
import { GetItemMetadata } from "./item.gql";

export default function useGetItemMetadata() {
  const { data, loading, refetch } = useQuery(GetItemMetadata);

  return {
    data,
    loading,
    refetch,
  };
}
