import React, { useState } from "react";
import { Page, Tab, Tabs } from "@nulogy/components";
import Exports from "./Exports";
import Imports from "./Imports";

export default function ManageData() {
  const [tab, setTab] = useState(0);
  const onTabClick = (_event, index) => {
    setTab(index);
  };

  return (
    <Page title="Manage data">
      <Tabs selectedIndex={tab} onTabClick={onTabClick}>
        <Tab label="Imports">
          <Imports />
        </Tab>
        <Tab label="Exports">
          <Exports />
        </Tab>
      </Tabs>
    </Page>
  );
}
