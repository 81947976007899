import DateTime from "utils/DateTime";
import Block from "domain/Block";

export default function resizeToReschedule(onRescheduleBlocks) {
  return async ({ eventRecord, changed }) => {
    if (!changed) return;

    await eventRecord.project.commitAsync();

    const startsAt = eventRecord.data.startDate;
    const endsAt = eventRecord.data.endDate;

    const block = Block.updateBlockTime(eventRecord.data.block, {
      startsAt: DateTime.toISO8601(startsAt),
      endsAt: DateTime.toISO8601(endsAt),
    });

    onRescheduleBlocks([block]);
  };
}
