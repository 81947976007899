import { useContext } from "react";
import UIStateContext from "contexts/UIStateContext";

export const SLIDE_OUT_MODES = {
  HIDDEN: "none",
  FILTER: "filter",
  WORK_ORDER_DETAILS: "work order details",
};

export default function useSlideOutState() {
  const {
    state: { slideOutState },
    setState,
  } = useContext(UIStateContext);

  const openFilterSidebar = () => setState({ slideOutState: { mode: SLIDE_OUT_MODES.FILTER, workOrderId: null } });
  const openWorkOrderDetails = (workOrderId) =>
    setState({ slideOutState: { mode: SLIDE_OUT_MODES.WORK_ORDER_DETAILS, workOrderId } });
  const closeSlideOut = () => setState({ slideOutState: { mode: SLIDE_OUT_MODES.HIDDEN, workOrderId: null } });
  const isOpen = () => slideOutState.mode !== SLIDE_OUT_MODES.HIDDEN;
  const isFilterMode = () => slideOutState.mode === SLIDE_OUT_MODES.FILTER;
  const isWorkOrderMode = () => slideOutState.mode === SLIDE_OUT_MODES.WORK_ORDER_DETAILS;

  return {
    isOpen,
    workOrderId: slideOutState.workOrderId,
    openFilterSidebar,
    openWorkOrderDetails,
    closeSlideOut,
    isFilterMode,
    isWorkOrderMode,
  };
}
