import React from "react";
import { Icon } from "@nulogy/components";
import styled from "styled-components";
import isNil from "lodash/isNil";
import { formatDate } from "components/helpers/format";
import WorkBlock from "domain/WorkBlock";

const DueDateWrapper = styled.div`
  vertical-align: middle;
  line-height: 21px;
`;

const WorkBlockPastDue = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  line-height: 21px;
`;

const PastDueDate = styled.span`
  margin-left: 4px;
`;

const PastDue = ({ dueDate }) => (
  <WorkBlockPastDue>
    <Icon data-testid="icon" icon="error" size="11px" />
    <PastDueDate data-testid="due-date">Due {formatDueDate(dueDate)}</PastDueDate>
  </WorkBlockPastDue>
);

export default function DueDate({ workBlock }) {
  const workBlockPastDue = WorkBlock.isPastDue(workBlock);
  const dueDate = workBlock.workOrder.due;

  if (workBlockPastDue) {
    return PastDue({ dueDate });
  } else {
    return <DueDateWrapper data-testid="due-date">Due {formatDueDate(dueDate)}</DueDateWrapper>;
  }
}

function formatDueDate(dueDate) {
  if (isNil(dueDate)) return "N/A";

  return formatDate(dueDate);
}
