import { useMutation } from "@apollo/client";
import { UnscheduleWorkOrder } from "./workOrder.gql";
import { removeWorkBlocksForWorkOrder } from "hooks/apollo/workBlock/helpers/handleUpdateWorkBlocks";
import useFetchShiftInstances from "hooks/apollo/shiftInstance/useFetchShiftInstances";

export default function useUnscheduleWorkOrder() {
  const { refetch: refetchShiftInstances } = useFetchShiftInstances();
  const [unscheduleWorkOrder] = useMutation(UnscheduleWorkOrder, {
    onCompleted: () => {
      refetchShiftInstances();
    },
  });

  return ({ workOrderId }) =>
    unscheduleWorkOrder({
      variables: {
        id: workOrderId,
      },
      update: (proxy) => removeWorkBlocksForWorkOrder(workOrderId, proxy),
    });
}
