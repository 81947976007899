import React from "react";
import { useQuery } from "@apollo/client";
import SiteIndexView from "./SiteIndexView";
import { GetAssignedSites } from "hooks/apollo/site/site.gql";
import paths from "config/routePaths";
import Authorization from "policies/Authorization";

export default function SiteIndexContainer() {
  const { data: assignedSitesData, loading: loadingSites } = useQuery(GetAssignedSites);
  const policy = Authorization.usePolicy();

  if (loadingSites) return null;

  const canCreateSite = policy.canCreate(Authorization.EntityTypes.Site);
  const canUpdateCompany = policy.canUpdate(Authorization.EntityTypes.Company);
  const createSitePath = paths.siteNew;

  const { assignedSites } = assignedSitesData;

  return (
    <SiteIndexView
      assignedSites={assignedSites}
      canCreateSite={canCreateSite}
      canUpdateCompany={canUpdateCompany}
      createSitePath={createSitePath}
    />
  );
}
