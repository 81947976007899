import styled from "styled-components";

const LockIcon = styled.span`
  margin-right: 2px;
  position: relative;
  top: 2px;
  right: 1px;
`;

export default LockIcon;
