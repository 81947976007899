import React, { Component } from "react";

const GlobalStateContext = React.createContext({});

export class GlobalStateProvider extends Component {
  constructor(props) {
    super(props);

    /* eslint-disable react/no-unused-state */
    this.state = {
      bryntumState: {
        schedulerInstance: null,
      },
      createBlockModalState: {
        block: null,
        isOpen: false,
        eventRecord: null,
      },
      deleteConfirmationModalState: {
        isOpen: false,
        data: {},
      },
      editDowntimeModalState: {
        block: null,
        isOpen: false,
      },
      editNotesPopUpState: {
        workOrderId: null,
        workBlockId: null,
        isOpen: false,
      },
      editWorkBlockModalState: {
        block: null,
        isOpen: false,
      },
      notificationState: {
        title: "",
        message: "",
        type: "",
        isPresent: false,
      },
      publishScheduleModalState: {
        isOpen: false,
      },
      publishSummaryModalState: {
        isOpen: false,
        data: {},
      },
      publishWorkBlockModalState: {
        isOpen: false,
        workBlock: {},
      },
      showDowntimeModalState: {
        block: null,
        isOpen: false,
      },
      showWorkBlockModalState: {
        block: null,
        isOpen: false,
      },
      updateConfirmDefaultAvailabilityChangesModalState: {
        isOpen: false,
      },
      updateAvailabilityOverridesUnsavedChangesModalState: {
        isOpen: false,
        startDate: null,
        endDate: null,
      },
      updateWorkOrderDatesModalState: {
        isOpen: false,
      },
    };
    /* eslint-enable react/no-unused-state */

    this.currentState = this.state;
  }

  // Fetches current application state synchronously.
  // This is useful if you need to access current application state before setState async updates
  getCurrentState = () => this.currentState;

  updateState = (newState) => {
    const oldState = this.currentState;

    this.currentState = {
      ...oldState,
      ...newState,
    };

    this.setState(this.currentState);
  };

  contextValue = () => ({ state: this.state, setState: this.updateState, getState: this.getCurrentState });

  render() {
    return <GlobalStateContext.Provider value={this.contextValue()}>{this.props.children}</GlobalStateContext.Provider>;
  }
}

export default GlobalStateContext;
