import React from "react";
import { Button, ButtonGroup, PrimaryButton } from "@nulogy/components";

export default function ModalFormButtons({ formId, onClose, disabled, pendoTagClass }) {
  return (
    <ButtonGroup className="spec-modal-form-buttons">
      <PrimaryButton form={formId} type="submit" disabled={disabled} className={pendoTagClass}>
        Save
      </PrimaryButton>
      <Button onClick={onClose}>Cancel</Button>
    </ButtonGroup>
  );
}
