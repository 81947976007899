import React from "react";
import { Box, Flex, Heading2, Page, PrimaryButton } from "@nulogy/components";
import Codenames from "policies/Codenames";
import UnstyledLink from "components/common/UnstyledLink";
import IndexTable from "components/common/IndexTable";
import paths from "config/routePaths";
import { formatDateTime } from "components/helpers/format";

function renderColumns(canUpdateCompany) {
  return [
    {
      label: "Company name",
      dataKey: "companyName",
      cellRenderer: ({ row }) => companyCellRenderer({ row, canUpdateCompany }),
      numeric: true,
    },
    { label: "Site name", dataKey: "name", cellRenderer: siteCellRenderer, numeric: true },
    { label: "Time zone", dataKey: "timeZoneDisplayName" },
    { label: "Last synced", dataKey: "lastSyncedAt", cellFormatter: formatTime },
    { label: "Created", dataKey: "createdAt", cellFormatter: formatTime },
    { label: "Last updated", dataKey: "updatedAt", cellFormatter: formatTime },
    { label: "UUID", dataKey: "id" },
    { label: `${Codenames.Packmanager} site UUID`, dataKey: "externalId" },
    { label: `${Codenames.Packmanager} account UUID`, dataKey: "externalAccountUuid" },
  ];
}

function formatTime({ cellData }) {
  return formatDateTime(cellData);
}

function companyCellRenderer({ row, canUpdateCompany }) {
  if (canUpdateCompany) {
    return (
      <UnstyledLink className="spec-company-name" href={paths.companyEdit(row.companyId)}>
        {row.companyName}
      </UnstyledLink>
    );
  }

  return <div className="spec-company-name">{row.companyName}</div>;
}

function siteCellRenderer({ row }) {
  return <UnstyledLink href={paths.siteEdit(row.id)}>{row.name}</UnstyledLink>;
}

function flattenSites(sites) {
  return sites.map((site) => ({ ...site, companyId: site.company?.id, companyName: site.company?.name }));
}

export default function SiteIndexView({ assignedSites, canCreateSite, canUpdateCompany, createSitePath }) {
  return (
    <Page>
      <Flex className="spec-site-index-page">
        <Heading2 paddingRight="x4" data-testid="title">
          Sites
        </Heading2>
        {canCreateSite && (
          <Box>
            <PrimaryButton asLink type="button" size="small" href={createSitePath} marginTop="x1">
              Create site
            </PrimaryButton>
          </Box>
        )}
      </Flex>
      <IndexTable
        columns={renderColumns(canUpdateCompany)}
        rows={flattenSites(assignedSites)}
        keyField="id"
        initialSortColumn="name"
        data-testid="sites-table"
      />
    </Page>
  );
}
