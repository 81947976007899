import { theme } from "@nulogy/components";

export default class LineHighlighter {
  constructor() {
    this.lineListCache = null;
  }

  start(currentLineId) {
    if (!this.lineListCache) {
      this.lineListCache = document.querySelector(".b-grid-subgrid").querySelectorAll(".line-label");
    }

    this.lineListCache.forEach((e) => {
      if (currentLineId && e.classList.contains(`line-${currentLineId}`)) {
        this._addHighlight(e);
      } else {
        this._removeHighlight(e);
      }
    });
  }

  stop() {
    if (this.lineListCache) {
      this.lineListCache.forEach((e) => this._removeHighlight(e));
      this.lineListCache = null;
    }
  }

  _addHighlight(e) {
    e.style.backgroundColor = theme.colors.lightGrey;
  }

  _removeHighlight(e) {
    e.style.backgroundColor = "";
  }
}
