import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import WorkOrderDetailsView from "./WorkOrderDetailsView";
import useSlideOutState from "hooks/state/useSlideOutState";
import UIStateContext from "contexts/UIStateContext";
import { GetWorkOrder } from "hooks/apollo/workOrder/workOrder.gql";
import Authorization from "policies/Authorization";

export default function WorkOrderDetailsContainer() {
  const slideOutState = useSlideOutState();
  const visible = slideOutState.isWorkOrderMode();

  const { setState: updateUIState, getState: getUIState } = useContext(UIStateContext);

  const { workOrderDetailsSectionsOpen } = getUIState();
  const toggleSectionState = (sectionKey) => () => {
    const { workOrderDetailsSectionsOpen: currentState } = getUIState();
    const state = currentState[sectionKey];
    const newState = { ...currentState, [sectionKey]: !state };
    updateUIState({ workOrderDetailsSectionsOpen: newState });
  };

  const policy = Authorization.usePolicy();
  const canUpdateWorkOrder = policy.canUpdate(Authorization.EntityTypes.WorkOrder);

  const { data, loading } = useQuery(GetWorkOrder, {
    fetchPolicy: "network-only",
    variables: { id: slideOutState.workOrderId },
    skip: !slideOutState.workOrderId,
  });

  const workOrder = data?.workOrder;

  if (loading || !workOrder || !visible) return null;

  return (
    <WorkOrderDetailsView
      readonly={!canUpdateWorkOrder}
      workOrder={workOrder}
      toggleSectionState={toggleSectionState}
      initialSectionOpenState={workOrderDetailsSectionsOpen}
    />
  );
}
