import React, { useState } from "react";
import { Alert, Box, Flex, Heading3 } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import DowntimeReasonSelect from "./DowntimeReasonSelect";
import BlockModal from "components/pages/schedule/styles/BlockModal";
import ModalFormButtons from "components/controls/ModalFormButtons";
import useDowntimeEditModal from "hooks/state/useDowntimeEditModal";
import useRescheduleBlocks from "hooks/apollo/blocks/useRescheduleBlocks";
import Form from "components/controls/Form";
import LockToggle from "components/controls/LockToggle";
import Select from "components/controls/Select";
import DatePicker from "components/controls/DatePicker";
import { GetAllLines } from "hooks/apollo/line/line.gql";

export default function EditDowntimeBlockModal() {
  const { block: blockData, isOpen, close } = useDowntimeEditModal();

  const { data: linesData, loading } = useQuery(GetAllLines, { variables: { activeOnly: true } });

  const rescheduleBlocks = useRescheduleBlocks();
  const [error, setError] = useState(false);

  if (!blockData || loading) return null;

  const { block } = blockData;
  const { lines } = linesData;

  const initialValues = {
    downtimeBlock: block,
  };

  const validate = (data) => {
    setError(new Date(data.downtimeBlock.startsAt) >= new Date(data.downtimeBlock.endsAt));
  };

  const handleSave = (data) =>
    new Promise(() => {
      rescheduleBlocks([{ ...data.downtimeBlock, __typename: "DowntimeBlock" }]);
      close();
    });

  const lineOptions = lines.map((line) => ({ label: line.name, value: line.id }));

  return (
    <BlockModal
      title="Edit downtime block"
      isOpen={isOpen}
      onRequestClose={close}
      className="spec-downtime-edit-modal"
      footerContent={
        <ModalFormButtons
          formId="editDowntime"
          onClose={close}
          disabled={error}
          pendoTagClass="pendo-edit-downtime-modal-save"
        />
      }
    >
      <Form id="editDowntime" validate={validate} initialValues={initialValues} onSave={handleSave}>
        {({
          values: {
            downtimeBlock: { lockedOnSchedule, reason, reasonCode },
          },
        }) => (
          <>
            <Flex justifyContent="space-between" alignItems="center" paddingY="15px">
              <Box>
                <Heading3 className="spec-scheduling-header">Scheduling details</Heading3>
              </Box>
              <Box>
                <LockToggle field="downtimeBlock[lockedOnSchedule]" />
              </Box>
            </Flex>
            {error ? (
              <Alert type="danger" marginBottom="16px">
                End time must be after start time
              </Alert>
            ) : (
              ""
            )}
            <DowntimeReasonSelect downtimeBlock={{ reasonCode, reason }} lockedOnSchedule={lockedOnSchedule} />
            <Box marginBottom="x2" data-testid="line-select">
              <Select
                className="spec-line-select"
                field="downtimeBlock[line][id]"
                labelText="Line"
                options={lineOptions}
                disabled={lockedOnSchedule}
              />
            </Box>
            <Box marginBottom="x2">
              <Flex className="customDatePicker">
                <Box width={1 / 2} marginRight="x3">
                  <DatePicker
                    field="downtimeBlock[startsAt]"
                    labelText="Starts at"
                    className="spec-startsAt"
                    disabled={lockedOnSchedule}
                  />
                </Box>
                <Box width={1 / 2} marginLeft="x3">
                  <DatePicker
                    field="downtimeBlock[endsAt]"
                    labelText="Ends at"
                    className="spec-endsAt"
                    disabled={lockedOnSchedule}
                  />
                </Box>
              </Flex>
            </Box>
          </>
        )}
      </Form>
    </BlockModal>
  );
}
