import React from "react";
import { Box, Page } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import { GetVersion } from "hooks/apollo/version.gql";

export default function Version() {
  const { data, loading } = useQuery(GetVersion);

  return (
    <Page className="spec-shifts-index-page">
      {loading && <Box>Loading...</Box>}
      {data && <Box>{data.version} </Box>}
    </Page>
  );
}
